/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';

import { getRecoveryMode } from '~frontendRoot/ducks/backup';

import Message from '../Message';


const RecoveryStatusBar = () => {
    const recoveryMode: boolean = useSelector(getRecoveryMode);
    if (!recoveryMode) {
        return null;
    }
    return (
        <div
            className="recoveryStatusBar"
        >
            <Message message="navbar:recovery.active" />
        </div>
    );
};

export default RecoveryStatusBar;
