/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';
import { call, put, select, StrictEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { createSelectorArrayOfObjectsShallow } from '~frontendLib/reduxUtils';
import { createNotification } from '~frontendRoot/lib/reactUtils';
import { axiosInstanceWithCompression } from '~frontendLib/axiosInstance';
import { HlcfgSchemaJSON, HlcfgSchemaTS } from '~frontendTypes/externalTypes';
import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg';
import { setInitDiffCards } from '~frontendDucks/activeDiffersCards';
import { undoDiff } from '~sharedLib/hlcfg/undoDiff/undoDiff';
import { ShallowArrMap } from '~commonLib/ShallowArrMap';
import { createPathGetter } from '~commonLib/objectUtils';
import { HLCFG_OFF } from '~sharedRoot/constants';
import { HlcfgPath, HlcfgVerificationTranslatedItem } from '~sharedLib/types';
import { notEmpty } from '~commonLib/arrayUtils';
import { selectSaga } from '~commonLib/sagaWrapper/sagaWrapper';
import { backendPost } from '~frontendLib/backendApiCalls';
import { flushableDebounce } from '~frontendRoot/lib/effectCreators';

import { GLCFG_WATCHER_INTERVAL, GLCFG_SETTER_PREFIX } from '../../constants';
import { getApiError } from '../../lib/apiUtils';
import {
    computeGlcfgSetters,
    schemaHlcfgToGlcfg,
    transformGlcfgToHlcfg,
    transformHlcfgToGlcfg,
} from '../../lib/glcfgTransformers';
import {
    getGlcfgTree,
    getState,
} from './glcfgGettersAndSetters';
import { recoverySuccess } from '../backup';
import { setInitialCards } from '../activeCards';


export * from './glcfgGettersAndSetters';
export * from './normalizeTableGettersAndSetters';
export * from './packetFilterGettersAndSetters';
export * from './namedObjectsGettersAndSetters';

// actions
const TREE_LOAD_REQUEST = 'ak/hlcfgEditor/TREE_LOAD_REQUEST';
const TREE_LOAD_SUCCESS = 'ak/hlcfgEditor/TREE_LOAD_SUCCESS';
const TREE_LOAD_ERROR = 'ak/hlcfgEditor/TREE_LOAD_ERROR';
export const SET_INIT_GLCFG_TREE = 'ak/hlcfgEditor/SET_INIT_GLCFG_TREE';

const SCHEMA_LOAD_REQUEST = 'ak/hlcfgEditor/SCHEMA_LOAD_REQUEST';
const SCHEMA_LOAD_SUCCESS = 'ak/hlcfgEditor/SCHEMA_LOAD_SUCCESS';
const SCHEMA_LOAD_ERROR = 'ak/hlcfgEditor/SCHEMA_LOAD_ERROR';

const SCHEMA_TRANSFORM_REQUEST = 'ak/hlcfgEditor/SCHEMA_TRANSFORM_REQUEST';

const TREE_DIRTY = 'ak/hlcfg/TREE_DIRTY';
const TREE_STORE_REQUEST = 'ak/hlcfgEditor/TREE_STORE_REQUEST';
export const TREE_STORE_SUCCESS = 'ak/hlcfgEditor/TREE_STORE_SUCCESS';
const TREE_STORE_ERROR = 'ak/hlcfgEditor/TREE_STORE_ERROR';
const TREE_CHANGES = 'ak/hlcfgEditor/TREE_CHANGES';


const RESET_GLCFG_VALUE = 'ak/hlcfgEditor/RESET_GLCFG_VALUE';
const RESET_GLCFG_PACKET_FILTER_RULE = 'ak/hlcfgEditor/RESET_GLCFG_PACKET_FILTER_RULE';
const RESET_GLCFG_RULE = 'ak/hlcfgEditor/RESET_GLCFG_RULE';
const RESET_GLCFG_CUSTOM_RULE = 'ak/hlcfgEditor/RESET_GLCFG_CUSTOM_RULE';
const RESET_GLCFG_RULE_SET = 'ak/hlcfgEditor/RESET_GLCFG_RULE_SET';
const RESET_GLCFG_NETWORK_INTERFACES_NAMES = 'ak/hlcfgEditor/RESET_GLCFG_NETWORK_INTERFACES_NAMES';
const RESET_GLCFG_VALUE_ROUTING_TABLE = 'ak/hlcfgEditor/RESET_GLCFG_VALUE_ROUTING_TABLE';
const RESET_GLCFG_RULE_ROUTING_TABLE = 'ak/hlcfgEditor/RESET_GLCFG_RULE_ROUTING_TABLE';
const RESRT_GLCFG_SURICATA_VARIABLE = '/ak/hlcfgEditor/RESRT_GLCFG_SURICATA_VARIABLE';

const SET_SYNC_CLUSTER_AFTER_APPLY = '/ak/hlcfgEditor/SET_SYNC_CLUSTER_AFTER_APPLY';
const RESET_GLCFG_TREE = '/ak/hlcfgEditor/RESET_GLCFG_TREE';
const RESET_GLCFG_TREE_INIT = '/ak/hlcfgEditor/RESET_GLCFG_TREE_INIT';
const GLCFG_CHANGED = '/ak/hlcfgEditor/GLCFG_CHANGED';
const GLCFG_PUT_HLCFG_NOW = '/ak/hlcfgEditor/GLCFG_PUT_HLCFG_NOW';

const CHANGE_DIFF_OPEN = '/ak/hlcfgEditor/CHANGE_DIFF_OPEN';
const UNDO_DIFFER = '/ak/hlcfgEditor/UNDO_DIFFER';
const REMOVE_DIFF = '/ak/hlcfgEditor/REMOVE_DIFF';
const RESET_SESSION_HLCFG = 'ak/hlcfgEditor/RESET_SESSION_HLCFG';
const NEEDS_RESET_SESSION_HLCFG = 'ak/hlcfgEditor/NEEDS_RESET_SESSION_HLCFG';


type initialStateType = {
    glcfgTree: any,
    glcfgSchema: any,
    hlcfgSchema: HlcfgSchemaJSON | Record<string, never>,
    incomprehensibleHlcfgNodes: any,
    initGlcfgTree: any,
    isSchemaLoading: boolean,
    isTreeLoading: boolean,
    isTreeStoring: boolean,
    treeChanges: number,
    storingError: any,
    schemaLoadingError: any,
    treeLoadingError: any,
    verificationErrors: HlcfgVerificationTranslatedItem[],
    verificationWarnings: HlcfgVerificationTranslatedItem[],
    syncClusterAfterApply: boolean,
    diffs: HlcfgDiff[],
    diffOpen: boolean,
    openFromChange: boolean,
    needsSessionReset: boolean,
}

// initial state
const initialState: initialStateType = {
    glcfgTree: {},
    glcfgSchema: {},
    hlcfgSchema: {},
    incomprehensibleHlcfgNodes: [], // values not understood by GUI
    initGlcfgTree: {},
    isSchemaLoading: false,
    isTreeLoading: false,
    isTreeStoring: false,
    treeChanges: 0,
    storingError: null,
    schemaLoadingError: null,
    treeLoadingError: null,
    verificationErrors: [],
    verificationWarnings: [],
    syncClusterAfterApply: false,
    diffs: [],
    diffOpen: false,
    openFromChange: false,
    needsSessionReset: false
};


// reducers
const glcfgReduce = (glcfgTree, action, initGlcfgTree, schema) => {
    const glcfgSetter = GLCFG_SETTERS[action.type];
    if (glcfgSetter) {
        const { glcfgKey, setter } = glcfgSetter;
        const newValue = setter ? setter({
            state: glcfgTree[glcfgKey],
            action,
            initValue: initGlcfgTree[glcfgKey],
            schema
        }) : action.payload;
        return (
            {
                ...glcfgTree,
                [glcfgKey]: newValue,
            }
        );
    }
    return glcfgTree;

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case TREE_LOAD_REQUEST:
        return { ...state, isTreeLoading: true, treeLoadingError: null, storingError: null };
    case TREE_LOAD_SUCCESS: {
        const { glcfgTree, incomprehensibleHlcfgNodes } = transformHlcfgToGlcfg(action.payload);
        return { ...state, isTreeLoading: false, glcfgTree, incomprehensibleHlcfgNodes };
    }
    case SET_INIT_GLCFG_TREE: {
        const initGlcfgTree = transformHlcfgToGlcfg(action.payload).glcfgTree;
        return { ...state, initGlcfgTree };
    }
    case SET_SYNC_CLUSTER_AFTER_APPLY:
        return { ...state, syncClusterAfterApply: action.payload };
    case TREE_LOAD_ERROR:
        return { ...state, isTreeLoading: false, treeLoadingError: action.payload };
    case SCHEMA_LOAD_REQUEST:
        return { ...state, isSchemaLoading: true, schemaLoadingError: null };
    case SCHEMA_LOAD_SUCCESS:
        return { ...state, isSchemaLoading: false, hlcfgSchema: action.payload };
    case SCHEMA_LOAD_ERROR:
        return { ...state, isSchemaLoading: false, schemaLoadingError: action.payload };
    case SCHEMA_TRANSFORM_REQUEST:
        return { ...state, glcfgSchema: schemaHlcfgToGlcfg(action.hlcfgSchemaTree) };
    case TREE_DIRTY:
        return { ...state, isTreeDirty: true };
    case TREE_STORE_REQUEST:
        return { ...state, isTreeStoring: true, storingError: null };
    case TREE_STORE_SUCCESS:
        return {
            ...state,
            isTreeStoring: false,
            isTreeDirty: false,
            verificationErrors: action.errors,
            verificationWarnings: action.warnings,
            diffs: action.diffs
        };
    case TREE_STORE_ERROR:
        return { ...state, isTreeStoring: false, isTreeDirty: false, storingError: action.payload };
    case TREE_CHANGES:
        return { ...state, treeChanges: action.changes };
    case RESET_GLCFG_TREE_INIT:
        return {
            ...state,
            treeChanges: 0,
            verificationErrors: [],
            verificationWarnings: [],
            diffs: [],
        };
    case NEEDS_RESET_SESSION_HLCFG:
        return {
            ...state,
            needsSessionReset: action.payload
        };
    case REMOVE_DIFF:
        return {
            ...state,
            diffs: action.diffs
        };
    case CHANGE_DIFF_OPEN:
        return {
            ...state,
            diffOpen: action.payload,
            openFromChange: action.fromChange
        };
    default:
        return { ...state, glcfgTree: glcfgReduce(state.glcfgTree, action, state.initGlcfgTree, state.glcfgSchema) };
    }
};

export default reducer;

/**
 * An object that describes a single glcfg node.
 *
 * @typedef {object} GlcfgObjDef
 * @property {string} hlcfgPath - path in the high-level configuration, separated by dots
 * @property {Function} [hlcfgToGlcfg] - Function that transforms hlcfg to glcfg. When undefined, identity is used. This
 * function has to be a pure function (no side effects, not modifying the parameters).
 * @property {Function} [glcfgToHlcfg] - Function that transforms glcfg to hlcfg. When undefined, identity is used. This
 * function has to be a pure function (no side effects, not modifying the parameters).
 */


/**
 * GUI-level configuration definition structure. Key is a glcfg node name, value is a definition structure.
 *
 * @type {Object<string, GlcfgObjDef>}
 */
const GLCFG_SETTERS = computeGlcfgSetters(GLCFG_SETTER_PREFIX);


// data accessors
export const getIncomprehensibleHlcfgNodes = rootState => getState(rootState).incomprehensibleHlcfgNodes;

export const getIsTreeStoring = rootState => getState(rootState).isTreeStoring;

export const getVerificationErrors = createSelectorArrayOfObjectsShallow(
    rootState => getState(rootState).verificationErrors,
    errors => errors
);


export const getVerificationWarnings = createSelectorArrayOfObjectsShallow(
    rootState => getState(rootState).verificationWarnings,
    warnings => warnings
);

export const getIsTreeDirty = rootState => getState(rootState).isTreeDirty;
export const getTreeChanges = rootState => getState(rootState).treeChanges;
export const getSyncClusterAfterApply = rootState => getState(rootState).syncClusterAfterApply;
export const getIsTreeStoringError = rootState => getState(rootState).storingError;
export const getHlcfgDiff = (rootState): HlcfgDiff[] => getState(rootState).diffs;
export const getHlcfgDiffNumber = rootState => getState(rootState).diffs?.length;
export const getHlcfgDiffOpen = rootState => getState(rootState).diffOpen;
export const getHlcfgOpenFromChange = rootState => getState(rootState).openFromChange;
export const getHlcfgSchema = rootState => getState(rootState).hlcfgSchema;
export const getHlcfg = rootState => getState(rootState).hlcfgSchema;
export const getNeedsSessionReset = rootState => getState(rootState).needsSessionReset;


// action creators
export const treeLoadRequest = () => ({ type: TREE_LOAD_REQUEST });

export const treeDirty = () => ({ type: TREE_DIRTY });

export const treeLoadSuccess = hlcfgTree => ({ type: TREE_LOAD_SUCCESS, payload: hlcfgTree });
export const treeLoadError = error => ({ type: TREE_LOAD_ERROR, payload: error });
export const setInitGlcfgTree = initHlcfgTree => ({ type: SET_INIT_GLCFG_TREE, payload: initHlcfgTree });

export const schemaLoadRequest = () => ({ type: SCHEMA_LOAD_REQUEST });
export const schemaLoadSuccess = hlcfgSchemaTree => ({ type: SCHEMA_LOAD_SUCCESS, payload: hlcfgSchemaTree });
export const schemaLoadError = error => ({ type: SCHEMA_LOAD_ERROR, payload: error });

export const schemaTransformRequest = hlcfgSchemaTree => ({ type: SCHEMA_TRANSFORM_REQUEST, hlcfgSchemaTree });

export const treeStoreRequest = () => ({ type: TREE_STORE_REQUEST });
export const treeStoreSuccess = (errors, warnings, diffs) => ({ type: TREE_STORE_SUCCESS, errors, warnings, diffs });
export const treeStoreError = error => ({ type: TREE_STORE_ERROR, payload: error });

export const treeChanges = (changes) => ({ type: TREE_CHANGES, changes });
export const setSyncClusterAfterApply = (payload) => ({ type: SET_SYNC_CLUSTER_AFTER_APPLY, payload });
export const setChangeDiffOpen = (payload: boolean, fromChange?: boolean) =>
    ({ type: CHANGE_DIFF_OPEN, payload, fromChange });


// data accessors
const getItemVerificationIssues = (verificationIssues, hlcfgPath) =>
    verificationIssues.filter(
        issueObject =>
            issueObject.hlcfgPaths.filter(
                issueHlcfgPath =>
                    issueHlcfgPath.indexOf(hlcfgPath) === 0
            ).length
    );

export const getItemVerificationErrors = (rootState, glcfgSchemaNode) =>
    glcfgSchemaNode ?
        getItemVerificationIssues(getVerificationErrors(rootState), glcfgSchemaNode.hlcfgPath) :
        [];

export const getItemVerificationWarnings = (rootState, glcfgSchemaNode) =>
    glcfgSchemaNode ?
        getItemVerificationIssues(getVerificationWarnings(rootState), glcfgSchemaNode.hlcfgPath) :
        [];


export const resetGlcfgValue = glcfgKey => ({ type: RESET_GLCFG_VALUE, glcfgKey });
export const resetGlcfgRule = key => ({ type: RESET_GLCFG_RULE, key });
export const resetGlcfgCustomRule = sid => ({ type: RESET_GLCFG_CUSTOM_RULE, sid });
export const resetGlcfgPacketFilterRule = position => ({ type: RESET_GLCFG_PACKET_FILTER_RULE, position });
export const resetGlcfgValueRoutingTable = (newItem, oldItem, key) =>
    ({ type: RESET_GLCFG_VALUE_ROUTING_TABLE, newItem, oldItem, key });
export const resetGlcfgRuleRoutingTable = (position, deleted) =>
    ({ type: RESET_GLCFG_RULE_ROUTING_TABLE, position, deleted });
export const resetGlcfgRuleSet = key => ({ type: RESET_GLCFG_RULE_SET, key });
export const resetGlcfgNetworkInterfacesNames = key => ({ type: RESET_GLCFG_NETWORK_INTERFACES_NAMES, key });
export const resetGlcfgSuricataVariable = (group, name) => ({ type: RESRT_GLCFG_SURICATA_VARIABLE, group, name });
export const resetAllChanges = () => ({ type: RESET_GLCFG_TREE });
export const glcfgChanged = () => ({ type: GLCFG_CHANGED });
export const resetAllChangesToInit = () => ({ type: RESET_GLCFG_TREE_INIT });
export const undoDiffFunction = (diff: HlcfgDiff) => ({ type: UNDO_DIFFER, diff });
export const removeDiff = (diffs: HlcfgDiff[]) => ({ type: REMOVE_DIFF, diffs });
export const resetSessionHlcfgRequest = () => ({ type: RESET_SESSION_HLCFG });
export const needsResetSessionHlcfg = (needsReset) => ({ type: NEEDS_RESET_SESSION_HLCFG,
    payload: needsReset });

// API endpoints
export const loadHlcfg = async () =>
    Axios.get('/api/hlcfg/tree');

export const storeHlcfg = async (hlcfgTree, axios = axiosInstanceWithCompression) =>
    axios.put('/api/hlcfg/tree', hlcfgTree);

export const loadInitHlcfg = async () =>
    Axios.get('/api/hlcfg/initHlcfg');

export const resetSessionHlcfg = backendPost('/cfg/resetSessionHlcfg');

// side effects
const workerGetHlcfg = function* () {
    try {
        const { data } = yield call(loadHlcfg);
        yield put(treeLoadSuccess(data));
    } catch (error) {
        yield put(treeLoadError(getApiError(error)));
    }
};

const workerResetSessionHlcfg = function* () {
    try {
        yield call(resetSessionHlcfg, {});
        yield sessionStorage.clear();
        yield put(recoverySuccess(false));
        yield put(resetAllChangesToInit());
        yield put(needsResetSessionHlcfg(false));
    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger' });
        yield put(treeLoadError(getApiError(error)));
    }
};

export const workerSerializeHlcfg = function* () {
    const glcfgTree = yield select(getGlcfgTree);
    const incomprehensibleHlcfgNodes = yield select(getIncomprehensibleHlcfgNodes);
    const hlcfgTree = transformGlcfgToHlcfg(glcfgTree, incomprehensibleHlcfgNodes);
    return hlcfgTree;
};

const workerPutHlcfg = function * () {
    const hlcfgTree = yield* workerSerializeHlcfg();
    yield put(treeStoreRequest());
    try {
        const { data: { errors, warnings, diffs } } = yield call(storeHlcfg, hlcfgTree);
        yield put(treeStoreSuccess(errors, warnings, diffs));
    } catch (error) {
        yield put(treeStoreError(getApiError(error)));
    }
};
export const workerGlcfgHasChanged = function* () {
    yield put(treeDirty());
    yield put(glcfgChanged());
};


const workerCalcGlcfgChanges = function* () {


};

const workerSetInitHlcfgTree = function* (hlcfgTree) {
    yield put(setInitGlcfgTree(hlcfgTree));
};

export const workerSetBothHlcfgs = function* () {
    try {
        const { data } = yield call(loadHlcfg);
        yield put(treeLoadSuccess(data));
        yield* workerSetInitHlcfgTree(data);
        yield put(setInitDiffCards());
        yield* workerGlcfgHasChanged();
        yield put(needsResetSessionHlcfg(false));

    } catch (error) {
        yield put(treeLoadError(getApiError(error)));
    }
};

const relatedDiffsMap = new ShallowArrMap<HlcfgPath, HlcfgPath[]>();
// This way of constructing the Map is hacking. If we require this mechanism for more than 2 paths,
// refactor so it is constructed differently (probably from schema)
const hlcfgPathGet = createPathGetter<HlcfgSchemaTS>();
relatedDiffsMap.set([ ...hlcfgPathGet.protection.honeypot.getPath(), HLCFG_OFF ], [
    hlcfgPathGet.tables.nftRule['nftRule:_HONEYPOT'][HLCFG_OFF].getPath()
]);

export const workerUndoDiffer =
    function* (payload: ReturnType<typeof undoDiffFunction>): Generator<StrictEffect, any, any> {
        try {
            const diffs = yield* selectSaga(getHlcfgDiff);
            const currentDiffs = new ShallowArrMap<HlcfgPath, HlcfgDiff>();
            diffs.forEach(diff => currentDiffs.set(diff.hlcfgRealPath, diff));

            const relatedDiffPaths = [ ...relatedDiffsMap.get(payload.diff.hlcfgRealPath) || [] ];
            const diffsToUndo = [
                payload.diff,
                ...relatedDiffPaths.map(diffPath => currentDiffs.get(diffPath))
            ].filter(notEmpty);


            const diffsRemoved = new ShallowArrMap<HlcfgPath, boolean>();
            diffsToUndo.forEach(diff => diffsRemoved.set(diff.hlcfgRealPath, true));

            const newDiffs = diffs.filter(diff => !diffsRemoved.has(diff.hlcfgRealPath));

            yield put(removeDiff(newDiffs));

            const newHlcfg = yield call(workerUndoDiffs, diffsToUndo);

            yield put(treeLoadSuccess(newHlcfg));
            yield* workerGlcfgHasChanged();

        } catch (error) {
            createNotification({
                title: 'modalWindows:ChangesConfirmationModal.undo.error',
                desc: getApiError(error).message,
                type: 'danger'
            });
        }
    };

export const workerUndoDiffs = function* (diffsToUndo: HlcfgDiff[]) {
    const hlcfgTree = yield call(workerSerializeHlcfg);
    const { data: initHlcfg } = yield call(loadInitHlcfg);


    return diffsToUndo.reduce((acc, diff) => {
        return undoDiff(initHlcfg, acc, diff);
    }, hlcfgTree);
};

export const workerResetBothHlcfgs = function* () {
    try {
        const { data } = yield call(loadInitHlcfg);
        yield put(treeLoadSuccess(data));
        yield put(setInitialCards(data));
        yield* workerSetInitHlcfgTree(data);
        yield put(setInitDiffCards());
        yield* workerGlcfgHasChanged();
        yield createNotification({
            title: 'modalWindows:ChangesConfirmationModal.reverse.success',
            desc: '',
            type: 'success'
        });

    } catch (error) {
        yield createNotification({
            title: 'modalWindows:ChangesConfirmationModal.reverse.error',
            desc: getApiError(error).message,
            type: 'danger'
        });
        yield put(treeLoadError(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(Object.keys(GLCFG_SETTERS), workerGlcfgHasChanged),
    takeLatest(TREE_LOAD_REQUEST, workerGetHlcfg),
    takeLatest(TREE_STORE_SUCCESS, workerCalcGlcfgChanges),
    takeLatest(RESET_GLCFG_VALUE, workerGlcfgHasChanged),
    takeLatest(RESRT_GLCFG_SURICATA_VARIABLE, workerGlcfgHasChanged),
    takeLatest(RESET_GLCFG_TREE, workerSetBothHlcfgs),
    takeEvery(UNDO_DIFFER, workerUndoDiffer),
    takeLatest(RESET_GLCFG_TREE_INIT, workerResetBothHlcfgs),
    takeLatest(RESET_SESSION_HLCFG, workerResetSessionHlcfg),
    flushableDebounce(
        GLCFG_WATCHER_INTERVAL, GLCFG_CHANGED, GLCFG_PUT_HLCFG_NOW, workerPutHlcfg
    ),
];
