/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * Renders all global modal windows.
 *
 * Modal windows that use a Redux Saga generally should not be used on multiple places because then multiple instances
 * of the same modal window could be rendered.
 */


import { createLazyWithSuspense } from '~frontendLib/createLazy';


const ModalWindows = createLazyWithSuspense(() => import('./ModalWindows'));
export default ModalWindows;
