/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {  MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon } from '~frontendComponents/Generic';


@withTranslation()
class Modal extends Component {
    static get propTypes() {
        return {
            modalOpen: PropTypes.bool,
            className: PropTypes.string,
            datacy: PropTypes.string,
            classNameBody: PropTypes.string,
            exitHandle: PropTypes.func,
            positiveResponse: PropTypes.func,
            negativeResponse: PropTypes.func,
            size: PropTypes.string,
            t: PropTypes.func,
            children: PropTypes.node,
            position: PropTypes.string.isRequired,
            headerText: PropTypes.string,
            bodyText: PropTypes.string,
            bodyParams: PropTypes.object,
            headerParams: PropTypes.object,
            body: PropTypes.node,
            headerClose: PropTypes.bool,
            clickOutsideClose: PropTypes.bool,
            error: PropTypes.object,
            dontCloseOnAction: PropTypes.bool,
            isLoading: PropTypes.bool,
            withoutNegative: PropTypes.bool,
            positiveText: PropTypes.string,
            header: PropTypes.node,
            negativeAsPrimary: PropTypes.bool,
            negativeText: PropTypes.string,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        };
    }

    componentDidMount() {
        const { modalOpen } = this.props;
        this.setState({
            modalOpen: modalOpen
        });
    }

    componentDidUpdate(prevProps) {
        const { modalOpen } = this.props;
        if (modalOpen !== prevProps.modalOpen) {
            this.setState({
                modalOpen: modalOpen
            });
        }
    }

    closeModal = () => {
        const { exitHandle } = this.props;
        this.setState({
            modalOpen: false
        });
        exitHandle(false);
    };

    render() {
        const { positiveResponse, className, negativeResponse,
            position, headerText, bodyText, t, children, datacy,
            classNameBody, size, bodyParams, headerParams, body, headerClose,
            clickOutsideClose, error,  dontCloseOnAction, isLoading, positiveText,
            withoutNegative, header, negativeAsPrimary, negativeText } = this.props;
        const { modalOpen } = this.state;
        return (
            <MDBModal
                className={className}
                data-cy="modal"
                isOpen={modalOpen}
                position={position}
                side
                size={size || 'md'}
                toggle={clickOutsideClose ? this.closeModal : () => null}
            >
                {headerText ?
                    <MDBModalHeader toggle={headerClose || error?.code  ?  this.closeModal : null}>
                        {t(headerText, headerParams)}
                    </MDBModalHeader> :
                    null}
                {header ? header : null}
                {bodyText || body ?
                    <MDBModalBody className={classNameBody}>
                        {bodyText ? t(bodyText, bodyParams) : null}
                        {body ? body : null}
                        <p className={classNames('infobox infobox--alert', {
                            'displayNone': !error?.code
                        })}
                        >
                            {error?.message}
                        </p>
                    </MDBModalBody> :
                    null}
                {children ? children :
                    <MDBModalFooter>
                        {withoutNegative ? null :
                            <MDBBtn
                                color={negativeAsPrimary ? 'primary' : 'secondary'}
                                data-cy={datacy ? `${datacy}-cancel` : ''}
                                onClick={event => {
                                    event.preventDefault();
                                    this.closeModal();
                                    negativeResponse();
                                }
                                }
                            >
                                {negativeText ? t(negativeText) : t('user:button.modalDecline')}
                            </MDBBtn>}
                        <MDBBtn
                            color={negativeAsPrimary ? 'secondary' : 'primary'}
                            data-cy={datacy ? `${datacy}-confirm` : ''}
                            disabled={isLoading || error?.code}
                            onClick={event => {
                                event.preventDefault();
                                if (!dontCloseOnAction) {
                                    this.closeModal();
                                }
                                positiveResponse();
                            }
                            }
                        >
                            {isLoading ?
                                <Icon
                                    className="loader__svg"
                                    name="loading"
                                /> : null}
                            {positiveText ? t(positiveText) : t('user:button.modalAccept')}
                        </MDBBtn>
                    </MDBModalFooter>
                }
            </MDBModal>
        );
    }
}

export default Modal;
