/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { connect } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { getIsAdvanced, setIsAdvanced } from '~frontendDucks/dynamicSettings';
import { getIsProductionMode } from '~frontendDucks/constantInformation';


/**
 * Defines setters for dynamicSettings.isAdvanced. The setters are defined as window properties because they are not to
 * be used by mere mortals who are not advanced enough.
 */
const defineSetters = (doSetIsAdvanced) => {
    Object.defineProperty(window, 'showAdvanced', {
        get: () => {
            doSetIsAdvanced(true);
            return 'You are now advanced';
        },
    });
    Object.defineProperty(window, 'hideAdvanced', {
        get: () => {
            doSetIsAdvanced(false);
            return 'You are no longer advanced';
        },
    });
};

let isDefined = false;

interface WhenAdvancedProps {
    children: JSX.Element,
    isAdvanced?: boolean,
    doSetIsAdvanced: ActionCreatorWithPayload<boolean>,
    showAnyway?: boolean,
    isInProduction?: boolean,
    developMode?: boolean
}

const WhenAdvancedBase = ({ children, isAdvanced, doSetIsAdvanced, showAnyway,
    isInProduction, developMode }: WhenAdvancedProps): JSX.Element | null => {
    if (!isDefined && typeof window !== 'undefined') {
        isDefined = true;
        defineSetters(doSetIsAdvanced);
    }
    if (isAdvanced || showAnyway || (developMode && !isInProduction)) {
        return children;
    }
    return null;
};

const mapStateToProps = state => ({
    isAdvanced: getIsAdvanced(state),
    isInProduction: getIsProductionMode(state)
});

const mapDispatchToProps = {
    doSetIsAdvanced: setIsAdvanced,
};

const WhenAdvanced = connect(mapStateToProps, mapDispatchToProps)(WhenAdvancedBase);
export default WhenAdvanced;
