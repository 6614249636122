/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { MDBRow, MDBCol } from 'mdbreact';
import { hot } from 'react-hot-loader/root';

import { Icon, InputTime } from '~frontendComponents/Generic';


const toDate = time =>
    (time instanceof moment ? time : moment(time)).toDate();

@hot
@withTranslation()
class InputRangeTime extends Component {
    static get propTypes() {
        return {
            onChange: PropTypes.func,
            minTime: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
            startDate: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
            endDate: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.object,
            ]),
            endId: PropTypes.string,
            startId: PropTypes.string,

        };
    }

    handleChange = ({ value, id }) => {
        const { onChange, startDate, endDate } = this.props;
        onChange({
            from: toDate(startDate),
            to: toDate(endDate),
            [id]: toDate(value)
        });
    };

    render() {
        const { minTime, startId, endId, startDate, endDate } = this.props;
        return (

            <MDBRow className="date-range">
                <MDBCol className="form-group m-0 mb-1">
                    <InputTime
                        calendar={false}
                        id={startId}
                        maxTime={toDate(endDate)}
                        minTime={minTime}
                        onChange={this.handleChange}
                        value={toDate(startDate)}
                    />
                </MDBCol>
                <Icon
                    className="mt-1"
                    name="arrow-right"
                    size="sm"
                />
                <MDBCol className="form-group m-0">
                    <InputTime
                        calendar={false}
                        id={endId}
                        minTime={toDate(startDate)}
                        onChange={this.handleChange}
                        value={toDate(endDate)}
                    />

                </MDBCol>
            </MDBRow>

        );
    }
}

export default InputRangeTime;
