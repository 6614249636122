/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBNavbar } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { collapseNavBar, getIsCollapsed, getIsTempOpen, tempCloseNavBar, unCollapseNavBar } from '~frontendDucks/layout/ducks/navBarLeft';
import { changePasswordModalOpen } from '~frontendDucks/userAuthentication/ducks/changePassword';
import { logoutRequest } from '~frontendDucks/userAuthentication/ducks/login';

import BottomMenu from './components/BottomMenu';
import TopMenu from './components/TopMenu';


@connect(
    state => ({
        isCollapsed: getIsCollapsed(state),
        isTempOpen: getIsTempOpen(state),
    }),
    {
        doLogoutRequest: logoutRequest,
        doChangePasswordModalOpen: changePasswordModalOpen,
        doCollapseNavBar: collapseNavBar,
        doUnCollapseNavBar: unCollapseNavBar,
        doTempCloseNavBar: tempCloseNavBar,
    },
)
class NavBarLeft extends Component {
    static get propTypes() {
        return {
            // from connect:
            doLogoutRequest: PropTypes.func,
            doChangePasswordModalOpen: PropTypes.func,
            doCollapseNavBar: PropTypes.func,
            doUnCollapseNavBar: PropTypes.func,
            doTempCloseNavBar: PropTypes.func,
            isCollapsed: PropTypes.bool,
            isTempOpen: PropTypes.bool,

            // from props:
            activeRoute: PropTypes.string.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            prunedSceneTree: PropTypes.array.isRequired,
        };
    }

    onCollapseClick = (event) => {
        event.stopPropagation();
        const {
            doUnCollapseNavBar, doTempCloseNavBar, doCollapseNavBar, isCollapsed, isTempOpen,
        } = this.props;

        if (isCollapsed && !isTempOpen) {
            doUnCollapseNavBar();
        } else if (isTempOpen && isCollapsed) {
            doTempCloseNavBar();
        } else {
            doCollapseNavBar();
            doTempCloseNavBar();
        }
    };

    render() {
        const {
            activeRoute,
            doChangePasswordModalOpen, doLogoutRequest,
            isCollapsed, isTempOpen, prunedSceneTree, setActiveRoute
        } = this.props;
        return (
            <MDBNavbar
                className={classNames({
                    'navbar--vertical': true,
                    'navbar--collapsed': isCollapsed && !isTempOpen,
                })}
                color="default-color-dark"
                dark
            >
                <TopMenu
                    activeRoute={activeRoute}
                    doLogoutRequest={doLogoutRequest}
                    isCollapsed={isCollapsed}
                    isTempOpen={isTempOpen}
                    onCollapseClick={this.onCollapseClick}
                    prunedSceneTree={prunedSceneTree}
                    setActiveRoute={setActiveRoute}
                />
                <BottomMenu
                    activeRoute={activeRoute}
                    doChangePasswordModalOpen={doChangePasswordModalOpen}
                    doLogoutRequest={doLogoutRequest}
                    isCollapsed={isCollapsed}
                    isTempOpen={isTempOpen}
                    prunedSceneTree={prunedSceneTree}
                    setActiveRoute={setActiveRoute}
                />
            </MDBNavbar>
        );
    }
}

export default NavBarLeft;
