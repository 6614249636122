/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import CustomFilterStorage from '~sharedLib/reporterLibrary/CustomFilterStorage';
import reportManipulator from '~sharedLib/reporterLibrary/reportManipulator';


const buildReportQuery = (
    { frozenReportDefinition, arrFilterContainers, reporterTemplates, time, filter }
) => {
    const customFilterStorage = new CustomFilterStorage({});
    let filters = { filters: [] };
    if (typeof frozenReportDefinition.report.params.filters === 'function') {
        filters = frozenReportDefinition.report.params.filters({
            time, filter });

    }
    const newArrFilterContainers = [
        ...arrFilterContainers,
        filters
    ];
    return reportManipulator.getReporterParams(
        customFilterStorage,
        reporterTemplates,
        frozenReportDefinition.report,
        newArrFilterContainers
    );
};

export default buildReportQuery;
