/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { PayloadAction, createSlice, } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import type { GetResponseType } from '~commonLib/apiUtils';
import { BackendApiDefinitionType } from '~frontendTypes/externalTypes';
import { getApiError } from '~frontendLib/apiUtils';
import { backendGet, backendPost } from '~frontendLib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';
import { slowDownWrap } from '~commonLib/asyncUtils';
import { SECOND } from '~commonLib/constants';


export type StatusType = GetResponseType<BackendApiDefinitionType['/cluster/dbReplicationStatus']>;
interface InitialStateType {
    status: StatusType,
    error?: any,
    isLoading: boolean
}

const initialState: InitialStateType = {
    status: {
        initialized: false,
        active: false,
        isOk: false,
        subscriptionStatus: undefined
    },
    isLoading: false,
};

const getRootState = (state): InitialStateType => state.clusterDbReplicationStatus;

export const getStatus = (state) => getRootState(state).status;
export const getIsLoading = (state) => getRootState(state).isLoading;
export const getError = (state) => getRootState(state).error;
export const getIsActive = (state) => getStatus(state).active;
export const getInitialized = (state) => getStatus(state).initialized;
export const getIsOk = (state) => getStatus(state).isOk;


const clusterDbReplicationStatus = createSlice({
    name: 'ak/clusterDbReplicationStatus',
    initialState,
    reducers: {
        clusterDbreplicationStatusRequest: (state) => {
            state.isLoading = true;
        },
        clusterDbReplicationSuccess: (state, { payload }: PayloadAction<StatusType>) => {
            state.status.subscriptionStatus = payload.subscriptionStatus;
            state.status.active = payload.active;
            state.status.initialized = payload.initialized;
            state.status.isOk = payload.isOk;
            state.isLoading = false;
            delete state.error;
        },
        clusterDbReplicationError: (state, { payload }) => {
            state.error = payload;
            state.isLoading = false;
            state.status.isOk = false;
        },
        clusterInitDbReplicationRequest: (state) => {
            state.isLoading = true;
        },
        clusterInitDbReplicationSuccess: (state) => {
            state.isLoading = false;
        }
    }
});


export const {
    clusterDbreplicationStatusRequest,
    clusterDbReplicationSuccess,
    clusterDbReplicationError,
    clusterInitDbReplicationRequest,
    clusterInitDbReplicationSuccess,
} = clusterDbReplicationStatus.actions;

export default clusterDbReplicationStatus.reducer;


const getClusterDbReplicationStatus = backendGet('/cluster/dbReplicationStatus');
const apiCallInitDbReplication = backendPost('/cluster/initializeDbReplication');


export const workerGetClusterDbReplicationStatus = function* () {
    try {
        const { data } = yield* callSaga(slowDownWrap(getClusterDbReplicationStatus, SECOND));
        yield put(clusterDbReplicationSuccess(data));
    } catch (error) {
        yield put(clusterDbReplicationError(getApiError(error)));
    }
};

export const workerInitDbReplication = function* () {
    try {
        yield callSaga(slowDownWrap(apiCallInitDbReplication, 2 * SECOND), {});
        yield put(clusterDbreplicationStatusRequest());
    } catch (error) {
        yield put(clusterDbReplicationError(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(clusterDbreplicationStatusRequest.type, workerGetClusterDbReplicationStatus),
    takeLatest(clusterInitDbReplicationRequest.type, workerInitDbReplication)
];
