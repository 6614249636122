/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getHlcfgSchema } from '~frontendRoot/ducks/hlcfgEditor';
import DevelStatusBar from '~frontendComponents/DevelStatusBar';
import RecoveryStatusBar from '~frontendComponents/RecoveryStatusBar';
import { getRouterPath } from '~frontendDucks/userAuthentication';
import ModalWindows from '~frontendComponents/ModalWindows';
import { getIsProductionMode } from '~frontendDucks/constantInformation';
import { getGlcfgValue, getHlcfgDiffOpen, getIsTreeDirty } from '~frontendDucks/hlcfgEditor';
import { getPrunedSceneTree, getFirstScene } from '~frontendScenes';
import { getShowTodos } from '~frontendDucks/dynamicSettings';
import { getIsHelp } from '~frontendDucks/layout/ducks/navBarLeft';
import GlobalNotifications from '~frontendComponents/GlobalNotifications/GlobalNotifications';
import { WrongGuiVersionBar } from '~frontendComponents/WrongGuiVersionBar';

import Content from './components/Content';
import ErrorBoundary from './components/ErrorBoundary';
import NavBarLeft from './components/NavBarLeft';
import NavBarTop from './components/NavBarTop';
import Help from './components/Help';
import HelpToggle from './components/Help/HelpToggle';
import Differ from './components/Differs/Differs';


@hot
@withRouter
@connect(
    state => ({
        schema: getHlcfgSchema(state),
        routerPath: getRouterPath(state),
    }),

)
class ApplicationContent extends Component {
    static get propTypes() {
        return {
            activeRoute: PropTypes.string,
            deployment: PropTypes.string,
            isTreeDirty: PropTypes.bool,
            prunedSceneTree: PropTypes.array.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            isHelp: PropTypes.bool,
            isDifferOpen: PropTypes.bool,
            schema: PropTypes.any,
            routerPath: PropTypes.string,
        };
    }

    render() {
        const {
            activeRoute, deployment, isTreeDirty,
            prunedSceneTree, setActiveRoute, isHelp,
            isDifferOpen, schema, routerPath
        } = this.props;
        return (
            <>
                <header className="header">
                    <NavBarTop
                        isTreeDirty={isTreeDirty}
                    />
                </header>

                <div className="content">
                    <aside className="aside">
                        <NavBarLeft
                            activeRoute={activeRoute}
                            prunedSceneTree={prunedSceneTree}
                            setActiveRoute={setActiveRoute}
                        />
                    </aside>
                    <main className="main">
                        <Content
                            deployment={deployment}
                        />
                        <HelpToggle />
                    </main>


                    {isHelp ?
                        <div className="helpDiv">
                            <Help
                                routerPath={routerPath}
                                schema={schema}
                            />
                        </div> :
                        null}
                    {isDifferOpen ?
                        <div className="differs ">
                            <Differ />
                        </div> :
                        null}
                </div>
            </>
        );
    }
}

/**
 * Renders the entire application.
 */
@hot
@withRouter
@connect(
    state => ({
        deployment: getGlcfgValue(state, 'deployment'),
        isProductionMode: getIsProductionMode(state),
        isTreeDirty: getIsTreeDirty(state),
        showTodos: getShowTodos(state),
        isHelp: getIsHelp(state),
        isDifferOpen: getHlcfgDiffOpen(state),
        schema: getHlcfgSchema(state),
        routerPath: getRouterPath(state),
    }),

) class Application extends Component {
    static get propTypes() {
        return {
            // from withRouter:
            location: PropTypes.object,
            history: PropTypes.object,

            // from connect:
            deployment: PropTypes.string,
            isProductionMode: PropTypes.bool,
            isTreeDirty: PropTypes.bool,
            showTodos: PropTypes.bool,
            isHelp: PropTypes.bool,
            isDifferOpen: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        const { deployment, isProductionMode, location, showTodos } = this.props;
        this.state = {
            // We want to perform a transition between clicking on a navbar link and showing the new section because
            // otherwise the navbar animation is stuttering due to the Content being updated at the same time. So the
            // navbar is updated immediately but the Content update is delayed due to history.push() being debounced.
            // NOT TRUE SINCE 5.5.2021 - JC
            activeRoute: location.pathname,
            // Computing the prunedSceneTree only when the deployment is changed is a performance optimization.
            prunedSceneTree: getPrunedSceneTree(deployment, isProductionMode, showTodos),
        };
    }

    pushToHistory = (path) => {
        const { history } = this.props;
        history.push(path);
    };

    setActiveRoute = (objRoute) => {
        const firstScene = getFirstScene(objRoute);
        const activeRoute = firstScene.route.key;
        this.setState({ activeRoute });
        this.pushToHistory(activeRoute);
    };

    componentDidUpdate(prevProps) {
        const { deployment, isProductionMode, location, showTodos } = this.props;
        if (location !== prevProps.location) {
            this.setState({
                activeRoute: location.pathname,
            });
        }
        if (deployment !== prevProps.deployment ||
            isProductionMode !== prevProps.isProductionMode ||
            showTodos !== prevProps.showTodos
        ) {
            this.setState({
                prunedSceneTree: getPrunedSceneTree(deployment, isProductionMode, showTodos),
            });
        }
    }

    render() {
        const { activeRoute, prunedSceneTree } = this.state;
        const { deployment, isTreeDirty, isHelp, isDifferOpen } = this.props;
        return (
            <ErrorBoundary>
                <DevelStatusBar />
                <WrongGuiVersionBar />
                <RecoveryStatusBar />
                <ApplicationContent
                    activeRoute={activeRoute}
                    deployment={deployment}
                    isDifferOpen={isDifferOpen}
                    isHelp={isHelp}
                    isTreeDirty={isTreeDirty}
                    prunedSceneTree={prunedSceneTree}
                    setActiveRoute={this.setActiveRoute}
                />
                <ModalWindows />
                <GlobalNotifications />
            </ErrorBoundary>
        );
    }
}

export default Application;
