/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import LogoWhiteImage from '../../img/svg/kernun-logo-white.svg';
import SvgInliner from '../Generic/SvgInliner';


class Logo extends Component {
    static get propTypes() {
        return {
            isBackend: PropTypes.bool,
            isLogin: PropTypes.bool,
            hostname: PropTypes.string,
        };
    }

    render() {
        const { isBackend, isLogin, hostname } = this.props;

        let LinkTag;
        if (isBackend || isLogin) {
            LinkTag = 'div';
        } else {
            LinkTag = Link;
        }

        return (
            <div className="logo">
                <div className="logo__inner">
                    <LinkTag to="/">
                        <SvgInliner
                            className="logo__svg"
                            isBackend={isBackend}
                            src={LogoWhiteImage}
                        />
                    </LinkTag>
                    <div className="logo__version">{hostname}</div>
                </div>
            </div>
        );
    }
}

export default Logo;
