/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {  MDBNavbarNav,  } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';


import NavBarMenuItem from '../NavBarMenuItem';


class BottomMenu extends Component {
    static get propTypes() {
        return {
            doLogoutRequest: PropTypes.func.isRequired,
            prunedSceneTree: PropTypes.array.isRequired,
            activeRoute: PropTypes.string.isRequired,
            isCollapsed: PropTypes.bool,
            isTempOpen: PropTypes.bool,
            setActiveRoute: PropTypes.func.isRequired,
        };
    }

    render() {
        const { activeRoute, isCollapsed, setActiveRoute,
            doLogoutRequest, prunedSceneTree, isTempOpen, } = this.props;
        return (
            <MDBNavbarNav className="bottom">
                {prunedSceneTree.map(objRoute => objRoute.menu === 'bottom' ?
                    <NavBarMenuItem
                        activeRoute={activeRoute}
                        className={objRoute.className}
                        doLogoutRequest={doLogoutRequest}
                        isCollapsed={isCollapsed}
                        isTempOpen={isTempOpen}
                        key={objRoute.route.key}
                        objRoute={objRoute}
                        setActiveRoute={setActiveRoute}
                    /> : null)
                }
            </MDBNavbarNav>
        );
    }
}

export default BottomMenu;
