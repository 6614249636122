/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { NetaddrBase } from '~commonLib/Netaddr/NetaddrBase';
import { IPV4, IPV6, NETADDR_DHCP_ADDRESSES, NETADDR_DHCP_NETWORKS } from '~commonLib/constants';
import { ADDR_FROM_DHCP_STRING_PREFIX } from '~commonLib/Netaddr/constants';

import { NetaddrDhcpData, NetaddrDhcpString } from './types';


export class NetaddrDhcp extends NetaddrBase {
    dhcp: {
        type: typeof NETADDR_DHCP_ADDRESSES | typeof NETADDR_DHCP_NETWORKS,
        ipVersion: typeof IPV4 | typeof IPV6,
        networkDevice: string,
    };

    constructor(dhcpNetaddr: NetaddrDhcpString | NetaddrDhcpData | string) {
        super();

        if (typeof dhcpNetaddr === 'string') {
            const [ networkDevice, ipVersion, type ] =
                dhcpNetaddr.slice(ADDR_FROM_DHCP_STRING_PREFIX.length).split('_');

            if (ipVersion !== IPV4 && ipVersion !== IPV6) {
                throw new Error(`Invalid IP version for NetaddrDhcp (${ipVersion})`);
            }
            if (type !== NETADDR_DHCP_ADDRESSES && type !== NETADDR_DHCP_NETWORKS) {
                throw new Error(`Invalid type for NetaddrDhcp (${type})`);
            }
            assert(networkDevice, 'Empty network device should not be possible');

            this.dhcp = {
                networkDevice,
                ipVersion,
                type,
            };
            return;
        }

        this.dhcp = {
            ...dhcpNetaddr.dhcp
        };
    }

    isValid() {
        const addr = this.toString();
        return addr.startsWith(ADDR_FROM_DHCP_STRING_PREFIX) && addr.split('_').length === 3;
    }

    toString(): NetaddrDhcpString {
        const dhcpStr = ADDR_FROM_DHCP_STRING_PREFIX +
            `${this.dhcp.networkDevice}_${this.dhcp.ipVersion}_${this.dhcp.type}`;
        return dhcpStr as NetaddrDhcpString;
    }

    isFromDhcp(): this is NetaddrDhcp {
        return true;
    }
}

export const isNetaddrDhcpData = (addr): addr is NetaddrDhcpData => {
    return 'dhcp' in addr;
};
export const isNetaddrDhcpString = (addr): addr is NetaddrDhcpString => {
    return addr.startsWith(ADDR_FROM_DHCP_STRING_PREFIX) && addr.split('_').length === 3;
};
