/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Draft, produce } from 'immer';

import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg';
import { deleteValue, getValue, setValue } from '~commonLib/objectUtils';
import { resolvedPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath';
import { guessInsert } from '~sharedLib/hlcfg/guessInsert';
import { ADD_DIFF_TYPE, CHANGE_DIFF_TYPE, REORDER_DIFF_TYPE,
    DELETE_DIFF_TYPE } from '~sharedConstants/constants';
import { isAddDiff, isRowAddDiff, isRowDeleteDiff, isRowReorderDiff } from '~sharedLib/hlcfg/diffUtils';
import { isOffPath } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/helpers';


export const undoDiff = <T extends object>(oldObj: T, newObj: T, diffToUndo: HlcfgDiff): T => {

    return produce(newObj, (draft) => {
        switch (true) {
        case isRowReorderDiff(diffToUndo):
            undoRowReorderDiff(oldObj, draft, diffToUndo);
            break;
        case isRowAddDiff(diffToUndo):
            undoRowAddDiff(oldObj, draft, diffToUndo);
            break;
        case isRowDeleteDiff(diffToUndo):
            undoRowDeleteDiff(oldObj, draft, diffToUndo);
            break;
        case isAddDiff(diffToUndo):
            undoSimpleAddDiff(oldObj, draft, diffToUndo);
            break;
        case isOffPath(diffToUndo.hlcfgRealPath):
            undoOffDiff(oldObj, draft, diffToUndo);
            break;
        default:
            undoSimpleDeleteOrChangeDiff(oldObj, draft, diffToUndo);
            break;
        }
    });
};


export const diffType = (diff: HlcfgDiff): string => {
    switch (true) {
    case isRowReorderDiff(diff):
        return REORDER_DIFF_TYPE;
    case isRowAddDiff(diff):
        return ADD_DIFF_TYPE;
    case isRowDeleteDiff(diff):
        return DELETE_DIFF_TYPE;
    case isAddDiff(diff):
    default:
        return CHANGE_DIFF_TYPE;
    }
};


const rowIdByRowDiff = (diff: HlcfgDiff) => {
    return diff.hlcfgDescriptivePath[diff.hlcfgDescriptivePath.length - 1];
};

const undoRowReorderDiff = <T extends object>(oldObj: T, newObj: Draft<T>, diffToUndo: HlcfgDiff) => {
    const descPath = diffToUndo.hlcfgDescriptivePath;
    const reorderDesc = descPath.slice(0, descPath.length - 1);
    const reorderReal = resolvedPathToRealPath(reorderDesc);

    const oldArr = getValue(oldObj, reorderReal);
    const newArr = getValue(newObj, reorderReal);

    const newIds = newArr.filter(id => !oldArr.includes(id));
    const oldArrWithoutDeleted = oldArr.filter(id => newArr.includes(id));

    const reorderedArr = newIds.reduce((acc, id) => {
        return guessInsert(acc, id, newArr);
    }, oldArrWithoutDeleted);

    setValue(newObj, reorderReal, reorderedArr);
};

const undoRowAddDiff = <T extends object>(oldObj: T, newObj: Draft<T>, diffToUndo: HlcfgDiff) => {
    const rowId = rowIdByRowDiff(diffToUndo);
    const descPath = diffToUndo.hlcfgDescriptivePath;
    const removeFromDesc = descPath.slice(0, descPath.length - 2);
    const removeFromReal = resolvedPathToRealPath(removeFromDesc);

    const rows = getValue(newObj, removeFromReal);

    setValue(newObj, removeFromReal, rows.filter(id => id !== rowId));
};

const undoRowDeleteDiff = <T extends object>(oldObj: T, newObj: Draft<T>, diffToUndo: HlcfgDiff) => {
    const rowId = rowIdByRowDiff(diffToUndo);
    const descPath = diffToUndo.hlcfgDescriptivePath;
    const addToDesc = descPath.slice(0, descPath.length - 2);
    const addToReal = resolvedPathToRealPath(addToDesc);

    const rows = getValue(newObj, addToReal);
    const initialRows = getValue(oldObj, addToReal);

    setValue(newObj, addToReal, guessInsert(rows, rowId, initialRows));
};


const undoSimpleAddDiff = <T extends object>(oldObj: T, newObj: Draft<T>, diffToUndo: HlcfgDiff) => {
    deleteValue(newObj, diffToUndo.hlcfgRealPath);
};

const undoSimpleDeleteOrChangeDiff = <T extends object>(oldObj: T, newObj: Draft<T>, diffToUndo: HlcfgDiff) => {
    setValue(newObj, diffToUndo.hlcfgRealPath, getValue(oldObj, diffToUndo.hlcfgRealPath));
};

const undoOffDiff = <T extends object>(oldObj: T, newObj: Draft<T>, diffToUndo: HlcfgDiff) => {
    if (diffToUndo.fromValue === true) {
        setValue(newObj, diffToUndo.hlcfgRealPath, true);
    } else {
        undoSimpleDeleteOrChangeDiff(oldObj, newObj, diffToUndo);
    }
};
