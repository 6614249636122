/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/lib/highcharts-more';
import boost from 'highcharts/lib/modules/boost';
//import boostCanvas from 'highcharts/lib/modules/boost-canvas';
import exporting from 'highcharts/lib/modules/exporting';
import heatmap from 'highcharts/lib/modules/heatmap';

import moment from '~commonLib/moment';


export const localizeHighcharts = language => {
    if (typeof window === 'undefined') {
        return; // Highcharts do not work on the frontend
    }
    switch (language) {
    case 'cs':
        Highcharts.setOptions({
            lang: {
                loading: 'Načítám',
                months: moment.months(),
                weekdays: moment.weekdays(),
                shortMonths: moment.monthsShort(),
                shortWeekdays: moment.weekdaysShort(),
                invalidDate: 'Neplatné datum',
                noData: 'Žádná data',
                resetZoom: 'Resetovat zoom',
                resetZoomTitle: 'Resetovat zoom na 1:1',
                thousandsSep: '\u0020',
                decimalPoint: ',',
            }
        });
        break;
    case 'en':
        Highcharts.setOptions({
            lang: {
                loading: 'Loading',
                months: moment.months(),
                weekdays: moment.weekdays(),
                shortMonths: moment.monthsShort(),
                shortWeekdays: moment.weekdaysShort(),
                invalidDate: 'Invalid date',
                noData: 'No data',
                resetZoom: 'Reset zoom',
                resetZoomTitle: 'Reset zoom to 1:1',
            }
        });
        break;
    default:
        throw new Error(`Language "${language}" not supported by Highcharts`);
    }
    Highcharts.theme = {
        credits: {
            enabled: false
        },
        global: {
            useUTC: false
        }
    };
    Highcharts.setOptions(Highcharts.theme);
    /*if (Highcharts.wrap) {
        Highcharts.wrap(Highcharts.Chart.prototype, 'showResetZoom', function(proceed) {
            proceed.apply(this, [].slice.call(arguments, 1));

            const button = this.resetZoomButton;

            // TODO this will not work because there are no glyphicons in AK
            button.textSetter('');
            button.css({
                fontFamily: 'Glyphicons Halflings'
            });
        });
    }*/
    highchartsMore(Highcharts);
    boost(Highcharts);
    //boostCanvas(Highcharts);
    exporting(Highcharts);
    heatmap(Highcharts);
};

export default Highcharts;
