/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { colonToDash, dehyphenize } from '~commonLib/stringUtils';


export const vpnSvcName = (vpnId: string) => {
    return dehyphenize(colonToDash(vpnId));
};

export const getOpenVPNCfgDownloadFilename = (vpnId: string) => {
    return `${vpnSvcName(vpnId)}.ovpn`;
};
