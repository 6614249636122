/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import kUtils from './kUtils';


const ReporterTemplates = function() {};

const mapColumns = {};
const assignColumnToFilterAs = {};

const Column = function(reporterTemplates, name) {
    const column = reporterTemplates.rawData.columns[name];
    if (!Array.isArray(column.typeset) || column.typeset.length < 1) {
        throw new TypeError('Column typeset is not an array of length > 1, is: ' + column.typeset);
    }
    this.desc = column.desc;
    this.isCategory = false;
    this.name = name;
    this.title = column.title;
    this.nullval = column.nullval;
    this.color = column.color;
    this.type = column.typeset[column.typeset.length - 1];
    this.types = {};
    this.typeset = [];
    this.tablesAreEmpty = true;
    if (column.filter_as) {
        if (mapColumns[column.filter_as]) {
            this.filterAs = mapColumns[column.filter_as];
        } else {
            if (!(column.filter_as in assignColumnToFilterAs)) {
                assignColumnToFilterAs[column.filter_as] = [];
            }
            assignColumnToFilterAs[column.filter_as].push(this);
        }
    }
    column.typeset.forEach((type) => {
        if (type === 'category') {
            this.isCategory = true;
        }
        this.types[type] = type;
        this.typeset.push(type);
    });
    mapColumns[name] = this;
    if (name in assignColumnToFilterAs) {
        assignColumnToFilterAs[name].forEach((col) => {
            col.filterAs = this;
        });
    }
};

const getColumnsByName = function(reporterTemplates, columns) {
    const ret = {};
    for (const columnName in columns) {
        ret[columnName] = new Column(reporterTemplates, columnName);
    }
    return ret;
};

const getColumnsByType = function(reporterTemplates, columns) {
    const ret = {};
    for (const columnName in columns) {
        const column = new Column(reporterTemplates, columnName);
        for (let index = 0, len = column.typeset.length; index < len; ++index) {
            const type = column.typeset[index];
            if (!ret[type]) {
                ret[type] = [];
            }
            ret[type].push(column);
        }
    }
    return ret;
};

const getColumnsFlat = function(reporterTemplates, columns) {
    const ret = [];
    for (const columnName in columns) {
        ret.push(new Column(reporterTemplates, columnName));
    }
    return ret;
};

const getCategoriesFlat = function(reporterTemplates, columns) {
    const ret = [];
    for (const columnName in columns) {
        const column = new Column(reporterTemplates, columnName);
        if (column.isCategory) {
            ret.push(column);
        }
    }
    return ret;
};

const getMetricsFlat = function(reporterTemplates, columns) {
    const ret = [];
    for (const columnName in columns) {
        const column = new Column(reporterTemplates, columnName);
        if (!column.isCategory) {
            ret.push(column);
        }
    }
    return ret;
};

/**
 * Returns true when column is of given type.
 *
 * @memberof reporter-templates-service
 * @param columnName {string} name of the column
 * @param type {string} type that the column is to be checked against
 * @returns {boolean}
 */
ReporterTemplates.prototype.columnIsOfType = function(columnName, type) {
    if (!this.columns) {
        return;
    }
    const column = this.columns.byName[columnName];
    if (!column) {
        throw new TypeError('Unknown column name: ' + columnName);
    }
    return column.typeset.indexOf(type) !== -1;
};

/**
 * Returns true when column type is a subtype of given type.
 *
 * @memberof reporter-templates-service
 * @param columnTypeName {string} name of the column type
 * @param type {string} type that the column is to be checked against
 * @returns {boolean}
 */
ReporterTemplates.prototype.columnTypeIsOfType = function(columnTypeName, type) {
    if (!this.columns) {
        return;
    }
    const columnType = this.columnTypes.byName[columnTypeName];
    if (!columnType) {
        throw new TypeError('Unknown column type name: ' + columnTypeName);
    }
    if (columnType.name === type) {
        return true;
    }
    if (columnType.parent) {
        return this.columnTypeIsOfType(columnType.parent, type);
    }
    return false;
};

const getSelect = function(reporterTemplates, processedColumns, tables) {
    return function(params) {
        if (!params || params.table === undefined) {
            return [];
        }
        return tables[params.table].columns.filter(function(item) {
            return !params.type || reporterTemplates.columnIsOfType(item, params.type);
        }).sort(function(first, second) {
            const firstColumn = processedColumns.byName[first];
            const secondColumn = processedColumns.byName[second];
            if (!firstColumn || !secondColumn) {
                return 0;
            }
            if (firstColumn.title < secondColumn.title) {
                return -1;
            }
            if (firstColumn.title > secondColumn.title) {
                return 1;
            }
            return 0;
        });
    };
};

const COLUMN_KIND_DEFAULT = {
    name: 'other',
    columnType: undefined
};

ReporterTemplates.prototype.COLUMN_KINDS = [
    {
        name: 'temporal',
        columnType: 'temporal',
    },
    {
        name: 'source',
        columnType: 'source',
    },
    {
        name: 'destination',
        columnType: 'destination'
    },
    {
        name: 'metric',
        columnType: 'metric'
    },
    COLUMN_KIND_DEFAULT
];

/**
 * Returns all columns.
 *
 * @memberof reporter-templates-service
 * @returns {object}
 */
const getColumns = function(reporterTemplates, columns, tables) {
    const processedColumns = {
        byName: getColumnsByName(reporterTemplates, columns),
        byType: getColumnsByType(reporterTemplates, columns),
        flat: getColumnsFlat(reporterTemplates, columns),
        categoriesFlat: getCategoriesFlat(reporterTemplates, columns),
        metricsFlat: getMetricsFlat(reporterTemplates, columns)
    };
    processedColumns.names = Object.keys(processedColumns.byName);
    processedColumns.names.sort(kUtils.getSortByString(true, function(columnName) {
        return processedColumns.byName[columnName].title;
    }));
    processedColumns.filtrableNames = processedColumns.names.filter(function(columnName) {
        return !processedColumns.byName[columnName].filterAs;
    });

    processedColumns.select = getSelect(reporterTemplates, processedColumns, tables);
    Object.keys(tables).forEach(function(tableName) {
        const table = tables[tableName];
        if (table.isEmpty) {
            return;
        }
        table.columns.forEach(function(colName) {
            processedColumns.byName[colName].tablesAreEmpty = false;
        });
    });
    return processedColumns;
};


/**
 * Returns all column types.
 *
 * @memberof reporter-templates-service
 * @returns {object}
 */
const getColumnTypes = function(reporterTemplates, columns) {
    return {
        byName: (function() {
            const ret = {};
            for (const columnName in columns) {
                const column = new Column(reporterTemplates, columnName);
                for (let index = column.typeset.length - 1; index >= 0; --index) {
                    const type = column.typeset[index];
                    const prevType = index !== 0 && column.typeset[index - 1];
                    ret[type] = {
                        name: type,
                        parent: prevType || undefined
                    };
                }
            }
            return ret;
        })()
    };
};

const getTables = function(tables) {
    Object.keys(tables).forEach(function(tableName) {
        const table = tables[tableName];
        table.mapColumns = {};
        table.columns.forEach(function(columnName) {
            table.mapColumns[columnName] = columnName;
        });
        table.isEmpty = !!table.is_empty;
    });
    Object.keys(tables).forEach(function(tableName) {
        const currTable = tables[tableName];
        const baseTable = tables[currTable.base];
        currTable.noAdditionalColumns = !baseTable ||
            currTable.columns.reduce(function(temp, columnName) {
                return temp || baseTable.columns.indexOf(columnName) === -1;
            }, false);
    });
    return tables;
};

const getTemplates = function(templates) {
    return templates;
};

const getColumnKinds = function(reporterTemplates, columnNames) {
    const columnKinds = {};
    columnNames.forEach(function(columnName) {
        let selectedKind = COLUMN_KIND_DEFAULT;
        reporterTemplates.COLUMN_KINDS.forEach(function(columnKind) {
            if (!columnKind.columnType) {
                return;
            }
            if (reporterTemplates.columnIsOfType(columnName, columnKind.columnType)) {
                selectedKind = columnKind;
            }
        });
        if (!columnKinds[selectedKind.name]) {
            columnKinds[selectedKind.name] = [];
        }
        columnKinds[selectedKind.name].push(columnName);
    });
    return columnKinds;
};

const walkTables = function(tables, currentBase, currentLevel) {
    Object.keys(tables).forEach(function(tableName) {
        const table = tables[tableName];
        if (table.base === currentBase) {
            table.level = currentLevel;
            walkTables(tables, tableName, currentLevel + 1);
        }
    });
};

const getTableOrder = function(tables) {
    walkTables(tables, null, 0);
    const tableOrder = Object.keys(tables).sort(kUtils.getSortByString(true, function(tableName) {
        const table = tables[tableName];
        // prepend zeroes so that it works when there are more than 9 levels
        return ('000000' + table.level).slice(-6) + '_' + table.title;
    }));
    return tableOrder;
};

ReporterTemplates.prototype.load = function(data) {
    this.rawData = data;
    this.tables = getTables(data.tables);
    this.templates = getTemplates(data.templates);
    this.columns = getColumns(this, data.columns, this.tables);
    this.columnTypes = getColumnTypes(this, data.columns);
    this.columnKinds = getColumnKinds(this, Object.keys(this.columns.byName));
    this.filtrableColumnKinds = getColumnKinds(this, this.columns.filtrableNames);
    this.tableOrder = getTableOrder(this.tables);
};

export default ReporterTemplates;
