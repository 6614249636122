/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Icon, Input, Select, Switch, CloseWithLock } from '~frontendRoot/components/Generic';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice';
import { getAllOptions, getGlcfgTables, getHlcfgSchema, getInitGlcfgTables, undoDiffFunction } from '~frontendRoot/ducks/hlcfgEditor';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils';
import {
    ADD_DIFF_TYPE,
    CHANGE_DIFF_TYPE,
    DELETE_DIFF_TYPE,
    REORDER_DIFF_TYPE,
    TypeOfScene
} from '~sharedConstants/constants';
import { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg';
import { diffType } from '~sharedLib/hlcfg/undoDiff/undoDiff';
import { SCHEMA_TYPE_ROW_ID, SCHEMA_TYPE_NEGATABLE_NETADDR_LIST, SCHEMA_TYPE_NETADDR, SCHEMA_TYPE_NETPORT } from '~sharedLib/schemaTypes';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter';
import { changeHeader, getActiveDiffCardHook } from '~frontendRoot/ducks/activeDiffersCards';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge';
import { pickParser } from '~frontendComponents/Generic/Select/Select';
import { findTranslationByHlcfgPath } from '~sharedLib/hlcfg/findTranslationByHlcfgPath';
import { SelectOptionsHeader } from '~frontendRoot/constants/types';
import {  getSelectedLanguage, } from '~frontendDucks/language';
import { LONG_TEXT_LENGTH, TEXTAREA, PASSWORD, TEXT, FAKE_FUNCTION } from '~frontendConstants';
import {
    DEFAULT_SCHEMA_VALUE,
    DURATION_SCHEMA_VALUE,
    FILE_SCHEMA_VALUE,
    PASSWORD_SCHEMA_VALUE,
    PLACEHOLDER_SCHEMA_VALUE, TRANSLATION_SCHEMA_VALUE
} from '~commonLib/schemaFlags';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils';

import DiffersPath from './DiffersPath';
import { CategoryTranslationContext } from './Differs';


const getInputTypeFromSchema = (value: string, schema): string => {
    switch (true) {
    case schema[PASSWORD_SCHEMA_VALUE]:
        return PASSWORD;
    case /\n/.test(value) ||
            value?.length >= LONG_TEXT_LENGTH:
        return TEXTAREA;
    default:
        return TEXT;
    }

};

const createHeaderFallbackDomino = (arr: string[], diff: HlcfgDiff): string[] => {
    const returnArray: string[] = [];
    const path: string[] = [ ...arr ];
    arr.forEach(() => {
        returnArray.push(`${path.join('.')}.${diffType(diff)}`);
        path.pop();
    });
    returnArray.push(`differs:fallback.header.${diffType(diff)}`);
    return returnArray;
};

type basicTableItem = {
    name?: string,
    color?: string,
    id?: string,
    fake?: string
}

interface DifferCardProps  {
    diff: HlcfgDiff,
    numberOfSameDiffChanges: number,
}

const DifferCard = ({ diff, numberOfSameDiffChanges }:
    DifferCardProps) => {
    const schema = useSelector(getHlcfgSchema);
    const originalDiff = deepCloneAndMerge(diff);
    const initTables = useSelector(getInitGlcfgTables);
    const tables = useSelector(getGlcfgTables);
    const language = useSelector(getSelectedLanguage);
    const type: TypeOfScene = diff.hlcfgDescriptivePath[0] as TypeOfScene;
    const isClosed = useSelector<any, boolean>(getActiveDiffCardHook(type));
    const dispatch = useDispatch();
    const options: SelectOptionsHeader = useSelector(getAllOptions);

    const clickIcon = () => {
        dispatch(changeHeader({ toClose: type, value: !isClosed }));
    };

    const resetChange = () => {
        dispatch(undoDiffFunction(originalDiff));
    };

    let item: basicTableItem = {};
    const reorderItems: basicTableItem[] = [];

    if (diff.hlcfgRealPath.includes('tables')) {
        const table = diff.hlcfgRealPath[diff.hlcfgRealPath.indexOf('tables') + 1];
        const itemUuid = diff.hlcfgRealPath[diff.hlcfgRealPath.indexOf('tables') + 2];
        item = initTables[table][itemUuid];
    }
    if (diffType(diff) === REORDER_DIFF_TYPE) {
        diff.toValue.forEach(id => reorderItems.push(tables[id.split(':')[0]][id]));
    }
    const localSchema = findSchemaByObjectPathAndSchema(diff.hlcfgRealPath, schema);
    const translationLine = findTranslationByHlcfgPath(diff.hlcfgRealPath);
    const header = translationLine.split('.');
    if (diffType(diff) === CHANGE_DIFF_TYPE) {
        header.pop();
    }
    const { t } = useTranslation();
    return (
        <>
            <div className={`differs__${diff.hlcfgDescriptivePath[0]}`}>
                <MDBCardTitle
                    className="differs__header"
                    onClick={clickIcon}
                >
                    {t(`differs:headers.${type}`)}
                    <span
                        className={classNames('scrollMenu__number ml-2 differs__changes__number')}
                        data-cy="usage"
                    >
                        {numberOfSameDiffChanges}
                    </span>

                    <Icon
                        className="ml-3"
                        name={isClosed ? 'chevron-down' : 'chevron-up'}
                    />
                </MDBCardTitle>
            </div>
            {!isClosed &&
            <div className={'differs__differ'}>
                <div className="differs__differ__title">
                    {`${t(createHeaderFallbackDomino(header, diff))}`}
                    <CloseWithLock
                        datacy={`close${diffType(diff)}`}
                        onClick={resetChange}

                    />
                </div>
                <div className="differs__differ__desc">
                    {(diffType(diff) === CHANGE_DIFF_TYPE) &&
                <MDBRow className={localSchema?.type === 'boolean' ?
                    '' : 'mt-1'}
                >
                    <MDBCol size={'12'}>
                        <PickEditor
                            diff={diff}
                            item={item}
                            language={language}
                            localSchema={localSchema}
                            options={options}
                            translationLine={translationLine}
                            value={localSchema?.type === 'boolean' ? diff.toValue : diff.fromValue}
                        />

                    </MDBCol>
                    {localSchema?.type !== 'boolean' && diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] !== '__off' &&
                    <>
                        <MDBCol
                            className="mt-1"
                            size={'12'}
                        >
                            <Icon
                                className="ml-2 differs__differ__icon"
                                name="undo"
                                size="sm"
                            />
                            <PickEditor
                                diff={diff}
                                item={item}
                                language={language}
                                localSchema={localSchema}
                                options={options}
                                toValue="toValue"
                                translationLine={translationLine}
                                value={diff.toValue}
                            />
                        </MDBCol>
                    </>
                    }
                </MDBRow>
                    }
                    {diffType(diff) === ADD_DIFF_TYPE &&
                    <MDBRow>
                        <MDBCol className="differs__differ__new">
                            <pre
                                className="fit-content ml-1 pt-2 pb-2 pl-3 pr-3"
                                style={{
                                    backgroundColor: diff.toValue?.color
                                }}
                            >{diff.toValue?.name || diff.toValue?.comment || t(translationLine + '.title')}
                            </pre>
                        </MDBCol>
                    </MDBRow>
                    }
                    {diffType(diff) === DELETE_DIFF_TYPE &&
                    <MDBRow>
                        <MDBCol className="differs__differ__new">
                            <pre
                                className="fit-content ml-1 pt-2 pb-2 pl-3 pr-3"
                                style={{
                                    backgroundColor: diff.fromValue?.color
                                }}
                            >{diff.fromValue?.name || diff.fromValue?.comment || t(translationLine + '.title')}
                            </pre>
                        </MDBCol>
                    </MDBRow>
                    }
                    {diffType(diff) === REORDER_DIFF_TYPE &&
                    <MDBRow>
                        <MDBCol>
                            {reorderItems.every(item => item?.name) ?
                                <>
                                    {t('differs:reorder')}
                                    {reorderItems?.map(item => (
                                        <pre
                                            className="fit-content mb-0 pt-2 pb-2 pl-3 pr-3"
                                            key={item.id}
                                            style={{
                                                backgroundColor: item?.color
                                            }}
                                        >{item?.name}
                                        </pre>
                                    ))}
                                </> :
                                t('differs:reorderWithoutNames')}
                        </MDBCol>
                    </MDBRow>
                    }
                    <Icon
                        className="differs__differ__typeIcon"
                        height={32}
                        name={diffType(diff)}
                        size="own"
                        width={32}
                    />
                </div>

                <DiffersPath
                    cutLast={true}
                    notClicable={diffType(diff) === DELETE_DIFF_TYPE}
                    paths={diff.hlcfgDescriptivePath}
                />

            </div>
            }
        </>
    );
};

export default DifferCard;

const PickEditor = ({ diff, value, translationLine, localSchema, toValue, options, language }: {
    diff: HlcfgDiff, item: basicTableItem, value: HlcfgDiff['fromValue'],
    translationLine: string, localSchema: any, toValue?: string, options: SelectOptionsHeader, language: string}) => {
    const { t } = useTranslation();
    const translations = useContext(CategoryTranslationContext);
    if (!localSchema) { //off + unknown
        if (diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] === '__off' &&
         !diff.hlcfgRealPath.includes('tables')) {
            return getButtons(value, t);
        }
        if ((diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] === '__off' ||
        diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] === 'enabled')  && diff.hlcfgRealPath.includes('tables')) {
            return (
                <>
                    <Switch
                        align="start"
                        checked={value}
                        className="m-0"
                        datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                        fake
                        id={translationLine}
                        label={
                            t('differs:off.title')}
                        onChange={FAKE_FUNCTION}
                        randomId
                        withoutBorder
                    />
                </>

            );
        }
        return null;
    }


    if (localSchema?.type === 'string' && !localSchema.enum && !localSchema[SCHEMA_TYPE_ROW_ID]) {
        const numberOfLines = (value?.match(/\n/g)?.length ?? 0) + 1;
        return (
            <Input
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                label={t(`${
                    localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
                }`)}
                name="name"
                placeholder={localSchema[PLACEHOLDER_SCHEMA_VALUE] ? t(`${
                    localSchema[PLACEHOLDER_SCHEMA_VALUE]
                }`) : undefined}
                readOnly
                //if this is textarea show max 10 rows
                rows={Math.max(5, Math.min(numberOfLines, 10))}
                type={getInputTypeFromSchema(value, localSchema)}
                value={value}
                withoutBorder
            />
        );
    }
    if (localSchema[FILE_SCHEMA_VALUE]) {
        return (
            <Input
                className="license__upload"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                disabled
                fakeFile={value}
                label={t(`${
                    localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
                }`)}
                maxSize={2000000}
                name={'name'}
                outline={false}
                renderId
                schema={localSchema}
                type="file"

            />
        );
    }
    if (localSchema?.type === 'integer' || localSchema?.type === 'number') {
        return (
            <Input
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                disabled
                label={t(`${
                    localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
                }`)}
                name="name"
                number
                placeholder={localSchema[DEFAULT_SCHEMA_VALUE] ?
                    localSchema[DURATION_SCHEMA_VALUE] ? valueFormatter.formatSeconds(
                        localSchema[DEFAULT_SCHEMA_VALUE]
                    ) :
                        localSchema[DEFAULT_SCHEMA_VALUE] :
                    undefined}
                value={localSchema[DURATION_SCHEMA_VALUE] && value ? valueFormatter.formatSeconds(value) : value}
                withoutBorder
            />
        );
    }
    if (localSchema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {

        return (
            <NetworkInterfaceDevice
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                fake={true}
                iface={stringifyAddress(value)}
                isMulti
                isRow
                label={t(`${
                    localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
                }`)}
                noDropdownIndicator
                noOptionsMessage
                paste={false}
                placeholder={t(`${
                    localSchema[PLACEHOLDER_SCHEMA_VALUE]
                }`)}
                schema={localSchema}
                withAllValues
                withoutBorder
            />
        );
    }
    if (localSchema?.type === 'boolean') {
        if (diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] === 'enabled' &&
         !diff.hlcfgRealPath.includes('tables')) {
            return getButtons(value, t);
        }
        const tranArr = translationLine.split('.');
        const fromTranslations =  translations[tranArr[tranArr.length - 1]]?.[language]?.title;
        return (
            <Switch
                align="start"
                checked={value}
                className="m-0"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                fake
                id={fromTranslations}
                label={
                    fromTranslations ||
                    t(`${localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
                    }`)}
                name={'name'}
                onChange={FAKE_FUNCTION}
                schema={localSchema}
                withoutBorder
            />
        );
    }
    if (localSchema.enum || localSchema.additionalItems?.enum) {
        const tranArr = translationLine.split('.');
        const fromTranslations = translations[tranArr[tranArr.length - 1]]?.[language]?.title;
        return (
            <Select
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                enumTranslationDifferPath={fromTranslations ? tranArr.slice(0, -1).join('.') : translationLine}
                fake
                isMulti={localSchema.additionalItems}
                label={fromTranslations || t(`${
                    localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine + '.title'
                }`)}
                name="enum"
                noDropdownIndicator
                noOptionsMessage
                paste={false}
                schema={localSchema}
                value={value}
            />
        );
    }
    if (localSchema['anyOf'] && !localSchema[SCHEMA_TYPE_NETADDR] && !localSchema[SCHEMA_TYPE_NETPORT]) {
        return (
            <NetworkInterfaceDevice
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                fake={true}
                iface={value ? stringifyAddress(value, true, pickParser(localSchema['anyOf'][0])) : []}
                isMulti
                isRow
                label={t(`${
                    localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
                }`)}
                noDropdownIndicator
                noOptionsMessage
                paste={false}
                placeholder={localSchema[PLACEHOLDER_SCHEMA_VALUE] && t(`${
                    localSchema[PLACEHOLDER_SCHEMA_VALUE]
                }`)}
                schema={localSchema['anyOf'][0]}
                withAllValues
                withoutBorder
            />
        );
    }
    return (
        <NetworkInterfaceDevice
            className="dataTableWidget__RowInput"
            datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
            fake={true}
            iface={value ? stringifyAddress(value, true, pickParser(localSchema)) : []}
            isMulti
            isRow
            label={t(`${
                localSchema[TRANSLATION_SCHEMA_VALUE] || translationLine
            }`)}
            noDropdownIndicator
            noOptionsMessage
            options={options}
            paste={false}
            placeholder={localSchema[PLACEHOLDER_SCHEMA_VALUE] && t(`${
                localSchema[PLACEHOLDER_SCHEMA_VALUE]
            }`)}
            schema={localSchema}
            withAllValues
            withoutBorder
        />
    );
};

const getButtons = (value, t) => {
    return (
        <>
            {value ?
                <MDBBtn
                    className="navigation__button pulse startButton noEffects"
                    data-cy="turnOn"
                    disabled={!value}
                    size="sm"
                    type="button"
                >
                    {t('differs:off.turnOn')}
                </MDBBtn> :
                <MDBBtn
                    className="navigation__button ml-1 pulse noEffects"
                    color="red"
                    data-cy="turnOff"
                    disabled={value}

                    size="sm"
                    type="button"
                >
                    {t('differs:off.turnOff')}
                </MDBBtn>
            }
        </>

    );
};
