/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ValuesType } from 'utility-types';

import { makeStringConstsObj } from '~commonLib/objectUtils';

import { icaNode, NODE_A_ID, NODE_B_ID, POSTGRESQL_VERSION } from './constants';


export const SUPERTYPE_STATISTICS = 'statistics';
export const SUPERTYPE_NONE = 'none';
export const SUPERTYPE_OS = 'os';
export const SUPERTYPE_NETWORK = 'network';
export const SUPERTYPE_USER_SERVICES = 'userServices';

export const SYSTEM_COMPONENT_AK_BACKEND = 'ak-backend';
export const SYSTEM_COMPONENT_HONEYPOT = 'honeypot';
export const SYSTEM_COMPONENT_NETWORKING = 'netmasterplan';
export const SYSTEM_COMPONENT_CALLHOME = 'callhome';
export const SYSTEM_COMPONENT_NTPD = 'ntpd';
export const SYSTEM_COMPONENT_DHCPD = 'dhcpd';
export const SYSTEM_COMPONENT_SSHD = 'sshd';
export const SYSTEM_COMPONENT_SURICATA = 'suricata';
export const SYSTEM_COMPONENT_PROXY = 'proxy';
export const SYSTEM_COMPONENT_NFTABLES = 'nftables';
export const SYSTEM_COMPONENT_KRB5_TICKET_REFRESH = 'krb5-ticket-refresh';
export const SYSTEM_COMPONENT_SYSTEMCTL_DAEMON = 'systemctl-daemon';
export const SYSTEM_COMPONENT_SYSCTL = 'sysctl';
export const SYSTEM_COMPONENT_JOURNALD = 'systemd-journald';
export const SYSTEM_COMPONENT_HOSTNAME = 'hostname';
export const SYSTEM_COMPONENT_NAMED = 'named';
export const SYSTEM_COMPONENT_SYSTEM_RESOLVER = 'named-local-resolver';
export const SYSTEM_COMPONENT_SNMPD = 'snmpd';
export const SYSTEM_COMPONENT_TIMEZONE = 'timezone';
export const SYSTEM_COMPONENT_ICASD_TUNNEL_A = `icasd-tunnel@${icaNode(NODE_A_ID)}`;
export const SYSTEM_COMPONENT_ICASD_TUNNEL_B = `icasd-tunnel@${icaNode(NODE_B_ID)}`;
export const SYSTEM_COMPONENT_REPORTER_DB = 'reporter-db';
export const SYSTEM_COMPONENT_CLAMAV_DAEMON = 'clamav-daemon';
export const SYSTEM_COMPONENT_CLAMAV_FRESHCLAM = 'clamav-freshclam';
export const SYSTEM_COMPONENT_RSYSLOG = 'rsyslog';
export const SYSTEM_COMPONENT_WATCHDOG = 'watchdog';
export const SYSTEM_COMPONENT_STRONGSWAN = 'strongswan-starter';
export const SYSTEM_COMPONENT_KLOGD = 'klogd';
export const SYSTEM_COMPONENT_KPF_RESOLVER = 'kpf-resolver';
export const SYSTEM_COMPONENT_KPF_POSTNETWORK = 'kpf-postnetwork-updater';
export const SYSTEM_COMPONENT_BACKEND_DB = `postgresql@${POSTGRESQL_VERSION}-akweb`;
export const SYSTEM_COMPONENT_KEEPALIVED = 'keepalived';

const IMPORTANT_SYSTEM_COMPONENTS = [
    SYSTEM_COMPONENT_BACKEND_DB, SYSTEM_COMPONENT_KPF_POSTNETWORK, SYSTEM_COMPONENT_KPF_RESOLVER,
    SYSTEM_COMPONENT_KLOGD, SYSTEM_COMPONENT_ICASD_TUNNEL_A, SYSTEM_COMPONENT_ICASD_TUNNEL_B,
    SYSTEM_COMPONENT_SYSTEM_RESOLVER, SYSTEM_COMPONENT_NAMED, SYSTEM_COMPONENT_JOURNALD, SYSTEM_COMPONENT_SYSCTL,
    SYSTEM_COMPONENT_NFTABLES, SYSTEM_COMPONENT_PROXY, SYSTEM_COMPONENT_SURICATA, SYSTEM_COMPONENT_SSHD,
    SYSTEM_COMPONENT_DHCPD, SYSTEM_COMPONENT_NTPD, SYSTEM_COMPONENT_CALLHOME, SYSTEM_COMPONENT_NETWORKING,
    SYSTEM_COMPONENT_AK_BACKEND,
];

export const systemComponentAction = makeStringConstsObj([
    'disable', 'enable', 'start', 'status', 'stop', 'restart', 'reload'
]);

export type ComponentAction = ValuesType<typeof systemComponentAction>;

/**
 * Actions that are "temporary" as they do not persist any state through system reboot.
 */
export type ComponentTemporaryAction = Exclude<ComponentAction, 'enable'|'disable'>

export const ACTIONS_WITHOUT_RELOAD: ComponentAction[] = [ 'disable', 'enable', 'start', 'status', 'stop', 'restart' ];
export const ACTIONS_WITH_RELOAD: ComponentAction[] = [ ...ACTIONS_WITHOUT_RELOAD, 'reload' ];

export type SystemComponentDeclaration = {
    name: string,
    actions: ComponentAction[],
    before?: string[], // actions of this component, should be done before components specified, allows '*'
    after?: string[], // actions of this component, should be done after components specified, allows '*'
    superType: string, // under which section component will be displayed on frontend
    takesLongTime?: ComponentAction[], // which actions take long time
    onMasterGain?: ComponentTemporaryAction, // in cluster, which action to do when gained master
    onMasterLost?: ComponentTemporaryAction, // in cluster, which action to do when lost master
    postponeOnUpgrade?: boolean,
    /** Sysmgr checks whether every component have enable or disable action. This bypasses that check for a component */
    exceptionFromMandatoryComponentAction?: boolean,
    //if there is actionIf of componentType present, also generate actionDo of this component
    systemComponentDependencies?: {
        componentType: string,
        actionIf: ComponentAction,
        actionDo: ComponentAction,
    }[]
}


/**
 * the order of this array is the order in which the components are to be started in sysmgr
 * and displayed in frontend on system / management / system components
 *
 * For system component customization see systemComponentsCustomizers.js
 */
export const systemComponentsDeclaration: SystemComponentDeclaration[] = [
    { name: SYSTEM_COMPONENT_KRB5_TICKET_REFRESH, actions: [ 'reload' ], superType: SUPERTYPE_NONE },
    {
        name: SYSTEM_COMPONENT_SYSTEMCTL_DAEMON,
        superType: SUPERTYPE_OS,
        actions: [ 'reload', 'restart' ],
        before: [ '*' ]
    },
    {
        name: SYSTEM_COMPONENT_SYSCTL,
        superType: SUPERTYPE_OS,
        actions: [ 'reload' ],
        after: [ SYSTEM_COMPONENT_NETWORKING ],
    },
    { name: SYSTEM_COMPONENT_JOURNALD, actions: [ 'reload' ], superType: SUPERTYPE_NONE },
    { name: SYSTEM_COMPONENT_KLOGD, actions: ACTIONS_WITH_RELOAD, superType: SUPERTYPE_NONE },
    { name: 'ak-sysmgr', superType: SUPERTYPE_OS, actions: [ 'enable', 'restart', 'start', 'status' ] },
    {
        name: SYSTEM_COMPONENT_NETWORKING,
        actions: [ 'enable', 'reload', 'restart' ],
        superType: SUPERTYPE_NETWORK,
    },
    { name: SYSTEM_COMPONENT_HOSTNAME, superType: SUPERTYPE_NETWORK, actions: [ 'reload' ] },
    {
        name: SYSTEM_COMPONENT_NAMED,
        superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITH_RELOAD,
        after: [ SYSTEM_COMPONENT_NETWORKING ],
    },
    {
        name: SYSTEM_COMPONENT_SYSTEM_RESOLVER,
        superType: SUPERTYPE_NETWORK,
        actions: [ 'restart', 'enable' ],
        // dhcpd is here to force dhcpd to get disabled before resolver is
        // started so these actions to not happen at the same time.
        after: [ SYSTEM_COMPONENT_NETWORKING, SYSTEM_COMPONENT_DHCPD ],
    },
    { name: SYSTEM_COMPONENT_RSYSLOG, superType: SUPERTYPE_STATISTICS, actions: [ 'restart', 'enable' ] },

    // those whose order doesn't matter
    { name: SYSTEM_COMPONENT_BACKEND_DB, superType: SUPERTYPE_OS,
        actions: [ 'enable', 'reload', 'restart', 'start', 'status' ] },
    {
        name: SYSTEM_COMPONENT_AK_BACKEND,
        superType: SUPERTYPE_OS,
        actions: [ 'enable', 'reload', 'restart', 'start', 'status' ],
    },
    {
        name: 'ak-sysmon',
        superType: SUPERTYPE_OS,
        actions: [ 'enable', 'restart', 'start', 'status' ]
    },
    {
        name: SYSTEM_COMPONENT_DHCPD,
        superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITHOUT_RELOAD,
        after: [ SYSTEM_COMPONENT_NETWORKING ],
    },
    { name: 'icamd', superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITHOUT_RELOAD },
    { name: 'icasd', superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITHOUT_RELOAD },
    { name: SYSTEM_COMPONENT_ICASD_TUNNEL_A, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITHOUT_RELOAD },
    { name: SYSTEM_COMPONENT_ICASD_TUNNEL_B, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITHOUT_RELOAD },
    {
        name: SYSTEM_COMPONENT_KEEPALIVED, superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITH_RELOAD,
        // Keepalived is both after and before NETWORKING, for that reason it can not be specified here
        // and must be specified with individual actions in generator.
        //after: [ SYSTEM_COMPONENT_NETWORKING ]
    },
    { name: SYSTEM_COMPONENT_NTPD, superType: SUPERTYPE_USER_SERVICES, actions: ACTIONS_WITHOUT_RELOAD },
    {
        name: SYSTEM_COMPONENT_NFTABLES, superType: SUPERTYPE_NETWORK,
        actions: [ 'reload', 'restart', 'status', 'enable' ]
    },
    {
        name: SYSTEM_COMPONENT_PROXY,
        superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITH_RELOAD,
        after: [ SYSTEM_COMPONENT_NFTABLES ],
    },
    {
        name: SYSTEM_COMPONENT_CALLHOME,
        superType: SUPERTYPE_NETWORK,
        actions: [ 'disable', 'enable', 'status' ],
        exceptionFromMandatoryComponentAction: true
    },
    {
        name: SYSTEM_COMPONENT_REPORTER_DB, superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITH_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: 'reporter-log@journal-honeypot', superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITHOUT_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: 'reporter-log@journal-nftables', superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITHOUT_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: 'reporter-log@journal-suricata', superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITHOUT_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: 'reporter-log@journal-sysmon', superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITHOUT_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: 'reporter-log@journal-proxy', superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITHOUT_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: 'reporter-mgr', superType: SUPERTYPE_STATISTICS,
        actions: ACTIONS_WITHOUT_RELOAD, postponeOnUpgrade: true,
        after: IMPORTANT_SYSTEM_COMPONENTS,
    },
    {
        name: SYSTEM_COMPONENT_SNMPD, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITHOUT_RELOAD,
        after: [ SYSTEM_COMPONENT_NETWORKING ]
    },
    {
        name: SYSTEM_COMPONENT_SSHD, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITHOUT_RELOAD,
        after: [ SYSTEM_COMPONENT_NETWORKING ]
    },
    {
        name: SYSTEM_COMPONENT_SURICATA,
        superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITH_RELOAD,
        after: [ SYSTEM_COMPONENT_NFTABLES ],
        takesLongTime: [ 'restart', 'reload', 'enable' ],
    },
    { name: SYSTEM_COMPONENT_TIMEZONE, superType: SUPERTYPE_USER_SERVICES, actions: [ 'reload' ] },
    {
        name: SYSTEM_COMPONENT_WATCHDOG, superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITH_RELOAD, after: [ SYSTEM_COMPONENT_NETWORKING ]
    },
    {
        name: SYSTEM_COMPONENT_CLAMAV_FRESHCLAM, superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITHOUT_RELOAD, after: [ SYSTEM_COMPONENT_NETWORKING ],
    },
    {
        name: SYSTEM_COMPONENT_CLAMAV_DAEMON, superType: SUPERTYPE_NETWORK,
        actions: ACTIONS_WITH_RELOAD, after: [ SYSTEM_COMPONENT_CLAMAV_FRESHCLAM ],
    },
    {
        name: SYSTEM_COMPONENT_HONEYPOT, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITH_RELOAD,
        before: [ SYSTEM_COMPONENT_NFTABLES ]
    },
    {
        name: SYSTEM_COMPONENT_KPF_RESOLVER, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITH_RELOAD,
        before: [ SYSTEM_COMPONENT_NFTABLES ]
    },
    {
        name: SYSTEM_COMPONENT_KPF_POSTNETWORK, superType: SUPERTYPE_NETWORK, actions: ACTIONS_WITH_RELOAD,
        after: [ SYSTEM_COMPONENT_NAMED, SYSTEM_COMPONENT_SYSTEM_RESOLVER ]
    },
    {
        name: SYSTEM_COMPONENT_STRONGSWAN, superType: SUPERTYPE_USER_SERVICES, actions: ACTIONS_WITH_RELOAD,
        after: [ SYSTEM_COMPONENT_NETWORKING ],
        onMasterGain: 'restart',
        onMasterLost: 'stop',
    },
];

export const systemComponentsDeclarationByName: Record<string, SystemComponentDeclaration> =
    systemComponentsDeclaration.reduce((acc, declaration) => {
        return { ...acc, [declaration.name]: declaration };
    }, {});
