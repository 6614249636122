/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { createReducer, createSelector } from '@reduxjs/toolkit';

import { compareDeep } from '~frontendLib/arrayUtils';
import { NODE_A_ID, NODE_B_ID } from '~sharedConstants';
import { NODE_SELF } from '~commonLib/constants';

import { getApiError } from '../../lib/apiUtils';
import { LOOPBACK_NAME } from '../systemComponents';
import { getMyNode } from '../clusterSetup';

// actions
const GET_NETWORK_INTERFACES_REQUEST = 'ak/networkInterfaces/GET_NETWORK_INTERFACES';
const GET_NETWORK_INTERFACES_SUCCESS = 'ak/networkInterfaces/GET_NETWORK_SUCCESS';
const GET_NETWORK_INTERFACES_ERROR = 'ak/networkInterfaces/GET_NETWORK_ERROR';
const GET_NETWORK_INFO_SUCCESS = 'ak/networkInterfaces/GET_NETWORK_INFO_SUCCESS';


// initialState
const initialState = {
    data: [],
    info: {},
    error: {},
    loading: false,
    showHidden: false,
};

// reducer
const reducer = createReducer(initialState, {
    [GET_NETWORK_INTERFACES_REQUEST]: (state) => {
        state.loading = true;
        state.error = {};
    },
    [GET_NETWORK_INTERFACES_SUCCESS]: (state, action) => {
        if (!compareDeep(state.data, action.payload)) {
            state.data = action.payload;
        }
        state.loading = false;
        state.error = {};
    },
    [GET_NETWORK_INTERFACES_ERROR]: (state, action) => {
        state.error = action.payload;
        state.loading = false;
    },
    [GET_NETWORK_INFO_SUCCESS]: (state, action) => {
        if (!compareDeep(state.info, action.payload)) {
            state.info = action.payload;
        }
        state.loading = false;
        state.error = {};
    },
});

export default reducer;

// data accessors
const getState = state => state.networkInterfaces;

export const getIsLoading = state => getState(state).loading;
export const getNetworkInterfaces = createSelector(
    getState,
    (state) => {
        return state.data[NODE_SELF] || state.data[NODE_A_ID]?.concat(state.data[NODE_B_ID]) || state.data;
    }
);
export const getNetworkInfo = createSelector(
    getState,
    (state) => state.info[NODE_SELF] || state.info[NODE_A_ID]?.concat(state.info[NODE_B_ID]) || state.info,
);
export const getError = state => getState(state).error;

/**
 * @param {object} state
 * @param {string} node
 */
export const getNetworkInterfacesFromNode = createSelector([
    getState,
    (state, node) => node
],
(state, node) => filterDevices(state, node));

export const getNetworkInterfacesFromMyNode = createSelector([
    getState,
    getMyNode,
],
(state, node) => filterDevices(state, node));


const filterDevices = (state, type) => (
    state.data[type] || state.data || []
).filter(item => {
    if (item.name === LOOPBACK_NAME) {
        return false;
    }
    if (item.name.startsWith('vlan') || item.name.startsWith('tun') || item.name.startsWith('tap')) {
        return false;
    }
    return true;
});

export const getHardwareInfoModalOpen = (state, type) => getState(state)[type];


// action creators
const setter = type => payload => ({ type, payload });
export const getNetworkInterfacesRequest = setter(GET_NETWORK_INTERFACES_REQUEST);
export const getNetworkInterfacesSuccess = setter(GET_NETWORK_INTERFACES_SUCCESS);
export const getNetworkInfoSuccess = setter(GET_NETWORK_INFO_SUCCESS);
export const getNetworkInterfacesError = setter(GET_NETWORK_INTERFACES_ERROR);


export const apiCallGetNetworkInterfaces = async () => {
    return axios.get('/api/system/getNetworkInterfaces');
};

export const apiCallGetNetworkInfo = async () => {
    return axios.get('/api/system/getNetworkInfo');
};

const workerGetNetworkInterfaces = function* () {
    try {
        while (true) {
            const { data } = yield call(apiCallGetNetworkInterfaces);
            const info = yield call(apiCallGetNetworkInfo);

            yield put(getNetworkInterfacesSuccess(data));
            yield put(getNetworkInfoSuccess(info.data));

            yield delay(30000);
        }
    } catch (error) {
        yield put(getNetworkInterfacesError(getApiError(error)));
    }
};
// sagas
export const sagas = [
    takeLatest(GET_NETWORK_INTERFACES_REQUEST, workerGetNetworkInterfaces),
];
