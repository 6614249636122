/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment, { TIME_FORMAT } from '~commonLib/moment';

import tFilter from './tFilter';
import staticData from './staticData';
import upToDateData from './upToDateData';
import valueFormatter from './valueFormatter';


const formatMoment = function(value, format) {
    const time = moment(value);
    return time.isValid() ? time.format(format) : '?';
};

const formatTrendNumber = origNumber => {
    let validNumberCounter = 0;
    const numbers = '0123456789';
    let formattedResult = '';
    let afterFloat = false;
    for (const number of origNumber) {
        if (numbers.indexOf(number) !== -1) {
            if (validNumberCounter < 2) {
                formattedResult += number;
                ++validNumberCounter;
            } else {
                if (!afterFloat) {
                    formattedResult += '0';
                }
            }
        }
        if (number === ',' || number === '.') {
            afterFloat = true;
            if (validNumberCounter < 2) {
                formattedResult += number;
            }
        }
        if (number === '+' || number === '%' || number === '\u00D7' || number === '-') {
            formattedResult += number;
        }
    }
    return formattedResult;
};

const vFilter = function(value, type, options) {
    if (!options) {
        options = {};
    }
    if (options.nonempty && !value) {
        return;
    }
    switch (type) {
    case 'isClusterMaster':
        return tFilter('global:node-is-master', {
            node: value ? staticData.currentSystem : staticData.otherSystems[0]
        });
    case 'pikeState':
        return tFilter(value ? 'global:pike-master' : 'global:pike-backup', {});
    case 'pikeReady':
        return tFilter(value ? 'global:pike-up' : 'global:pike-down', {});
    case 'pikePrio':
        return tFilter(value ? 'global:pike-primary' : 'global:pike-secondary', {});
    case 'error':
        return value && typeof value === 'object' ?
            (value.i18n && tFilter(value.i18n)) || value.data ||
            value.message || value :
            value || '';
    case 'datetime':
        return formatMoment(value, TIME_FORMAT.userDateTimeShort);
    case 'generated':
        return tFilter('report:generated-at') + '\xa0' +
            formatMoment(value, 'LLLL');
    case 'datetime_milliseconds': { // milliseconds since 1970
        const m1 = moment(value);
        return m1.isValid() ? m1.unix() * 1000 + m1.milliseconds() : '?';
    }
    case 'in-x-days':
        value = parseInt(value) || 0;
        return tFilter('global:in-x-days_interval', { count: value, postProcess: 'interval' });
    case 'date':
        return formatMoment(value, 'll');
    case 'dddd LL':
        return formatMoment(value, 'dddd LL');
    case 'dddd LLL':
        return formatMoment(value, 'dddd LLL');
    case 'LL':
        return formatMoment(value, 'LL');
    case 'LLL':
        return formatMoment(value, TIME_FORMAT.userDateTimeFull);
    case 'LLLL':
        return formatMoment(value, 'LLLL');
    case 'timestamp':
        return formatMoment(value, options.format || 'll LTS');
    case 'duration-short':
        return valueFormatter.formatSeconds(value, { isShort: true, isTextLong: true });
    case 'duration-ago':
        return valueFormatter.formatSeconds(value, { isShort: true, isTextAgo: true });
    case 'duration-fuzzy':
        return valueFormatter.formatSeconds(value, { isShort: true, isTextLong: true, isFuzzy: true  });
    case 'duration':
    case 'seconds':
        return valueFormatter.formatSeconds(value);
    case 'bytes':
    case 'traffic':
        return valueFormatter.formatBytes(value, options.precision);
    case 'bits':
        return valueFormatter.formatBits(value, options.precision);
    case 'bytesPerSecond':
    case 'speed':
        return valueFormatter.formatBytes(value, options.precision) + '/s';
    case 'bytesPerMinute':
        return valueFormatter.formatBytes(value, options.precision) + '/min';
    case 'bitsPerSecond':
        return valueFormatter.formatBits(value, options.precision) + '/s';
    case 'count':
    case 'sum':
        return valueFormatter.formatCount(value, options.precision);
    case 'countPerSecond':
        return valueFormatter.formatCount(value, options.precision) + '/s';
    case 'time':
        return formatMoment(value, 'LT');
    case 'moment':
        return formatMoment(value, options.format || 'LL LTS');
    case 'time-ago': {
        const m2 = moment(upToDateData.currSys.serverTime);
        return m2.isValid() ? vFilter(m2.diff(value, 'second'), 'duration-ago') : '?';
    }
    case 'time-short': {
        const m3 = moment(upToDateData.currSys.serverTime);
        return m3.isValid() ? vFilter(m3.diff(value, 'second'), 'duration-short') : '?';
    }
    case 'temperature':
        return valueFormatter.formatTemperature(value, options.precision);
    case 'percent':
        return valueFormatter.formatPercent(value && 100 * parseFloat(value), options.precision);
    case 'percentHundred':
        return valueFormatter.formatPercent(value, options.precision);
    case 'trendChartDiff': {
        let difference;
        let result;
        if (value.older !== 0) {
            difference = value.newer * 100 / value.older - 100;
            if (difference > 1000) {
                difference = (difference / 100) + 1;
                if (difference > 100) {
                    difference = Infinity;
                    return '\u221E';
                } else {
                    result = valueFormatter.formatCount(difference) + ' \u00D7';
                }
            } else {
                result = (difference < 0 ? '' : '+') + valueFormatter.formatPercent(difference, 0);
            }
        } else {
            difference = Infinity;
            return '\u221E';
        }
        return formatTrendNumber(result);
    }
    default:
        return value;
    }
};

export default vFilter;
