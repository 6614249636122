/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { getApiError } from '~frontendLib/apiUtils';
import {
    allRolesSelectOptions, DEFAULT_SELECTED_ROLE
} from '~frontendScenes/Users/scenes/UsersListScene/utils/userRoleUtils';


// actions
const LOAD_ALL_USERS_REQUEST = 'ak/userManagement/allUsers/LOAD_ALL_USERS_REQUEST';
const LOAD_ALL_USERS_SUCCESS = 'ak/userManagement/allUsers/LOAD_ALL_USERS_SUCCESS';
const LOAD_ALL_USERS_ERROR = 'ak/userManagement/allUsers/LOAD_ALL_USERS_ERROR';

const LOAD_ALL_USERS_LAST_LOGIN_REQUEST = 'ak/userManagement/allUsers/LOAD_ALL_USERS_LAST_LOGIN_REQUEST';
const LOAD_ALL_USERS_LAST_LOGIN_SUCCESS = 'ak/userManagement/allUsers/LOAD_ALL_USERS_LAST_LOGIN_SUCCESS';
const LOAD_ALL_USERS_LAST_LOGIN_ERROR = 'ak/userManagement/allUsers/LOAD_ALL_USERS_LAST_LOGIN_ERROR';

const SET_USER_FILTER_SHOW_DEACTIVATED = 'ak/userManagement/allUsers/SET_SHOW_DEACTIVATED';
const SET_USER_FILTER_SELECTED_FILTER = 'ak/userManagement/allUsers/SET_USER_FILTER_SELECTED_FILTER';
const SET_USER_FILTER_VISBLE = 'ak/userManagement/allUsers/SET_USER_FILTER_VISBLE';


// initial state
const initialState = {
    usersIsLoading: false,
    usersError: null,
    users: [],
    lastLogins: null,
    lastLoginsIsLoading: false,
    lastLoginsError: null,
    filter: {
        showDeactivated: false,
        selectedFilter: DEFAULT_SELECTED_ROLE,
        visible: false,
    }
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOAD_ALL_USERS_REQUEST:
        return {
            ...state,
            usersIsLoading: true,
        };
    case LOAD_ALL_USERS_SUCCESS:
        return {
            ...state,
            usersError: null,
            usersIsLoading: false,
            users: action.payload,
        };
    case LOAD_ALL_USERS_ERROR:
        return {
            ...state,
            usersError: action.payload,
            usersIsLoading: false,
            users: [],
        };
    case LOAD_ALL_USERS_LAST_LOGIN_REQUEST:
        return {
            ...state,
            lastLoginsIsLoading: true,
        };
    case LOAD_ALL_USERS_LAST_LOGIN_SUCCESS:
        return {
            ...state,
            lastLoginsError: null,
            lastLoginsIsLoading: false,
            lastLogins: action.payload,
        };
    case LOAD_ALL_USERS_LAST_LOGIN_ERROR:
        return {
            ...state,
            lastLoginsError: action.payload,
            lastLoginsIsLoading: false,
            lastLogins: null
        };
    case SET_USER_FILTER_SHOW_DEACTIVATED:
        return {
            ...state,
            filter: {
                ...state.filter,
                showDeactivated: action.payload,
            }
        };
    case SET_USER_FILTER_SELECTED_FILTER:
        return {
            ...state,
            filter: {
                ...state.filter,
                selectedFilter: action.payload
            }
        };
    case SET_USER_FILTER_VISBLE:
        return {
            ...state,
            filter: {
                ...state.filter,
                visible: action.payload,
            }
        };
    default:
        return state;
    }
};

export default reducer;


// data accessors
const getRootState = state =>  state.userManagement.allUsers;

export const getAllUsers = state => getRootState(state).users;
export const getCountAllUsers = state => getRootState(state).users.length;
export const getIsLoadingAllUsers = state => getRootState(state).usersIsLoading;
export const getErrorAllUsers = state => getRootState(state).usersError;

export const getAllUsersLastLogin = state => getRootState(state).lastLogins;
export const getIsLoadingAllUsersLastLogin = state => getRootState(state).lastLoginsIsLoading;
export const getErrorAllUsersLastLogin = state => getRootState(state).lastLoginsError;

export const getUserFilterShowDeactivated = state => getRootState(state).filter.showDeactivated;
export const getUserFilterSelectedFilter = state => getRootState(state).filter.selectedFilter;
export const getUserFilterVisible = state => getRootState(state).filter.visible;
export const getFilteredUsers = state => {
    const allUsers = getAllUsers(state);
    const userFilterVisible = getUserFilterVisible(state);
    if (!userFilterVisible) {
        return allUsers;
    }
    const showDeactivated = getUserFilterShowDeactivated(state);
    const selectedFilter = getUserFilterSelectedFilter(state);
    let result = allUsers
        .filter(user => user.role === selectedFilter);
    if (!showDeactivated) {
        result = result.filter(user => user.deactivated === false);
    }
    return result;
};

export const getUserFilterSelectOptions = state => {
    let allUsers = getAllUsers(state);
    const allOptions = allRolesSelectOptions;
    const showDeactivated = getUserFilterShowDeactivated(state);
    if (!showDeactivated) {
        allUsers = allUsers.filter(user => user.deactivated === false);
    }
    return allOptions.map(option => {
        if (allUsers.find(user => user.role === option.value)) {
            return {
                ...option,
            };
        }
        return {
            ...option,
            disabled: true,
        };
    });
};


// action creators
export const loadAllUsersRequest = () =>
    ({ type: LOAD_ALL_USERS_REQUEST });

const loadAllUsersSuccess = payload =>
    ({ type: LOAD_ALL_USERS_SUCCESS, payload });

const loadAllUsersError = payload =>
    ({ type: LOAD_ALL_USERS_ERROR, payload });

export const loadAllUsersLastLoginRequest = () =>
    ({ type: LOAD_ALL_USERS_LAST_LOGIN_REQUEST });

const loadAllUsersLastLoginSuccess = payload =>
    ({ type: LOAD_ALL_USERS_LAST_LOGIN_SUCCESS, payload });

const loadAllUsersLastLoginError = payload =>
    ({ type: LOAD_ALL_USERS_LAST_LOGIN_ERROR, payload });

export const setUserFilterShowDeactivated = payload =>
    ({ type: SET_USER_FILTER_SHOW_DEACTIVATED, payload });

export const setUserFilterSelectedFilter = payload =>
    ({ type: SET_USER_FILTER_SELECTED_FILTER, payload });

export const setUserFilterVisible = payload =>
    ({ type: SET_USER_FILTER_VISBLE, payload });


// API endpoints
const apiCallLoadAllUsers = async () =>
    axios.get('/api/users/getAllUsers');

const apiCallLoadAllUsersLastLogin = async () =>
    axios.get('/api/users/getAllUsersLastLogin');


// side effects
const loadAllUsersWorker = function* () {
    try {
        const { data } = yield call(apiCallLoadAllUsers);
        yield put(loadAllUsersSuccess(data));
    } catch (error) {
        yield put(loadAllUsersError(getApiError(error)));
    }
};

const loadAllUsersLastLoginWorker = function* () {
    try {
        const { data } = yield call(apiCallLoadAllUsersLastLogin);
        yield put(loadAllUsersLastLoginSuccess(data));
    } catch (error) {
        yield put(loadAllUsersLastLoginError(getApiError(error)));
    }
};

const setUserFilterShowDeactivatedWorker = function* () {
    const filteredUsers = yield select(getFilteredUsers);
    if (!filteredUsers.length) {
        yield put(setUserFilterSelectedFilter(DEFAULT_SELECTED_ROLE));
    }
};


export const sagas = [
    takeLatest(LOAD_ALL_USERS_REQUEST, loadAllUsersWorker),
    takeLatest(LOAD_ALL_USERS_LAST_LOGIN_REQUEST, loadAllUsersLastLoginWorker),
    takeLatest(SET_USER_FILTER_SHOW_DEACTIVATED, setUserFilterShowDeactivatedWorker),
];
