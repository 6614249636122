/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getApiError } from '../../lib/apiUtils';


const DHCP_CLIENT_LEASES_REQUEST = 'ak/dhcpClient/DHCP_CLIENT_LEASES_REQUEST';
const DHCP_CLIENT_LEASES_SUCCESS = 'ak/dhcpClient/DHCP_CLIENT_LEASES_SUCCESS';
const DHCP_CLIENT_LEASES_FAILURE = '/ak/dhcpClient/DHCP_CLIENT_LEASES_FAILURE';

// initial state
const initialState = {
    values: null,
    loadingError: null,
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case DHCP_CLIENT_LEASES_SUCCESS:
        return {
            ...state,
            values: action.payload,
        };
    case DHCP_CLIENT_LEASES_FAILURE:
        return {
            ...state,
            loadingError: action.payload,
        };
    case DHCP_CLIENT_LEASES_REQUEST:
    default:
        return state;
    }
};

export default reducer;

//data accessors
const getState = rootState => rootState.dhcpClient;
export const getDhcpClientValues = rootState => getState(rootState).values;

// action creators
export const dhcpClientValuesRequest = () =>
    ({ type: DHCP_CLIENT_LEASES_REQUEST });

const dhcpClientValuesSuccess = data =>
    ({ type: DHCP_CLIENT_LEASES_SUCCESS, payload: data });

const dhcpClientValuesFailure = error =>
    ({ type: DHCP_CLIENT_LEASES_FAILURE, payload: error });

// API
const getDhcpClientValuesApi = async () =>
    Axios.get('/api/dhcp/client');

// worker
const workerDhcpClientValuesLoad = function* () {
    try {
        const { data } = yield call(getDhcpClientValuesApi);
        yield put(dhcpClientValuesSuccess(data));
    } catch (error) {
        yield put(dhcpClientValuesFailure(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(DHCP_CLIENT_LEASES_REQUEST, workerDhcpClientValuesLoad),
];
