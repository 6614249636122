/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from '~commonLib/moment';


const filterObjectTimeManipulator = {};

const AVAILABLE_TIME_UNITS = {
    minute: {
        periodType: 'minutes',
        moveBy: 'minute',
        defaultValueType: 'last-minute'
    },
    hour: {
        periodType: 'hours',
        moveBy: 'hour',
        defaultValueType: 'last-hour'
    },
    day: {
        periodType: 'days',
        moveBy: 'day',
        defaultValueType: 'yesterday'
    },
    week: {
        periodType: 'weeks',
        moveBy: 'week',
        defaultValueType: 'last-week'
    },
    month: {
        periodType: 'months',
        moveBy: 'month',
        defaultValueType: 'last-month'
    },
    year: {
        periodType: 'years',
        moveBy: 'year',
        defaultValueType: 'last-year'
    },
};
const DEFAULT_TIME_UNIT = AVAILABLE_TIME_UNITS.day;

const GUI_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const toReporterTimeFormat = function(value) {
    return moment(value).format('YYYY-MM-DDTHH:mm');
};

filterObjectTimeManipulator.createNewFilterObject = function(
    columnName, value
) {
    const timeUnit = AVAILABLE_TIME_UNITS[columnName.split('_')[1]] ||
        DEFAULT_TIME_UNIT;
    const mFrom = moment(value).startOf(timeUnit.moveBy);
    const mTo = mFrom.clone();
    if (value) {
        mTo.add(1, timeUnit.moveBy);
    } else {
        mFrom.subtract(1, timeUnit.moveBy);
    }
    return {
        selected: {
            from: mFrom.format(GUI_TIME_FORMAT),
            to: mTo.format(GUI_TIME_FORMAT),
            type: value ? 'interval' : timeUnit.defaultValueType,
            offset: 1,
            count: 1,
            periodType: timeUnit.periodType,
            isRelative: !value
        },
    };
};

filterObjectTimeManipulator.addValueToFilter = null;

filterObjectTimeManipulator.removeValueFromFilter = null;

filterObjectTimeManipulator.getReporterParams = function(
    customFilterStorage, reporterTemplates, filterContainerManipulator,
    filterObject
) {
    if (filterObject.isDisabled || !filterObject.selected) {
        return undefined;
    }
    if (filterObject.selected.isRelative) {
        return {
            col: 'event.time',
            type: 'relative_range',
            neg: filterObject.isNegated,
            params: [
                filterObject.selected.periodType,
                filterObject.selected.offset,
                filterObject.selected.count
                // start time would go here
            ]
        };
    }
    return {
        col: 'event.time',
        type: 'range',
        neg: filterObject.isNegated,
        params: [
            toReporterTimeFormat(filterObject.selected.from),
            toReporterTimeFormat(filterObject.selected.to),
        ]
    };
};

export const getReporterTimeFrom = (time) => moment(time);

// Reporter interprets the end time as an open end of the interval.
// Furthermore, Reporter wants the end of an interval to be the beginning of the hour or day.
export const getReporterTimeTo = (time) => moment(time).add(1, 'minute');

export default filterObjectTimeManipulator;
