/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { getTypeOf } from '~commonLib/getTypeOf';


/**
 * Asserts that all types of the parameters are equal and returns the type, but:
 *    - undefined parameters are ignores
 *    - 'array' is also a type
 *
 * @param  {...any} params
 * @returns {string}
 */
const assertType = (...params) =>
    params.reduce(
        (temp, object) => {
            if (typeof object === 'undefined') {
                return temp;
            }
            const type = getTypeOf(object);
            if (temp && type !== temp) {
                throw new Error(`Expected type ${temp}, got ${type}`);
            }
            return type;
        },
        undefined
    ) || 'undefined';

/**
 * Clones and merges attributes from params, right parameters are copied over the
 * left ones.
 */
export const deepCloneAndMerge = (...params) => {
    const type = assertType(...params);
    switch (type) {
    case 'array':
    case 'object': {
        const result = type === 'array' ? [] : {};
        const mapKeys = {};
        for (const object of params) {
            if (!object) {
                continue;
            }
            for (const key in object) {
                mapKeys[key] = key;
            }
        }
        for (const key in mapKeys) {
            result[key] = deepCloneAndMerge(...params.map(object =>
                object && object[key]));
        }
        return result;
    }
    case 'boolean':
    case 'function':
    case 'number':
    case 'string':
    case 'symbol':
        return params.reduce(
            (temp, value) =>
                typeof value === 'undefined' ?
                    temp :
                    value,
            undefined
        );
    case 'undefined':
        return undefined;
    default:
        throw new Error(`Unsupported type ${type}`);
    }
};

export const deepClone = <T>(value: T): T => deepCloneAndMerge(value);
