/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import classNames from 'classnames';

import { Icon, Tooltip } from '~frontendComponents/Generic';


@withTranslation()
class TextWithTooltip extends Component {
    static get propTypes() {
        return {
            tooltipText: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            className: PropTypes.string,
            withoutTranslation: PropTypes.bool,
            text: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            t: PropTypes.func,
            children: PropTypes.node,
            onClick: PropTypes.func,
            defaultClass: PropTypes.bool,
            icon: PropTypes.string,
            datacy: PropTypes.string,
        };
    }

    static defaultProps = {
        defaultClass: true,

    };

    render() {
        const { t,
            tooltipText,
            text, withoutTranslation, children, className, onClick, defaultClass,
            icon, datacy } = this.props;
        return (
            <Tooltip
                className={classNames(className,
                    { 'textWithTooltip': defaultClass })}
                content={withoutTranslation ? tooltipText : t(tooltipText)}
                datacy={`${datacy}-tooltip`}
                onClick={onClick}
            >
                <span
                    data-cy={datacy}
                    onClick={onClick}
                    to="#"
                >
                    {icon && (
                        <Icon
                            name={icon}
                            size="sm"
                        />
                    )}
                    {children || (withoutTranslation ? text : t(text))}
                </span>
            </Tooltip>
        );
    }
}

export default TextWithTooltip;
