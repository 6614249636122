/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import PropTypes from 'prop-types';

import { typeApiError } from '~frontendTypes';
import ApiError from '~frontendComponents/Generic/ApiError';

import Loader from '../../Loader';


/**
 * General wrapper for loading data.
 *
 * @param {object} props
 * @param props.className - className
 * @param {React.Component} props.children - content
 * @param {React.Component} [props.error] - error description
 * @param {*} [props.data] - data, expected to be falsy when not initialized
 * @param {boolean} [props.isLoading=false] - isLoading, expected to be true when loading
 * @returns {React.Component}
 */
const Loading = ({
    children, error, isLoading, className, data
}: {
    children: React.ReactNode,
    error?: any, //TODO: AK-2469
    isLoading: boolean,
    className?: string,
    data?: boolean
}) => {
    if (isLoading && data) {
        return (
            <React.Fragment>
                <Loader className={className} />
                {children}
            </React.Fragment>
        );
    }
    if (isLoading) {
        return (
            <Loader className={className} />
        );
    }
    if (error) {
        return (
            <div className={className}>
                <ApiError error={error} />
            </div>
        );
    }
    return (
        <>
            {children}
        </>
    );
};

Loading.propTypes = {
    children: PropTypes.node.isRequired,
    error: typeApiError,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    data: PropTypes.any

};

export default Loading;
