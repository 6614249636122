/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Component } from 'react';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Svg from 'react-inlinesvg';
import classNames from 'classnames';

import WhenAdvanced from '~frontendRoot/components/WhenAdvanced';

import Icon from '../Icon';
import Select from '../Select';


@withTranslation()
class ToggleBox extends Component {
    static get propTypes() {
        return {
            label: PropTypes.node,
            name: PropTypes.string.isRequired,
            description: PropTypes.node,
            t: PropTypes.func,
            onClick: PropTypes.func,
            svg: PropTypes.string,
            className: PropTypes.string,
            blockOpening: PropTypes.bool,
            isOpen: PropTypes.bool,
            isActive: PropTypes.bool,
            labelClassName: PropTypes.string,
            leftAlign: PropTypes.bool,
            id: PropTypes.string,
            disabled: PropTypes.bool,
            replaceButton: PropTypes.node,
            buttonText: PropTypes.string,
            buttonColor: PropTypes.string,
            buttonIcon: PropTypes.node,
            textClassName: PropTypes.string,
            isLoading: PropTypes.bool,
            toggleBoxClassName: PropTypes.string,
            clickWhole: PropTypes.func,
            availableList: PropTypes.array,
            selected: PropTypes.string,
            changeSelected: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        const { isOpen, isActive } = props;
        this.state = {
            isOpen: isOpen,
            isActive: isActive,
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen, isActive } = this.props;
        if (isOpen !== prevProps.isOpen) {
            this.setState({
                isOpen: isOpen
            });
        }
        if (isActive !== prevProps.isActive) {
            this.setState({
                isActive: isActive
            });
        }
    }

    toggleChange = () => {
        const { isOpen, } = this.state;
        this.setState({
            isOpen: !isOpen,
        });
    };

    toggleActive = () => {
        const { onClick, name, id } = this.props;
        onClick({ value: id, id, name });
    };

    render() {
        const { isOpen, isActive } = this.state;
        const { label, description, t, svg, blockOpening, className, labelClassName, leftAlign,
            disabled, replaceButton, buttonText, buttonColor, buttonIcon, textClassName, isLoading,
            toggleBoxClassName, clickWhole, availableList, selected, changeSelected, id } = this.props;
        return (
            <div className={classNames(
                className,
                'toggleBox',
                { ' toggleBox--active': isActive },
                { ' toggleBox--isClosed': !isOpen },
                { ' toggleBox--isDisabled': disabled },
                { ' toggleBox--clicable': !disabled && clickWhole }


            )}
            >
                <label className="toggleBox__label">
                    <input
                        checked={!isOpen}
                        className="toggleBox__input"
                        disabled={disabled}
                        onChange={blockOpening ? this.toggleActive : this.toggleChange}
                        type="checkbox"
                    />
                    <div className="toggleBox__box">
                        <div
                            className={
                                classNames(
                                    labelClassName,
                                    'toggleBox__labelSlide',
                                    { ' toggleBox__labelSlide--active': isActive },
                                    { ' toggleBox__labelSlide--leftAlign': leftAlign },

                                )}
                        >
                            {label}
                        </div>
                    </div>
                </label>
                {isOpen  ?
                    <div
                        className={
                            classNames(
                                toggleBoxClassName,
                                'toggleBox__content',

                            )}
                        onClick={clickWhole ? this.toggleActive : () => {}}
                    >
                        {svg ?
                            <Svg
                                src={svg}
                            /> :
                            null
                        }
                        <div className={classNames(
                            textClassName,
                            'toggleBox__text',
                        )}
                        >
                            {isLoading ?
                                <Icon
                                    className="upgrade__loaderIcon"
                                    name="loading"
                                /> : description}
                        </div>
                        {replaceButton ? replaceButton :
                            <>
                                <WhenAdvanced>

                                    {availableList ?
                                        <Select
                                            id="selectVersion"
                                            onChange={changeSelected}
                                            options={availableList}
                                            paste={false}
                                            value={selected}
                                        /> : null
                                    }
                                </WhenAdvanced>
                                <MDBBtn
                                    className={isActive ?
                                        'toggleBox__button toggleBox__button--active' :
                                        'toggleBox__button'}
                                    color={buttonColor}
                                    data-cy={id}
                                    disabled={disabled}
                                    onClick={this.toggleActive}
                                    outline={!buttonColor}
                                >
                                    {buttonIcon || null}
                                    {buttonText ? buttonText :
                                        isActive ? t('components:ToggleBox.active') :
                                            t('components:ToggleBox.choose')}
                                </MDBBtn>
                            </>
                        }
                    </div> :
                    null
                }
            </div>
        );
    }
}

export default ToggleBox;
