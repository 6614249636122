/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { isPrefix } from '~commonLib/stringUtils';
import { DropdownWrap } from '~frontendComponents/Generic';
import { getIsCollapsed, getIsTempOpen, tempCloseNavBar, tempOpenNavBar } from '~frontendDucks/layout/ducks/navBarLeft';


@connect(
    state => ({
        isCollapsed: getIsCollapsed(state),
        isTempOpen: getIsTempOpen(state),
    }),
    {
        doTempCloseNavBar: tempCloseNavBar,
        doTempOpenNavBar: tempOpenNavBar,
    }
) class MenuDropdown extends Component {
    static get propTypes() {
        return {
            // from connect:
            doTempCloseNavBar: PropTypes.func,
            doTempOpenNavBar: PropTypes.func,
            isCollapsed: PropTypes.bool,
            isTempOpen: PropTypes.bool,

            // from props:
            activeRoute: PropTypes.string,
            children: PropTypes.node,
            className: PropTypes.string,
            thisRoute: PropTypes.string,
            objRoute: PropTypes.object,
            setActiveRoute: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
    }

    getIsOpen() {
        const { isCollapsed, isTempOpen, thisRoute, activeRoute } = this.props;
        let isOpen = false;
        if (isTempOpen || !isCollapsed) {
            if (isPrefix(activeRoute, thisRoute)) {
                isOpen = true;
            }
        }
        return isOpen;
    }

    toggleDropwdown = () => {
        const { doTempCloseNavBar, doTempOpenNavBar, setActiveRoute, objRoute } = this.props;
        const isOpen = this.getIsOpen();
        if (isOpen) {
            doTempCloseNavBar();
        } else {
            doTempOpenNavBar();
            if (setActiveRoute) {
                setActiveRoute(objRoute);
            }
        }
    };

    render() {
        const { children, className } = this.props;
        const isOpen = this.getIsOpen();

        return (
            <DropdownWrap
                className={classNames('dropdown', className, isOpen ? 'show' : '')}
                isOpen={isOpen}
                toggle={this.toggleDropwdown}
            >
                {children}
            </DropdownWrap>
        );
    }
}

export default MenuDropdown;
