/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Redirect, Route } from 'react-router-dom';
import { Suspense } from 'react';

import Handbook from '~handbook';
import Loader from '~frontendComponents/Loader/index';


export const createScene = ({
    component, className, exact, isLogout, hideFromMenu,
    path, scenes, title, Title, icon, menu, isDevice, isAdvanced,
}) => {
    const TheScene = component;
    const routeComponent = () => <Suspense fallback={<Loader className="loader--middle" />}><TheScene /></Suspense>;
    const route = (
        <Route
            component={routeComponent}
            exact={typeof exact === 'undefined' ? !scenes : exact}
            key={path}
            path={path}
        />
    );
    return {
        hideFromMenu: typeof hideFromMenu === 'undefined' ? !title && !Title : hideFromMenu,
        route: route,
        className,
        menu,
        title,
        Title,
        isDevice,
        isLogout,
        isAdvanced,
        scenes,
        icon,
    };
};

export const createRedirect = ({ exact, path, to }) => ({
    hideFromMenu: true,
    route: (
        <Redirect
            exact={exact}
            from={path}
            key={path}
            to={to}
        />
    ),
});

export const HANDBOOK_PATH = '/profile/handbook';


export const HANDBOOK = (t) => {
    return {
        hideFromMenu: true,
        route: (
            <Route
                key={HANDBOOK_PATH}
                path={HANDBOOK_PATH}
                render={props => (
                    <Handbook
                        t={t}
                        {...props}
                    />
                )}
            />
        )
    };
};
