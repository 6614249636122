/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';

import type { PostResponseType } from '~commonLib/apiUtils';
import { BackendApiDefinitionType } from '~frontendTypes/externalTypes';
import { NetaddrIp4 } from '~sharedLib/Netaddr/NetaddrIp4';
import { getApiError } from '~frontendLib/apiUtils';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils';
import { backendPost } from '~frontendLib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';


interface InitialStateType {
    data?: PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']>
    error?: any,
    ipToFind?: NetaddrIp4,
    isLoading: boolean
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
};


type isIpRequest = {
    ip: NetaddrIp4,
}

const isInAF = createSlice({
    name: 'ak/isInAF',
    initialState,
    reducers: {
        isIpRequest: (state, action: PayloadAction<isIpRequest>) => {
            state.ipToFind = action.payload.ip;
            state.isLoading = Boolean(action.payload.ip);
            delete state.data;

        },
        isIpSuccess: (state, { payload }) => {
            state.data = payload;
            state.isLoading = false;
            delete state.error;
        },
        isIpError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },

    },
});

export const {
    isIpRequest,
    isIpSuccess,
    isIpError
} = isInAF.actions;

export default isInAF.reducer;


const getRootState = state => state.isInAf;

export const getDataHook =
 (state): PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']> | undefined =>
     getRootState(state).data;
export const getIsLoadingHook = (state) => getRootState(state).isLoading;
export const getIpToFindHook = (state) => stringifyAddress(getRootState(state).ipToFind, true);
export const getError = (state) => getRootState(state).error;


const postIsAddressInAf = backendPost('/adaptiveFirewall/isAddressInAf');
const getIsIpInAf = async (ip: NetaddrIp4) => {
    return postIsAddressInAf({ ip: ip.toString() });
};

export const workerGetIsIpInAf = function* (action: PayloadAction<isIpRequest>) {
    try {
        if (action.payload.ip) {
            const { data } = yield* callSaga(getIsIpInAf, action.payload.ip);
            yield put(isIpSuccess(data));
        }
    } catch (error) {
        yield put(isIpError(getApiError(error)));
    }
};

export const sagas = [
    takeEvery(isIpRequest.type, workerGetIsIpInAf)
];
