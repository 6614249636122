/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { takeEvery } from 'redux-saga/effects';
import { createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { ACTION_SEQUENCE_NTPD_TIME_SYNC } from '~sharedConstants';
import {
    errorAdder,
    getCommonActionSequenceGetters,
    getSequenceOpener,
    getWorkerActionSequence,
    InitialStateByNode,
    progressAdder,
    successAdder
} from '~frontendLib/actionSequence/actionSequence';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    getStepObjectForSocketIOProgress,
} from '~frontendLib/actionSequence/lib';
import { EVENT_PREFIX_NTPD_START, EVENT_PREFIX_NTPD_STOP, EVENT_PREFIX_NTPD_SYNC, NODE_SELF } from '~commonLib/constants';
import { ActionSequenceInfo } from '~sharedLib/types';


export const ACTION_STEP_NTPD_TIMESTOP = 'ntpdTimeStop';
export const ACTION_STEP_NTPD_TIMESYNC = 'ntpdTimeSync';
export const ACTION_STEP_NTPD_TIMESTART = 'ntpdTimeStart';

const initialState = {
    byNode: {} as InitialStateByNode['byNode'],
    isOpen: false,
    error: null,
};

const getState = rootState => rootState.ntpdTimeSync;

export const {
    getIsOpen: getTimesyncIsOpen,
    getIsLoading: getTimesyncIsLoading,
    getIsAborted: getTimesyncIsAborted,
    getError: getTimesyncError,
    getProgress: getTimesyncProgress,
} = getCommonActionSequenceGetters(getState);

const ntpdTimeSyncSlice = createSlice({
    name: 'ak/ntpdTimeSync',
    initialState,
    reducers: {
        timeSyncOpened: getSequenceOpener(initialState),
        timeSyncClose: (state) => {
            state.isOpen = false;
        },

        ntpdTimeSyncSuccess: successAdder,
        ntpdTimeSyncError: errorAdder,

        ntpdStopStarted: progressAdder(ACTION_STEP_NTPD_TIMESTOP, getStateProgressStarted),
        ntpdStopProgress: progressAdder(ACTION_STEP_NTPD_TIMESTOP, getStateProgressProgress),
        ntpdStopSuccess: progressAdder(ACTION_STEP_NTPD_TIMESTOP, getStateProgressSuccess),
        ntpdStopFailure: progressAdder(ACTION_STEP_NTPD_TIMESTOP, getStateProgressFailure),

        ntpdSyncStarted: progressAdder(ACTION_STEP_NTPD_TIMESYNC, getStateProgressStarted),
        ntpdSyncProgress: progressAdder(ACTION_STEP_NTPD_TIMESYNC, getStateProgressProgress),
        ntpdSyncSuccess: progressAdder(ACTION_STEP_NTPD_TIMESYNC, getStateProgressSuccess),
        ntpdSyncFailure: progressAdder(ACTION_STEP_NTPD_TIMESYNC, getStateProgressFailure),

        ntpdStartStarted: progressAdder(ACTION_STEP_NTPD_TIMESTART, getStateProgressStarted),
        ntpdStartProgress: progressAdder(ACTION_STEP_NTPD_TIMESTART, getStateProgressProgress),
        ntpdStartSuccess: progressAdder(ACTION_STEP_NTPD_TIMESTART, getStateProgressSuccess),
        ntpdStartFailure: progressAdder(ACTION_STEP_NTPD_TIMESTART, getStateProgressFailure),
    },
});


const actions = ntpdTimeSyncSlice.actions;
export const {
    timeSyncOpened,
    timeSyncClose,
} = actions;
export default ntpdTimeSyncSlice.reducer;


const timeSyncStart = async ({ action, breakLock }) => {

    const nodes = action?.payload?.nodes || [ NODE_SELF ];
    const { data } = await axios.post(
        '/api/utils/timeSync', { breakLock, nodes }
    ) as AxiosResponse<ActionSequenceInfo>;
    return data;
};

// Individual steps in the sequence
const workerStepNtpdStop = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.ntpdStopStarted,
    actionStepProgressed: actions.ntpdStopProgress,
    actionStepSucceeded: actions.ntpdStopSuccess,
    actionStepFailed: actions.ntpdStopFailure,
    eventPrefix: EVENT_PREFIX_NTPD_STOP,
});

const workerStepNtpdSync = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.ntpdSyncStarted,
    actionStepProgressed: actions.ntpdSyncProgress,
    actionStepSucceeded: actions.ntpdSyncSuccess,
    actionStepFailed: actions.ntpdSyncFailure,
    eventPrefix: EVENT_PREFIX_NTPD_SYNC,
});

const workerStepNtpdStart = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.ntpdStartStarted,
    actionStepProgressed: actions.ntpdStartProgress,
    actionStepSucceeded: actions.ntpdStartSuccess,
    actionStepFailed: actions.ntpdStartFailure,
    eventPrefix: EVENT_PREFIX_NTPD_START,
});

const workerNtpdTimeSync = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_NTPD_TIME_SYNC,
    actionSequenceSucceeded: actions.ntpdTimeSyncSuccess,
    actionSequenceFailed: actions.ntpdTimeSyncError,
    fnStart: timeSyncStart,
    workers: [
        workerStepNtpdStop,
        workerStepNtpdSync,
        workerStepNtpdStart,
    ],
    close: timeSyncClose
});

export const sagas = [
    takeEvery(actions.timeSyncOpened.type, workerNtpdTimeSync),
];
