/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import { Icon } from '~frontendRoot/components/Generic';
import { HlcfgVerificationTranslatedItem } from '~sharedLib/types';

import DiffersPath from './DiffersPath';


const DifferErrorCard = ({ error }:
    { error: HlcfgVerificationTranslatedItem }) => {

    return (
        <div className="differs__error">
            <div className="differs__error__title">
                <Icon
                    className="m-1"
                    color="red"
                    name="alert-outline"
                    size="sm"
                />
                {error.title}
            </div>
            <div className="differs__error__desc">{error.desc}

            </div>
            <DiffersPath
                paths={error.hlcfgPaths}
            />
        </div>
    );
};

export default DifferErrorCard;
