/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { REGEX_NAME_DUPLICATION } from '~frontendConstants';


export const getStringMatch = ({ toMatch, searchValue }) => {
    if (typeof toMatch !== 'string') {
        //eslint-disable-next-line
        console.error('toMatch is not a string, this is developer mistake', toMatch);
        return;
    }
    return toMatch.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
        .includes(searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase());
};


export const copyTag = (arrayOfTags) => {
    let number = 1;
    for (const takenTag of arrayOfTags.sort((first, second) => first - second)) {
        if (takenTag === number) {
            number++;
        }
    }
    return number;

};


export const copyName = (name, arrayOfNames, maxLength) => {
    let match;

    let number = 1;
    if (!REGEX_NAME_DUPLICATION.test(name)) {
        if (name.length + 4 > maxLength) {
            name = name.slice(0, -(name.length - maxLength + 4));
        }
        name += '_(1)';
    }

    let longerI = 0;
    while (longerI < arrayOfNames.length) {
        match = REGEX_NAME_DUPLICATION.exec(arrayOfNames[longerI]);

        if (match && name.slice(0, match?.index) === arrayOfNames[longerI].slice(0, match?.index)) {
            const numberFromName = match[0].match(/[\d]+/g);
            if (number <= Number(numberFromName[0])) {
                number = Number(numberFromName[0]);
                name = name.replace(REGEX_NAME_DUPLICATION, `_(${number + 1})`);
            }
        }
        longerI++;
    }
    if (name.length > maxLength) {
        const createdNum =  REGEX_NAME_DUPLICATION.exec(name);
        name = name.slice(0, -(name.length - maxLength + createdNum[0]?.length)) + createdNum[0];
    }
    return name;
};

export const roundWithPrecision = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

//this is not very good but it is what it is
export const replaceSizeString = (value, translation) => {
    return value?.replace('M', ' MB').replace('G', ' GB').replace('T', ' TB').replace('.',
        translation('global:decimalDelimiter'));
};

export const isItemAllValuesConst = (item) =>
    item.endsWith('_ipv4_address') || item.endsWith('_ipv4_network') ? item : '';

export const getTheLowestPossibleNumber = (numbers) => {
    let newIndex = 1;
    numbers.sort((first, second) => first - second);
    while (true) {
        if (newIndex > numbers.length) {
            return newIndex;
        }
        if (numbers.find(item => item === newIndex)) {
            newIndex++;
        } else {
            return newIndex;
        }
    }
};

/**
 * Adds suffix to uuid with splitter
 *
 * @param uuid uuid
 * @param suffix value to add at the end
 * @param splitter splitter
 * @returns uuid + splitter + suffix
 * @example addSuffixToUuid('uuid', 'suffix', '/') => 'uuid/suffix'
 */

export const addSuffixToUuid = (uuid, suffix, splitter = '/') => {
    return uuid + splitter + suffix;
};

/**
 * Gets suffix from uuid
 *
 * @param value uuid
 * @param splitter splitter
 * @returns suffix
 * @example getSuffixFromUuid('uuid/suffix', '/') => 'suffix'
 */

export const getSuffixFromUuid = (value, splitter = '/') => {
    return value.split(splitter)[1];
};

/**
 * Gets uuid from uuid with suffix
 *
 * @param value uuid with suffix
 * @param splitter suffix splitter
 * @returns uuid
 * @example getUuidFromSuffixedUuid('uuid/suffix', '/') => 'uuid'
 */

export const getUuidFromSuffixedUuid = (value, splitter = '/') => {
    return value.split(splitter)[0];
};


export const generateRandomColor = () => {
    if (window?.Cypress) {
        return '#998276';
    }
    return colors[Math.floor(Math.random() * colors.length)];
};

export const colors = [
    '#998276',
    '#c4c484',
    '#abd883',
    '#a2f2bd',
    '#b88488',
    '#d1b182',
    '#d4eb91',
    '#ccfcc4',
    '#907699',
    '#c484a4',
    '#ea8c79',
    '#f2e5a2',
    '#9a84b8',
    '#d182ca',
    '#eb91a8',
    '#ffddc4',
    '#768d99',
    '#8484c4',
    '#c479ea',
    '#f2a2d7',
    '#84b8b4',
    '#82a2d1',
    '#a791eb',
    '#fbc8f5',
    '#7c957a',
    '#84c4a4',
    '#79d7ea',
    '#a2aff2',
    '#a2b884',
    '#82d189',
    '#91ebd4',
    '#c9e5fa',
    '#b8a784',
    '#b9ca89',
    '#91eb91',
    '#c9fce9',
    '#957686',
    '#c49484',
    '#eade7a',
    '#c3f2a2',
    '#b884af',
    '#d1828f',
    '#ebbd91',
    '#f7f9c4',
    '#797699',
    '#b484c4',
    '#ea79bb',
    '#f2a9a2',
    '#8495b8',
    '#9d82d1',
    '#ea91eb',
    '#ffc8d4',
    '#76958d',
    '#84b4c4',
    '#7982ea',
    '#d1a2f2',
    '#84b88d',
    '#82d1c4',
    '#91beeb',
    '#d2c6fa',
    '#969976',
    '#79eaa8',
    '#a2ebf2',
    '#94c484',
];
