/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MAX_32_BIT_SIGNED_INT } from '~commonLib/constants';

/**
 * A function that wraps setTimeout() and prevents overflow caused by too large timeout,
 * instead it throws to prevent strange behaviours.
 */
export const setTimeoutSafe = (fn, timeout) => {
    if (timeout > MAX_32_BIT_SIGNED_INT) {
        throw new Error(`Timeout can be maximum of ${MAX_32_BIT_SIGNED_INT}`);
    }
    return setTimeout(fn, timeout);
};


/**
 * A function that wraps setTimeout() in a promise.
 *
 * @param {number} miliseconds
 * @returns {Promise}
 */
export const timeout = async (miliseconds) => {
    return new Promise(resolve => {
        setTimeoutSafe(() => resolve(), miliseconds);
    });
};

/**
 * A setTimeout() function that can be used with an async function as its
 * argument. This is unsafe however because if that function rejects,
 * the application is terminated because the rejection is unhandled.
 */
export const setTimeoutUnsafeAsync = (fn, timeout) => {
    return setTimeoutSafe(() => {
        fn().then(
            () => {},
            error => {
                throw error;
            }
        );
    }, timeout);
};

/**
 * Returns a promise that resolves after a random time.
 *
 * @param {number} minSeconds - minimum number of seconds to wait
 * @param {number} maxSeconds - maximum number of seconds to wait
 * @param {Function} [fnLog] - function that gets the chosen number of seconds to wait
 * @returns {Promise}
 */
export const randomTimeout = async (minSeconds, maxSeconds, fnLog) => {
    const seconds = Math.floor(minSeconds + Math.random() * (maxSeconds - minSeconds));
    if (typeof fnLog === 'function') {
        fnLog(seconds);
    }
    return timeout(seconds * 1000);
};

/**
 * Returns the number of seconds between two dates.
 *
 * @param {Date} startedDate - started date
 * @param {Date} [endDate] - end date, defaults to right now
 * @param {number} [precision=3] - number of decimals of the result
 * @returns {string}
 */
export const formatDuration = (startedDate, endDate = new Date(), precision = 3) => {
    const difference = (endDate - startedDate) / 1000;
    return difference.toFixed(precision);
};
