/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getApiError } from '~frontendLib/apiUtils';
import { createNotification } from '~frontendRoot/lib/reactUtils';
import { resetAllChanges } from '~frontendDucks/hlcfgEditor';
import { backendPost } from '~frontendLib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';
import { FirstParam } from '~commonLib/types';

import { cfgActivationRequest } from '../cfgActivation';
import { createRequest } from '../ducksUtils';
import { setHelp } from '../layout/ducks/navBarLeft';


interface InitialStateType {
    error?: any,
    isLoading: boolean
    recoveryMode: boolean
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
    recoveryMode: false
};

const backup = createSlice({
    name: 'ak/backup',
    initialState,
    reducers: {
        backupRequest: createRequest<InitialStateType, GetBackupTypes>(
            (state) => {
                state.isLoading = true;
            }
        ),
        backupSuccess: (state) => {
            state.isLoading = false;
        },
        backupError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },
        startRecoveryRequest: createRequest<InitialStateType, PostStartBackupTypes>(
            (state) => {
                state.isLoading = true;
            }
        ),
        recoverySuccess: (state, { payload }) => {
            state.recoveryMode = payload;
            state.isLoading = false;
        },

    },
});

export const {
    backupRequest,
    backupSuccess,
    backupError,
    startRecoveryRequest,
    recoverySuccess
} = backup.actions;

export default backup.reducer;


const getRootState = (state): InitialStateType => state.backup;
export const getIsLoading = (state) => getRootState(state).isLoading;
export const getError = (state) => getRootState(state).error;
export const getRecoveryMode = (state) => getRootState(state).recoveryMode;


const getBackup = backendPost('/system/backupConfiguration', { responseType: 'blob' });
type GetBackupTypes = FirstParam<typeof getBackup>;

const postStartBackup = backendPost('/system/startBackupRestoration',);
type PostStartBackupTypes = FirstParam<typeof postStartBackup>;


export const workerGetBackup = function* (action: PayloadAction<GetBackupTypes>) {
    try {
        const res = yield* callSaga(getBackup, action.payload);
        const name = res.headers['content-disposition']?.split('filename=')?.[1];
        const file = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = file;
        link.download = name;
        link.click();
        link.remove();
        yield put(backupSuccess());

    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger'
        });
        yield put(backupError(getApiError(error)));
    }
};

export const workerStartBackup = function* (action: PayloadAction<PostStartBackupTypes>) {
    try {
        yield call(postStartBackup, action.payload);
        yield put(resetAllChanges());
        yield put(setHelp(true));
        yield put(recoverySuccess(true));
    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger'
        });
        yield put(backupError(getApiError(error)));
    }
};

export const workerRemoveRecoveryMode = function* () {
    yield put(recoverySuccess(false));
};


export const sagas = [
    takeLatest(backupRequest.type, workerGetBackup),
    takeLatest(startRecoveryRequest.type, workerStartBackup),
    takeLatest(cfgActivationRequest.type, workerRemoveRecoveryMode)
];
