/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';

import { getApiError } from '~frontendLib/apiUtils';
import { backendPost } from '~frontendLib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';


type ResponseData = Awaited<ReturnType<typeof postCrlUrlTest>>

export type DataType = Record<string, ResponseData>

interface InitialStateType {
    data?: DataType,
    error?: any,
    isLoading: boolean
    testedVpn: string
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
    testedVpn: ''
};


const crlUrlTest = createSlice({
    name: 'ak/crlUrlTest',
    initialState,
    reducers: {
        crlUrlTestRequest: (state, { payload }) => {
            state.isLoading = true;
            state.testedVpn = payload;
        },
        crlUrlTestSuccess: (state, { payload = 'OK' }) => {
            state.data = payload;
            state.isLoading = false;
            delete state.error;
        },
        crlUrlTestError: (state, { payload }) => {
            state.error = payload.message;
            state.data = undefined;
            state.isLoading = false;
        },

        resetErrorCrl: (state) => {
            state.error = undefined;
            state.data = undefined;
        }
    },
});

export const {
    crlUrlTestRequest,
    crlUrlTestSuccess,
    crlUrlTestError,
    resetErrorCrl
} = crlUrlTest.actions;

export default crlUrlTest.reducer;


const getRootState = (state): InitialStateType => state.crlUrlTest;

export const getData = (state) => getRootState(state).data;
export const getTestedVpn = (state) => getRootState(state).testedVpn;

export const getIsLoading = (state) => getRootState(state).isLoading;
export const getError = (state) => getRootState(state).error;

export const isData = state => Boolean(getData(state));

const postCrlUrlTest = backendPost('/cfg/tryVpnCrlUrl');

export const workerPostCrlUrlTest = function* (action) {
    try {
        const { data } = yield* callSaga(postCrlUrlTest, { vpnId: action.payload });
        yield put(crlUrlTestSuccess(data));

    } catch (error) {
        yield put(crlUrlTestError(getApiError(error)));
    }
};

export const sagas = [
    takeEvery(crlUrlTestRequest.type, workerPostCrlUrlTest),
];
