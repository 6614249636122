/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from '~commonLib/moment';

import arrayUtils from './arrayUtils';
import cwCategories from './cwCategories';
import tFilter from './tFilter';


const filterObjectEnumsManipulator = {};

const getNewValue = function(value) {
    return {
        selected: value || ''
    };
};

filterObjectEnumsManipulator.createNewFilterObject = function(
    columnName, value
) {
    return {
        selected: [
            getNewValue(value)
        ],
    };
};

filterObjectEnumsManipulator.addValueToFilter = function(
    filterObject, value
) {
    const newValue = getNewValue(value);
    if (filterObject.selected.length === 1 &&
        !filterObject.selected[0].selected &&
        value)
    {
        filterObject.selected[0] = newValue;
    } else {
        filterObject.selected.push(newValue);
    }
};

filterObjectEnumsManipulator.removeValueFromFilter = function(
    filterObject, index
) {
    arrayUtils.removeAtIndex(filterObject.selected, index);
};

const filterObjValue = function(objValue) {
    return objValue && objValue.selected !== '';
};

filterObjectEnumsManipulator.getReporterParams = function(
    customFilterStorage, reporterTemplates, filterContainerManipulator,
    filterObject
) {
    if (filterObject.isDisabled ||
        !filterObject.selected ||
        !filterObject.selected.reduce(function(temp, objValue) {
            return temp || filterObjValue(objValue);
        }, false))
    {
        return undefined;
    }
    return  {
        col: filterObject.columnName,
        neg: filterObject.isNegated,
        type: 'in',
        params: filterObject.selected.filter(
            filterObjValue
        ).map(function(item) {
            return item.selected;
        })
    };
};

const weekDays = moment.weekdays();

const addNull = function(object) {
    if (!object) {
        return object;
    }
    const result = {
        'null': '<NULL>',
    };
    Object.keys(object).forEach(function(key) {
        result[key] = object[key];
    });
    return result;
};

const ENUM_VALUES = {
    'kcw_category': {
        '<NULL>': '<NULL>',
    },
    'day_of_week': {
        0: weekDays[0] || 0,
        1: weekDays[1] || 1,
        2: weekDays[2] || 2,
        3: weekDays[3] || 3,
        4: weekDays[4] || 4,
        5: weekDays[5] || 5,
        6: weekDays[6] || 6,
    },
    'table': {
        'dns_proxy': 'DNS Proxy',
        'ftp_proxy': 'FTP Proxy',
        'gk_proxy': 'GK Proxy',
        'h323_proxy': 'H323 Proxy',
        'debug': 'Debug',
        'haproxy': 'HAProxy',
        'http_proxy': 'HTTP Proxy',
        'icap_server': 'ICAP Server',
        'imap4_server': 'IMAP4 Server',
        'openvpn': 'OpenVPN',
        'pop3_proxy': 'POP3 Proxy',
        'sip_proxy': 'SIP Proxy',
        'smtp_proxy': 'SMTP Proxy',
        'smtp_proxy_at': 'SMTP Proxy Attachment',
        'sqlnet_proxy': 'SQL*Net Proxy',
        'tcp_proxy': 'TCP Proxy',
        'udp_proxy': 'UDP Proxy',
        'snort': 'Snort',
    },
    'event_result': tFilter('reporter:values.event_result', { returnObjectTrees: true }),
    'network_protocol': tFilter('reporter:values.network_protocol', { returnObjectTrees: true }),
    'accepted_rejected': tFilter('reporter:values.accepted_rejected', { returnObjectTrees: true }),
    'http_proxy_action': tFilter('reporter:values.http_proxy_action', { returnObjectTrees: true }),
    'smtp_proxy_action': tFilter('reporter:values.smtp_proxy_action', { returnObjectTrees: true }),
    'smtp_proxy_at_action': tFilter('reporter:values.smtp_proxy_at_action', { returnObjectTrees: true }),
    'boolean': tFilter('reporter:values.boolean', { returnObjectTrees: true }),
    'virus_status': addNull(tFilter('reporter:values.virus_status', { returnObjectTrees: true })),
    'dns_opcode': {
        'query': 'query',
        'iquery': 'iquery',
        'status': 'status',
        'notify': 'notify',
        'update': 'update',
    },
    'msg_severity': {
        'X': 'X (emergency)',
        'A': 'A (alert)',
        'C': 'C (critical)',
        'E': 'E (error)',
        'W': 'W (warning)',
        'N': 'N (notice)',
        'K': 'K (kernun)',
        'I': 'I (info)',
        'D': 'D (debug)',
        'T': 'T (trace)',
        'F': 'F (full)',
    },
    'grey_blocked': {
        0: 'permitted',
        1: 'blocked',
    },
    'http_status_code_class': tFilter('reporter:values.http_status_code_class', { returnObjectTrees: true }),
    'termination_reason': {
        // From the HAProxy manual:
        // On the first character, a code reporting the first event which
        // caused the session to terminate
        'C': 'Aborted by client',
        'S': 'Aborted by server',
        'P': 'Aborted by proxy',
        'R': 'Resource exhausted',
        'I': 'Internal error',
        'c': 'Client-side timeout',
        's': 'Server-side timeout',
        '-': 'Normal session completion',
    },
    'session_state': {
        // From the HAProxy manual:
        // on the second character, the TCP or HTTP session state when it
        // was closed
        'R': 'Valid request',
        'Q': 'Waiting in queue',
        'C': 'Waiting for connection',
        'H': 'Waiting for response headers',
        'D': 'Processing data',
        'L': 'Transmitting last dat',
        'T': 'Tarpitted request',
        '-': 'Normal session end',
    },
    'cookie_operation': {
        // From the HAProxy manual:
        // the third character tells whether the persistence cookie was
        // provided by the client (only in HTTP mode)
        'N': 'No cookie',
        'I': 'Invalid cookie',
        'D': 'Down cookie',
        'P': 'Valid cookie',
        'R': 'Expired cookie',
        'O': 'Old cookie',
        '-': 'Not configured',
    },
    'persistence_cookie': {
        // From the HAProxy manual:
        // the last character reports what operations were performed on the
        // persistence cookie returned by the server (only in HTTP mode)
        'N': 'No cookie',
        'I': 'Inserted by proxy',
        'U': 'Updated by proxy',
        'P': 'Provided by server',
        'R': 'Rewritten by proxy',
        'D': 'Deleted by proxy',
        '-': 'Not configured',
    },
};

cwCategories.names.forEach(function(catName) {
    ENUM_VALUES.kcw_category[cwCategories.catNameToValue(catName)] =
        cwCategories.getTitle(catName);
});

// Immutable empty object, used below
const EMPTY_OBJECT = {};

/**
 * Returns an array of possible enum values.
 *
 * @memberof filterObjectEnumsManipulator
 * @param reporterTemplates
 * @param {string} columnName
 * @returns {string[]}
 */
filterObjectEnumsManipulator.getEnumValues = function(
    reporterTemplates, columnName
) {
    const columnObject = reporterTemplates.columns.byName[columnName];
    // Since this is used from a directive, it has to return a structure
    // that does not change upon every invocation of the function,
    // otherwise Angular would never stop calling it.
    return (columnObject && ENUM_VALUES[columnObject.type]) ||
        EMPTY_OBJECT;
};

export default filterObjectEnumsManipulator;
