/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import staticData from './staticData';
import tFilter from './tFilter';


/**
 * Service that provides common functions for objects that have localized
 * name and description.
 *
 * @name localizedName
 */

/**
 * An object that contains a localized string.
 *
 * @typedef {object} LocalizedString
 * @property {string} cs - Czech localized string
 * @property {string} en - English localized string
 * @property {string} [user] - user-defined localized string
 */
const localizedName = {};

const DESCRIPTION = 'description';
const NAME = 'name';
const KEYS = [ NAME, DESCRIPTION ];

const USER = 'user';

/**
 * Initializes an object. The object will have attributes KEYS set to
 * {@link LocalizedString} objects.
 *
 * @memberof localizedName
 * @param {object} object
 */
localizedName.initialize = function(object) {
    KEYS.forEach(function(key) {
        if (!object[key]) {
            object[key] = {};
        }
        staticData.langs.forEach(function(lang) {
            object[key][lang] = '';
        });
    });
};

/**
 * Copies properties from src to dst.
 *
 * @memberof localizedName
 * @param {object} dst - destination object
 * @param {object} src - source object
 * @param {string} [defaultName] - default name, will be used as user name
 * if both user name and current language name are not specified
 */
localizedName.copy = function(dst, src, defaultName) {
    localizedName.initialize(dst);
    KEYS.forEach(function(key) {
        staticData.langs.concat(USER).forEach(function(lang) {
            if (src && src[key] && typeof src[key][lang] !== 'undefined') {
                dst[key][lang] = src[key][lang];
            }
        });
        if (key === NAME &&
            !dst[key][staticData.lang] &&
            !dst[key][USER] &&
            defaultName)
        {
            dst[key][USER] = defaultName;
        }
    });
};


/**
 * Clones properties from src to dst. Similar to copying but the default
 * localized values are removed.
 *
 * @memberof localizedName
 * @param {object} dst - destination object
 * @param {object} src - source object
 */
localizedName.clone = function(dst, src) {
    localizedName.copy(dst, src);
    KEYS.forEach(function(key) {
        if (!dst[key][USER] &&
            src &&
            src[key] &&
            src[key][staticData.lang])
        {
            dst[key][USER] = src[key][staticData.lang];
        }
        if (key === NAME && dst[key][USER]) {
            dst[key][USER] += tFilter('report:is-copy');
        }
        staticData.langs.forEach(function(lang) {
            dst[key][lang] = '';
        });
    });
};

/**
 * Stores properties from src to dst.
 *
 * @memberof localizedName
 * @param {object} dst - destination object
 * @param {object} src - source object
 */
localizedName.store = function(dst, src) {
    KEYS.forEach(function(key) {
        dst[key] = {};
        staticData.langs.concat(USER).forEach(function(lang) {
            dst[key][lang] = (src && src[key] && src[key][lang]) || '';
        });
    });
};

const getGetter = function(key) {
    return function(object) {
        return (object && object[key] &&
            (object[key][USER] || object[key][staticData.lang])
        ) || '';
    };
};

/**
 * Returns the localized name of an object.
 *
 * @memberof localizedName
 * @param {object} object
 * @returns {string}
 */
localizedName.getName = getGetter(NAME);

/**
 * Returns the localized description of an object.
 *
 * @memberof localizedName
 * @param {object} object
 * @returns {string}
 */
localizedName.getDescription = getGetter(DESCRIPTION);

export default localizedName;
