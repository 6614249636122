/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { SET_SERVER_TIMESTAMP } from '~frontendRoot/ducks/currentTime';
import { REPORT_REFRESH_SUCCESS } from '~frontendRoot/ducks/reporterEntities';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge';

import getRootReducer from '../ducks';
import sagaMiddleware from '../saga/sagaMiddleware';
import { setStore } from './store';


const createSanitizedObjectFromRows = (rows) => ({
    thisIsSanitizedObject:
        'This object is not actually in redux, its sanitized for performence reasons of dev tools inside storeLib.js',
    rowsFirstAndLast: [
        rows[0],
        rows[rows.length - 1]
    ],
    length: rows.length
});


/*
    Super confused, if you get action that is not from saga and doesnt contain prop PAYLOAD it will sanitized as
    <UNDEFINED> which is then send to react dev tools, we dont want to force of usage prop PAYLOAD, so its faked here if
    nessesary, also added prop to tell the developer what is going on.
*/
const actionSanitizer = (action) => {
    return action.type === REPORT_REFRESH_SUCCESS && action.data ?
        { ...action,
            data: { ...action.data, rows: createSanitizedObjectFromRows(action.data.rows) } } : action.payload ?
            action :  { type: action.type, payload: { ...action, THIS_IS_SANITIZED_ACTION: true }  };
};

export const createStoreFunc = (history, props) => {
    const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
            {
                maxAge: 15,
                actionsDenylist: [ SET_SERVER_TIMESTAMP ],
                actionSanitizer,
                stateSanitizer: (state) => {
                    if (state.reporterEntities?.dashboardDefinitionMap) {
                        const dashboardDefinitionMap = deepCloneAndMerge(state.reporterEntities.dashboardDefinitionMap);
                        Object.values(dashboardDefinitionMap || {}).forEach(item => {
                            if (item.reports) {
                                item.reports.forEach(report => {
                                    if (report?.clientOnly?.data?.rows) {
                                        const rows = report.clientOnly.data.rows;
                                        report.clientOnly.data.rows = createSanitizedObjectFromRows(rows);
                                    }
                                });
                            }
                        });
                        return { ...state, reporterEntities: {
                            ...state.reporterEntities,
                            dashboardDefinitionMap: dashboardDefinitionMap } };
                    }
                    return state;
                }
            }
        )) || compose;
    setStore(createStore(
        getRootReducer(connectRouter(history)),
        props,
        composeEnhancers(
            applyMiddleware(routerMiddleware(history)),
            applyMiddleware(sagaMiddleware),
        ),
    ));
};
