/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { expectTypeOf } from 'expect-type';

import { netportObjSchema } from '~sharedLib/Netport/lib/netportObjSchema';
import { array, emptyObjectSchema, object, SchemaTestHelper, string } from '~commonLib/schemaUtils';
import { TypeNetport } from '~sharedLib/types';
import { netportSchema } from '~sharedLib/schemaTypes';


export const getNetserviceSchemaWithNetportSchema = <T extends TypeNetport>(netportSch: T) => object({
    protocol: string(),
    ports: array(netportSchema(netportSch))
}, [ 'protocol', 'ports' ]);

expectTypeOf(<SchemaTestHelper<typeof netserviceSchema>>{}).not.toMatchTypeOf<'fromSchNever'|'not schema'>();
export const netserviceSchema = object({
    protocol: string(),
    ports: array(netportObjSchema)
}, [ 'protocol', 'ports' ]);

expectTypeOf(<SchemaTestHelper<typeof netserviceObjSchema>>{}).not.toMatchTypeOf<'fromSchNever'|'not schema'>();
export const netserviceObjSchema = <const>{
    anyOf: [
        netserviceSchema,
        emptyObjectSchema,
    ]
};
