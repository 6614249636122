/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import React from 'react';

import Message from '../../../../components/Message';


const Languages = ({ doSetSelectedLanguage, selectedLanguage }) => (
    <div className="languages">
        <div className="languages__inner">
            <div className="languages__btnGroup">
                <MDBBtn
                    className="languages__btn languages__btn--en"
                    color="white"
                    disabled={selectedLanguage === 'en'}
                    onClick={() => doSetSelectedLanguage({ selectedLanguage: 'en' })}
                    outline
                    size="sm"
                >
                    <Message message="login:languages.button.en" />
                </MDBBtn>
                <MDBBtn
                    className="languages__btn languages__btn--cs"
                    color="white"
                    disabled={selectedLanguage === 'cs'}
                    onClick={() => doSetSelectedLanguage({ selectedLanguage: 'cs' })}
                    outline
                    size="sm"
                >
                    <Message message="login:languages.button.cs" />
                </MDBBtn>
            </div>
        </div>
    </div>
);

Languages.propTypes = {
    doSetSelectedLanguage: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
};

export default Languages;
