/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import React, { useCallback } from 'react';
import { debounce } from 'throttle-debounce';

import { useString } from '~frontendLib/hooks/defaultHooks';


type ColorInputProps = {
    value?: string;
    onChange: ({ value, name }: {value: string, name: string}) => void;
    callback?: () => void;
    __off?: boolean;
    className?: string;
};

const ColorInput = ({ value, onChange, callback, __off, className }: ColorInputProps) => {
    const [ colorState, setColorState ] = useString(value ?? '');


    //debouce inside useCallback, dependencies are set correctly
    //eslint-disable-next-line react-hooks/exhaustive-deps
    const setColorCallback = useCallback(debounce(1000, (color) => {
        assert(onChange);
        onChange({
            value: color,
            name: 'color',
        });
        if (callback) {
            return callback();
        }

    }), [ onChange, callback ]);


    const setColor = useCallback(event => {
        event.preventDefault();
        setColorState.set(event.target.value);
        setColorCallback(event.target.value);
    }, [ setColorState, setColorCallback ]);

    return (
        <input
            className={className}
            disabled={__off}
            id="background-color"
            onChange={setColor}
            type="color"
            value={colorState || '#FFFFFF'}
        />
    );
};

export default ColorInput;
