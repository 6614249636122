/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { takeLatest, } from 'redux-saga/effects';
import { store } from 'react-notifications-component';

import { restartAllTask } from '~frontendRoot/saga/sagaMiddleware';
import { RESET_SELF_PASSWORD_SUCCESS } from '~frontendConstants';


// actions
export const LOGIN_REQUEST = 'ak/userAuthentication/login/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'ak/userAuthentication/login/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'ak/userAuthentication/login/LOGIN_FAILURE';

const LOGIN_FORM_SHOW = 'ak/userAuthentication/login/LOGIN_FORM_SHOW';
const LOGIN_FORM_HIDE = 'ak/userAuthentication/login/LOGIN_FORM_HIDE';

const LOGIN_CLEAR_ERROR = 'ak/userAuthentication/login/LOGIN_CLEAR_ERROR';

const LOGIN_RESET_PASSWORD_CHECK = 'ak/userAuthentication/login/LOGIN_RESET_PASSWORD';
const LOGIN_RESET_PASSWORD_ERROR_LONG = 'ak/userAuthentication/login/LOGIN_RESET_PASSWORD_ERROR_LONG';
const LOGIN_RESET_PASSWORD_ERROR_MATCH = 'ak/userAuthentication/login/LOGIN_RESET_PASSWORD_ERROR_MATCH';
const LOGIN_RESET_PASSWORD_ERROR_SERVER = 'ak/userAuthentication/login/LOGIN_RESET_PASSWORD_ERROR_SERVER';
const LOGIN_RESET_PASSWORD_OK = 'ak/userAuthentication/login/LOGIN_RESET_PASSWORD_OK';

export const LOGOUT_REQUEST = 'ak/userAuthentication/login/LOGOUT_REQUEST';


// initial state
export const initialState = {
    isLoginChecking: false,
    isPassword: false,
    isLogin: false,
    isLoginFormVisible: true,
    isResetPassword: false,
    isResetPasswordErrorMatch: false,
    isResetPasswordErrorLong: false,
    error: null,
    data: null,
    wasLoggedOut: false,
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOGIN_REQUEST:
        return {
            ...state,
            isLoginChecking: true,
            error: null,
        };
    case LOGIN_SUCCESS:
        return {
            ...state,
            isLoginChecking: false,
            data: action.data,
            wasLoggedOut: false,
            isResetPassword: action.data.pwdReset,
        };
    case LOGIN_FAILURE:
        return {
            ...state,
            isLoginChecking: false,
            error: action.error
        };
    case LOGIN_FORM_SHOW:
        return {
            ...state,
            isLoginFormVisible: true
        };
    case LOGIN_FORM_HIDE:
        return {
            ...state,
            isLoginFormVisible: false
        };
    case LOGIN_RESET_PASSWORD_CHECK:
        return {
            ...state,
            isPasswordChecking: true,
            error: null
        };
    case LOGIN_RESET_PASSWORD_ERROR_LONG:
        return {
            ...state,
            isPasswordChecking: false,
            isResetPasswordErrorLong: true
        };
    case LOGIN_RESET_PASSWORD_ERROR_MATCH:
        return {
            ...state,
            isPasswordChecking: false,
            isResetPasswordErrorMatch: true
        };
    case LOGIN_RESET_PASSWORD_OK:
        return {
            ...state,
            isPasswordChecking: false,
            isResetPasswordErrorMatch: false,
            isResetPasswordErrorLong: false
        };
    case LOGIN_CLEAR_ERROR:
        return {
            ...state,
            error: null,
            isResetPasswordErrorMatch: false,
            isResetPasswordErrorLong: false
        };
    case RESET_SELF_PASSWORD_SUCCESS:
        return {
            ...state,
            error: null,
            isResetPassword: false,
            data: {
                ...state.data,
                pwdReset: false
            }
        };
    case LOGIN_RESET_PASSWORD_ERROR_SERVER:
        return {
            ...state,
            error: action.error,
            data: null,
            isResetPassword: false,
        };
    case LOGOUT_REQUEST:
        throw new Error('This should never get called because it is handled in ducks.js');
    default: return state;
    }
};
export default reducer;


// data accessors
const getState = rootState => rootState?.userAuthentication?.login || initialState;

/** User is being authenticated and authorized. */
export const getIsLoginChecking = state => getState(state).isLoginChecking;

/** User authentication or authorization error. */
export const getLoginError = state => getState(state).error;

/** Information about the current user. */
export const getCurrentUser = state => getState(state)?.data;

/** Information about the current user. */
export const getCurrentUserName = state => getCurrentUser(state)?.username;

/** Information about whether the user was logged out */
export const getWasLoggedOut = state => getState(state).wasLoggedOut;

/** User was successfully authenticated and authorized. */
export const getIsLoggedIn = state => !!getCurrentUser(state);

/** The login form is visible. */
export const getIsLoginFormVisible = state => getState(state).isLoginFormVisible;

/** Whether the user should reset password before logging in */
export const getIsResetPassword = state => getState(state).isResetPassword;

/** Check user new password after change form is sent */
export const getIsResetPasswordChecking = state => getState(state).isPasswordChecking;

/** Passwords don't match */
export const getIsResetPasswordErrorMatch = state => getState(state).isResetPasswordErrorMatch;

/** Passwords are too short */
export const getIsResetPasswordErrorLong = state => getState(state).isResetPasswordErrorLong;
export const getLogintTime = state => getState(state).data?.loginTime;

// action creators
export const loginRequest = (username, password) =>
    ({ type: LOGIN_REQUEST, username, password });

export const loginSuccess = data =>
    ({ type: LOGIN_SUCCESS, data });

export const loginFailure = error =>
    ({ type: LOGIN_FAILURE, error });

export const loginFormShow = () =>
    ({ type: LOGIN_FORM_SHOW });

export const loginFormHide = () =>
    ({ type: LOGIN_FORM_HIDE });

export const loginResetPasswordCheck = (password1, password2) =>
    ({ type: LOGIN_RESET_PASSWORD_CHECK, password1, password2 });

export const loginResetPasswordErrorMatch = error =>
    ({ type: LOGIN_RESET_PASSWORD_ERROR_LONG, error });

export const loginResetPasswordErrorServer = error =>
    ({ type: LOGIN_RESET_PASSWORD_ERROR_SERVER, error });

export const loginResetPasswordErrorLong = error =>
    ({ type: LOGIN_RESET_PASSWORD_ERROR_MATCH, error });

export const loginResetPasswordOk = () =>
    ({ type: LOGIN_RESET_PASSWORD_OK });

export const loginClearError = () =>
    ({ type: LOGIN_CLEAR_ERROR });

export const logoutRequest = (payload) =>
    ({ type: LOGOUT_REQUEST, payload });


// API endpoints
export const signoutSessionUser = async () =>
    axios.get('/api/users/signoutUser'); // TODO post instead


// side effects
export const logoutUser = async function(action) { //also restarts socketsIO
    try {
        document.body.classList.remove('loggedIn');
        const params = { ...window._PARAMS_FOR_INITIAL_STATE };
        window._PARAMS_FOR_INITIAL_STATE = {
            apiError: params.apiError,
            currentTime: params.currentTime,
            i18Options: params.i18Options,
            isProductionMode: params.isProductionMode,
            isWebpackInitialized: params.isWebpackInitialized,
            selectedLanguage: params.selectedLanguage,
            sessionManagement: {},
            statusCode: params.statusCode,
            systemInfo: { hostname: params.systemInfo.hostname, isCluster: params.systemInfo.isCluster },
            useSSR: params.useSSR
        };
        await store.removeAllNotifications();
        await restartAllTask();
        sessionStorage.clear(); //clear all values in session storage
        if (!action?.payload?.alreadySignout) {
            await signoutSessionUser();
        }

    } catch (error) {
        console.log('failed to log out', error); // eslint-disable-line no-console
    }
};


export const sagas = [
    takeLatest(LOGOUT_REQUEST, logoutUser),
    takeLatest(LOGIN_RESET_PASSWORD_ERROR_SERVER, logoutUser),
];
