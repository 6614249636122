/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import SvgInliner from '~frontendComponents/Generic/SvgInliner';

import BlackLogo from './img/svg/kernun-logo-black.svg';
import { sectionKAF, sectionInstallation, sectionGui } from './sections';
import { TYPE_CODE, TYPE_IMAGE, TYPE_LINK, TYPE_TABLE } from './textObjectTypes';


const SECTIONS = [
    sectionKAF,
    sectionInstallation,
    sectionGui
];

const FirstLevelHeadline = ({ children, tag }) => (
    <h1
        id={tag}
        key={tag}
    >
        {children}
    </h1>
);
FirstLevelHeadline.propTypes = {
    children: PropTypes.node,
    tag: PropTypes.string,
};

const SecondLevelHeadline = ({ children, tag }) => (
    <h2
        id={tag}
        key={tag}

    >
        {children}
    </h2>
);
SecondLevelHeadline.propTypes = {
    children: PropTypes.node,
    tag: PropTypes.string,
};

const ThirdLevelHeadline = ({ children, tag }) => (
    <h3
        id={tag}
        key={tag}

    >
        {children}
    </h3>
);
ThirdLevelHeadline.propTypes = {
    children: PropTypes.node,
    tag: PropTypes.string,
};

const ParagraphHeadline = ({ children, tag }) => (
    <h4
        id={tag}
        key={tag}
    >
        {children}
    </h4>
);
ParagraphHeadline.propTypes = {
    children: PropTypes.node,
    tag: PropTypes.string
};

const Paragraph = ({ children }) => (
    <p>
        {children}
    </p>
);
Paragraph.propTypes = {
    children: PropTypes.node
};

const createParagraphNoTextContent = ({ object, t, key }) => {
    switch (object.type) {
    case TYPE_LINK:
        return (
            <a
                href={`#${object.link}`}
                key={key}
            >
                {t(object.text)}
            </a>
        );
    case TYPE_CODE:
        return (
            <code key={key}>
                {t(object.text)}
            </code>
        );
    case TYPE_TABLE:
        return (
            <div
                key={key}
                style={object.divStyle}
            >
                <table style={object.tableStyle}>
                    {
                        object.rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {
                                    row.cols.map((col, colIndex) =>
                                        rowIndex === 0 ?
                                            <th
                                                colSpan={col.colSpan}
                                                key={colIndex}
                                                style={{
                                                    ...col.style,
                                                    padding: '5px',
                                                    border: 'solid 1px black',
                                                }}
                                            >
                                                {t(col.text)}
                                            </th> :
                                            <td
                                                colSpan={col.colSpan}
                                                key={colIndex}
                                                style={{
                                                    ...col.style,
                                                    padding: '5px',
                                                    border: 'solid 1px black',
                                                }}
                                            >
                                                {t(col.text)}
                                            </td>)
                                }
                            </tr>
                        ))
                    }
                </table>
            </div>
        );
    case TYPE_IMAGE:
        return (
            <img
                alt={object.alt || 'no_pic'}
                key={key}
                src={object.src}
                style={{
                    maxWidth: '50rem',
                    marginBottom: '2rem'
                }}
            />
        );
    default: throw new Error(`Unsupported paragraph conent type ${object.type}`);
    }
};


const RenderParagraph = ({ paragraph, t }) => (
    <>
        {paragraph.title ? <ParagraphHeadline>{t(paragraph.title)}</ParagraphHeadline> : null}
        {
            paragraph.isSpecial ?
                createParagraphNoTextContent({ object: paragraph.specialObject, t }) :
                <Paragraph>
                    {paragraph.text.map((text, idx) =>
                        typeof text === 'string' ? <span key={`RenderParagrapsh-span${idx}`}>{t(text)}</span> :
                            createParagraphNoTextContent({
                                object: text, t, key: `RenderParagrapsh-content${idx}`
                            }))
                    }
                </Paragraph>
        }
    </>
);
RenderParagraph.propTypes = {
    paragraph: PropTypes.object,
    t: PropTypes.func
};

const RenderThirdLevelSection = ({ section, t, index }) => (
    <>
        <ThirdLevelHeadline
            tag={section.tag}
        >
            {`${index} ${t(section.title)}`}
        </ThirdLevelHeadline>
        {
            section.pars.map((par, ix) => (
                <RenderParagraph
                    key={ix}
                    paragraph={par}
                    t={t}
                />
            ))
        }
    </>
);
RenderThirdLevelSection.propTypes = {
    section: PropTypes.object,
    t: PropTypes.func,
    index: PropTypes.string
};

const RenderSecondLevelSection = ({ section, t, index }) => (
    <>
        <SecondLevelHeadline
            tag={section.tag}
        >
            {`${index} ${t(section.title)}`}
        </SecondLevelHeadline>
        {
            section.pars.map((par, ix) => (
                <RenderParagraph
                    key={ix}
                    paragraph={par}
                    t={t}
                />
            ))
        }
        {
            section.sections ?
                section.sections.map((sec, ix) => (
                    <RenderThirdLevelSection
                        index={`${index}.${ix + 1}`}
                        key={`sec_${ix}`}
                        section={sec}
                        t={t}
                    />
                )) :
                null
        }
    </>
);
RenderSecondLevelSection.propTypes = {
    section: PropTypes.object,
    t: PropTypes.func,
    index: PropTypes.string
};


const RenderFirstLevelSection = ({ section, t, index }) => (
    <>
        <FirstLevelHeadline
            tag={section.tag}
        >
            {`${index} ${t(section.title)}`}
        </FirstLevelHeadline>
        {
            section.pars.map((par, ix) => (
                <RenderParagraph
                    key={ix}
                    paragraph={par}
                    t={t}
                />
            ))
        }
        {
            section.sections ?
                section.sections.map((sec, ix) => (
                    <RenderSecondLevelSection
                        index={`${index}.${ix + 1}`}
                        key={`sec_${ix}`}
                        section={sec}
                        t={t}
                    />
                )) :
                null
        }
    </>
);
RenderFirstLevelSection.propTypes = {
    section: PropTypes.object,
    t: PropTypes.func,
    index: PropTypes.string
};

const Handbook = ({ isBackend, t }) => {
    return (
        <div className="handbook">
            <div>
                <SvgInliner
                    className="logo__svg"
                    isBackend={isBackend}
                    src={BlackLogo}
                />
            </div>
            {
                SECTIONS.map((section, ix) => (
                    <RenderFirstLevelSection
                        index={`${ix + 1}`}
                        key={section.title}
                        section={section}
                        t={t}
                    />
                ))
            }
        </div>
    );
};
Handbook.propTypes = {
    isBackend: PropTypes.bool,
    t: PropTypes.func
};


export default Handbook;
