/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import ReactSelect from 'react-select/';
import { makeCreatableSelect } from 'react-select/creatable';

/**
 *   Replacing method inside ReactSelect.
 *   We use HOC call for didMount to replace method inside a reference that was stored during mounting.
 *   Possible problems during upgrade of lib:
 *   - HOC has componentDidMount defined in new version.
 *   - Name change or complete change of logic inside referenced class.
 *
 */

class ReactSelectMethodReplacer extends ReactSelect {
    componentDidMount() {
        this.select.getNextFocusedOption = (options) => {
            const { focusedOption: lastFocusedOption } = this.state;
            return lastFocusedOption && options.indexOf(lastFocusedOption) > -1 && !options[0]?.__isNew__ ?
                lastFocusedOption :
                options[0];
        };
    }
}

const SelectCreatable = makeCreatableSelect(
    ReactSelectMethodReplacer
);

export default SelectCreatable;
