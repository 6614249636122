/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Socket, Manager } from 'socket.io-client';
import axios from 'axios';
import { call, put, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { ActionCreatorWithOptionalPayload, createAction, PayloadAction } from '@reduxjs/toolkit';
import { EventChannel, eventChannel } from 'redux-saga';
import { ActionCreator } from 'redux';

import { LOGIN_REQUEST, LOGIN_SUCCESS, logoutRequest } from '~frontendDucks/userAuthentication/ducks/login';
import { createNotification } from '~frontendLib/reactUtils';
import { sleep } from '~commonLib/asyncUtils';
import { SECOND } from '~commonLib/constants';
import { RequireAtLeastOne } from '~commonLib/types';
import { NOT_AUTHENTICATED } from '~sharedConstants';
import { noConnection, yesConnection } from '~frontendDucks/socketIO/connectionLostNotifier';
import { SocketIOPayload } from '~sharedLib/types';
import { SOCKET_IO_NO, SOCKET_IO_PING_EVENT, SOCKET_IO_YES } from '~sharedLib/socketIOEvents';


type SocketIOAction = ActionCreator<any> | ActionCreatorWithOptionalPayload<any, any>
export type SocketIoEventWithActions = RequireAtLeastOne<{
    event: string,
    actionCreator?: SocketIOAction|SocketIOAction[],
    actionCustom?: (data?: any) => void,
}, 'actionCreator' | 'actionCustom'>

export type SocketIOReduxObject = {
    namespace?: string,
    eventsWithActions?: SocketIoEventWithActions[],
    emitArray?: boolean,
}

type SocketIOReduxObjectPayload = PayloadAction<SocketIOReduxObject>;

export const dispatchOnEventInNamespaceAction =
    createAction<SocketIOReduxObject>('ak/socketIO/LISTEN_FOR_EVENT_IN_NAMESPACE');

export const stopDispatchingOnEventInNamespaceAction =
    createAction<SocketIOReduxObject>('ak/socketIO/STOP_LISTENING_FOR_EVENT_IN_NAMESPACE');

export const removeAllSocketIoSocketsAction =
    createAction('ak/socketIO/REMOVE_ALL_SOCKETS');

const SEQ_NUMS_TO_KEEP = 10;

let socketIOToken = null;
let manager: Manager|null = null;

const createManager = () => {
    const manager = new Manager({
        autoConnect: false,
    });
    manager.on('reconnect', () => {
        yesConnection();
    });
    manager.on('reconnect_error', () => {
        noConnection();
    });
    return manager;
};

const sockets: Record<string, {
    io: Socket,
    manager: Manager
    listeners: number,
}> = {};
const getSocket = (namespaceWithoutLeadingSlash) => {
    const namespace = '/' + namespaceWithoutLeadingSlash;
    if (!sockets[namespace]) {
        if (!manager) {
            manager = createManager();
        }
        sockets[namespace] = {
            io: manager.socket(namespace, { auth: { token: socketIOToken } }),
            manager,
            listeners: 0,
        };
        sockets[namespace].io.connect();
    }
    return sockets[namespace];
};
const removeSocket = namespaceWithoutLeadingSlash => {
    const namespace = '/' + namespaceWithoutLeadingSlash;
    if (sockets[namespace]) {
        sockets[namespace].io.off();
        sockets[namespace].io.disconnect();
        delete sockets[namespace];
    }
};

const getToken = async () => axios.get('/api/sessions/getToken');

const workerGetToken = function* () {
    const { data } = yield call(getToken);
    const { token } = data;
    socketIOToken = token;
};

const createSocketChan = (socketObject: SocketIOReduxObject) => {
    const { namespace = '', eventsWithActions = [], emitArray = false } = socketObject;
    const socket = getSocket(namespace);
    socket.listeners++;

    const seenSequenceNumbers = [] as number[];
    let lastSocketId = socket.io.id;
    // `eventChannel` takes a subscriber function
    // the subscriber function takes an `emit` argument to put messages onto the channel
    return eventChannel(emit => {

        const doAction = (fn, data) => {
            if (emitArray) {
                fn(data);
            } else {
                data.forEach(data => fn(data));
            }
        };

        const emitWrap = fn => (...data) => emit(fn(...data));

        const handlersWithEvents = eventsWithActions.map(({ event, actionCreator, actionCustom }) => {
            return {
                event,
                handler: ({ sequenceNum, data }: SocketIOPayload) => {
                    if (socket.io.id !== lastSocketId) {
                        // Socket has changed, so we need to forget seen numbers as they do not apply to this socket.
                        seenSequenceNumbers.splice(0);
                        lastSocketId = socket.io.id;
                    }
                    if (seenSequenceNumbers.includes(sequenceNum)) {
                        return;
                    }
                    seenSequenceNumbers.push(sequenceNum);
                    if (seenSequenceNumbers.length >= SEQ_NUMS_TO_KEEP) {
                        seenSequenceNumbers.shift();
                    }
                    if (!Array.isArray(data)) {
                        createNotification({
                            title: 'ERROR',
                            desc: 'SocketIO data is not an array',
                            type: 'danger',
                            persistent: true
                        });
                        throw new Error(`SocketIO data is not an array ${JSON.stringify(data)}`);
                    }
                    if (actionCreator) {
                        if (Array.isArray(actionCreator)) {
                            actionCreator.forEach(creator => {
                                doAction(emitWrap(creator), data);
                            });
                        } else {
                            doAction(emitWrap(actionCreator), data);
                        }
                    }
                    if (actionCustom) {
                        doAction(actionCustom, data);
                    }
                } };
        });

        handlersWithEvents.forEach(({ event, handler }) => {
            socket.io.on(event, (data: SocketIOPayload, ack) => {
                ack(SOCKET_IO_YES);
                return handler(data);
            });
        });
        const pingHandler = (data: SocketIOPayload, ack) => {
            const [ aboutToReceiveEvent ] = data.data;
            const amListening = handlersWithEvents.some(({ event }) => event === aboutToReceiveEvent);

            ack(amListening ? SOCKET_IO_YES : SOCKET_IO_NO);
        };
        socket.io.on(SOCKET_IO_PING_EVENT, pingHandler);

        socket.io.on('connect_error', error => {
            if (error.message === NOT_AUTHENTICATED) {
                emit(logoutRequest({ alreadySignout: true, reason: error.message, statusCode: 401 }));
            } else if (error.message === 'xhr poll error') {
                noConnection();
            }
        });

        // the subscriber must return an unsubscribe function
        // this will be invoked when the saga calls `channel.close` method
        return () => {
            socket.listeners--;

            handlersWithEvents.forEach(({ event, handler }) => {
                socket.io.off(event, handler);
            });
            socket.io.off(SOCKET_IO_PING_EVENT, pingHandler);

            if (socket.listeners === 0) {
                removeSocket(namespace);
            }
        };
    });
};

interface SocketSubscription {
    eventChan: EventChannel<any>,
    objForSocket: SocketIOReduxObject,
    active: boolean,
}

const activeSubscriptions = [] as SocketSubscription[];

const listenForEventInNamespaceWorker = function *({ payload: objForSocket }: SocketIOReduxObjectPayload) {
    if (!socketIOToken) {
        yield call(workerGetToken);
    }
    if (activeSubscriptions.some(subscr => subscr.objForSocket === objForSocket)) {
        return;
    }

    const eventChan = createSocketChan(objForSocket);
    const subscription = { eventChan, objForSocket, active: true } as SocketSubscription;
    activeSubscriptions.push(subscription);

    try {
        while (subscription.active) {
            const payload = yield take(eventChan);
            if (subscription.active && payload?.type) {
                yield put(payload);
            }
        }
    } finally {
        if (subscription.active) {
            yield put(stopDispatchingOnEventInNamespaceAction(objForSocket));
        }
    }
};

const stopDispatchingOnEventInNamespaceWorker = function *({ payload: objForSocket }: SocketIOReduxObjectPayload) {
    const subcscrIdx = activeSubscriptions.findIndex(subscr => subscr.objForSocket === objForSocket);
    if (subcscrIdx !== -1) {
        activeSubscriptions[subcscrIdx].active = false;
        const eventChan = activeSubscriptions[subcscrIdx].eventChan;
        activeSubscriptions.splice(subcscrIdx, 1);
        // sleeping for a little bit allows finishing confirmation of last message,
        // preventing emitting unnecessary warnings on the backend that the last message is being deleted
        // because frontend did not confirm that it accepted it
        yield call(sleep, SECOND);
        eventChan.close();
    }
};

const removeAllSockets = async () => {
    const subcriptions = activeSubscriptions.splice(0, activeSubscriptions.length);


    await Promise.all(subcriptions.map(async subscr => {
        subscr.active = false;
        const eventChan = subscr.eventChan;
        // sleeping for a little bit allows finishing confirmation of last message,
        // preventing emitting unnecessary warnings on the backend that the last message is being deleted
        // because frontend did not confirm that it accepted it
        await sleep(SECOND);
        eventChan.close();
    }));
    Object.keys(sockets).forEach(nsp => {
        sockets[nsp].io.disconnect();
        delete sockets[nsp];
    });
    manager = null;
    socketIOToken = null;
};

export const sagas = [
    takeLeading(LOGIN_SUCCESS, workerGetToken),
    takeEvery(dispatchOnEventInNamespaceAction, listenForEventInNamespaceWorker),
    takeEvery(stopDispatchingOnEventInNamespaceAction, stopDispatchingOnEventInNamespaceWorker),

    takeLeading(LOGIN_REQUEST, removeAllSockets), // because there may be some leftover sockets from previous session
    takeEvery(removeAllSocketIoSocketsAction, removeAllSockets),
];
