/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import charts from './charts';
import tFilter from './tFilter';


const valueFormatter = {
    defaults: {
        precision: 2
    }
};

const isNaN = (typeof window === 'object' && window.isNaN) ||
    (typeof global === 'object' && global.isNaN) ||
    Number.isNaN;

const kilo = 1000;
const mega = kilo * 1000;
const giga = mega * 1000;
const tera = giga * 1000;
const getUnitFormatted = (value, precision, separator) => {
    if ((value >= 0) && (value < kilo)) {
        return charts.toFixed(value, precision) + separator;
    } else if ((value >= kilo) && (value < mega)) {
        return charts.toFixed(value / kilo, precision) + '\xa0k';
    } else if ((value >= mega) && (value < giga)) {
        return charts.toFixed(value / mega, precision) + '\xa0M';
    } else if ((value >= giga) && (value < tera)) {
        return charts.toFixed(value / giga, precision) + '\xa0G';
    } else if (value >= tera) {
        return charts.toFixed(value / tera, precision) + '\xa0T';
    } else {
        return value + separator;
    }
};

const getDelimiterFormatted =
        value => `${value}`.replace('.', tFilter('global:decimalDelimiter', {}, '.'));

const formatVals = (suffix, notSuffixable, perSec) => (value, precision) => {
    if (typeof precision !== 'number') {
        precision = valueFormatter.defaults.precision;
    }
    let prefix = '';
    value = Number(value);
    if (isNaN(value)) {
        value = '?';
    }
    if (value === Infinity) {
        value = '\u221E';
    }
    if (value === -Infinity) {
        value = '-\u221E';
    }
    if (value < 0) {
        value = -value;
        prefix = '-';
    }
    const separator = suffix ? '\xa0' : '';
    if (notSuffixable) {
        return prefix +
            getDelimiterFormatted(typeof value === 'number' ? charts.toFixed(value, precision) : value) +
            separator +
            suffix;
    }
    const valueFormatted = getDelimiterFormatted(getUnitFormatted(value, precision, separator));
    return prefix + valueFormatted + suffix + (perSec ? '/s' : '');
};

valueFormatter.formatBits = formatVals('b');
valueFormatter.formatBytes = formatVals('B');
valueFormatter.formatBytesPerSec = formatVals('B', false, true);
valueFormatter.formatCount = formatVals('', true);
valueFormatter.formatPercent = formatVals('%', true);
valueFormatter.formatTemperature = formatVals('°C', true);

const FUZZY_ORDER = [ 'one', 'few', 'half' ];
// thresholds for fuzzy counting
const FUZZY_THRESHOLDS = {
    s: {
        // about a second
        one: 2,
        // a few seconds
        few: 10,
        // half a minute
        half: 45,
        // about a minute
    },
    m: {
        // about a minute
        one: 2,
        // a few minutes
        few: 10,
        // half an hour
        half: 45,
        // about an hour
    },
    h: {
        // about an hour
        one: 2,
        // a few hours
        few: 6,
        // half a day
        half: 18,
        // about a day
    },
    // more than a day is shown exactly
};

/**
 * Formats given value as a number of seconds.
 *
 * @memberof valueFormatter
 * @param {number} seconds the value to be formatted
 * @param {object} [options]
 * @param {boolean} [options.isFuzzy=false] - when true, the duration is
 * fuzzied
 * @param {boolean} [options.isShort=false] - when true, the duration is
 * rounded down
 * @param {boolean} [options.isTextLong=false] - when true, the text will be
 * shown in long form
 * @param {boolean} [options.isTextAgo=false]
 * @returns {string} formatted value
 */
valueFormatter.formatSeconds = function(seconds, options) {
    if (isNaN(1 * seconds)) {
        return '?';
    }
    if (!seconds) {
        if (options && options.isTextAgo) {
            return tFilter('global:timeAgo.right-now');
        }
        // TODO check if the next line was required
        //return '0 s';
    }
    let isNegative = false;
    if (seconds < 0) {
        isNegative = true;
        seconds = -seconds;
    }
    const minutes = 60;
    const hours = minutes * 60;
    const days = hours * 24;
    const years = days * 365;
    let timeArray = [];
    const yAxis = parseInt(Math.floor(seconds / years));
    timeArray.push({ type: 'y', value: yAxis });
    seconds -= years * yAxis;
    const nDays = parseInt(Math.floor(seconds / days));
    timeArray.push({ type: 'd', value: nDays });
    seconds -= days * nDays;
    const nHours = parseInt(Math.floor(seconds / hours));
    timeArray.push({ type: 'h', value: nHours });
    seconds -= hours * nHours;
    const nMonths = parseInt(Math.floor(seconds / minutes));
    timeArray.push({ type: 'm', value: nMonths });
    seconds -= minutes * nMonths;
    timeArray.push({ type: 's', value: Math.round(seconds) });
    let from = 0;
    let to = timeArray.length - 1;
    while (from < to) {
        if (timeArray[from].value) {
            break;
        }
        ++from;
    }
    while (to > from) {
        if (timeArray[to].value) {
            break;
        }
        --to;
    }
    timeArray = timeArray.slice(from, to + 1).map(function(timeObject, index) {
        const timeText = timeObject.type;
        const timeValue = timeObject.value;
        if (options && options.isFuzzy && !isNegative) {
            const fuzzyThreshold = FUZZY_THRESHOLDS[timeText];
            if (fuzzyThreshold) {
                for (let index = 0, len = FUZZY_ORDER.length; index < len; ++index) {
                    const fuzzyOrder = FUZZY_ORDER[index];
                    if (timeValue < fuzzyThreshold[fuzzyOrder]) {
                        return tFilter('global:timeFuzzy.' + fuzzyOrder + '_' + timeText);
                    }
                }
                return tFilter('global:timeFuzzy.up_' + timeText);
            }
        }
        if (options && options.isTextLong) {
            return (isNegative ? '-' : '') + timeValue + '\xa0' +
                tFilter('global:timeShort_interval.' + timeText, { count: timeValue, postProcess: 'interval' });
        }
        if (options && options.isTextAgo) {
            return tFilter(
                'global:timeAgo_interval.' + timeText,
                {
                    count: (isNegative ? -1 : 1) * timeValue,
                    postProcess: 'interval',
                }
            );
        }
        return (isNegative && !index ? '-' : '') + timeValue + '\xa0' +
            tFilter('global:timeSymbol.' + timeText);
    });
    if (options && options.isShort) {
        return timeArray[0];
    }
    return timeArray.join(' ');
};

export default valueFormatter;
