/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { SchemaError, ValidationError, ValidatorResult } from 'jsonschema';

import { IPV4, IPV6 } from '~commonLib/constants';
import {
    forValidatorResult
} from '~backendModules/hlcfgManipulator/lib/hlcfgValidation/jsonSchemaErrorToVerificationError';
import { InvalidNetaddrError, netaddr } from '~commonLib/Netaddr/Netaddr';
import { NetaddrDataObj, TFunctionLike, TypeNetaddr } from '~sharedLib/types';
import { NetaddrDhcp } from '~commonLib/Netaddr/NetaddrDhcp';
import { netaddrRegexes } from '~commonLib/netaddrRegexes';
import { SCHEMA_TYPE_NETADDR, StackedSchemaError } from '~sharedLib/schemaTypes';


export const netaddrValidate = <TFunc extends TFunctionLike>(
    tFunction: TFunc, instance: NetaddrDataObj|string, typeNetaddr: TypeNetaddr
): ReturnType<TFunc>[] => {
    if (!typeNetaddr) {
        return [ tFunction('cfg:netaddr.verifyError.desc.missingTypeNetaddr') ];
    }
    const {
        domain, ip4, ip6, mask, prefix,
        cannotBeNetworkAddress, cannotBeBroadcastAddress,
        mustBeNetworkAddress, mustBeBroadcastAddress, optionalMask,
        domainWithPattern, canBeInterfaceAddress = false, canBeFromDhcp = false,
        simpleCanBeZeroes = false,
    } = typeNetaddr;

    const validateFromDhcp = (objNetaddr: NetaddrDhcp) => {
        const isNetworkType = objNetaddr.dhcp.type === 'network';
        const ip6Exclusive = ip6 && !ip4;
        const ip4Exclusive = ip4 && !ip6;
        const isIpv4 = objNetaddr.dhcp.ipVersion === IPV4;
        const isIpv6 = objNetaddr.dhcp.ipVersion === IPV6;

        if (mustBeBroadcastAddress) {
            return [ tFunction('cfg:netaddr.verifyError.desc.mustBeBroadcastAddress') ];
        }

        if (mustBeNetworkAddress && !isNetworkType) {
            return [ tFunction('cfg:netaddr.verifyError.desc.mustBeNetworkAddress') ];
        }
        if (cannotBeNetworkAddress && isNetworkType) {
            return [ tFunction('cfg:netaddr.verifyError.desc.cannotBeNetworkAddress') ];
        }
        if (ip4Exclusive && !isIpv4) {
            return [ tFunction('cfg:netaddr.verifyError.desc.ip4Basic') ];
        }
        if (ip6Exclusive && !isIpv6) {
            return [ tFunction('cfg:netaddr.verifyError.desc.ip6Basic') ];
        }

        return [];
    };

    if (mustBeNetworkAddress && mustBeBroadcastAddress) {
        // To be honest, any address with mask 32 is both a network address and a broadcast address, but then this
        // condition is tautologic.
        throw new Error('No address can be both a network address and a broadcast address at the same time');
    }
    if (cannotBeNetworkAddress && mustBeNetworkAddress) {
        throw new Error('This condition can never be true');
    }
    if (cannotBeBroadcastAddress && mustBeBroadcastAddress) {
        throw new Error('This condition can never be true');
    }
    const result:ReturnType<TFunc>[] = [];

    try {
        const objNetaddr = netaddr(instance);
        const strNetaddr = objNetaddr.toString();

        if (!strNetaddr) {
            return [ tFunction('cfg:netaddr.verifyError.desc.optional') ];
        }
        if (canBeFromDhcp && objNetaddr.isFromDhcp()) {
            return validateFromDhcp(objNetaddr);
        }
        if (!canBeFromDhcp && objNetaddr.isFromDhcp()) {
            return [ tFunction('cfg:netaddr.verifyError.desc.cannotBeFromDhcp') ];
        }

        if (mustBeNetworkAddress && (!objNetaddr.isIp() || !objNetaddr.isNetworkAddress())) {
            return [ tFunction('cfg:netaddr.verifyError.desc.mustBeNetworkAddress') ];
        }
        if (cannotBeNetworkAddress && (objNetaddr.isIp() && objNetaddr.isNetworkAddress())) {
            return [ tFunction('cfg:netaddr.verifyError.desc.cannotBeNetworkAddress') ];
        }
        if (mustBeBroadcastAddress && (!objNetaddr.isIp() || !objNetaddr.isBroadcast())) {
            return [ tFunction('cfg:netaddr.verifyError.desc.mustBeBroadcastAddress') ];
        }
        if (cannotBeBroadcastAddress && (objNetaddr.isIp() && objNetaddr.isBroadcast())) {
            return [ tFunction('cfg:netaddr.verifyError.desc.cannotBeBroadcastAddress') ];
        }
        if (!canBeInterfaceAddress && objNetaddr.isIp() && objNetaddr.isNetIfAddress()) {
            return [
                tFunction(
                    'cfg:netaddr.verifyError.desc.mustBeNetworkOrSimpleAddress',
                    { addr: objNetaddr.toNetworkOrSimpleAddr().toString() }
                )
            ];
        }
        if (ip4) {
            if (optionalMask) {
                if (objNetaddr.isIp4() && objNetaddr.isValid()) {
                    if (!objNetaddr.hasMask() && !simpleCanBeZeroes && objNetaddr.getIp() === '0.0.0.0') {
                        return [ tFunction('cfg:netaddr.verifyError.desc.canNotBeZeroes') ];
                    } else {
                        return [];
                    }
                }
                result.push(tFunction('cfg:netaddr.verifyError.desc.optionalMask'));
            } else if (mask) {
                if (objNetaddr.isIp4() && objNetaddr.isValid() && objNetaddr.hasMask()) {
                    return [];
                }
                result.push(tFunction('cfg:netaddr.verifyError.desc.ip4WithMask'));
            } else {
                if (objNetaddr.isIp4() && objNetaddr.isValid() && !objNetaddr.hasMask()) {
                    if (!simpleCanBeZeroes && objNetaddr.getIp() === '0.0.0.0') {
                        return [ tFunction('cfg:netaddr.verifyError.desc.canNotBeZeroes') ];
                    } else {
                        return [];
                    }
                }
                result.push(tFunction('cfg:netaddr.verifyError.desc.ip4Basic'));
            }
        }
        if (ip6) {
            if (optionalMask) {
                if (objNetaddr.isIp6() && objNetaddr.isValid()) {
                    if (!objNetaddr.hasMask() && !simpleCanBeZeroes && objNetaddr.getIp() === '::') {
                        return [ tFunction('cfg:netaddr.verifyError.desc.canNotBeZeroes') ];
                    } else {
                        return [];
                    }
                }
                result.push(tFunction('cfg:netaddr.verifyError.desc.optionalMask'));
            } else if (prefix) {
                if (objNetaddr.isIp6() && objNetaddr.isValid() && objNetaddr.hasMask()) {
                    return [];
                }
                result.push(tFunction('cfg:netaddr.verifyError.desc.ip6WithPrefix'));
            } else {
                if (objNetaddr.isIp6() && objNetaddr.isValid() && !objNetaddr.hasMask()) {
                    if (!simpleCanBeZeroes && objNetaddr.getIp() === '::') {
                        return [ tFunction('cfg:netaddr.verifyError.desc.canNotBeZeroes') ];
                    } else {
                        return [];
                    }
                }
                result.push(tFunction('cfg:netaddr.verifyError.desc.ip6Basic'));
            }
        }
        if (domain) {
            if (netaddrRegexes.domain.test(strNetaddr)) {
                return [];
            }
            result.push(tFunction('cfg:netaddr.verifyError.desc.domain'));
        }
        if (domainWithPattern) {
            if (netaddrRegexes.domainWithPattern.test(strNetaddr)) {
                return [];
            }
            result.push(tFunction('cfg:netaddr.verifyError.desc.domain'));
        }
        return result;
    } catch (error) {
        if (error instanceof InvalidNetaddrError) {
            return [ tFunction('cfg:netaddr.verifyError.desc.invalidAddr') ];
        }
        throw error;
    }
};

/**
 * Custom validation function. Used as a JSON Schema verification function.
 */
export const getValidateNetaddrScalar = (tFunction) =>
    (instance, schema, options, ctx) => {
        const typeNetaddr = schema[SCHEMA_TYPE_NETADDR];
        if (typeof typeNetaddr !== 'object') {
            throw new SchemaError('"typeNetaddr" expects an object', schema);
        }
        const errors: (string|ValidationError)[] = [];
        switch (typeof instance) {
        case 'undefined':
            break;
        case 'object': {
            try {
                errors.push(...netaddrValidate(tFunction, instance, typeNetaddr));
            } catch (error) {
                throw new StackedSchemaError(error, schema);
            }
            break;
        }
        default:
            errors.push(tFunction('cfg:netaddr.verifyError.desc.notObject'));
        }
        if (!errors.length) {
            return undefined;
        }
        const validatorResult = new ValidatorResult(instance, schema, options, ctx);
        const title = tFunction('cfg:netaddr.verifyError.title');
        for (const errorMessage of errors) {
            validatorResult.addError(`title=${forValidatorResult(title)} desc=${forValidatorResult(errorMessage)}`);
        }
        return validatorResult;
    };
