/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';

import {
    EMPTY_IMMUTABLE_ARR,
} from '~sharedConstants';
import { stringifyAddress } from '~frontendLib/addressUtils';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils';
import { SCHEMA_TYPE_NEGATABLE_NETADDR_LIST, SCHEMA_TYPE_NETADDR } from '~sharedLib/schemaTypes';
import { NAMED_OBJECTS_NETADDR_SCALAR, NAMED_OBJECTS_NETADDR_VECTOR,
    NAMED_OBJECTS_NETADDR_VECTOR_TABLE, NAMED_OBJECTS_NETADDR_SCALAR_TABLE } from '~frontendRoot/constants';
import { WITHOUT_NAMED_OBJECTS } from '~commonLib/schemaFlags';

import { getNormalizedTableGetters, getGlcfgValue,
    getGlcfgSchema,
    getSchemaNormalizeTableParams } from './glcfgGettersAndSettersUtils';


export const getNamedObjectNetaddrVector = (state) => getGlcfgValue(state, NAMED_OBJECTS_NETADDR_VECTOR);
export const getNamedObjectNetaddrVectorTable = (state) => getGlcfgValue(state, NAMED_OBJECTS_NETADDR_VECTOR_TABLE);

export const getNamedObjectNetaddrScalar = (state) => getGlcfgValue(state, NAMED_OBJECTS_NETADDR_SCALAR);
export const getNamedObjectNetaddrScalarTable = (state) => getGlcfgValue(state, NAMED_OBJECTS_NETADDR_SCALAR_TABLE);


export const {
    getByIdType: getNamedObjectNetaddrVectorById,
    getIds: getNamedObjectNetaddrVectorIds,
    getItems: getNamedObjectNetaddrVectorItems
} = getNormalizedTableGetters(NAMED_OBJECTS_NETADDR_VECTOR);

export const {
    getByIdType: getNamedObjectNetaddrScalarById,
    getIds: getNamedObjectNetaddrScalarIds,
    getItems: getNamedObjectNetaddrScalarItems,
} = getNormalizedTableGetters(NAMED_OBJECTS_NETADDR_SCALAR);

export const getNamedObjectsSelector = () => createSelector(
    [
        getNamedObjectNetaddrVectorItems,
        getNamedObjectNetaddrScalarItems,
        (state) => getNamedObjectNetaddrAllValues(state),
        (state, schema) => schema,
    ],
    (netaddrVector, netaddr, tooltips, schema,) => {
        if (!schema || schema?.[WITHOUT_NAMED_OBJECTS] || schema?.items?.[WITHOUT_NAMED_OBJECTS]) {
            return EMPTY_IMMUTABLE_ARR;
        }
        const returnValue = [];
        if (schema) {
            if (schema.items?.[SCHEMA_TYPE_NETADDR] || schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
                returnValue.push(...netaddrVector.map(item =>
                    ({ label: item?.name, value: item.id, tooltipValues: tooltips[item.id] })));
                returnValue.push(...netaddr.map(item =>
                    ({ label: item?.name, value: item.id, tooltipValues: tooltips[item.id] })));

            } else if (schema[SCHEMA_TYPE_NETADDR]) {
                returnValue.push(...netaddr.map(item =>
                    ({ label: item?.name, value: item.id, tooltipValues: tooltips[item.id] })));
            }

        }
        return returnValue;
    }
);

export const getNamedObjectNetaddrVectorSchema = (state) => {
    return getSchemaNormalizeTableParams(getGlcfgSchema(state, 'namedObjectsNetaddrVector')).value;
};

export const getNamedObjectNetaddrScalarSchema = (state) => {
    return getSchemaNormalizeTableParams(getGlcfgSchema(state, 'namedObjectsNetaddrScalar')).value;
};

export const getNamedObjectNetaddrAll = createSelector([
    getNamedObjectNetaddrScalarById,
    getNamedObjectNetaddrVectorById
], (scalar, vector) => ({ ...scalar, ...vector }));

const getValueFromNamedObject = (scalar, vector, value, seen) => {
    if (seen[value?.id]) {
        return;
    }
    seen[value?.id] = true;
    if (isNamedObject(value?.value)) {
        return getValueFromNamedObject(scalar, vector, scalar.find(item =>
            item.id === namedObjectToString(value?.value)) || vector.find(item =>
            item.id === namedObjectToString(value?.value)), seen);

    } else {
        return stringifyAddress(value?.value);
    }

};

export const getNamedObjectNetaddrAllValues = createSelector([
    getNamedObjectNetaddrScalarItems,
    getNamedObjectNetaddrVectorItems,
], (scalar, vector) => {
    const returnObj = {};
    scalar.forEach(item => {
        if (isNamedObject(item.value)) {
            returnObj[item.id] = [ getValueFromNamedObject(scalar, vector, item, {}) ];
        } else {
            returnObj[item.id] = stringifyAddress(item.value);
        }
    });

    vector.forEach(item => {
        returnObj[item.id] = (item?.value || []).map(val => {
            if (isNamedObject(val)) {
                return getValueFromNamedObject(scalar, vector, { id: item.id, value: val }, {});
            } else {
                return stringifyAddress(val);
            }
        });});
    return returnObj;

});
