/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBNavItem } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { isPrefix } from '~commonLib/stringUtils';

import NavBarMenuDropdown from '../NavBarMenuDropdown';


class NavBarMenuItem extends Component {
    static get propTypes() {
        return {
            activeRoute: PropTypes.string.isRequired,
            className: PropTypes.string,
            isCollapsed: PropTypes.bool.isRequired,
            isTempOpen: PropTypes.bool.isRequired,
            objRoute: PropTypes.object.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            doLogoutRequest: PropTypes.func,
        };
    }

    render() {
        const {
            activeRoute, doLogoutRequest, className,
            isCollapsed, isTempOpen, objRoute, setActiveRoute
        } = this.props;
        if (!objRoute || objRoute.hideFromMenu || !objRoute.scenes) {
            return null;
        }
        return (
            <MDBNavItem
                active={(isCollapsed && !isTempOpen) && isPrefix(activeRoute, objRoute.route.key)}
                className={className}
            >
                <NavBarMenuDropdown
                    activeRoute={activeRoute}
                    className={objRoute.className}
                    doLogoutRequest={doLogoutRequest}
                    objRoute={objRoute}
                    setActiveRoute={setActiveRoute}
                />
            </MDBNavItem>
        );
    }
}

export default NavBarMenuItem;
