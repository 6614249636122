/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Suspense, lazy } from 'react';

import { memoizeByFirstStringifiedArg, retryFnNTimesWithInterval } from '~commonLib/functionUtils';
import { SECOND } from '~commonLib/constants';


let preloading = false;
const imports = [];
const preloadWorker = {
    startPreloading: () => {
        preloading = true;
        imports.forEach(importStatement => importStatement());
    },
    preload: importStatement => {
        if (preloading) {
            importStatement();
        } else {
            imports.push(importStatement);
        }
    }
};

if (window) {
    window.addEventListener('load', () => {
        // add arbitrary low timeout, to let frontend finish the loading
        // which is done during this event, so we want not to slow it down
        setTimeout(() => {
            preloadWorker.startPreloading();
        }, 100);
    });
}

export const createLazy = (importStatement) => {
    const doImport = memoizeByFirstStringifiedArg(async () => {
        return await retryFnNTimesWithInterval({
            fn: importStatement,
            times: 3,
            interval: 3 * SECOND,
        });
    });
    const LazyComponent = lazy(doImport);
    preloadWorker.preload(doImport);
    return LazyComponent;
};
export const createLazyWithSuspense = (importStatement, suspenseFallback = '') => {
    const LazyComponent = createLazy(importStatement);
    const LazyComponentWithSuspense = (props) => (
        <Suspense fallback={suspenseFallback}>
            <LazyComponent {...props} />
        </Suspense>
    );
    return LazyComponentWithSuspense;
};
