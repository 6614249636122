/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    IPS_EVENTS_REPORT,
    IPS_TOP_ATTACK,
    IPS_TOP_PROTOCOL,
    IPS_REPORT_PER_HOUR
} from '~frontendConstants';

import { addTimeFilter, getTwoWeeksTimeStaticFilter } from '../reportsFilters';
import { LEGEND } from '../reportsUtils';


export const ipsReports = [
    {
        'charts': [
            {
                'config': {
                    'type': 'line',
                    'overridingParameters': {
                        'type': 'line',
                        'legend': LEGEND,
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'fillOpacity': 0.5,
                        'col': '12',
                        'plotPoints': false,
                    }
                }
            },
        ],
        'report': {
            'id': IPS_EVENTS_REPORT,
            'type': 'universal',
            'name': {
                'cs': 'Nebezpečná spojení',
                'en': 'Dangerous connections',
            },
            'params': {
                'database': 'reporter',
                'table': 'net',
                'categories': [
                    'event.date_minute',
                ],
                'metrics': [
                    'net.blocked_ips_events',
                    'net.alerted_ips_events',
                ],
                'orderBy': [
                    {
                        'col': 'event.date_minute',
                        'dir': 'asc'
                    }
                ],
                'filters': addTimeFilter()
            }
        },
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'bar',
                    'additionalParameters': {
                        'col': '6',
                        'className': 'mt-2'
                    }
                }
            },
        ],
        'report': {
            'name': {
                'cs': 'Nejčastější typy útoků',
                'en': 'The most common attack types',
            },
            'id': IPS_TOP_ATTACK,
            'type': 'universal',
            'params': {
                'database': 'reporter',
                'table': 'eve_e_alert',
                'categories': [
                    'eve_e_alert.alert_signature',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.events',
                        'dir': 'desc'
                    },
                ],
                'rowsTo': 10,
                'filters': addTimeFilter()
            }
        }
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'bar',
                    'additionalParameters': {
                        'col': '6',
                        'className': 'mt-2'
                    }
                }
            },
        ],
        'report': {
            'name': {
                'cs': 'Nejčastější protokoly',
                'en': 'The most common protocols',
            },
            'id': IPS_TOP_PROTOCOL,
            'type': 'universal',
            'params': {
                'database': 'reporter',
                'table': 'eve_e_alert',
                'categories': [
                    'eve_e.proto',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.events',
                        'dir': 'desc'
                    },
                ],
                'rowsTo': 10,
                'filters': addTimeFilter()
            }
        }
    },
    {
        'charts': [
            {
                'config': {
                    'type': 'heatmap',
                    'overridingParameters': {
                        'legend': {
                            'enabled': false,
                        },
                        'yAxis': {
                            'gridLineWidth': 1,
                        },
                    },
                    'additionalParameters': {
                        'colors': [],
                        'fillOpacity': 0.5,
                        'title': 'sum',
                        'sortBy': 'action',
                        'col': '12',
                        'className': 'mt-2',
                        'traffic': true
                    }
                }
            },
        ],
        'report': {
            'id': IPS_REPORT_PER_HOUR,
            'type': 'universal',
            'name': {
                'cs': 'Provoz zachycený IPS',
                'en': 'Traffic captured by IPS',
            },
            'params': {
                'database': 'reporter',
                'table': 'eve_e_alert',
                'categories': [
                    'event.date',
                    'event.hour_of_day',
                ],
                'metrics': [
                    'event.events'
                ],
                'orderBy': [
                    {
                        'col': 'event.date',
                        'dir': 'asc'
                    },
                ],
                'filters': getTwoWeeksTimeStaticFilter()
            }
        },
    },
];
