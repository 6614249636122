/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { RESET_PASSWORD_TIMEOUT_FE } from '~sharedConstants';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime';
import { getPasswordResetTimerStart } from '~frontendDucks/sessionsManagement';
import { resetSelfPasswordRequest } from '~frontendDucks/userManagement/ducks/selectedUser';

import Countdown from '../../../../components/Countdown';
import { Icon, Input } from '../../../../components/Generic';
import { typeApiError } from '../../../../types';
import {
    getCurrentUser, getIsResetPasswordErrorLong, getIsResetPasswordErrorMatch, loginResetPasswordErrorMatch,
    loginResetPasswordErrorLong
} from '../../../../ducks/userAuthentication/ducks/login';
import { getGuiLoadingError } from '../../../../ducks/userAuthentication/ducks/guiLoading';


@withTranslation()
@connect(
    state => ({
        currentUser: getCurrentUser(state),
        guiLoadingError: getGuiLoadingError(state),
        isResetPasswordErrorLong: getIsResetPasswordErrorLong(state),
        isResetPasswordErrorMatch: getIsResetPasswordErrorMatch(state),
        currentBeTime: getCurrentServerTimestamp(state),
        resetPasswordStart: getPasswordResetTimerStart(state),
    }),
    {
        doResetUser: resetSelfPasswordRequest,
        doLoginResetPasswordErrorMatch: loginResetPasswordErrorMatch,
        doLoginResetPasswordErrorLong: loginResetPasswordErrorLong,
    }
) class LoginNewPassForm extends Component {
    static get propTypes() {
        return {
            doLoginResetPasswordErrorMatch: PropTypes.func,
            doLoginResetPasswordErrorLong: PropTypes.func,
            doResetUser: PropTypes.func,
            guiLoadingError: typeApiError,
            isResetPasswordErrorMatch: PropTypes.bool,
            isResetPasswordErrorLong: PropTypes.bool,
            t: PropTypes.func,
            currentBeTime: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
            resetPasswordStart: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
        };
    }

    handleLoginNewPassSubmit = (event) => {
        const { doResetUser, doLoginResetPasswordErrorMatch, doLoginResetPasswordErrorLong } = this.props;
        const password1 = event.target.loginNewPassword1.value;
        const password2 = event.target.loginNewPassword2.value;

        event.preventDefault();

        if (password1.length < 5) {
            // redux state set error long
            doLoginResetPasswordErrorLong();
        } else if (password2 !== password1) {
            // redux state set error (to LoginError component)
            doLoginResetPasswordErrorMatch();
        } else {
            // set new password and go to inside app screen
            doResetUser({
                password: password2,
            });
        }
    };

    render() {
        const {
            guiLoadingError, isResetPasswordErrorLong, isResetPasswordErrorMatch, t, resetPasswordStart, currentBeTime
        } = this.props;

        return (
            <form
                onSubmit={this.handleLoginNewPassSubmit}
            >
                <h2 className="loginForm__title">{t('login:form.titleNewPass')}</h2>
                <p className="loginForm__perex">{t('login:form.perexNewPass')}</p>
                <p className="loginForm__perex">
                    {t('login:form.timerNewPass')}
                    <Countdown
                        currentTime={currentBeTime}
                        duration={RESET_PASSWORD_TIMEOUT_FE}
                        startTime={resetPasswordStart}
                    />
                </p>
                <fieldset className="loginForm__fieldset">
                    <Input
                        autoComplete="username"
                        className="loginForm__username"
                        containerClass="d-none"
                        dark
                        disabled
                        id="loginUsername"
                        type="hidden"
                    />
                    <Input
                        autoComplete="new-password"
                        className="loginNewPassForm__newpassword1"
                        dark
                        disabled={Boolean(guiLoadingError || isResetPasswordErrorMatch || isResetPasswordErrorLong)}
                        id="loginNewPassword1"
                        label={t('login:form.newPass1')}
                        type="password"
                    />
                    <Input
                        autoComplete="new-password"
                        className="loginNewPassForm__newpassword2"
                        dark
                        disabled={Boolean(guiLoadingError || isResetPasswordErrorMatch || isResetPasswordErrorLong)}
                        id="loginNewPassword2"
                        label={t('login:form.newPass2')}
                        type="password"
                    />
                    <MDBBtn
                        className="loginForm__btn--submit loginNewPassForm__btn--submit"
                        color="primary"
                        disabled={Boolean(guiLoadingError || isResetPasswordErrorMatch || isResetPasswordErrorLong)}
                        type="submit"
                    >
                        {t('login:form.btnSubmit2')}
                        <Icon name="arrow-right" />
                    </MDBBtn>

                </fieldset>
            </form>
        );
    }
}

export default LoginNewPassForm;
