/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { combineReducers } from 'redux';
import { all, call, put, select, takeLatest, take, fork } from 'redux-saga/effects';

import { PASSTHROUGH_URLS } from '~sharedConstants';
import { SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM } from '~commonLib/constants';
import { upgradeConfirm, apiCallGetUpgradeState, upgradeStateFail, upgradeStateRequest, newerVersionRequest } from '~frontendDucks/upgrade';
import { setCategories } from '~frontendDucks/policy';
import { setHardwareInfo, setHostname, setProductVersion } from '~frontendDucks/systemInfo/systemInfo';
import { loadReporterTemplatesRequest } from '~frontendDucks/reporterDbStructure';
import { setAllHwInterfacesAsFilter } from '~frontendDucks/reporterEntities';
import { setModalState } from '~frontendDucks/modals';
import { UPGRADE_NOTICE_CONFIRM, RESET_SELF_PASSWORD_SUCCESS, INTERFACES_COLUMNS_IP6 } from '~frontendConstants';
import { setInitialCards } from '~frontendDucks/activeCards';
import { setColumns } from '~frontendDucks/selectedColumns';
import { fetchUserSettingsRequest } from '~frontendDucks/userSettings/index';

import { getApiError } from '../../lib/apiUtils';
import { promiseSetTimeout } from '../../lib/timeUtils';
import guiLoading, { guiLoadFailure, guiLoadRequest, guiLoadSuccess, sagas as guiLoadingSagas } from './ducks/guiLoading';
import login, { loginFailure, loginSuccess, LOGIN_REQUEST, sagas as loginSagas, LOGIN_SUCCESS, } from './ducks/login';
import changePassword, { sagas as changePasswordSagas } from './ducks/changePassword';
import { schemaLoadSuccess, setInitGlcfgTree, treeLoadSuccess, treeStoreSuccess, schemaTransformRequest, SET_INIT_GLCFG_TREE } from '../hlcfgEditor';
import { getIsProductionMode } from '../constantInformation/constantInformation';
import { workerApplyLanguageToUser, workerSyncLanguageToSessionLang } from '../language';
import { setStatusCode } from '../statusCode';
import { setSessionTerminateReasonRequest } from '../sessionsManagement';
import { loadExpectedComponents, loadSystemComponents } from '../systemComponents';
import { databaseInfoRequest } from '../database';
import { databaseInfoRequest as protectionDatabaseInfoRequest } from '../protectionDatabases';
import { getNetworkInterfacesRequest } from '../networkInterfaces';
import { getHealthIssuesRequest, setNodesRequest } from '../clusterSetup';
import { cfgActivationError, cfgActivationSuccess } from '../cfgActivation';
import { imIpRequest } from '../imInAF';
import { certificationExpirationRequest } from '../certificationExpiration';
import { setInitialClusterSetup } from '../clusterSetup/actions';
import { ipsecStatusRequest } from '../ipsec';

// reducer
const reducer = combineReducers({ login, guiLoading, changePassword });
export default reducer;


// API endpoints
export const authUser = async (username, password) =>
    axios.post('/api/users/authenticateUser', { username, password });

export const getSessionUser = async () =>
    axios.get('/api/users/getUser');

export const signoutSessionUser = async () =>
    axios.get('/api/users/signoutUser'); // TODO post instead

export const callChangeUserPasswd = async (data) =>
    axios.post('/api/users/passwdChange', data);

export const getHlcfgInitialInformation = async () =>
    axios.get('/api/hlcfg/initialInformation');

export const getRouterPath = state => state.router.location.pathname;

// side effects

/** Loads initial data for the GUI. */
const workerLoadGui = function* () {
    const { data } = yield call(getHlcfgInitialInformation);
    const {
        hlcfgSchemaTree, initHlcfgTree,
        hlcfgTree, hlcfgVerificationErrors, hlcfgVerificationWarnings,
        cwCategories, systemInfo, templatesCfg, shouldUpgradeNoticeOpen
    } = data;
    // keep this logic in sync with getParamsForInitialState() in webApp.js.
    yield all([
        put(schemaLoadSuccess(hlcfgSchemaTree)),
        put(setInitGlcfgTree(initHlcfgTree)),
        put(treeLoadSuccess(hlcfgTree)),
        put(treeStoreSuccess(hlcfgVerificationErrors, hlcfgVerificationWarnings)),
        put(schemaTransformRequest(hlcfgSchemaTree)),
        put(setCategories(cwCategories)),
        put(setProductVersion({ pretty: systemInfo.productVersion, raw: systemInfo.productVersionRaw })),
        put(setHardwareInfo(systemInfo.hardwareInfo)),
        put(setHostname(systemInfo.hostname)),
        put(loadReporterTemplatesRequest(templatesCfg)),
        put(setAllHwInterfacesAsFilter(initHlcfgTree.interfaces)),
        put(setModalState({ value: Boolean(shouldUpgradeNoticeOpen), modal: UPGRADE_NOTICE_CONFIRM })),
        put(setSessionTerminateReasonRequest(null)),
        put(setInitialClusterSetup(hlcfgTree)),
        put(setInitialCards(hlcfgTree)),
        put(setColumns(INTERFACES_COLUMNS_IP6, 'interfaces', initHlcfgTree.network.ipv6Enabled,
            !initHlcfgTree.network.ipv6Enabled))
    ]);
};

const getStartTime = () => {
    return new Date();
};

const waitUpTo = async (startTime, limitMs) => {
    const elapsedMs = new Date() - startTime;
    if (elapsedMs > limitMs) {
        return;
    }
    await promiseSetTimeout({ waitTime: limitMs - elapsedMs });
};

/** Worker that checks the user credentials. */
const workerUserAuthentication = function* (action) {
    const isProductionMode = yield select(getIsProductionMode);
    let response;
    yield put(setStatusCode(200));
    yield put(setSessionTerminateReasonRequest());
    try {
        const startTime = getStartTime();
        const { username, password } = action;
        response = yield call(authUser, username, password);
        if (!isProductionMode) {
            yield call(waitUpTo, startTime, 1000);
        }
    } catch (error) {
        yield put(loginFailure(getApiError(error)));
        yield promiseSetTimeout({ waitTime: 3000 });
        yield put(loginFailure(null));
        return {
            isAuthenticated: false,
            loadGui: false,
        };
    }
    yield put(loginSuccess(response.data));
    return {
        isAuthenticated: true,
        loadGui: !response.data.pwdReset,
    };
};

/** Worker that loads initial data for the GUI. */
const workerGuiLoading = function* () {
    const isProductionMode = yield select(getIsProductionMode);
    try {
        const startTime = getStartTime();
        yield put(guiLoadRequest());
        yield* workerLoadGui();
        if (!isProductionMode) {
            yield call(waitUpTo, startTime, 3000);
        }
    } catch (error) {
        yield put(guiLoadFailure(getApiError(error)));
    }
    yield put(guiLoadSuccess({}));
};

/** Worker that performs the login process. */
const workerLogin = function* (action) {
    const { isAuthenticated, loadGui } = yield* workerUserAuthentication(action);
    if (!isAuthenticated) {
        return;
    }

    yield* workerApplyLanguageToUser();
    yield* workerSyncLanguageToSessionLang();
    if (loadGui) {
        yield* workerGuiLoading();
    }
};

const workerPostLogin = function* () {
    const currentUrl = yield select(getRouterPath);
    try {
        const { data } =  yield call(apiCallGetUpgradeState);
        if (PASSTHROUGH_URLS.includes(currentUrl)) {
            window.location.reload();
        }
        if (data.state === SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM) {
            yield put(upgradeConfirm());
        }
    } catch (error) {
        yield put(upgradeStateFail(getApiError(error)));
    }
};

const workerGetApiDataStartup = function* () {
    yield all([
        yield put(upgradeStateRequest()),
        yield put(newerVersionRequest()),
        yield put(loadSystemComponents()),
        yield put(loadExpectedComponents()),
        yield put(databaseInfoRequest()),
        yield put(getNetworkInterfacesRequest()),
        yield put(imIpRequest()),
        yield put(certificationExpirationRequest()),
        yield put(protectionDatabaseInfoRequest()),
        yield put(fetchUserSettingsRequest()),
        yield put(ipsecStatusRequest())

    ]);
    const { shouldUpgradeNoticeOpen, systemInfo } = window._PARAMS_FOR_INITIAL_STATE;
    if (shouldUpgradeNoticeOpen) {
        yield put(setModalState({ value: true, modal: UPGRADE_NOTICE_CONFIRM }));
    }
    if (systemInfo.isCluster) {
        yield put(setNodesRequest());
        yield put(getHealthIssuesRequest());
    }

};

const workerStartSaga = function* () {
    const { currentUser } = window._PARAMS_FOR_INITIAL_STATE;
    if (currentUser) {
        yield call(workerGetApiDataStartup);
    }
    while (true) {
        yield take([ SET_INIT_GLCFG_TREE, cfgActivationSuccess.type, cfgActivationError.type ]);
        yield call(workerGetApiDataStartup);

    }
};

export const sagas = [
    ...loginSagas,
    ...guiLoadingSagas,
    ...changePasswordSagas,
    fork(workerStartSaga),
    takeLatest(LOGIN_REQUEST, workerLogin),
    takeLatest(LOGIN_SUCCESS, workerPostLogin),
    takeLatest(RESET_SELF_PASSWORD_SUCCESS, workerGuiLoading),
];
