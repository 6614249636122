/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy';
import { PROTECTION_WAF_PROFILES_SCENE_PATH } from '~frontendConstants';
import { createScene } from '~frontendLib/scenes';


const PolicyProfilesNew = createLazy(() => import('./WafProfile'));


const PROTECTION_PROXY_POLICY_PROFILES = createScene({
    path: PROTECTION_WAF_PROFILES_SCENE_PATH,
    component: PolicyProfilesNew,
    title: 'scenes:scenes.Protection.scenes.Waf.scenes.Profiles.title',
});


const scenes = () => {
    return [
        PROTECTION_PROXY_POLICY_PROFILES,
    ];
};

export default scenes;
