/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

import WhenAdvanced from '~frontendRoot/components/WhenAdvanced';

import Message from '../../../../components/Message';
import Icon from '../../../../components/Generic/Icon';


const StatusCodeHeader = ({ statusCode, terminateReason }) => {
    switch (statusCode) {
    case 400:
        return <Message message="login:content.400.title" />;
    case 401:
        return <Message message="login:content.401.title" />;
    case 403:
        return <Message message="login:content.403.title" />;
    case 404:
        return <Message message="login:content.404.title" />;
    case 500:
        if (terminateReason) {
            return <Message message="login:content.force.title" />;
        }
        return <Message message="login:content.500.title" />;
    default:
        return <Message message="login:content.unknownError.title" />;
    }
};

StatusCodeHeader.propTypes = {
    statusCode: PropTypes.number,
    terminateReason: PropTypes.string,
};


const StatusCodeMoreInfo = ({ statusCode, terminateReason }) => {
    switch (statusCode) {
    case 400:
        return <p className="loginContent__body"><Message message="login:content.400.desc" /></p>;
    case 403:
        return <p className="loginContent__body"><Message message="login:content.403.desc" /></p>;
    case 404:
        return <p className="loginContent__body"><Message message="login:content.404.desc" /></p>;
    case 500:
        if (terminateReason) {
            return (
                <>
                    <pre>{terminateReason}</pre>
                    <p className="loginContent__body">
                        <Message message="login:content.force.desc" />
                    </p>
                </>
            );
        }
        return <p className="loginContent__body"><Message message="login:content.500.desc" /></p>;
    default:
        return null;
    }
};

StatusCodeMoreInfo.propTypes = {
    statusCode: PropTypes.number,
    terminateReason: PropTypes.string,
};


const StatusCodeButtons = ({ doLoginFormShow, isLoginFormVisible, statusCode }) => (
    <div className="loginContent__btnGroup btn-group">
        {statusCode === 401 ?
            <MDBBtn
                className="loginContent__btn--test"
                color="white"
                onClick={doLoginFormShow}
                outline
            >
                <Icon name="test-tube" />
                <Message message="login:content.btn.test" />
            </MDBBtn> :
            null
        }
        <MDBBtn
            className="loginContent__btn--help"
            color="white"
            onClick={
                () => console.log('click on help btn! TODO: redirect to User Guide') // eslint-disable-line no-console
            }
            outline
        >
            <Icon name="help" />
            <Message message="login:content.btn.help" />
        </MDBBtn>
        {isLoginFormVisible ?
            null :
            <MDBBtn
                className="loginContent__btn--login"
                color="white"
                onClick={doLoginFormShow}
                outline
            >
                <Icon name="account" />
                <Message message="login:content.btn.login" />
            </MDBBtn>
        }
    </div>
);

StatusCodeButtons.propTypes = {
    doLoginFormShow: PropTypes.func.isRequired,
    isLoginFormVisible: PropTypes.bool,
    statusCode: PropTypes.number,
};


const LoginContent = ({
    isGuiLoading, isLoggedIn, isErrorStatusCode, statusCode, doLoginFormShow, isLoginFormVisible, terminateReason
}) => {
    if (!isErrorStatusCode && !terminateReason) {
        return null;
    }
    return (
        <div
            className={classNames({
                'loginContent__wrap': true,
                'loginContent__wrap--fullWidth': (isGuiLoading || isErrorStatusCode || isLoggedIn || terminateReason) &&
                    !isLoginFormVisible,
            })}
        >
            <div className="loginContent">
                <div className="loginContent__inner">
                    <h1 className="loginContent__code">
                        {statusCode}
                    </h1>
                    <h2 className="loginContent__title">
                        <StatusCodeHeader
                            statusCode={statusCode}
                            terminateReason={terminateReason}
                        />
                    </h2>
                    <StatusCodeMoreInfo
                        statusCode={statusCode}
                        terminateReason={terminateReason}
                    />
                    <WhenAdvanced>
                        <StatusCodeButtons
                            doLoginFormShow={doLoginFormShow}
                            isLoginFormVisible={isLoginFormVisible}
                            statusCode={statusCode}
                        />
                    </WhenAdvanced>
                </div>
            </div>
        </div>
    );
};

LoginContent.propTypes = {
    doLoginFormShow: PropTypes.func.isRequired,
    isLoginFormVisible: PropTypes.bool,
    isGuiLoading: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool,
    isErrorStatusCode: PropTypes.bool,
    statusCode: PropTypes.number,
    terminateReason: PropTypes.string,
};

export default LoginContent;
