/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { getStringMatch } from './stringUtils';


export const areArraysEqual = (leftArray, rightArray) => {
    if (!Array.isArray(leftArray) || !Array.isArray(rightArray) || leftArray.length !== rightArray.length) {
        return false;
    }
    for (const index in leftArray) {
        if (leftArray[index] !== rightArray[index]) {
            return false;
        }
    }
    return true;
};


const isArrayOrObj  = variable => variable !== null && (typeof variable === 'object' || Array.isArray(variable));

export const compareDeep = (first, second) => {
    if (isArrayOrObj(first) && isArrayOrObj(second)) {
        if (Array.isArray(first) !== Array.isArray(second)) {
            return false;
        }
        const keysFirst = Object.keys(first);
        if (keysFirst.length !== Object.keys(second).length) {
            return false;
        }
        for (const key of keysFirst) {
            if (!compareDeep(first[key], second[key])) {
                return false;
            }
        }
        return true;
    } else {
        return first === second;
    }
};


export const areArraysEqualDeep = ({ leftArray, rightArray }) => {
    if (!Array.isArray(leftArray) || !Array.isArray(rightArray) || leftArray.length !== rightArray.length) {
        return false;
    }
    for (const index in leftArray) {
        switch (typeof leftArray[index]) {
        case 'string':
            if (!getStringMatch({ toMatch: leftArray[index], searchValue: rightArray[index] })) {
                return false;
            }
            break;
        case 'number':
            if (!getStringMatch({ toMatch: String(leftArray[index]), searchValue: rightArray[index] })) {
                return false;
            }
            break;
        //array in array not supported
        case 'object':
            if (!areObjectsEqual({ leftObject: leftArray[index],
                rightObject: rightArray[index] })) {
                return false;
            }
            break;
        default:
            break;
        }}
    return true;
};


const areObjectsEqual = ({ leftObject, rightObject }) => {
    Object.keys(leftObject).forEach(item => {
        switch (typeof leftObject[item]) {
        case 'string':
            if (!getStringMatch({ toMatch: leftObject[item], searchValue: rightObject[item] })) {
                return false;
            }
            break;
        case 'number':
            if (!getStringMatch({ toMatch: String(leftObject[item]), searchValue: rightObject[item] })) {
                return false;
            }
            break;
        case 'object':
            if (Array.isArray(leftObject[item])) {
                if (!areArraysEqualDeep({ leftArray: leftObject[item], rightArray: rightObject[item] })) {
                    return false;
                }
            } else {

                if (!areObjectsEqual({ leftObject: leftObject[item], rightObject: rightObject[item] })) {
                    return false;
                }
            }
            break;
        default:
            if (leftObject[item] !== rightObject[item]) {
                return false;
            }
        }});
    return true;
};
