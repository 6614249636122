/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from 'moment-timezone';

import { DEFAULT_LANGUAGE } from './constants';


export const TIME_FORMAT = <const>{
    time: 'HH:mm:ss',
    userDateTimeFull: 'LLL',
    userDateTimeShort: 'lll',
    unixTimestampSeconds: 'X',
    unixTimestampMilliseconds: 'x',
    filenameParseableDateTime: 'YYYY-MM-DD_HH-mm-ss',
    underscoreDateTime: 'YYYY_MM_DD_HH_mm_ss',
    systemdTime: 'YYYY-MM-DD HH:mm:ss',
    sql: 'X',
    journal: 'MMM DD HH:mm:ss',
};

moment.locale(DEFAULT_LANGUAGE);

/**
 * Returns a human-readable timestamp
 *
 * @param {string} refGuiTimestamp - a reference timestamp obtained from the GUI on the same time refKernunTimestamp was
 * obtained
 * @param {string} refKernunTimestamp - a reference timestamp obtained from the device on the same time refGuiTimestamp
 * was obtained
 * @param {string} pastKernunTimestamp - timestamp obtained from the device to refer to
 * @param {string} currentGuiTimestamp - current timestamp from GUI to refer to
 * @param {boolean} [noTimeFromFewMomentsInFuture=true] - when true, displays 'before a moment' instead of 'in an few
 * moments'
 * @returns {string}
 */
export const getTimeFrom = (
    refGuiTimestamp, refKernunTimestamp, pastKernunTimestamp, currentGuiTimestamp, noTimeFromFewMomentsInFuture = true
) => {
    const subtractedTime = moment(pastKernunTimestamp).add(refGuiTimestamp.diff(refKernunTimestamp));
    if (noTimeFromFewMomentsInFuture) {
        const timeDiff = subtractedTime.diff(currentGuiTimestamp);
        if (timeDiff > 0 && timeDiff < 60000) { // time is from the future, but not too much
            return moment(subtractedTime).from(subtractedTime);
        }
    }
    return moment(subtractedTime).from(currentGuiTimestamp);
};

/**
 * Returns time difference in miliseconds
 *
 * @param {string} refGuiTimestamp - a reference timestamp obtained from the GUI on the same time refKernunTimestamp was
 * obtained
 * @param {string} refKernunTimestamp - a reference timestamp obtained from the device on the same time refGuiTimestamp
 * was obtained
 * @param {string} pastKernunTimestamp - timestamp obtained from the device to refer to
 * @param {string} currentGuiTimestamp - current timestamp from GUI to refer to
 * @returns {number}
 */
export const getTimeDiff = (refGuiTimestamp, refKernunTimestamp, pastKernunTimestamp, currentGuiTimestamp) =>
    // Minus because it is supposed that the timestamp is from the past and we want the time to usually have a positive
    // difference.
    -moment(pastKernunTimestamp).add(refGuiTimestamp.diff(refKernunTimestamp)).diff(currentGuiTimestamp, 'ms');
/**
 * Sets the localization.
 *
 * @param {string} language - language to set
 */
export const localizeMoment = (language) => {
    // moment.locale(language) returns the language it set, so if it stays the same, it wasn't changed
    if (moment.locale(language) !== language) {
        throw new Error(`Language "${language}" was not set, does it exist?`);
    }
};

export default moment;
