/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';

import { backendPost } from '~frontendLib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';
import { getApiError } from '~frontendLib/apiUtils';
import { createNotification } from '~frontendLib/reactUtils';
import { createOtpAuthUrl } from '~frontendRoot/lib/urlUtils';
import { HlcfgTypeOpenVpnUser, HlcfgTypeOpenVpn } from '~frontendRoot/types/externalTypes';
import { FirstParam } from '~commonLib/types';

import { getGlcfgValue, getTableItem } from '../hlcfgEditor';
import { getActiveCard } from '../activeCards';
import { createRequest } from '../ducksUtils';


type ApiStates = {
    loading?: boolean;
    error?: any,
}

type InitialStateType = {
    issuer?: string,
    label?: string,
    secret?: string
}

interface State extends InitialStateType, ApiStates {}


const initialState: State = {};

const VpnUserQrcode = createSlice({
    name: 'ak/vpnUserQrcode',
    initialState,
    reducers: {
        vpnUserQrcodeRequest: createRequest<typeof initialState, PostVpnUserSecretTypes>(
            (state) => {
                state = {};
                state.loading = true;
            }
        ),
        vpnUserQrcodeError: (state, { payload }: PayloadAction<ApiStates>) => {
            state.error = payload;
            state.loading = false;
        },
        vpnUserQrcodeSuccess: (state, { payload }: PayloadAction<InitialStateType>) => payload,


    },
});

export default VpnUserQrcode.reducer;


// Action creators
export const {
    vpnUserQrcodeRequest,
    vpnUserQrcodeError,
    vpnUserQrcodeSuccess

} = VpnUserQrcode.actions;


// Getters
const getState = (state): State => state.vpnUserQrcode;

export const getVpnUserQrcode = (state) => {
    const values = getState(state);
    return createOtpAuthUrl({ secret: values.secret, issuer: values.issuer, label: values.label });
};

export const getIsLoading = (state) => Boolean(getState(state).loading);
export const getIsError = (state) => getState(state).error;


export const getVpnUserQrcodeFilename = (state) => {
    const values = getState(state);
    return `${values.issuer} (${values.label})`;
};

const postVpnUserSecret = backendPost('/openvpn/userGoogleAuthSecret');
type PostVpnUserSecretTypes = FirstParam<typeof postVpnUserSecret>;


const workerFetchVpnUserSecret = function* (action: PayloadAction<PostVpnUserSecretTypes>) {
    try {
        const { data } = yield* callSaga(postVpnUserSecret, action.payload);
        const vpn: HlcfgTypeOpenVpn = yield select((state) => getTableItem(state, getActiveCard(state, 'vpn')));
        const userVpn: HlcfgTypeOpenVpnUser =
         yield select((state) => getTableItem(state, action.payload.openvpnUserId));
        const hostname: string = yield select((state) => getGlcfgValue(state, 'hostname'));
        yield put(vpnUserQrcodeSuccess({ label: `${userVpn.commonName}@${hostname}`, issuer: vpn.name, secret: data }));
    } catch (error) {
        yield put(vpnUserQrcodeError(getApiError(error)));
        createNotification({
            title: 'widgets:Vpn.qr.error',
            type: 'danger',
            desc: getApiError(error).message,
        });
    }
};


export const sagas = [
    takeLatest(vpnUserQrcodeRequest, workerFetchVpnUserSecret),
];
