/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { USERS_DASHBOARD_PATH } from '~frontendConstants';
import { createLazy } from '~frontendLib/createLazy';

import { createScene } from '../../../lib/scenes';


const UsersListScene = createLazy(() => import('./UsersListScene'));
const UserDashboard = createLazy(() => import('./UserDashboard'));


const USERS_LIST_SCENE = createScene({
    path: '/users/list',
    component: UsersListScene,
    title: 'scenes:scenes.Users.scenes.List.title',
});
const USERS_DASHBOARD_SCENE = createScene({
    path: USERS_DASHBOARD_PATH,
    component: UserDashboard,
    title: 'scenes:scenes.Users.scenes.Dashboard.title',
});


const scenes = ({ showTodos } = {}) => {
    if (typeof showTodos === 'undefined' || showTodos) {
        return [
            USERS_LIST_SCENE,
            USERS_DASHBOARD_SCENE,
        ];
    }
    return [
        USERS_LIST_SCENE
    ];
};

export default scenes;
