/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { COLOR_PRIMARY_MDB_ALPHA_01 } from '~frontendConstants';


export const onMouseEnter = (id) => () => {
    if (document.getElementById(id)) {
        document.getElementById(id).style.backgroundColor = COLOR_PRIMARY_MDB_ALPHA_01;
        document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

export const onMouseLeave = (id) => () => {
    if (document.getElementById(id)) {
        document.getElementById(id).style.backgroundColor = 'white';
    }
};
