/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { NetaddrDhcp } from '~commonLib/Netaddr/NetaddrDhcp';
import type { NetaddrDomain } from '~commonLib/Netaddr/NetaddrDomain';
import type { NetaddrIpBase } from '~commonLib/Netaddr/NetaddrIpBase';
import type { NetaddrIp6 } from '~commonLib/Netaddr/NetaddrIp6';
import type { NetaddrIp4 } from '~commonLib/Netaddr/NetaddrIp4';

import { NetBase } from '../NetBase';


export abstract class NetaddrBase extends NetBase {

    isIp4(): this is NetaddrIp4 {
        return false;
    }
    /**
     * Used to cast type of netaddr to Ip4. Will throw if it is not ip4.
     */
    asIp4(): NetaddrIp4 {
        throw new Error('This netaddr is not ip4');
    }
    asDomain(): NetaddrDomain {
        throw new Error('This netaddr is not domain');
    }
    isIp6(): this is NetaddrIp6 {
        return false;
    }
    isIp(): this is NetaddrIpBase<any, any, any> {
        return false;
    }
    isDomain(): this is NetaddrDomain {
        return false;
    }
    isFromDhcp(): this is NetaddrDhcp {
        return false;
    }
}
