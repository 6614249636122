/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { HlcfgPath } from '~sharedLib/types';


export const resolvedPathToRealPath = (path: HlcfgPath) => {
    let realPath: string[] = [];
    let previousSegmentType = '';
    for (const segment of path) {
        if (segment === '$byId' || segment === '$ids' || segment === '$id') {
            previousSegmentType = segment;
            continue;
        }
        if (previousSegmentType === '$byId' || previousSegmentType === '$id') {
            const [ table ] = segment.split(':');
            realPath = [ 'tables', table, segment ];
            previousSegmentType = '';
            continue;
        }
        if (previousSegmentType === '$ids') {
            return realPath;
        }

        realPath.push(segment);
        previousSegmentType = '';
    }
    return realPath;
};
// alias, because this is what i usually search for...
export const descriptiveHlcfgPathToRealPath = resolvedPathToRealPath;
