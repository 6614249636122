/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
    ACTIVATE_WITH_STEP,
    CREATE_CLUSTER,
    createClusterError,
    DOWNLOAD_CLUSTER_REQUEST,
    downloadError,
    downloadSuccess,
    FINISH_CLUSTER_SETUP,
    GET_HEALTH_ISSUES_REQUEST,
    getHealthIssuesError,
    getHealthIssuesSuccess,
    getNodeStateError,
    getNodeStateSuccess,
    INIT_DB_REPLICATION_FROM_OTHER_NODE,
    initDbReplicationFromOtherNode,
    initDbReplicationError,
    SET_NODES_REQUEST,
    setNodesError,
    setNodesSuccess,
    setUploadHlcfgError,
    SYNC_START,
    SYNC_TO_NODE_REQUEST,
    syncToNodeError,
    UPLOAD_HLCFG,
    uploadSuccess
} from '~frontendDucks/clusterSetup/actions';
import { getApiError } from '~frontendLib/apiUtils';
import { HTTP_HEADER_CLUSTER_NODE, NODE_A_ID, NODE_B_ID } from '~commonLib/constants';
import {
    CLUSTER_NODE,
    CLUSTER_STEP_A,
    CLUSTER_STEP_B,
    ERROR_CODE_UPLOAD_HLCFG_ERRORS,
} from '~frontendRoot/constants';
import {
    getGlcfgValue,
    getTableById,
    setGlcfgValueScalar,
    setNormalize,
    treeLoadSuccess
} from '~frontendDucks/hlcfgEditor';
import { cfgActivationRequest } from '~frontendDucks/cfgActivation';
import { getMyNode, getOtherNode } from '~frontendDucks/clusterSetup/clusterSetup';
import { getUninitializedNodeUuid } from '~sharedConstants';
import { backendPost } from '~frontendRoot/lib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';
import { HlcfgSchemaTS } from '~frontendTypes/externalTypes';
import { createNotification } from '~frontendRoot/lib/reactUtils';

import { loadSystemComponents } from '../systemComponents';


const apiCallGetNodeId = async () => {
    return Axios.get('/api/cluster/getNodeId');
};
const apiCallGetNodeState = async (node) => {
    const headers = {
        [HTTP_HEADER_CLUSTER_NODE]: node,
    };
    const axios = Axios.create({ headers });
    return axios.get('/api/cluster/getNodeState');
};

const apiCallPostAddCluster = backendPost('/cluster/addToCluster');

export const apiCallGetHealthIssues = async () => {
    return Axios.get('/api/cluster/getHealthIssues');
};
export const apiCallInitDbReplicationFromOtherNode = async (otherNode) => {
    const headers = {
        [HTTP_HEADER_CLUSTER_NODE]: otherNode,
    };
    const axios = Axios.create({ headers });
    return axios.post('/api/cluster/initializeDbReplication');
};


export const apiCallPostSyncToNode = backendPost('/cluster/syncToNode');

const apiCallGetclusterDownloadApi = backendPost('/cluster/downloadHlcfg', { responseType: 'blob' });

const workerCreateCluster = function* () {
    try {
        const hostname = yield select(state => getGlcfgValue(state, 'hostname'));
        yield put(setGlcfgValueScalar('firewallClusterAHostname', hostname + '-a'));
        yield put(setGlcfgValueScalar('firewallClusterBHostname', hostname + '-b'));

        yield put(setNormalize({ uuid: NODE_B_ID, key: 'uuid', value: getUninitializedNodeUuid(NODE_B_ID) }));

        const nodeId = yield call(apiCallGetNodeId);
        yield put(setNormalize({ uuid: NODE_A_ID, key: 'uuid', value: nodeId.data.id }));
        yield put(setGlcfgValueScalar('isCluster', true));

    } catch (error) {
        yield put(createClusterError(getApiError(error)));
    }
};
const workerSync = function* () {
    const otherNode = yield select(getOtherNode);
    const myNode = yield select(getMyNode);

    yield put(cfgActivationRequest({ nodes: [ otherNode, myNode ] }));
};
const workerSyncToNode = function* (action) {
    try {
        if (action.payload) {
            yield* callSaga(apiCallPostSyncToNode, action.payload);
        } else {
            const idToSync = yield select(getOtherNode);
            yield* callSaga(apiCallPostSyncToNode, { nodeName: idToSync });
        }
        yield call(workerGetHealthIssues);

    } catch (error) {
        yield put(syncToNodeError(getApiError(error)));
    }
};

const workerSetPasswordAndDownload = function* (action) {
    try {
        const res = yield* callSaga(apiCallGetclusterDownloadApi, action.payload);
        const name = res.headers['content-disposition']?.split('filename=')?.[1];
        const file = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = file;
        link.download = name;
        link.click();
        link.remove();
        yield put(downloadSuccess());

    } catch (error) {
        const responseObj = JSON.parse(yield error.response.data.text()); //Blob response type
        responseObj.errors?.forEach(item =>
            createNotification({
                title: item.title,
                desc: item.message,
                type: 'danger'
            }));
        yield put(downloadError({ error: responseObj }));

    }
};


const workerSetNodes = function* () {
    try {
        const { data } = yield call(apiCallGetNodeId);
        const clusterNodesById: HlcfgSchemaTS['tables']['clusterNode'] =
            yield select(state => getTableById(state, CLUSTER_NODE));
        const myNode = Object.values(clusterNodesById).find(item => item.uuid === data.id)?.id;
        const otherNode = Object.values(clusterNodesById).find(item => item.uuid !== data.id)?.id;
        yield put(setNodesSuccess({ myNode: myNode, otherNode: otherNode }));
    } catch (error) {
        yield put(setNodesError(getApiError(error)));
    }
};

interface WithErrors {
    errors: {
        title: string,
        desc: string
    }[],
    warnings: {
        title: string,
        desc: string
    }[]
}

const isWithErrors = (data): data is WithErrors => 'errors' in data;

const workerUploadHlcfg = function* (action) {
    try {
        const { data } = yield* callSaga(apiCallPostAddCluster, action.payload);
        if (isWithErrors(data)) {
            if (data.errors) {
                yield put(setUploadHlcfgError({
                    code: ERROR_CODE_UPLOAD_HLCFG_ERRORS,
                    message: data.errors.map(item => item.desc + ' '),
                }));
            }
            return;
        }
        yield put(treeLoadSuccess(data));
        yield put(uploadSuccess());


    } catch (error) {
        yield put(setUploadHlcfgError(getApiError(error)));
    }
};
export const workerGetHealthIssues = function* () {
    try {
        const { data } = yield call(apiCallGetHealthIssues);
        yield put(getHealthIssuesSuccess(data));

        const myNode = yield select(getMyNode);
        const otherNode = yield select(getOtherNode);
        for (const node of [ myNode, otherNode ]) {
            try {
                const nodeState = yield call(apiCallGetNodeState, node);
                yield put(getNodeStateSuccess({
                    node: node,
                    state: nodeState.data.state,
                }));
            } catch (error) {
                yield put(getNodeStateError({ error: getApiError(error), node }));
            }
        }
    } catch (error) {
        yield put(getHealthIssuesError(getApiError(error)));
    }
};
export const workerInitDbReplicationFromOtherNode = function* () {
    try {
        const otherNode = yield select(getOtherNode);
        yield call(apiCallInitDbReplicationFromOtherNode, otherNode);
    } catch (error) {
        yield put(initDbReplicationError(getApiError(error)));
    }
};

export const workerFinishClusterSetup = function* () {

    yield put(loadSystemComponents());
    yield put(initDbReplicationFromOtherNode());
    yield put(setGlcfgValueScalar(CLUSTER_STEP_A, 0));
    yield put(setGlcfgValueScalar(CLUSTER_STEP_B, 0));
    const myNode = yield select(getMyNode);
    const otherNode = yield select(getOtherNode);
    yield put(cfgActivationRequest({ isOpen: false, nodes: [ otherNode, myNode ] }));

};

export const workerActivateWithStep = function* (action) {
    yield put(setGlcfgValueScalar(action.payload.addingToCluster ? CLUSTER_STEP_B : CLUSTER_STEP_A, 3));
    yield put(cfgActivationRequest({ isOpen: false }));

};

// sagas
export const clusterSetupSagas = [
    takeLatest(SET_NODES_REQUEST, workerSetNodes),
    takeLatest(SYNC_START, workerSync),
    takeLatest(CREATE_CLUSTER, workerCreateCluster),
    takeLatest(UPLOAD_HLCFG, workerUploadHlcfg),
    takeLatest(SYNC_TO_NODE_REQUEST, workerSyncToNode),
    takeLatest(GET_HEALTH_ISSUES_REQUEST, workerGetHealthIssues),
    takeLatest(INIT_DB_REPLICATION_FROM_OTHER_NODE, workerInitDbReplicationFromOtherNode),
    takeLatest(DOWNLOAD_CLUSTER_REQUEST, workerSetPasswordAndDownload),
    takeLatest(FINISH_CLUSTER_SETUP, workerFinishClusterSetup),
    takeLatest(ACTIVATE_WITH_STEP, workerActivateWithStep)
];
