/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';

import { Icon } from '~frontendComponents/Generic';
import { useCombinedRefs } from '~frontendLib/reactUtils';
import { reorder } from '~commonLib/arrayUtils';
import { getActiveCard, setActiveCard } from '~frontendDucks/activeCards';
import WhenAdvanced from '~frontendComponents/WhenAdvanced';
import Message from '~frontendRoot/components/Message';
import { COLOR_PRIMARY } from '~frontendRoot/constants';

import MenuItem from './MenuItem';


const MenuItemSimple = ({ selected,  index, selectClick,
    item, itemClassName, isDragDisabled, menuItemProps,
}) => {
    return (
        <Draggable
        //it needs string
            draggableId={String(item)}
            index={index}
            isDragDisabled={isDragDisabled}
            key={item}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={'scrollMenu'}

                >

                    <div
                        className={classNames('scrollMenu__item',
                            { 'scrollMenu__item--selected': selected  },
                            itemClassName)}
                        onClick={() => selectClick(item)}
                    >

                        {menuItemProps?.icon &&
                            <Icon
                                color={menuItemProps?.iconColor || COLOR_PRIMARY}
                                name={menuItemProps.icon}
                                size="sm"
                            />
                        }
                        <div
                            className="scrollMenu__text"
                        >
                            <Message message={`widgets:${item}.title`} />
                            {menuItemProps?.isLoading  &&
                            <Icon
                                className={'ml-1'}
                                height={18}
                                name="loading"
                                size="own"
                                width={18}
                            />}
                            {Boolean(menuItemProps?.number) &&
                                    <span
                                        className={classNames('scrollMenu__number ml-2')}
                                        data-cy="usage"
                                    >
                                        {menuItemProps.number}
                                    </span>
                            }
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

MenuItemSimple.propTypes = {
    selected: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
    changeValue: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.string,
    selectClick: PropTypes.func,
    menuItemProps: PropTypes.object,
    debouncedOnChangeColor: PropTypes.func,
    deleteService: PropTypes.func,
    copyService: PropTypes.func,
    isDragDisabled: PropTypes.bool,
    itemClassName: PropTypes.string,

};

@hot
@connect(
    (state, { type }) => ({
        active: getActiveCard(state, type),
    }),
    {
        setCard: setActiveCard,

    }
)
class Cards extends Component {
    static get propTypes() {
        return {
            ids: PropTypes.array,
            setValue: PropTypes.func,
            setOrder: PropTypes.func,
            menuItemProps: PropTypes.object,
            wrapClassName: PropTypes.string,
            type: PropTypes.string,
            active: PropTypes.string,
            setCard: PropTypes.func,
            deleteService: PropTypes.func,
            copyService: PropTypes.func,
            simple: PropTypes.bool,
            withoutColorChange: PropTypes.bool,
            itemClassName: PropTypes.string,
            isDragDisabled: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.myRef = createRef();

    }

    componentDidMount() {
        this.myRef.current?.addEventListener('wheel', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { ids, active, setCard, type } = this.props;
        if (ids.length && ids.length !== prevProps.ids.length && ids.length < prevProps.ids.length) {
            const index = prevProps.ids.findIndex(item => item === active) - 1;
            setCard({ type, value: ids[index > 0 ? index : 0] });

        } else if (ids.length && ids.length !== prevProps.ids.length) {
            setCard({ type, value: ids.filter(item => prevProps.ids.indexOf(item) === -1)[0] });

        }
    }

    componentWillUnmount() {
        this.myRef.current?.removeEventListener('wheel', this.handleScroll);
    }

    shouldHideArrows = () => {
        /*  const { leftArrow, rightArrow } = this.state; THIS NEEDS OWN COMPONENT PERFORMANCE ISSUES
        if (!leftArrow && this.myRef.current.scrollLeft) {
            this.setState({ leftArrow: true });
        }
        if (leftArrow && !this.myRef.current.scrollLeft) {
            this.setState({ leftArrow: false });
        }
        if (!rightArrow && this.myRef.current.clientWidth > this.myRef.current.scrollLeft) {
            this.setState({ rightArrow: true });
        }
        if (rightArrow && this.myRef.current.clientWidth < this.myRef.current.scrollLeft) {
            this.setState({ rightArrow: false });
        }
        */

    };

    handleScroll = (event) => {
        event.preventDefault();
        this.myRef.current.scrollLeft += event.deltaY * 3;
        this.shouldHideArrows();
    };

    handleScrollLeft = (event) => {
        event.preventDefault();
        this.myRef.current.scrollLeft += -150;
        this.shouldHideArrows();
    };

    handleScrollRight = (event) => {
        event.preventDefault();
        this.myRef.current.scrollLeft += 150;
        this.shouldHideArrows();
    };

    scrollMaxLeft = () => {
        setTimeout(() => {
            this.myRef.current.scrollLeft = this.myRef.current.scrollWidth - this.myRef.current.clientWidth;
        }, 200);
    };

    changeValue = ({ value, name, uuid }) => {
        const { setValue, active } = this.props;
        setValue({ value, uuid: uuid || active, key: name });
    };


    changeSelected = (uuid) => {
        const { setCard, type } = this.props;
        setCard({ type, value: uuid });
    };

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const { ids, setOrder } = this.props;
        const newData = reorder(
            ids,
            result.source.index,
            result.destination.index
        );
        setOrder({ order: newData });
    };

    render() {
        const { ids, menuItemProps, wrapClassName, active, deleteService, copyService, simple,
            withoutColorChange, itemClassName, isDragDisabled } = this.props;
        return (
            <div  className={classNames('dragable', wrapClassName)}>
                <DragDropContext
                    onDragEnd={this.onDragEnd}
                >
                    <Droppable
                        direction="horizontal"
                        droppableId="scrollMenu"

                    >
                        {(provided) => (
                            <div
                                {...provided.droppableProps}

                                className="scrollMenu__wrapperItem"
                                ref={useCombinedRefs(provided.innerRef, this.myRef)}
                            >
                                <WhenAdvanced>
                                    <Icon
                                        className="scrollMenu__arrow--left"
                                        name="arrow-left"
                                        onClick={this.handleScrollLeft}
                                        size="sm"
                                    />
                                </WhenAdvanced>

                                {ids.map((item, index) => {
                                    if (simple) {
                                        return (
                                            <MenuItemSimple
                                                index={index}
                                                isDragDisabled={isDragDisabled}
                                                item={item}
                                                itemClassName={itemClassName}
                                                key={item}
                                                menuItemProps={menuItemProps?.[item]}
                                                selectClick={this.changeSelected}
                                                selected={item === active}
                                            />
                                        );
                                    }
                                    return (
                                        <MenuItem
                                            changeValue={this.changeValue}
                                            copyService={copyService}
                                            debouncedOnChangeColor={this.debouncedOnChangeColor}
                                            deleteService={deleteService}
                                            index={index}
                                            item={item}
                                            key={item}
                                            menuItemProps={menuItemProps[item]}
                                            selectClick={this.changeSelected}
                                            selected={item === active}
                                            withoutColorChange={withoutColorChange}
                                        />
                                    );
                                })
                                }
                                {!ids.length &&
                                    <MenuItem
                                        changeValue={this.changeValue}
                                        copyService={copyService}
                                        debouncedOnChangeColor={this.debouncedOnChangeColor}
                                        deleteService={deleteService}
                                        index={1}
                                        item={'item'}
                                        key={'item'}
                                        menuItemProps={menuItemProps['item']}
                                        selectClick={this.changeSelected}
                                        selected={true}
                                    />
                                }

                                <WhenAdvanced>

                                    <Icon
                                        className="scrollMenu__arrow--right"
                                        name="arrow-right"
                                        onClick={this.handleScrollRight}
                                        size="sm"

                                    />
                                </WhenAdvanced>

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

export default Cards;
