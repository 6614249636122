/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import * as ric from '../../constants/reportsIdConstants';
import * as dic from '../../constants/dashboardsIdConstants';


const createReportUsage = (id) => ({
    'id': id,
    'charts': [
        {
            'config': {
                'type': null
            }
        }
    ],
});

const defaultDashboards = {
    'dashboards': [
        {
            'id': dic.SYSTEM_HEALTH_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.SYSMON_GENERAL),
                createReportUsage(ric.GENERIC_UPTIME_ID)
            ],
            'filter': { filterId: ric.SYSMON_GENERAL,  time: true, refresh: true },
            'preloadPriority': 'normal',
            'className': 'systemChart',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Stav systému',
                'en': 'System state',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }
        },
        {
            'id': dic.CPU_HEALTH_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.CPU_BUSY_CHART_ID),
                createReportUsage(ric.CPU_THREADS_CHART_ID),
                createReportUsage(ric.CPU_LOAD_CHART_ID)

            ],
            'filter': { filterId: ric.CPU_BUSY_CHART_ID,  time: true, refresh: true },
            'preloadPriority': 'normal',
            'className': 'systemChart',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Stav systému',
                'en': 'System state',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }
        },
        {
            'id': dic.MEMORY_HEALTH_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.MEMORY_USAGE_CHART_ID),
                createReportUsage(ric.SWAP_USAGE_CHART_ID)
            ],
            'filter': { filterId: ric.MEMORY_USAGE_CHART_ID,  time: true, refresh: true },
            'preloadPriority': 'normal',
            'className': 'systemChart',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Stav systému',
                'en': 'System state',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }
        },
        {
            'id': dic.DISK_HEALTH_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.DISK_SPACE_TOTAL_CHART_ID),
                createReportUsage(ric.DISK_OPENED_FILES_ID),
            ],
            'filter': {},
            'preloadPriority': 'normal',
            'className': 'systemChart',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Stav systému',
                'en': 'System state',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }
        },
        {
            'id': dic.DISK_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.DISK_USAGE_CHART_ID),
                createReportUsage(ric.DISK_TIME_CHART_ID),
                createReportUsage(ric.DISK_DONEIOS_ID)
            ],
            'filter': {},
            'preloadPriority': 'normal',
            'className': 'systemChart',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Stav systému',
                'en': 'System state',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }
        },
        {
            'id': dic.NFT_REPORT,
            'reports': [
                createReportUsage(ric.NFTABLE_EVENT_REPORT),
                createReportUsage(ric.NFTABLE_TOP_NAME_ALLOWED),
                createReportUsage(ric.NFTABLE_TOP_NAME_BLOCKED),
                createReportUsage(ric.NFTABLE_TOP_ADDRESS),
                createReportUsage(ric.NFTABLE_TOP_ADDRESS_BLOCKED),
                createReportUsage(ric.NFTABLE_EVENT_REPORT_PER_HOUR),
            ],
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Události stavového firewallu ',
                'en': 'Stateful firewall events',
                'user': ''
            },

        },
        {
            'id': dic.ADAPTIVE_FIREWALL_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.ADAPTIVE_FIREWALL_EVENTS_REPORT_OUT),
                createReportUsage(ric.ADAPTIVE_FIREWALL_EVENTS_REPORT),
                createReportUsage(ric.ADAPTIVE_FIREWALL_TOP_ADDRESS),
                createReportUsage(ric.ADAPTIVE_FIREWALL_TOP_PORT),
                createReportUsage(ric.ADAPTIVE_FIREWALL_TOP_LOCAL_ADDRESSES),
                createReportUsage(ric.ADAPTIVE_FIREWALL_TOP_LOCAL_PORT),
                createReportUsage(ric.ADAPTIVE_FIREWALL_REPORT_PER_HOUR),

            ],
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Události Adaptivního firewallu',
                'en': 'Adaptive firewall events',
                'user': ''
            },

        },
        {
            'id': dic.IPS_DASHBOARD_ID,
            'reports': [
                createReportUsage(ric.IPS_EVENTS_REPORT),
                createReportUsage(ric.IPS_TOP_ATTACK),
                createReportUsage(ric.IPS_TOP_PROTOCOL),
                createReportUsage(ric.IPS_REPORT_PER_HOUR),
            ],
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Události IPS',
                'en': 'IPS events',
                'user': ''
            },

        },

        {
            'id': dic.NETWORK_REPORT,
            'reports': [
                createReportUsage(ric.FAST_NETWORK_CONNECTIONS),
            ],
            'filter': {},
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'fastFilter': true,
            'name': {
                'cs': 'IDS/IPS události',
                'en': 'IDS/IPS events',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }

        },
        {
            'id': dic.NETWORK_CARD_REPORT,
            'reports': [
                createReportUsage(ric.GENERIC_BYTES_ID),
                createReportUsage(ric.GENERIC_PACKETS_ID),
            ],
            'filter': {},
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Provoz na síťových rozhraních',
                'en': 'Traffic on network interfaces',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }

        },
        {
            'id': dic.NETWORK_CARD_DROP_PACKETS_REPORT,
            'reports': [
                createReportUsage(ric.GENERIC_DROP_PACKETS_ID),
                createReportUsage(ric.GENERIC_DROP_BYTES_ID)
            ],
            'filter': {},
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'withoutFilter': true,
            'name': {
                'cs': 'Zahozený provoz na síťových rozhraních',
                'en': 'Dropped traffic on network interfaces',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }

        },
        {
            'id': dic.PROXY_REPORT_FAST,
            'reports': [
                createReportUsage(ric.PROXY_REPORT_ALLOWED),
                createReportUsage(ric.PROXY_REPORT_IN_AND_OUT),
            ],
            'filter': { filterId: ric.PROXY_REPORT_ALLOWED, time: true, refresh: true },
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'fastFilter': true,
            'name': {
                'cs': 'Proxy události',
                'en': 'Proxy events',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }

        },
        {
            'id': dic.PROXY_REPORT,
            'reports': [
                createReportUsage(ric.PROXY_REPORT_ALLOWED),
                createReportUsage(ric.PROXY_REPORT_IN_AND_OUT),
                createReportUsage(ric.PROXY_REPORT_ERROR),
                createReportUsage(ric.PROXY_REPORT_DEST_HOST),
                createReportUsage(ric.PROXY_REPORT_SRC_IP),
                createReportUsage(ric.PROXY_PER_HOUR)
            ],
            'preloadPriority': 'normal',
            'filtersStatic': {
                'filters': []
            },
            'name': {
                'cs': 'Proxy události',
                'en': 'Proxy events',
                'user': ''
            },
            'description': {
                'cs': '',
                'en': ''
            }

        },
    ],
    'filtersDynamic': {
        'filters': []
    }
};

export default defaultDashboards;
