/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { addOrDeleteBeforeNormalize, notEmpty } from '~commonLib/arrayUtils';
import { copyName, generateRandomColor } from '~frontendRoot/lib/stringUtils';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils';
import { getValue, setValue } from '~commonLib/objectUtils';


export const add = ({ nextState, newObject }) => {
    nextState[hlcfgTableNameByRowId(newObject.id)][newObject.id] = newObject;
};

export type DefaultParams = {
    nextState: any,
    value: any,
    key: string,
    uuid: string,
    subkey?: string,
    subsubkey?: string,
    array?: boolean,
    addingAfter: boolean,
    uuidToAddBefore: string,
    emptyArrayAsUndefined?: boolean
}

export const set = ({ nextState, value, key, uuid, subkey, subsubkey, array,
    addingAfter, uuidToAddBefore, emptyArrayAsUndefined }: DefaultParams) => {
    const table = hlcfgTableNameByRowId(uuid);

    const pathToSet = [ table, uuid, key, subkey, subsubkey ].filter(notEmpty);
    const originalValue = getValue(nextState, pathToSet);

    if (array || (Array.isArray(originalValue) && !Array.isArray(value)) && !emptyArrayAsUndefined) {
        setValue(nextState, pathToSet, addOrDeleteBeforeNormalize({
            array: originalValue || [],
            value,
            uuidToAddBefore,
            addingAfter
        }));
    } else {
        setValue(nextState, pathToSet, value);
    }
};


export const setParent = ({ nextState, value, key,  subkey, subsubkey }) => {
    if (!nextState) {
        return { [key]: value };
    }
    if (subsubkey) {
        nextState[key][subkey][subsubkey] = value;
    } else if (subkey) {
        nextState[key][subkey] = value;
    } else {
        nextState[key] = value;
    }
};

export const copy = ({
    nextState,
    uuid,
    table = hlcfgTableNameByRowId(uuid),
    newUuid,
    reverse,
    extraValues,
    names,
    nameMaxLength
}) => {
    const copy = deepCloneAndMerge(nextState[table][uuid]);
    copy.id = newUuid;
    if (extraValues) {
        for (const prop of Object.keys(extraValues)) {
            copy[prop] = extraValues[prop];
        }
    }
    if (copy.name && !reverse) {
        copy.name = copyName(copy.name, names, nameMaxLength);
    }
    if (copy.color) {
        copy.color = generateRandomColor();
    }
    nextState[table][newUuid] = copy;
};
