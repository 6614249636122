/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { ValuesType } from 'utility-types';

import type { GetResponseType } from '~commonLib/apiUtils';
import { BackendApiDefinitionType } from '~frontendTypes/externalTypes';
import { getApiError } from '~frontendLib/apiUtils';
import { groupAndModify } from '~commonLib/arrayUtils';
import { createNotification } from '~frontendRoot/lib/reactUtils';
import moment from '~commonLib/moment';
import { backendGet, backendPost } from '~frontendLib/backendApiCalls';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper';


type ResponseData = GetResponseType<
    BackendApiDefinitionType['/adaptiveFirewall/getLocalAddressesReportedInKcs']
    >['addrs']

export type DataType = Record<string, ResponseData>

interface InitialStateType {
    data?: DataType,
    error?: any,
    isLoading: boolean
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
};


const imInAF = createSlice({
    name: 'ak/imInAF',
    initialState,
    reducers: {
        imIpRequest: (state) => {
            state.isLoading = true;
            delete state.data;
            delete state.error;
        },
        imIpSuccess: (state, { payload }) => {
            state.data = payload;
            state.isLoading = false;
            delete state.error;
        },
        imIpError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },
        imInConfirm: (state) => {
            state.isLoading = false;
            delete state.data;
            delete state.error;
        },

    },
});

export const {
    imIpRequest,
    imIpSuccess,
    imIpError,
    imInConfirm
} = imInAF.actions;

export default imInAF.reducer;


const getRootState = state => state.imInAf;

export const getDataKeys = (state): string[] => Object.keys(getRootState(state).data || {});

export const getDataValuesHook = (key: string) => (state): ResponseData => getRootState(state).data?.[key];
export const getDataValuesFirstFoundHook = (key: string) => (state) =>  getRootState(state).data?.[key]?.[0]?.timestamp;
export const getDataValuesLastFoundHook = (key: string) => (state) =>  getRootState(state).data?.[key]
    ?.[getRootState(state).data?.[key]?.length - 1]?.timestamp;
export const getIsLoading = (state) => getRootState(state).isLoading;
export const getError = (state) => getRootState(state).error;

export const isData = state => Boolean(Object.keys(getRootState(state).data || {}).length);


const getImInAf = backendGet('/adaptiveFirewall/getLocalAddressesReportedInKcs');

export const workerGetImIpInAf = function* () {
    try {
        const { data } = yield* callSaga(getImInAf);
        const grouped = groupAndModify(data.addrs, (item: ValuesType<ResponseData>) => item.address);
        for (const ip in grouped) {
            grouped[ip].sort(
                (first: ValuesType<ResponseData>, second: ValuesType<ResponseData>) => {
                    return 1000 * (moment(first.timestamp).unix() - moment(second.timestamp).unix());
                }
            );
        }
        yield put(imIpSuccess(grouped));

    } catch (error) {
        yield put(imIpError(getApiError(error)));
    }
};

const postConfirm = backendPost('/adaptiveFirewall/forgetLocalAddressesInKcs');

export const workerConfirm = function* () {
    try {
        yield* callSaga(postConfirm, {});
    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger'
        });
    }
};

export const sagas = [
    takeEvery(imIpRequest.type, workerGetImIpInAf),
    takeEvery(imInConfirm.type, workerConfirm)
];
