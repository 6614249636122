/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils';
import { netaddr } from '~sharedLib/Netaddr/Netaddr';
import { ClusterOwnSelector, NODE_SHARED } from '~commonLib/constants';
import { setValue } from '~commonLib/objectUtils';
import { ParsedIp } from '~frontendTypes/externalTypes';


type UpdateInterfaceType = {
    nextState: any,
    uuid: string,
    hwParams: ParsedIp,
    isCluster: boolean,
    myNode: ClusterOwnSelector,
    ipv6: boolean
}

export const updateInterface = ({ nextState, uuid, hwParams, isCluster, myNode, ipv6  }: UpdateInterfaceType) => {
    const table = hlcfgTableNameByRowId(uuid);
    if (isCluster) {
        if (!nextState[table][uuid].address?.[NODE_SHARED]) {
            setValue(nextState, [ table, uuid, 'address', NODE_SHARED ], []);
        }
        setValue(nextState, [ table, uuid, 'address', myNode ], hwParams.addresses4.filter(
            item => !nextState[table][uuid].address[NODE_SHARED].some(
                sharedAddress => `${netaddr(sharedAddress)}` === `${item.address}/${item.mask}`
            )
        ).map(
            item => {
                if (item.dhcp) {
                    nextState[table][uuid].dhcp = true;
                }
                return netaddr(`${item.address}/${item.mask}`);
            }
        ));

        if (ipv6) {
            if (!nextState[table][uuid].address6) {
                nextState[table][uuid].address6 = {
                    [NODE_A_ID]: [],
                    [NODE_B_ID]: [],
                    shared: []
                };
            }
            nextState[table][uuid].address6[myNode] = hwParams.addresses6.filter(
                item => !nextState[table][uuid].address6[NODE_SHARED].some(
                    sharedAddress => `${netaddr(sharedAddress)}` === `${item.address}/${item.prefix}`
                )
            ).map(
                item => netaddr(`${item.address}/${item.prefix}`)
            );
        }
    } else {
        setValue(nextState, [ table, uuid, 'address', NODE_SHARED ], hwParams.addresses4.map(item => {
            return netaddr(`${item.address}/${item.mask}`);
        }));
        setValue(nextState, [ table, uuid, 'dhcp' ], hwParams.addresses4.some(item => item.dhcp) ||
        (ipv6 && hwParams.addresses6.some(item => item.dhcp)));
        if (ipv6) {
            if (!nextState[table][uuid].address6) {
                nextState[table][uuid].address6 = {
                    [NODE_A_ID]: [],
                    [NODE_B_ID]: [],
                    shared: []
                };
            }
            nextState[table][uuid].address6[NODE_SHARED] = hwParams.addresses6.map(item =>
                netaddr(`${item.address}/${item.prefix}`));

        }
    }
};
