/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getIsProductionMode, getIsWebpackInitialized, setIsWebpackInitialized } from '../../ducks/constantInformation';


@connect(
    state => ({
        isProductionMode: getIsProductionMode(state),
        isWebpackInitialized: getIsWebpackInitialized(state),
    }),
    {
        doSetIsWebpackInitialized: setIsWebpackInitialized
    }
) class DevelStatusBar extends Component {
    static get propTypes() {
        return {
            // from connect:
            isProductionMode: PropTypes.bool,
            isWebpackInitialized: PropTypes.bool,
            doSetIsWebpackInitialized: PropTypes.func,
        };
    }

    render() {
        const { doSetIsWebpackInitialized, isProductionMode, isWebpackInitialized } = this.props;
        if (isProductionMode || isWebpackInitialized) {
            return null;
        }
        return (
            <div
                className="develStatusBar"
                onClick={() => doSetIsWebpackInitialized(true)}
            >
                The frontend is currently being compiled, you might be viewing old version.
            </div>
        );
    }
}

export default DevelStatusBar;
