/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBDropdownMenu } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';


import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced';
import { getHlcfgDiff } from '~frontendRoot/ducks/hlcfgEditor';
import { setModalState } from '~frontendRoot/ducks/modals';
import { CONFIRM_LOGOUT_MODAL } from '~frontendRoot/constants';

import MenuDropdown from '../MenuDropdown';
import MenuDropdownDevice from '../MenuDropdownDevice';
import MenuDropdownItem from '../MenuDropdownItem';
import MenuDropdownToggle from '../MenuDropdownToggle';


@connect(
    state => ({
        hlcfgDiff: getHlcfgDiff(state),
    }),
    {
        doSetModalState: setModalState,
    }
)
class NavBarMenuDropdown extends Component {
    static get propTypes() {
        return {
            activeRoute: PropTypes.string.isRequired,
            objRoute: PropTypes.object.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            doLogoutRequest: PropTypes.func,
            hlcfgDiff: PropTypes.array,
            doSetModalState: PropTypes.func,
        };
    }

    resolveTitle = () => {
        const { objRoute } = this.props;
        if (!objRoute.Title) {
            return objRoute.title;
        }
        return <objRoute.Title />;
    };

    confirmLogoutRequest = () => {
        const { doSetModalState, hlcfgDiff, doLogoutRequest } = this.props;
        if (hlcfgDiff?.length) {
            doSetModalState({ modal: CONFIRM_LOGOUT_MODAL, value: true });
        }
        else {
            doLogoutRequest();
        }
    };

    render() {
        const { activeRoute, doLogoutRequest, objRoute, setActiveRoute } = this.props;
        if (objRoute.isDevice) {
            return (
                <MenuDropdownDevice />
            );
        }
        if (!objRoute.scenes) {
            return (
                <MenuDropdownItem
                    active={objRoute.route.key === activeRoute}
                    className={objRoute.className}
                    icon={objRoute.icon}
                    key={objRoute.route.key}
                    message={this.resolveTitle()}
                    onClick={objRoute.isLogout ? this.confirmLogoutRequest : () => setActiveRoute(objRoute)}
                    routeKey={objRoute.route.key}
                />
            );
        }

        return (
            <MenuDropdown
                activeRoute={activeRoute}
                key={objRoute.route.key}
                objRoute={objRoute}
                setActiveRoute={setActiveRoute}
                thisRoute={objRoute.route.key}
            >
                <MenuDropdownToggle
                    activeRoute={activeRoute}
                    icon={objRoute.icon}
                    message={this.resolveTitle()}
                    objRoute={objRoute}
                    setActiveRoute={setActiveRoute}
                    thisRoute={objRoute.route.key}
                />
                <MDBDropdownMenu>
                    {objRoute.scenes.map(childObjRoute => (
                        <NavBarMenuDropDownAdvanced
                            activeRoute={activeRoute}
                            className={objRoute.className}
                            doLogoutRequest={doLogoutRequest}
                            key={childObjRoute.route.key}
                            objRoute={childObjRoute}
                            routeKey={childObjRoute.route.key}
                            setActiveRoute={setActiveRoute}
                        />
                    ))}
                </MDBDropdownMenu>
            </MenuDropdown>
        );
    }
}

const NavBarMenuDropDownAdvanced = (props) => {
    if (props?.objRoute?.isAdvanced) {
        return (
            <WhenAdvanced>
                <NavBarMenuDropdown {...props} />
            </WhenAdvanced>
        );
    }
    return <NavBarMenuDropdown {...props} />;
};

NavBarMenuDropDownAdvanced.propTypes = {
    objRoute: PropTypes.object.isRequired,
};

export default NavBarMenuDropDownAdvanced;
