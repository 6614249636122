/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Axios from 'axios';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import { promiseSetTimeout } from '~frontendLib/timeUtils';
import { EMPTY_IMMUTABLE_ARR, EMPTY_IMMUTABLE_OBJ } from '~sharedConstants';
import { componentActionSequenceClose } from '~frontendDucks/systemComponentAction/systemComponentAction';
import { SYSTEM_COMPONENT_ICASD_TUNNEL_A, SYSTEM_COMPONENT_ICASD_TUNNEL_B, SUPERTYPE_NETWORK, SUPERTYPE_NONE,
    SUPERTYPE_OS,
    SUPERTYPE_STATISTICS,
    SUPERTYPE_USER_SERVICES, } from '~commonLib/systemComponentsDeclaration';

import { getApiError } from '../../lib/apiUtils';


export const LOOPBACK_NAME = 'lo';


// actions
export const LOAD_SYSTEM_COMPONENTS = 'ak/systemComponents/LOAD_SYSTEM_COMPONENTS';
export const SHOULD_UPDATE_SYSTEM_COMPONENTS = 'ak/systemComponents/SHOULD_UPDATE_SYSTEM_COMPONENTS';

export const LOAD_EXPECTED_COMPONENTS_REQUEST = 'ak/systemComponents/LOAD_EXPECTED_COMPONENTS_REQUEST';
export const LOAD_EXPECTED_COMPONENTS_SUCCESS = 'ak/systemComponents/LOAD_EXPECTED_COMPONENTS_SUCCESS';
export const LOAD_EXPECTED_COMPONENTS_ERROR = 'ak/systemComponents/LOAD_EXPECTED_COMPONENTS_ERROR';


const LOAD_SUCCESS = 'ak/systemComponents/LOAD_SUCCESS';
const LOAD_ERROR = 'ak/systemComponents/LOAD_ERROR';


// initial state
export const initialState = {
    data: null,
    error: null,
    isLoading: false,
    shouldUpdate: false,
    expected: {
        data: null,
        isLoading: false,
        error: null,
    }
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOAD_SYSTEM_COMPONENTS:
        return { ...state, isLoading: true, error: null };
    case LOAD_SUCCESS:
        return { ...state, isLoading: false, data: action.payload };
    case LOAD_ERROR:
        return { ...state, isLoading: false, error: action.payload, data: null };
    case SHOULD_UPDATE_SYSTEM_COMPONENTS:
        return { ...state, shouldUpdate: !state.shouldUpdate };
    case LOAD_EXPECTED_COMPONENTS_REQUEST:
        return { ...state, expected: { ...state.expected, isLoading: true } };
    case LOAD_EXPECTED_COMPONENTS_SUCCESS:
        return { ...state, expected: { error: null, data: action.payload, isLoading: false } };
    case LOAD_EXPECTED_COMPONENTS_ERROR:
        return { ...state, expected: { error: action.payload, data: null, isLoading: false } };
    default:
        return state;
    }
};

export default reducer;


// data accessors
const getState = rootState => rootState.systemComponents;

export const getData = (rootState) => getState(rootState).data || EMPTY_IMMUTABLE_OBJ;

export const getLoadingError = rootState => getState(rootState).error;

export const getIsLoading = rootState => getState(rootState).isLoading;

export const getShouldUpdate = rootState => getState(rootState).shouldUpdate;

export const getComponentStatus = (rootState, component) => getData(rootState)?.[component]?.status;
export const getComponentStatusAdditionalInformation = (rootState, component) =>
    getData(rootState)?.[component]?.status?.additionalInformation;

export const getComponentActions = (rootState, component) => getData(rootState)?.[component]?.actions;

const getExpectedComponents = rootState => getState(rootState).expected.data;

export const getExpectedComponent = (rootState, component) => getExpectedComponents(rootState)?.[component];

export const getClusterTunnelAnyRunning = (rootState) => {
    const components = getData(rootState);
    return components?.[SYSTEM_COMPONENT_ICASD_TUNNEL_B]?.status?.code === 0 ||
    components?.[SYSTEM_COMPONENT_ICASD_TUNNEL_A]?.status?.code === 0;
};

export const getExpectedExceptionsComponents = createSelector(
    [ getExpectedComponents, getData ],
    (expected, components) =>  {
        if (!expected) {
            return EMPTY_IMMUTABLE_ARR;
        }
        const enabled = Object.keys(expected).filter(
            item => expected[item] === 'enabled' && components?.[item]?.superType
        );
        const disabled = Object.keys(expected).filter(
            item =>  expected[item] === 'disabled' && components?.[item]?.superType
        );
        return enabled.filter(item => components?.[item]?.status?.code).concat(
            disabled.filter(item => !components?.[item]?.status?.code)
        );
    }
);

export const getSortedSystemComponents = rootState => {
    const systemComponents = getData(rootState);
    const sortedComponents = {
        [SUPERTYPE_STATISTICS]: [], [SUPERTYPE_OS]: [], [SUPERTYPE_NETWORK]: [], [SUPERTYPE_USER_SERVICES]: []
    };

    if (systemComponents) {
        for (const component in systemComponents) {
            const superType = systemComponents[component]?.superType;
            if (superType && superType !== SUPERTYPE_NONE) {
                sortedComponents[superType].push(systemComponents[component]);
            }
        }
        return sortedComponents;
    }
    return EMPTY_IMMUTABLE_OBJ;
};


export const getExpectedComponentsIsLoading = rootState => getState(rootState).expected.isLoading;

export const getExpectedComponentsError = rootState => getState(rootState).expected.error;

// action creators
export const loadSystemComponents = () =>
    ({ type: LOAD_SYSTEM_COMPONENTS });

export const shouldUpdateSystemComponents = () =>
    ({ type: SHOULD_UPDATE_SYSTEM_COMPONENTS });

export const loadSuccess = (payload) =>
    ({ type: LOAD_SUCCESS, payload });

export const loadError = (payload) =>
    ({ type: LOAD_ERROR, payload });

export const loadExpectedComponents = () =>
    ({ type: LOAD_EXPECTED_COMPONENTS_REQUEST });

const loadExpectedComponentsSuccess = payload =>
    ({ type: LOAD_EXPECTED_COMPONENTS_SUCCESS, payload });

const loadExpectedComponentsError = payload =>
    ({ type: LOAD_EXPECTED_COMPONENTS_ERROR, payload });


// API endpoints
export const getSystemComponentList = async () =>
    Axios.get('/api/components/list');

export const getExcpectedComponentsList = async () =>
    Axios.get('/api//components/expectedList');


// side effects
export const workerGetSystemComponentList = function* () {
    try {
        const { data } = yield call(getSystemComponentList);
        yield put(loadSuccess(data));
        const shouldUpdate = yield select(getShouldUpdate);
        if (shouldUpdate) {
            yield call(promiseSetTimeout, { waitTime: 10000 });
            yield put(loadSystemComponents());
        }
    } catch (error) {
        yield put(loadError(getApiError(error)));
    }
};

export const workerGetExpectedComponents = function* () {
    try {
        const { data } = yield call(getExcpectedComponentsList);
        yield put(loadExpectedComponentsSuccess(data));
    } catch (error) {
        yield put(loadExpectedComponentsError(getApiError(error)));
    }
};


/*
- used in develop scene for networking
const interfaceUpdate = function* () {
    while (true) {
        yield put(loadSystemComponents());
        yield promiseSetTimeout({waitTime: 10 * 1000});
    }
};
*/


export const sagas = [
    takeLatest(LOAD_SYSTEM_COMPONENTS, workerGetSystemComponentList),
    takeLatest(componentActionSequenceClose.type, workerGetSystemComponentList),
    takeLatest(SHOULD_UPDATE_SYSTEM_COMPONENTS, workerGetSystemComponentList),

    takeLatest(LOAD_EXPECTED_COMPONENTS_REQUEST, workerGetExpectedComponents),

    /* typeof window === 'undefined' ?
        undefined :
        fork(interfaceUpdate),
    */

];
