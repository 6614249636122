/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import {
    getNewNodeInitialState,
    getWorkerActionSequence,
    InitialStateByNode,
    progressAdder,
} from '~frontendLib/actionSequence/actionSequence';
import { ACTION_SEQUENCE_COMPONENT_ACTION } from '~sharedConstants';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    getStepObjectForSocketIOProgress,
} from '~frontendLib/actionSequence/lib';
import { ACTION_SEQUENCE_PROGRESS_ERROR_KEY, EVENT_PREFIX_COMPONENT_ACTION, HTTP_HEADER_CLUSTER_NODE, NODE_SELF } from '~commonLib/constants';
import { ActionSequencePayloadError } from '~frontendLib/actionSequence/types';
import { ActionSequenceInfo } from '~sharedLib/types';
import { getHealthIssuesRequest } from '~frontendDucks/clusterSetup/actions';


export const ACTION_STEP_COMPONENT_ACTION = 'componentAction';
// initial state
const initialOptionsState = {
    componentType: '', // component type
    componentId: '', // component ID
    componentAction: '', // what action to perform with the component
};

const initialState = {
    byNode: {} as InitialStateByNode['byNode'],

    node: '',
    isOpen: false,
    isFinished: false,
    options: initialOptionsState,
    replaceMaster: false,
    isConfirmed: false,
    error: null,
};

const getState = rootState => rootState.systemComponentAction;
export const getComponentAction = rootState => getOptions(rootState).componentAction;
export const getComponentId = rootState => getOptions(rootState).componentId;
export const getComponentType = rootState => getOptions(rootState).componentType;
const getOptions = rootState => getState(rootState).options;
const getNode = rootState => getState(rootState).node;
export const getIsConfirmed = rootState => getState(rootState).isConfirmed;
export const getIsReplaceMaster = rootState => getState(rootState).replaceMaster;

const getNodeState = (rootState, node = NODE_SELF) => getState(rootState).byNode[node] || getNewNodeInitialState();
export const getIsOpen = rootState => getState(rootState).isOpen;
export const getIsLoading = rootState => !getState(rootState).isFinished;
export const getIsAborted = (rootState, node) => getNodeState(rootState, node).isAborted;
export const getError = (rootState, node) => getNodeState(rootState, node).error;
export const getProgress = (rootState, node) => getNodeState(rootState, node).progress;


// reducer

const systemComponentActionSlice = createSlice({
    name: 'ak/systemComponentAction',
    initialState,
    reducers: {

        componentActionSequenceOpen: (
            state,
            action: PayloadAction<{ componentType: string, componentId,
                componentAction: string, replaceMaster: boolean, node: string }>
        ) => {
            const { payload } = action;
            return {
                ...initialState,
                node: payload.node,
                isOpen: true,
                replaceMaster: payload.replaceMaster,
                options: {
                    componentType: payload.componentType,
                    componentId: payload.componentId,
                    componentAction: payload.componentAction
                },
            };
        },

        componentActionSequenceConfirm: (state) => {
            state.isConfirmed = true;
        },
        componentActionSequenceConfirmReplace: (state) => {
            state.isConfirmed = true;
            state.replaceMaster = false;
        },

        componentActionSequenceClose: (state) => {
            state.isOpen = false;
        },

        componentActionSequenceSuccess: (state) => {
            state.isFinished = true;
        },


        componentActionSequenceError: (state, action: PayloadAction<ActionSequencePayloadError[]>) => {
            action.payload.forEach(event => {
                const nodes = event.sourceNodes || Object.keys(state.byNode);
                nodes.forEach(node => state.byNode[node].error = event[ACTION_SEQUENCE_PROGRESS_ERROR_KEY]);
            });
            state.isFinished = true;
        },

        componentActionStepStarted: progressAdder(ACTION_STEP_COMPONENT_ACTION, getStateProgressStarted),
        componentActionStepProgress: progressAdder(ACTION_STEP_COMPONENT_ACTION, getStateProgressProgress),
        componentActionStepSuccess: progressAdder(ACTION_STEP_COMPONENT_ACTION, getStateProgressSuccess),
        componentActionStepFailure: progressAdder(ACTION_STEP_COMPONENT_ACTION, getStateProgressFailure),
    },
});

const actions = systemComponentActionSlice.actions;
export const {
    componentActionSequenceOpen,
    componentActionSequenceConfirm,
    componentActionSequenceConfirmReplace,
    componentActionSequenceClose,
} = actions;
export default systemComponentActionSlice.reducer;

export const componentActionStart = function* ({ breakLock }) {
    const componentAction = yield select(getComponentAction);
    const componentId = yield select(getComponentId);
    const componentType = yield select(getComponentType);
    const nodes = yield select(getNode);
    const headers = { [HTTP_HEADER_CLUSTER_NODE]: nodes ?? NODE_SELF };
    const response = yield call(
        axios.post,
        `/api/component/${componentType}/${componentId}/${componentAction}`, { breakLock },
        { headers: headers }

    );
    const { data } = response as AxiosResponse<ActionSequenceInfo>;
    return data;
};

const workerStepComponentAction = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.componentActionStepStarted,
    actionStepProgressed: actions.componentActionStepProgress,
    actionStepSucceeded: actions.componentActionStepSuccess,
    actionStepFailed: actions.componentActionStepFailure,
    eventPrefix: EVENT_PREFIX_COMPONENT_ACTION,
});

const workerComponentAction = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_COMPONENT_ACTION,
    actionSequenceSucceeded: actions.componentActionSequenceSuccess,
    actionSequenceFailed: actions.componentActionSequenceError,
    fnStart: componentActionStart,
    workers: [
        workerStepComponentAction,
    ],
    close: componentActionSequenceClose
});

const workerComponentActionReplaceMaster = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_COMPONENT_ACTION,
    actionSequenceSucceeded: actions.componentActionSequenceSuccess,
    actionSequenceFailed: actions.componentActionSequenceError,
    fnStart: componentActionStart,
    workers: [
        workerStepComponentAction,

    ],
    close: componentActionSequenceClose
});

const workerComponentActionReplaceMasterAndRefresh = function*(action) {
    yield workerComponentActionReplaceMaster(action);
    yield put(getHealthIssuesRequest());
};


// side effects
export const sagas = [
    takeLatest(actions.componentActionSequenceConfirm.type, workerComponentAction),
    takeLatest(actions.componentActionSequenceConfirmReplace.type, workerComponentActionReplaceMasterAndRefresh)
];
