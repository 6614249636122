/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import { Icon, Select } from '~frontendRoot/components/Generic';
import Message from '~frontendComponents/Message';
import { userSetting, SELECTABLE_COLUMNS, ColumnDefinitionType, SelectableTables } from '~frontendConstants';
import { useUserSettingPathSetter } from '~frontendRoot/lib/hooks/userSettings';
import { UserSettings } from '~sharedLib/schemas/userSettings.schema';


const selectOptions = (value: ColumnDefinitionType[],
    allColumns: UserSettings['columnsByTable'], id: SelectableTables) => value.map((item, index) => {
    return {
        id: String(index),
        value: item.title,
        disabled: item.disabled,
        label: (
            <div className="policy__selectIcon">
                {allColumns?.[id]?.[item.title] ?? item.selected ?
                    <Icon
                        className="policy__icon"
                        name="check"
                        size="sm"
                    /> : null}
                <span className="pl-2"><Message message={item.title + '.title'} /></span>
            </div>)
    };
});


const SelectColumns = ({ id }: {id: SelectableTables}) => {

    const columns = SELECTABLE_COLUMNS[id];
    const [ allColumns, setColumn ] = useUserSettingPathSetter(userSetting.columnsByTable);

    const doSetColumns = ({ value }) => {
        setColumn({
            path: [ id, value ],
            value: !(allColumns?.[id]?.[value] ?? columns.find(item => item.title === value)?.selected)
        });

    };
    return (
        <Select
            className="packetFilterColumnsSelect mr-1"
            classNamePrefix="packetFilterSelect"
            closeMenuOnSelect={false}
            id="selectColumnsId"
            name="selectColumns"
            noOptionsMessage
            onChange={doSetColumns}
            options={selectOptions(columns, allColumns, id)}
            paste={false}
            placeholder="widgets:global.selectColumns"
            value={null}
            withoutValue
        />
    );

};
export default SelectColumns;
