/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import arrayUtils from './arrayUtils';


const COMPARATORS = [
    {
        selected: '<',
        name: '<'
    },
    {
        selected: '<=',
        name: '≤'
    },
    {
        selected: '=',
        name: '='
    },
    {
        selected: '!=',
        name: '≠'
    },
    {
        selected: '>=',
        name: '≥'
    },
    {
        selected: '>',
        name: '>'
    }
];

const COMPARATORS_NEGATED = {
    '<': '≥',
    '<=': '>',
    '=': '≠',
    '!=': '=',
    '>=': '<',
    '>': '≤'
};

const COMPARATOR_COLUMNS = {
    'event.events': COMPARATORS[4],
    'network.download': COMPARATORS[4],
    'network.duration': COMPARATORS[4],
    'network.upload': COMPARATORS[4],
    'smtp_proxy_at.size': COMPARATORS[4],
    'user_http_activity.duration': COMPARATORS[4],
};

const COMPARATOR_DEFAULT = COMPARATORS[2];

const getComparator = function(columnName) {
    return COMPARATOR_COLUMNS[columnName] || COMPARATOR_DEFAULT;
};

const filterObjectNumbersManipulator = {};

const getNewValue = function(columnName, value) {
    const selected = Number(value);
    return {
        selected: isNaN(selected) ? '' : selected,
        comparator: getComparator(columnName)
    };
};

filterObjectNumbersManipulator.createNewFilterObject = function(
    columnName, value
) {
    return {
        selected: [
            getNewValue(columnName, value)
        ],
    };
};

filterObjectNumbersManipulator.addValueToFilter = function(filterObject, value) {
    filterObject.selected.push(getNewValue(filterObject.columnName, value));
};

filterObjectNumbersManipulator.removeValueFromFilter = function(filterObject, index) {
    arrayUtils.removeAtIndex(filterObject.selected, index);
};

const filterObjValue = function(objValue) {
    return objValue && typeof objValue.selected === 'number';
};

filterObjectNumbersManipulator.getReporterParams =
    function(customFilterStorage, reporterTemplates, filterContainerManipulator, filterObject) {
        if (filterObject.isDisabled ||
            !filterObject.selected ||
            !filterObject.selected.reduce(function(temp, objValue) {
                return temp || filterObjValue(objValue);
            }, false))
        {
            return undefined;
        }
        return {
            type: filterObject.isAnd ? 'AND' : 'OR',
            neg: filterObject.isNegated,
            params: filterObject.selected.filter(filterObjValue).map(
                function(item) {
                    return {
                        col: filterObject.columnName,
                        type: 'compare',
                        params: [ item.comparator.selected, item.selected ]
                    };
                }
            )
        };
    };

filterObjectNumbersManipulator.COMPARATORS = COMPARATORS;
filterObjectNumbersManipulator.COMPARATORS_NEGATED = COMPARATORS_NEGATED;

export default filterObjectNumbersManipulator;
