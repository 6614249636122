/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { call, put, takeLatest, select } from 'redux-saga/effects';

import { getNetworkInterfaces } from '~frontendDucks/networkInterfaces';
import { backendPost } from '~frontendLib/backendApiCalls';

import { getApiError } from '../../lib/apiUtils';
import { workerGetSystemComponentList } from '../systemComponents';


export const NMAP_USER_DHCP_REQUEST = 'ak/nmapUser/NMAP_USER_FIND_DHCP_REQUEST';
const NMAP_USER_DHCP_SUCCESS = 'ak/nmapUser/NMAP_USER_FIND_DHCP_SUCCESS';
const NMAP_USER_DHCP_ERROR = 'ak/nmapUser/NMAP_USER_FIND_DHCP_ERROR';
const NMAP_USER_DHCP_CALLED = 'ak/nmapUser/NMAP_USER_DHCP_CALLED';


// initial state
export const initialState = {
    dhcp: {
        loadCounter: 0,
        loadingError: {},
        offers: {},
    }
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case NMAP_USER_DHCP_REQUEST:
        return {
            ...state,
            dhcp: {
                ...state.dhcp,
                loadCounter: 0,
            }
        };
    case NMAP_USER_DHCP_CALLED:
        return {
            ...state,
            dhcp: {
                ...state.dhcp,
                loadCounter: state.dhcp.loadCounter + 1,
            }
        };
    case NMAP_USER_DHCP_SUCCESS:
        return {
            ...state,
            dhcp: {
                ...state.dhcp,
                loadCounter: state.dhcp.loadCounter - 1,
                offers: {
                    ...state.dhcp.offers,
                    [action.payload.interfaceName]: action.payload.data,
                }
            }
        };
    case NMAP_USER_DHCP_ERROR:
        return {
            ...state,
            dhcp: {
                ...state.dhcp,
                loadCounter: 0,
                loadingError: action.payload,
            }
        };
    default:
        return state;
    }
};

export default reducer;


// data accessors
const getState = rootState => rootState.nmapUser;
export const getNmapDhcp = rootState => getState(rootState).dhcp;

// action creators
export const getNmapDhcpRequest = () =>
    ({ type: NMAP_USER_DHCP_REQUEST });

const getNmapDhcpSuccess = (data, interfaceName) =>
    ({
        type: NMAP_USER_DHCP_SUCCESS,
        payload: {
            data: data,
            interfaceName: interfaceName,
        }
    });

const getNmapDhcpError = error =>
    ({ type: NMAP_USER_DHCP_ERROR, payload: error });

const getNmapDhcpCalled = () =>
    ({ type: NMAP_USER_DHCP_CALLED });

// API
const nmapDhcp = backendPost('/nmap/dhcp');
const getNmapDhcpApi = async (interfaceName) => {
    return nmapDhcp({ interfaceName });
};


// workers
const workerNmapDhcp = function* () {
    try {
        yield* workerGetSystemComponentList();
        const networkInterfacesInfo = yield select(rootState => getNetworkInterfaces(rootState));
        for (const networkInterface of networkInterfacesInfo) {
            if (!networkInterface.physicalLayerUp ||
                !networkInterface.types.includes('hw') ||
                !networkInterface.types.includes('ethernet'))
            {
                continue;
            }
            yield put(getNmapDhcpCalled());
            const { data } = yield call(getNmapDhcpApi, networkInterface.name);
            yield put(getNmapDhcpSuccess(data, networkInterface.name));
        }
    } catch (error) {
        yield put(getNmapDhcpError(getApiError(error)));
    }
};


export const sagas = [
    takeLatest(NMAP_USER_DHCP_REQUEST, workerNmapDhcp),
];
