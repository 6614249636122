/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getTFunction = (instanceI18n) => {
    return instanceI18n.t.bind(instanceI18n);
};

const getCounter = () => {
    let cnt = 0;
    return {
        inc: () => {
            cnt++;
        },
        count: () => cnt,
    };
};

export const getCounterById = <T>() => {
    const counters = new Map<T, ReturnType<typeof getCounter>>();
    return {
        inc: (id: T) => {
            if (!counters.has(id)) {
                counters.set(id, getCounter());
            }
            // pls, eslint. I just checked or set...
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            counters.get(id)!.inc();
        },
        count: (id: T) => {
            return counters.get(id)?.count() || 0;
        },
        getIds: () => [ ...counters.keys() ],
        entries: () => [ ...counters.entries() ].map(entry => <const>[ entry[0], entry[1].count() ]),
    };
};

type Recorder<T> = Record<PropertyKey, T>;
export const getEntriesRecorder = <T>(): Recorder<T[]> => {
    return new Proxy({}, {
        get: function(obj, prop) {
            return obj[prop] ??= [];
        }
    });
};

const arrayObjWithEmptyPush: any = [];
arrayObjWithEmptyPush.push = () => {};
Object.freeze(arrayObjWithEmptyPush);

export const getDefinedEntriesRecorder = <T>(): Record<any, T[]> => {
    return new Proxy({}, {
        get: function(obj, prop) {
            if (prop === 'undefined') {
                return arrayObjWithEmptyPush;
            }
            return obj[prop] ??= [];
        }
    }) as Record<any, T[]>;
};


export const timestampMs = () => Date.now();
export const timestampSec = () => Math.floor(Date.now() / 1000);

/**
 * Returns random number from range, including the min and max.
 */
export const randomIntegerFromRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
