/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCollapse, MDBFormInline, MDBNavbar, MDBNavbarBrand, MDBModalFooter, MDBBtn  } from 'mdbreact';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import { connect } from 'react-redux';

import Logo from '~frontendComponents/Logo';
import { Modal, Tooltip } from '~frontendComponents/Generic';
import { SYSTEM_UPGRADE_STATE_PREPARED,  SYSTEM_UPGRADE_STATE_FAILURE, } from '~commonLib/constants';
import { getIsUpgradeStateOfType, upgradeCommit,
    getUpgradeError,
    upgradeDiscard, getIsUpgradeLoading } from '~frontendDucks/upgrade';
import { getExpectedExceptionsComponents, getComponentStatus, getLoadingError } from '~frontendDucks/systemComponents';
import { getRouterPath } from '~frontendDucks/userAuthentication';
import { BACKUP, CERTIFICATION_EXPIRATION, FAILURE, FAULT, MASTER, STOP } from '~frontendConstants';
import { getGlcfgValue, getIsCluster, getProxyEnabled, getIsAnyUnknownInterface, resetSessionHlcfgRequest, getNeedsSessionReset } from '~frontendDucks/hlcfgEditor';
import { getHealthIssues, isNodeUnreachable, getHealthIssuesRequest, getMyNodeState } from '~frontendDucks/clusterSetup';
import { getCwdbState, getSuricataRulesUpdateState } from '~frontendDucks/protectionDatabases';
import { getHostname } from '~frontendDucks/systemInfo';
import { isData } from '~frontendRoot/ducks/imInAF';
import { getRecoveryMode } from '~frontendRoot/ducks/backup';
import ButtonWithLock from '~frontendComponents/Generic/ButtonWithLock';
import { iconShouldBeShown } from '~frontendRoot/ducks/certificationExpiration';
import { setModalState } from '~frontendRoot/ducks/modals';

import NavBarIcon from '../NavBarIcon/NavBarIcon';
import CfgActivationButton from '../Header/components/CfgActivationButton';

// TODO: Make this dynamicaly from site map (navigation)

const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);


export const getTranslationFromReactPath = (reactPath, translator) => {
    const values = reactPath.split('/');
    values.shift();
    const sceneNames = values.map(item => capitalize(item));
    if (sceneNames[2]) {
        // Lord have merce on my soul
        return `${translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.title`,
            { defaultValue: translator('widgets:global.notFound') })} / ${translator(
            `scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.scenes.${sceneNames[2]}.title`
            , { defaultValue: translator('widgets:global.notFound') }
        )}`;
    }
    if (sceneNames[0]) {
        return translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.title`,
            { defaultValue: translator('widgets:global.notFound') });
    }
    return '';
};

@withTranslation()
@connect(
    state => ({
        isUpgradePrepared: getIsUpgradeStateOfType(state, SYSTEM_UPGRADE_STATE_PREPARED),
        isUpgradeLoading: getIsUpgradeLoading(state),
        isUpgradeFailed: getIsUpgradeStateOfType(state, SYSTEM_UPGRADE_STATE_FAILURE),
        isFiveMinuteTimer: getGlcfgValue(state, 'upgrade')?.isFiveMinuteTimer,
        routerPath: getRouterPath(state),
        expectedExceptionsComponents: getExpectedExceptionsComponents(state),
        callhome: getComponentStatus(state, 'callhome')?.code,
        loadingError: getLoadingError(state),
        healthIssues: getHealthIssues(state),
        nodeUnreachable: isNodeUnreachable(state),
        error: getUpgradeError(state),
        isCluster: getIsCluster(state),
        myNodeState: getMyNodeState(state),
        cwdbState: getCwdbState(state),
        proxyEnabled: getProxyEnabled(state),
        suricataEnabled: getGlcfgValue(state, 'suricataEnabled'),
        suricataStatus: getSuricataRulesUpdateState(state),
        hostname: getHostname(state),
        isAnyUnknow: getIsAnyUnknownInterface(state),
        amIInAf: isData(state),
        needsSessionReset: getNeedsSessionReset(state),
        recoveryMode: getRecoveryMode(state),
        iconShouldBeShown: iconShouldBeShown(state)

    }), {
        discardUpgrade: upgradeDiscard,
        doUpgradeCommit: upgradeCommit,
        resetSessionHlcfg: resetSessionHlcfgRequest,
        getHealthIssues: getHealthIssuesRequest,
        setModalState: setModalState
    }
)

class NavBarTop extends Component {
    static get propTypes() {
        return {
            isCollapsed: PropTypes.bool.isRequired,
            isTreeDirty: PropTypes.bool.isRequired,
            isUpgradePrepared: PropTypes.bool,
            t: PropTypes.func,
            isUpgradeFailed: PropTypes.bool,
            doUpgradeCommit: PropTypes.func,
            isFiveMinuteTimer: PropTypes.bool,
            routerPath: PropTypes.string,
            expectedExceptionsComponents: PropTypes.array,
            callhome: PropTypes.number,
            loadingError: PropTypes.object,
            getHealthIssues: PropTypes.func,
            healthIssues: PropTypes.array,
            nodeUnreachable: PropTypes.bool,
            isCluster: PropTypes.bool,
            cwdbState: PropTypes.string,
            proxyEnabled: PropTypes.bool,
            suricataEnabled: PropTypes.bool,
            suricataStatus: PropTypes.string,
            isAnyUnknow: PropTypes.bool,
            discardUpgrade: PropTypes.func,
            isUpgradeLoading: PropTypes.bool,
            hostname: PropTypes.string,
            amIInAf: PropTypes.bool,
            resetSessionHlcfg: PropTypes.func,
            needsSessionReset: PropTypes.bool,
            recoveryMode: PropTypes.bool,
            iconShouldBeShown: PropTypes.bool,
            setModalState: PropTypes.func,
            myNodeState: PropTypes.string
        };
    }

    componentDidMount() {
        const { routerPath, t,
            hostname,
        } = this.props;
        document.title = `${hostname} - ${getTranslationFromReactPath(routerPath, t)}`;
    }

    componentDidUpdate(prevProps) {
        const { routerPath, t,
            isCluster, getHealthIssues, hostname } = this.props;
        if (routerPath !== prevProps.routerPath) {
            document.title = `${hostname} - ${getTranslationFromReactPath(routerPath, t)}`;
            if (isCluster) {
                getHealthIssues();
            }
        }
    }

    openModal = () => {
        const { setModalState } = this.props;
        setModalState({ modal: CERTIFICATION_EXPIRATION, value: true });
    };

    static defaultProps = {
        isCollapsed: false,
    };

    render() {
        const { t, isCollapsed, isTreeDirty, isUpgradePrepared,
            isUpgradeFailed, doUpgradeCommit, isFiveMinuteTimer,
            expectedExceptionsComponents, callhome, loadingError, healthIssues, nodeUnreachable,
            myNodeState, cwdbState, proxyEnabled, suricataEnabled, suricataStatus,
            discardUpgrade, isUpgradeLoading, hostname, isAnyUnknow, amIInAf, resetSessionHlcfg,
            needsSessionReset, recoveryMode, iconShouldBeShown
        } = this.props;
        return (
            <>
                <MDBNavbar
                    className="navbar--horizontal"
                    color="default-color-dark"
                    dark
                    expand="md"
                >
                    <MDBNavbarBrand>
                        <Logo hostname={hostname} />
                    </MDBNavbarBrand>
                    <MDBCollapse
                        id="navbarCollapse"
                        isOpen={isCollapsed}
                        navbar
                    >
                        <MDBFormInline
                            className="searchForm"
                        >
                            {/* // Not used and makes some error in console
                            showTodos ?
                                <Select
                                    className="searchForm__select"
                                    dark
                                    id="searchForm__select"

                                    name="searchForm__select"
                                    // TODO: Make this dynamicaly from site map (navigation)
                                    onChange={this.onChangeEvent}
                                    options={selectOptionsList}
                                    placeholder={'navigation:search.title'}
                                /> :
                                null
                                */
                            }
                        </MDBFormInline>
                        {(needsSessionReset || recoveryMode) &&
                        <div className="navbarResetBtn">
                            <Tooltip content={t(`navbar:${recoveryMode ? 'recovery' : 'reset'}.desc`)}>

                                <ButtonWithLock
                                    color={recoveryMode ? undefined : ' primary'}
                                    messageOnLocked={`navbar:${recoveryMode ? 'recovery' : 'reset'}.title`}
                                    messageOnOpen={`navbar:${recoveryMode ? 'recovery' : 'reset'}.title`}
                                    onClick={() => resetSessionHlcfg()}
                                />
                            </Tooltip>
                        </div>}
                        <div className="navbarIcons">
                            <div className="navbarIcons__container">
                                <NavBarIcon
                                    represents="ClusterUnreachable"
                                    shouldDisplay={nodeUnreachable && !!healthIssues.length}
                                />
                                <NavBarIcon
                                    represents="SyncProblems"
                                    shouldDisplay={!nodeUnreachable && !!healthIssues.length}
                                />
                                <NavBarIcon
                                    represents="UpgradePrepared"
                                    shouldDisplay={isUpgradePrepared}
                                />
                                <NavBarIcon
                                    represents="ExpectedExceptionsComponents"
                                    shouldDisplay={expectedExceptionsComponents.length}
                                />
                                <NavBarIcon
                                    represents="CallHomeOn"
                                    shouldDisplay={callhome === 0}
                                />
                                <NavBarIcon
                                    represents="CallHomeOff"
                                    shouldDisplay={callhome !== 0}
                                />
                                <NavBarIcon
                                    represents="LoadingError"
                                    shouldDisplay={loadingError}
                                />
                                <NavBarIcon
                                    represents="SuricataError"
                                    shouldDisplay={suricataEnabled && suricataStatus === 'error'}
                                />
                                <NavBarIcon
                                    represents="SuricataWarning"
                                    shouldDisplay={suricataEnabled && suricataStatus === 'warning'}
                                />
                                <NavBarIcon
                                    represents="Unknown"
                                    shouldDisplay={isAnyUnknow}
                                />
                                <NavBarIcon
                                    represents="ProxyError"
                                    shouldDisplay={proxyEnabled && cwdbState === 'error'}
                                />
                                <NavBarIcon
                                    represents="ProxyWarning"
                                    shouldDisplay={proxyEnabled && cwdbState === 'warning'}
                                />
                                <NavBarIcon
                                    represents="MyNodeIsMaster"
                                    shouldDisplay={myNodeState === MASTER}
                                />
                                <NavBarIcon
                                    represents="MyNodeIsBackup"
                                    shouldDisplay={myNodeState === BACKUP}
                                />
                                <NavBarIcon
                                    represents="MyNodeHasFault"
                                    shouldDisplay={myNodeState === FAULT}
                                />
                                <NavBarIcon
                                    represents="MyNodeHasStopped"
                                    shouldDisplay={myNodeState === STOP ||
                                    myNodeState === FAILURE}
                                />
                                <NavBarIcon
                                    represents="IAmInAF"
                                    shouldDisplay={amIInAf}
                                />
                                <NavBarIcon
                                    onClick={this.openModal}
                                    represents="CertificationExpiration"
                                    shouldDisplay={iconShouldBeShown}
                                />

                            </div>
                            <CfgActivationButton
                                isTreeDirty={isTreeDirty}
                            />
                        </div>
                    </MDBCollapse>
                </MDBNavbar>
                <Modal
                    bodyText="upgrade:failed.desc"
                    headerText="upgrade:failed.title"
                    modalOpen={isUpgradeFailed}
                    position="top-right"
                >
                    <MDBModalFooter>
                        <MDBBtn
                            color="secondary"
                            disabled={isUpgradeLoading}
                            onClick={event => {
                                event.preventDefault();
                                discardUpgrade();
                            }}
                        >
                            {t('upgrade:failed.discard')}
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            disabled={isUpgradeLoading}
                            onClick={event => {
                                event.preventDefault();
                                doUpgradeCommit(isFiveMinuteTimer);
                            }}
                        >
                            {t('upgrade:failed.tryAgain')}
                        </MDBBtn>
                    </MDBModalFooter>
                </Modal>
            </>
        );
    }
}

export default NavBarTop;
