/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { WAF_PROFILES, PROFILES } from '~frontendRoot/constants';
import { DASHBOARD_PROTECTION, DASHBOARD_SERVICE, DASHBOARD_SYSTEM } from '~sharedConstants';


const ACTIVE_CARD_CHANGE = 'ak/activeCards/ACTIVE_CARD_CHANGE';
const SET_INITIAL_CARDS = 'ak/activeCards/SET_INITIAL_CARDS';

// initial state
export const initialState = {
    vpn: '',
    dhcpd: '',
    qos: '',
    changes: 'changes',
    ipsec: '',
    [WAF_PROFILES]: '',
    [PROFILES]: '',
};


export const clicableItems =  [
    'openvpnRas', 'openvpnClient', 'dhcpServer', 'dhcpRelay', 'tcQueue', 'ipsec', PROFILES, WAF_PROFILES
];

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
    case ACTIVE_CARD_CHANGE:
        return {
            ...state,
            [action.payload.type]: action.payload.value,
        };
    case SET_INITIAL_CARDS:
        return {
            [PROFILES]: action.hlcfgTree.protection.policy?.profiles[0],
            vpn: action.hlcfgTree.services.vpn.openvpn[0],
            ipsec: action.hlcfgTree.services.vpn.ipsec[0],
            dhcpd: action.hlcfgTree.services.dhcpd[0],
            qos: action.hlcfgTree.network.trafficControl?.queues?.[0],
            [DASHBOARD_PROTECTION]: action.hlcfgTree.dashboards[DASHBOARD_PROTECTION][0],
            [DASHBOARD_SYSTEM]: action.hlcfgTree.dashboards[DASHBOARD_SYSTEM][0],
            [DASHBOARD_SERVICE]: action.hlcfgTree.dashboards[DASHBOARD_SERVICE][0],
            [WAF_PROFILES]: action.hlcfgTree.protection.policy?.wafProfiles[0],
            changes: 'changes'
        };
    default:
        return state;
    }
};


const getRootState = state => state.activeCards;

export const getActiveCard = (state, type) => getRootState(state)[type];

export const getActiveCardHook = type => (state) => getRootState(state)[type];

// getActiveCardSchema is in hlcfgEditor.ts to remove import cycle.

// action creators


export const setActiveCard = (payload) =>
    ({ type: ACTIVE_CARD_CHANGE, payload });

export const setInitialCards = (hlcfgTree) =>
    ({ type: SET_INITIAL_CARDS, hlcfgTree });
