/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Message from '~frontendComponents/Message';


export const DEFAULT_SELECTED_ROLE = 'root';

export const translateRole = ({ t, role }) => {
    switch (role) {
    case 'root':
        return t('user:filter.values.root');
    case 'audit':
        return t('user:filter.values.audit');
    case 'sys_admin':
        return t('user:filter.values.sysAdmin');
    case 'sec_admin':
        return t('user:filter.values.secAdmin');
    case 'kbi_user':
        return t('user:filter.values.kbiUser');
    default:
        return role;
    }
};


export const allRolesSelectOptions = [
    {
        id: 0,
        value: 'root',
        label: <Message message="user:filter.values.root" />, //TODO: get rid of <Message />
    },
    {
        id: 1,
        value: 'audit',
        label: <Message message="user:filter.values.audit" />, //TODO: get rid of <Message />
    },
    {
        id: 2,
        value: 'sys_admin',
        label: <Message message="user:filter.values.sysAdmin" />, //TODO: get rid of <Message />
    },
    {
        id: 3,
        value: 'sec_admin',
        label: <Message message="user:filter.values.secAdmin" />, //TODO: get rid of <Message />
    },
    {
        id: 4,
        value: 'kbi_user',
        label: <Message message="user:filter.values.kbiUser" />, //TODO: get rid of <Message />
    }
];
