/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '../../../lib/scenes';
import System, { scenes as scenesSystem } from './System';
import Traffic, { scenes as scenesTraffic } from './Traffic';


const MONITORING_SYSTEM_SCENE = createScene({
    path: '/monitoring/system',
    component: System,
    title: 'scenes:scenes.Monitoring.scenes.System.title',
    scenes: scenesSystem,
});

const MONITORING_TRAFFIC_SCENE = createScene({
    path: '/monitoring/traffic',
    component: Traffic,
    title: 'scenes:scenes.Monitoring.scenes.Traffic.title',
    scenes: scenesTraffic,
});

const scenes = () => [
    MONITORING_SYSTEM_SCENE,
    MONITORING_TRAFFIC_SCENE
].filter(Boolean);

export default scenes;
