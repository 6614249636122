/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { IPV4, IPV6 } from '~commonLib/constants';


export const ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS = 'main_address';
export const ADDRESSES_SELECTOR_TYPE_NETWORK = 'network';
export const ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES = 'address';

export const ADDRESS_SELECTOR_KEY = '__addressesSelectorObject';

type addressesSelectorIpVersion = typeof IPV4 | typeof IPV6

type addressesSelectorAddressTypeIfaceType =
    typeof ADDRESSES_SELECTOR_TYPE_NETWORK |
    typeof ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES;

type addressesSelectorAddressTypeIfaceId =
    typeof ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS |
    addressesSelectorAddressTypeIfaceType;

type AddressesSelectorById = {
    [ADDRESS_SELECTOR_KEY]: {
        ipVersion: addressesSelectorIpVersion,
        addressType: addressesSelectorAddressTypeIfaceId,
        ifaceId: string,
    }
}
type AddressesSelectorByIfaceType= {
    [ADDRESS_SELECTOR_KEY]: {
        ipVersion: addressesSelectorIpVersion,
        addressType: addressesSelectorAddressTypeIfaceType,
        ifaceType: 'isInternal'|'isExternal'|'every',
    }
}
export type AddressesSelector = AddressesSelectorById | AddressesSelectorByIfaceType;

export const getAddressesSelectorContent =
    (obj: AddressesSelector): AddressesSelector[typeof ADDRESS_SELECTOR_KEY] => obj?.[ADDRESS_SELECTOR_KEY];

export const isAddressesSelector = (obj): obj is AddressesSelector => !!getAddressesSelectorContent(obj);


export const isAddressesSelectorByIdString = (str) =>  {
    const is = new RegExp(`[${IPV4}|${IPV6}]_[${
        ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES}|${ADDRESSES_SELECTOR_TYPE_NETWORK}|
        ${ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS}]`, 'g');
    return is.test(str);
};

export const createAddressesSelectorByIfaceId = (
    { ipVersion, ifaceId, addressType }: AddressesSelectorById[typeof ADDRESS_SELECTOR_KEY]
): AddressesSelectorById => {
    checkByIfaceId(ipVersion, addressType);
    return {
        [ADDRESS_SELECTOR_KEY]: {
            ipVersion,
            addressType,
            ifaceId
        }
    };
};

export const createAddressesSelectorByIfaceType =
    (
        { ipVersion, ifaceType, addressType }: AddressesSelectorByIfaceType[typeof ADDRESS_SELECTOR_KEY]
    ): AddressesSelectorByIfaceType => {
        checkByIfaceType(ipVersion, addressType);
        return {
            [ADDRESS_SELECTOR_KEY]: {
                ipVersion,
                addressType,
                ifaceType
            }
        };
    };

export const interfaceIsPartOfAddressesSelector = (iface, selector: AddressesSelector) => {
    const content = getAddressesSelectorContent(selector);
    if ('ifaceType' in content && iface[content.ifaceType]) {
        return true;
    }
    if ('ifaceId' in content && iface.id === content.ifaceId) {
        return true;
    }
};

const basicCheck = types => (ipVersion, addressType) => {
    assert(types.includes(addressType), 'Invalid addressType was passed to addresses selector creator.');
    assert(ipVersions.includes(ipVersion), 'Invalid ipVersion was passed to addresses selector creator.');
};

const typesForIfaceType = [
    ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES,
    ADDRESSES_SELECTOR_TYPE_NETWORK
];
const typesForIfaceId = [
    ...typesForIfaceType,
    ADDRESSES_SELECTOR_TYPE_MAIN_ADDRESS
];

const ipVersions = [
    IPV4, IPV6
];
const checkByIfaceId = basicCheck(typesForIfaceId);
const checkByIfaceType = basicCheck(typesForIfaceType);
