/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy';

import { createScene } from '../../../../lib/scenes';


const AdaptiveFirewall = createLazy(() => import('./AdaptiveFirewall'));


export const PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_PATH = '/protection/adaptiveFirewall/settings';

const PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_SCENE = createScene({
    component: AdaptiveFirewall,
    path: PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_PATH,
    title: 'scenes:scenes.Protection.scenes.AdaptiveFirewall.scenes.Settings.title',
});


const scenes = () => {
    return [
        PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_SCENE
    ];
};

export default scenes;
