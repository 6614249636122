/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { call, put, takeLatest, select, fork } from 'redux-saga/effects';
import moment from 'moment';

import { getApiError } from '../../lib/apiUtils';
import { promiseSetTimeout } from '../../lib/timeUtils';
import { getIsResetPassword, LOGIN_SUCCESS } from '../userAuthentication/ducks/login';


const LOAD_USERS_ACTIVE_SESSIONS_REQUEST = 'ak/sessionsManagement/LOAD_USERS_ACTIVE_SESSIONS_REQUEST';
const LOAD_USERS_ACTIVE_SESSIONS_SUCCESS = 'ak/sessionsManagement/LOAD_USERS_ACTIVE_SESSIONS_SUCCESS';
const LOAD_USERS_ACTIVE_SESSIONS_FAILURE = 'ak/sessionsManagement/LOAD_USERS_ACTIVE_SESSIONS_FAILURE';

const FORCE_SESSION_END_REQUEST = 'ak/sessionsManagement/FORCE_SESSION_END_REQUEST';
const FORCE_SESSION_END_SUCCESS = 'ak/sessionsManagement/FORCE_SESSION_END_SUCCESS';
const FORCE_SESSION_END_FAILURE = 'ak/sessionsManagement/FORCE_SESSION_END_FAILURE';

const SET_PASSWORD_RESET_TIMER_START = 'ak/sessionsManagement/SET_PASSWORD_RESET_TIMER_START';
const SET_PASSWORD_RESET_TIMER_START_FAILURE = 'ak/sessionsManagement/SET_PASSWORD_RESET_TIMER_START_FAILURE';

export const SET_SESSION_TERMINATE_REASON_REQUEST = 'ak/sessionsManagement/SET_SESSION_TERMINATE_REASON';
export const SET_SHOW_SESSION_TERMINATION_WARNING = 'ak/sessionsManagement/SET_SHOW_SESSION_TERMINATION_WARNING';

export const INCREMENT_SESSIONS_WATCHERS = 'ak/sessionsManagement/INCREMENT_SESSIONS_WATCHERS';
export const DECREMENT_SESSIONS_WATCHERS = 'ak/sessionaManagement/DECREMENT_SESSIONS_WATCHERS';

// initial state
export const initialState = {
    usersActiveSessions: {},
    showSessionTerminationWarning: false,
    isLoading: false,
    error: null,
    sessionsWatchers: 0,
    sessionEnd: {
        sessionTerminateReason: null,
        isLoading: false,
        error: null,
    },
    passwordResetTimerStart: null,
};


// reducer
export default (state = initialState, action) => {
    switch (action.type) {
    case LOAD_USERS_ACTIVE_SESSIONS_REQUEST:
        return { ...state, isLoading: true, usersActiveSessions: {}, error: null };
    case LOAD_USERS_ACTIVE_SESSIONS_SUCCESS:
        return { ...state, isLoading: false, usersActiveSessions: action.payload, error: null };
    case LOAD_USERS_ACTIVE_SESSIONS_FAILURE:
        return { ...state, isLoading: false, usersActiveSessions: {}, error: action.payload.message };
    case FORCE_SESSION_END_REQUEST:
        return {
            ...state,
            sessionEnd: {
                ...state.sessionEnd,
                isLoading: true
            }
        };
    case FORCE_SESSION_END_SUCCESS:
        return {
            ...state,
            sessionEnd: {
                ...state.sessionEnd,
                isLoading: false,
                error: null
            }
        };
    case FORCE_SESSION_END_FAILURE:
        return {
            ...state,
            sessionEnd: {
                ...state.sessionEnd,
                isLoading: false,
                error: action.payload.message
            }
        };
    case SET_SESSION_TERMINATE_REASON_REQUEST:
        return {
            ...state,
            sessionEnd: {
                ...state.sessionEnd,
                sessionTerminateReason: action.payload
            }
        };
    case SET_SHOW_SESSION_TERMINATION_WARNING:
        return {
            ...state,
            showSessionTerminationWarning: action.payload,
        };
    case INCREMENT_SESSIONS_WATCHERS:
        return {
            ...state,
            sessionsWatchers: state.sessionsWatchers + 1,
        };
    case DECREMENT_SESSIONS_WATCHERS:
        return {
            ...state,
            sessionsWatchers: state.sessionsWatchers > 0 ? state.sessionsWatchers - 1 : 0,
        };
    case SET_PASSWORD_RESET_TIMER_START:
        return {
            ...state,
            passwordResetTimerStart: action.payload ? moment(action.payload) : action.payload,
        };
    case SET_PASSWORD_RESET_TIMER_START_FAILURE:
        return {
            ...state,
            error: action.payload,
        };
    default:
        return state;
    }
};


// data accessors
const getRootState = state => state.sessionsManagement;

export const getIsLoading = state => getRootState(state).isLoading;
export const getError = state => getRootState(state).error;
export const getUsersActiveSessions = state => getRootState(state).usersActiveSessions;
export const getSessionTerminateReason = state => getRootState(state).sessionEnd.sessionTerminateReason;
export const getForceSessionEndIsLoading = state => getRootState(state).sessionEnd.isLoading;
export const getForceSessionEndError = state => getRootState(state).sessionEnd.error;
export const getShowSessionTerminationWarning = state => getRootState(state).showSessionTerminationWarning;
export const getPasswordResetTimerStart = state => getRootState(state).passwordResetTimerStart;

const getSessionsWatchers = state => getRootState(state).sessionsWatchers;


// action creators
export const loadUsersActiveSessions = () =>
    ({ type: LOAD_USERS_ACTIVE_SESSIONS_REQUEST });

const loadUsersActiveSessionsSuccess = payload =>
    ({ type: LOAD_USERS_ACTIVE_SESSIONS_SUCCESS, payload });

const loadUsersActiveSessionsFailure = payload =>
    ({ type: LOAD_USERS_ACTIVE_SESSIONS_FAILURE, payload });

export const forceSessionEnd = payload =>
    ({ type: FORCE_SESSION_END_REQUEST, payload });

const forceSessionEndSuccess = () =>
    ({ type: FORCE_SESSION_END_SUCCESS });

const forceSessionEndFailure = payload =>
    ({ type: FORCE_SESSION_END_FAILURE, payload });

export const setSessionTerminateReasonRequest = payload =>
    ({ type: SET_SESSION_TERMINATE_REASON_REQUEST, payload });

export const setShowSessionTerminationWarning = payload =>
    ({ type: SET_SHOW_SESSION_TERMINATION_WARNING, payload });

export const incrementSessionsWatchers = () =>
    ({ type: INCREMENT_SESSIONS_WATCHERS });

export const decrementSessionsWatchers = () =>
    ({ type: DECREMENT_SESSIONS_WATCHERS });

export const setPasswordResetTimerStart = payload =>
    ({ type: SET_PASSWORD_RESET_TIMER_START, payload });

const setPasswordResetTimerStartFailure = payload =>
    ({ type: SET_PASSWORD_RESET_TIMER_START_FAILURE, payload });


// API endpoints
export const apiCallLoadUsersActiveSessions = async () =>
    axios.get('/api/sessions/getUsersActiveSessions');

export const apiCallForceSessionEnd = async userAndReason =>
    axios.post('/api/sessions/forceSessionEnd', userAndReason);

const apiCallGetPasswordResetTimerStart = async () =>
    axios.get('/api/sessions/getPasswordResetTimerStart');

// side effects
export const workerLoadUsersActiveSessions = function* () {
    try {
        const { data } = yield call(apiCallLoadUsersActiveSessions);
        yield put(loadUsersActiveSessionsSuccess(data));
    } catch (error) {
        yield put(loadUsersActiveSessionsFailure(getApiError(error)));
    }
};

const workerForceSessionEnd = function* (action) {
    try {
        yield call(apiCallForceSessionEnd, action.payload);
        yield put(forceSessionEndSuccess());
    } catch (error) {
        yield put(forceSessionEndFailure(getApiError(error)));
    }
};

const workerGetPwdResetTimerStart = function* () {
    try {
        const isResetPassword = yield select(getIsResetPassword);
        if (isResetPassword) {
            const { data } = yield call(apiCallGetPasswordResetTimerStart);
            yield put(setPasswordResetTimerStart(data.passwordResetTimerStart));
        }
    } catch (error) {
        yield put(setPasswordResetTimerStartFailure(getApiError(error)));
    }
};

const sessionsWatchersWorker = function* () {
    while (true) {
        const watchers = yield select(getSessionsWatchers);
        if (watchers) {
            yield put(loadUsersActiveSessions());
        }
        yield promiseSetTimeout({ waitTime: 10 * 1000 });
    }
};

export const sagas = [
    takeLatest(LOGIN_SUCCESS, workerGetPwdResetTimerStart),
    takeLatest(LOAD_USERS_ACTIVE_SESSIONS_REQUEST, workerLoadUsersActiveSessions),
    takeLatest(FORCE_SESSION_END_REQUEST, workerForceSessionEnd),
    typeof window === 'undefined' ?
        undefined :
        fork(sessionsWatchersWorker),
];
