/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { SYSTEM_UPGRADE_STATE_AWAITING, SYSTEM_UPGRADE_STATE_DOWNLOADING, SYSTEM_UPGRADE_STATE_PREPARING,
} from '~commonLib/constants';
import { promiseSetTimeout } from '~frontendLib/timeUtils';
import { stopAllTask } from '~frontendRoot/saga/sagaMiddleware';
import { frontendReplace, createNotification } from '~frontendLib/reactUtils';
import { expectConnectionLossPromiseWrap } from '~sharedLib/apiUtils';

import { getApiError } from '../../lib/apiUtils';

// actions
const UPGRADE_STATE_REQUEST = 'ak/upgrade/UPGRADE_STATE_REQUEST';
const UPGRADE_STATE_SUCCESS = 'ak/upgrade/UPGRADE_STATE_SUCCESS';
const UPGRADE_STATE_FAIL = 'ak/upgrade/UPGRADE_STATE_FAIL';
const NEWER_VERSION_REQUEST = 'ak/upgrade/NEWER_VERSION_REQUEST';
const NEWER_VERSION_SUCCESS = 'ak/upgrade/NEWER_VERSION_SUCCESS';
const NEWER_VERSION_ERROR = 'ak/upgrade/NEWER_VERSION_ERROR';
const TESTER_OF_CHANGING_STATE = 'ak/upgrade/TESTER_OF_CHANGING_STATE';
const UPGRADE_REQUEST = 'ak/upgrade/UPGRADE_REQUEST';
const UPGRADE_SUCCESS = 'ak/upgrade/UPGRADE_SUCCESS';
const UPGRADE_FAIL = 'ak/upgrade/UPGRADE_FAIL';
const UPGRADE_CONFIRM = 'ak/upgrade/UPGRADE_CONFIRM';
const UPGRADE_COMMIT = 'ak/upgrade/UPGRADE_COMMIT';
const DOWNGRADE_COMMIT = 'ak/upgrade/DOWNGRADE_COMMIT';
const UPGRADE_DISCARD = 'ak/upgrade/UPGRADE_DISCARD';
const SEEN_UPGRADE_NOTICE_REQUEST = 'ak/upgrade/SEEN_UPGRADE_NOTICE_REQUEST';
const SEEN_UPGRADE_NOTICE_SUCCESS = 'ak/upgrade/SEEN_UPGRADE_NOTICE_SUCCESS';
const SEEN_UPGRADE_NOTICE_ERROR = 'ak/upgrade/SEEN_UPGRADE_NOTICE_ERROR';
const SET_CHANGELOG = 'ak/upgrade/SET_CHANGELOG';
const SET_CHANGELOG_ERROR = 'ak/upgrade/SET_CHANGELOG_ERROR';
const SET_CHANGELOG_SUCCESS = 'ak/upgrade/SET_CHANGELOG_SUCCESS';
const SET_SELECTED = 'ak/upgrade/SET_SELECTED';
const NEWER_VERSION_REQUEST_STOPPED = 'ak/upgrade/NEWER_VERSION_REQUEST_STOPPED';
const UPGRADE_STATE_SUCCESS_LIST = 'ak/upgrade/UPGRADE_STATE_SUCCESS_LIST';
const WARNINGS_TO_NEXT_VERSION = 'ak/upgrade/WARNINGS_TO_NEXT_VERSION';
const WARNINGS_TO_NEXT_VERSION_SUCCESS = 'ak/upgrade/WARNINGS_TO_NEXT_VERSION_SUCCESS';
const WARNINGS_TO_NEXT_VERSION_ERROR = 'ak/upgrade/WARNINGS_TO_NEXT_VERSION_ERROR';


// initial state
export const initialState = {
    upgradeState: {
        state: SYSTEM_UPGRADE_STATE_AWAITING,
    },
    loading: false,
    error: {},
    upgradeMessage: '',
    newerVersionState: {
        newVersionAvailable: false,
        newerVersion: {}
    },
    newerVersionError: {},
    newerVersionLoading: false,
    confirmingUpgradeNotice: false,
    changelog: [],
    changelogError: null,

    list: [],
    selected: '',

    warnings: [],
};


// reducer
export default (state = initialState, action) => {
    switch (action.type) {
    case UPGRADE_STATE_REQUEST:
        return {
            ...state,
            error: {},
            loading: true
        };
    case UPGRADE_STATE_SUCCESS:
        return {
            ...state,
            upgradeState: action.payload,
            list: action.list,
            loading: false,
        };
    case UPGRADE_STATE_SUCCESS_LIST:
        return {
            ...state,
            list: action.list,
            loading: false,
        };
    case UPGRADE_STATE_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false
        };
    case TESTER_OF_CHANGING_STATE:
        return {
            ...state,
            upgradeState: {
                ...state.upgradeState,
                state: action.payload,
            },
            loading: false
        };
    case NEWER_VERSION_REQUEST:
        return {
            ...state,
            newerVersionError: {},
            loading: true,
        };
    case NEWER_VERSION_REQUEST_STOPPED:
        return {
            ...state,
            loading: false,
        };
    case NEWER_VERSION_SUCCESS:
        return {
            ...state,
            newerVersionState: action.payload,
            loading: false,
        };
    case NEWER_VERSION_ERROR:
        return {
            ...state,
            newerVersionError: action.payload,
            loading: false,
        };
    case UPGRADE_REQUEST:
        return {
            ...state,
            error: {},
            loading: true
        };
    case UPGRADE_SUCCESS:
        return {
            ...state,
            error: {},
            loading: false,
            upgradeMessage: action.payload?.message
        };
    case UPGRADE_FAIL:
        return {
            ...state,
            error: action.payload,
            loading: false,
            upgradeMessage: ''
        };
    case UPGRADE_COMMIT:
    case UPGRADE_CONFIRM:
    case UPGRADE_DISCARD:
        return {
            ...state,
            loading: true
        };
    case SEEN_UPGRADE_NOTICE_REQUEST:
        return {
            ...state,
            confirmingUpgradeNotice: true
        };
    case SEEN_UPGRADE_NOTICE_SUCCESS:
    case SEEN_UPGRADE_NOTICE_ERROR:
        return {
            ...state,
            confirmingUpgradeNotice: false
        };
    case SET_CHANGELOG:
        return {
            ...state,
            changelog: [],
            changelogError: [],
        };
    case SET_CHANGELOG_SUCCESS:
        return {
            ...state,
            changelog: action.changelog
        };
    case SET_CHANGELOG_ERROR:
        return {
            ...state,
            changelogError: action.error
        };
    case SET_SELECTED:
        return {
            ...state,
            selected: action.payload
        };
    case WARNINGS_TO_NEXT_VERSION:
        return {
            ...state,
            loading: true
        };
    case WARNINGS_TO_NEXT_VERSION_SUCCESS:
        return {
            ...state,
            loading: false,
            warnings: action.payload
        };
    case WARNINGS_TO_NEXT_VERSION_ERROR:
        return {
            ...state,
            loading: false,
            error: action.error
        };
    default:
        return state;
    }
};


const getRootState = state => state.upgrade;

export const getUpgradeState = state => getRootState(state).upgradeState;
export const getIsUpgradeStateOfType = (state, type) => getRootState(state).upgradeState.state === type;

export const getIsUpgradeLoading = state => getRootState(state).loading;
export const getUpgradeError = state => getRootState(state).error;
export const getUpgradeMessage = state => getRootState(state).upgradeMessage;

export const getNewerVersionLoading = state => getRootState(state).newerVersionLoading;
export const getNewerVersionError = state => getRootState(state).newerVersionError;
export const getNewerVersionState = state => getRootState(state).newerVersionState;

export const getIsNewerVersionAvailable = state => getNewerVersionState(state).newVersionAvailable;
export const getNewerVersion = state => getNewerVersionState(state).newerVersion;
export const getConfirmingUpgradeNotice = state => getRootState(state).confirmingUpgradeNotice;
export const getChangelog = state => getRootState(state).changelog;
export const getAvailableVersions = state => getRootState(state).list;
export const getSelected = state => getRootState(state).selected;

export const getWarningsToNextVersion = state => getRootState(state).warnings;

// action creators

export const testerOfChangingState = (value) =>
    ({ type: TESTER_OF_CHANGING_STATE, value });

export const newerVersionRequest = () =>
    ({ type: NEWER_VERSION_REQUEST, });

export const newerVersionSuccess = (payload) =>
    ({ type: NEWER_VERSION_SUCCESS, payload });

export const newerVersionError = (payload) =>
    ({ type: NEWER_VERSION_ERROR, payload });

export const upgradeStateRequest = () =>
    ({ type: UPGRADE_STATE_REQUEST, });

export const upgradeStateSuccess = (payload, list) =>
    ({ type: UPGRADE_STATE_SUCCESS,  payload, list });

export const upgradeStateSuccessList = (list) =>
    ({ type: UPGRADE_STATE_SUCCESS_LIST, list });

export const upgradeStateFail = (payload) =>
    ({ type: UPGRADE_STATE_FAIL,  payload });

export const upgradeRequest = (payload) =>
    ({ type: UPGRADE_REQUEST, payload });

export const upgradeConfirm = () =>
    ({ type: UPGRADE_CONFIRM, });

export const upgradeDiscard = () =>
    ({ type: UPGRADE_DISCARD, });

export const upgradeCommit = (isFiveMinuteTimer) =>
    ({ type: UPGRADE_COMMIT, isFiveMinuteTimer });

export const upgradeFail = (payload) =>
    ({ type: UPGRADE_FAIL,  payload });

export const upgradeSuccess = (payload) =>
    ({ type: UPGRADE_SUCCESS,  payload });

export const setSelected = (payload) =>
    ({ type: SET_SELECTED,  payload });

export const downgradeCommit = () =>
    ({ type: DOWNGRADE_COMMIT });

export const seenUpgradeNotice = () =>
    ({ type: SEEN_UPGRADE_NOTICE_REQUEST });

export const seenUpgradeNoticeSuccess = () =>
    ({ type: SEEN_UPGRADE_NOTICE_SUCCESS });

export const seenUpgradeNoticeError = () =>
    ({ type: SEEN_UPGRADE_NOTICE_ERROR });

export const setChangelog = (error) =>
    ({ type: SET_CHANGELOG, error });

export const setChangelogError = (error) =>
    ({ type: SET_CHANGELOG_ERROR, error });

export const setChangelogSuccess = (changelog) =>
    ({ type: SET_CHANGELOG_SUCCESS, changelog });

export const stopRequest = () =>
    ({ type: NEWER_VERSION_REQUEST_STOPPED });

export const warningsToNextVersionRequest = () =>
    ({ type: WARNINGS_TO_NEXT_VERSION });

export const warningsToNextVersionSuccess = (payload) =>
    ({ type: WARNINGS_TO_NEXT_VERSION_SUCCESS, payload });

export const warningsToNextVersionError = (error) =>
    ({ type: WARNINGS_TO_NEXT_VERSION_ERROR, error });
// API endpoints

export const apiCallGetUpgradeState = async () => {
    return axios.get('/api/systemUpgrade/state');
};
export const apiCallGetAvailableVersions = async () => {
    return axios.get('/api/systemUpgrade/availableVersions');
};

export const apiCallGetChangelog = async () => {
    return axios.get('/api/systemUpgrade/changelog');
};

export const apiCallGetUpgrade = async (ignorePreparedVersion, specificVersion) => {
    return axios.post('/api/systemUpgrade/upgrade', { ignorePreparedVersion: Boolean(ignorePreparedVersion),
        specificVersion });
};

export const apiCallConfirmUpgrade = async () => {
    return axios.post('/api/systemUpgrade/confirm');
};

export const apiCallCommitUpgrade = async (isFiveMinuteTimer) => {
    //Backend is expecting minutes
    return expectConnectionLossPromiseWrap(
        axios.post('/api/systemUpgrade/commit', { timeout: isFiveMinuteTimer ? 5 : 20 })
    );
};

export const apiCallCommitDowngrade = async () => {
    return axios.post('/api/systemUpgrade/downgrade');
};

export const apiCallNewerVersion = async () => {
    return axios.get('/api/systemUpgrade/newerVersion');
};

export const apiCallSeenUpgradeNotice = async () => {
    return axios.post('/api/systemUpgrade/seenUpgradeNotice');
};

export const apiCallWarningsToNextVersion = async () => {
    return axios.get('/api/systemUpgrade/warningsToNextVersion');
};


// side effects
const workerUpgradeState = function* () {
    try {
        const { data } = yield call(apiCallGetUpgradeState);
        yield put(upgradeStateSuccess(data));
        if (data.state === SYSTEM_UPGRADE_STATE_DOWNLOADING || data.state === SYSTEM_UPGRADE_STATE_PREPARING) {
            yield call(promiseSetTimeout, { waitTime: 2000 });
            yield put(upgradeStateRequest());
        }
    } catch (error) {
        yield put(upgradeStateFail(getApiError(error)));
    }
};

const workerCommitUpgrade = function* (action) {
    try {
        yield call(apiCallCommitUpgrade, action.isFiveMinuteTimer);
        frontendReplace({
            first: { message: 'upgrade:tutorial.header' },
            second: { message: 'upgrade:tutorial.upgrading' },
            third: { message: 'upgrade:tutorial.manualReload' },
            reload: true
        }, true);
        yield call(stopAllTask);
    } catch (error) {
        yield put(upgradeFail(getApiError(error)));
    }
};

const workerCommitDowngrade = function* () {
    try {
        yield call(apiCallCommitDowngrade);
        frontendReplace(
            {
                first: { message: 'upgrade:tutorial.header' },
                second: { message: 'upgrade:tutorial.downgrading' },
                third: { message: 'upgrade:tutorial.manualReload' },
                reload: true
            },
            true
        );
        yield call(stopAllTask);
    } catch (error) {
        yield put(upgradeFail(getApiError(error)));
    }
};

const workerUpgrade = function* ({ payload }) {
    try {
        const { data } = yield call(apiCallGetUpgrade, payload?.ignorePreparedVersion, payload?.specificVersion);
        yield put(upgradeSuccess(data));
        yield put(upgradeStateRequest());
    } catch (error) {
        yield put(upgradeFail(getApiError(error)));
    } finally {
        // why was this here???
        // yield put(upgradeStateRequest());
    }
};

const workerConfirmUpgrade = function* () {
    try {
        createNotification({ title: 'upgrade:confirming.title',
            type: 'info' });
        const { data } = yield call(apiCallConfirmUpgrade);
        yield put(upgradeStateSuccess(data));
        yield put(upgradeStateRequest());
        createNotification({ title: 'upgrade:confirming.success',
            type: 'success' });
    } catch (error) {
        yield put(upgradeFail(getApiError(error)));
        createNotification({ title: 'upgrade:confirming.error',
            desc: getApiError(error).message,
            type: 'danger' });
    }
};

const workerDiscardUpgrade = function* () {
    try {
        createNotification({ title: 'upgrade:discarding.title',
            type: 'info' });
        const { data } = yield call(apiCallConfirmUpgrade);
        yield put(upgradeStateSuccess(data));
        yield put(upgradeStateRequest());
        createNotification({ title: 'upgrade:discarding.success',
            type: 'success' });
    } catch (error) {
        yield put(upgradeFail(getApiError(error)));
        createNotification({ title: 'upgrade:discarding.error',
            desc: getApiError(error).message,
            type: 'danger' });
    }
};

const workerChangelog = function* () {
    try {
        const { data } = yield call(apiCallGetChangelog);
        yield put(setChangelogSuccess(data));
    } catch (error) {
        yield put(setChangelogError(getApiError(error)));
    }
};

const workerNewerVersion = function* () {
    try {
        const selected = yield select(getSelected);
        if (selected) {
            yield put(stopRequest());
            return;
        }
        const { data } = yield call(apiCallNewerVersion);
        const list = yield call(apiCallGetAvailableVersions);
        yield put(upgradeStateSuccessList(list.data));
        yield put(newerVersionSuccess(data));
    } catch (error) {
        yield put(newerVersionError(getApiError(error)));
    }
};

const workerNewVersionWarnings = function* () {
    try {
        const { data } = yield call(apiCallWarningsToNextVersion);
        yield put(warningsToNextVersionSuccess(data));
    } catch (error) {
        yield put(warningsToNextVersionError(getApiError(error)));
    }
};
const workerSeenUpgradeNotice = function* () {
    try {
        const { data } = yield call(apiCallSeenUpgradeNotice);
        yield put(seenUpgradeNoticeSuccess(data));
    } catch (error) {
        createNotification({
            title: 'upgrade:upgradeNotice.error',
            type: 'danger',
            desc: getApiError(error).message,
        });
        yield put(seenUpgradeNoticeError(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(UPGRADE_STATE_REQUEST, workerUpgradeState),
    takeLatest(UPGRADE_REQUEST, workerUpgrade),
    takeLatest(UPGRADE_COMMIT, workerCommitUpgrade),
    takeLatest(DOWNGRADE_COMMIT, workerCommitDowngrade),
    takeLeading(UPGRADE_CONFIRM, workerConfirmUpgrade),
    takeLatest(NEWER_VERSION_REQUEST, workerNewerVersion),
    takeLeading(UPGRADE_DISCARD, workerDiscardUpgrade),
    takeLeading(SEEN_UPGRADE_NOTICE_REQUEST, workerSeenUpgradeNotice),
    takeLatest(SET_CHANGELOG, workerChangelog),
    takeLatest(WARNINGS_TO_NEXT_VERSION, workerNewVersionWarnings),

];
