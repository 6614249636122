/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import 'core-js'; // this has to be first
import 'regenerator-runtime/runtime'; // this has to be right after core-js
import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { I18nextProvider } from 'react-i18next';
import { connect, Provider } from 'react-redux';
import { StaticRouter } from 'react-router';
import ReactNotification from 'react-notifications-component';

import initializeI18n from '~sharedLib/initializeI18n';
import { FORCED_SESSION_END_REASONS, NOT_AUTHENTICATED } from '~sharedConstants';
import { getSessionTerminateReason } from '~frontendDucks/sessionsManagement';
import { setServerTimestamp } from '~frontendDucks/currentTime';

import { getInitialState } from './ducks';
import { getI18nStore, notifyMissingMessage } from './ducks/i18next';
import { setLocalization } from './ducks/language';
import { getIsErrorStatusCode } from './ducks/statusCode';
import { getIsGuiLoadingDone } from './ducks/userAuthentication/ducks/guiLoading';
import { getIsLoggedIn, logoutRequest } from './ducks/userAuthentication/ducks/login';
import { Application, LoginPage } from './layout';
import { setOnSubSagaError } from './lib/combineSagas';
import { getReactWrapper, registerInstanceI18n } from './lib/reactUtils';
import { createStoreFunc } from './store/storeLib';
import getStore from './store/store';
import { runGlobalTask, updateCurrentOnRootSagaError } from './saga/sagaMiddleware';
// On the client, initializes the single page application (SPA).
// On the server, exports a functions that renders the initial HTML for server-side rendering (SSR).

@hot
@connect(
    (state) => ({
        isErrorStatusCode: getIsErrorStatusCode(state),
        isGuiLoadingDone: getIsGuiLoadingDone(state),
        isLoggedIn: getIsLoggedIn(state),
    })
)
class MainElement extends Component {
    static get propTypes() {
        return {
            isGuiLoadingDone: PropTypes.bool,
            isLoggedIn: PropTypes.bool,
            isErrorStatusCode: PropTypes.bool,
        };
    }

    render() {
        const { isErrorStatusCode, isGuiLoadingDone, isLoggedIn } = this.props;
        if (!isErrorStatusCode && isGuiLoadingDone && isLoggedIn) {
            document.body.classList.add('loggedIn');
            window['__react-beautiful-dnd-disable-dev-warnings'] = true; //removes warning in console
            return <Application />;
        }
        return <LoginPage />;
    }
}

/**
 * Returns main React application element.
 *
 * @param {object} params
 * @param {object} params.history - instance of a history object
 * @param {object} params.instanceI18n - instance of i18next
 * @param {object} params.initialState - initial redux state
 * @param {object} params.reqUrl
 * @returns {Component}
 */
const RootElement = ({ history, instanceI18n, initialState, reqUrl }) => {
    const props = initialState;
    createStoreFunc(history, props);
    setLocalization(initialState, instanceI18n);
    const store = getStore();

    if (window.Cypress) { //testing
        window.appReady = true;
        window.store = store; // upgrade.cy.ts

    }

    axios.interceptors.response.use(function(response) {
        store.dispatch(setServerTimestamp(response.headers.date));
        return response;
    }, function(error) {
        const message = error?.response?.data?.message;
        const sessionTerminateReason = getSessionTerminateReason(store.getState());
        const isLoggedIn = getIsLoggedIn(store.getState());
        if (message in FORCED_SESSION_END_REASONS && !(sessionTerminateReason in FORCED_SESSION_END_REASONS)) {
            store.dispatch(logoutRequest({ alreadySignout: true, reason: message, statusCode: 401 }));

        }
        if (message === NOT_AUTHENTICATED && isLoggedIn) {
            store.dispatch(logoutRequest({ alreadySignout: true, reason: message, statusCode: 401 }));
        }
        return Promise.reject(error);
    });

    // rootSaga has to run on backend as well, otherwise the state would be slightly different due to state
    // initialization
    runGlobalTask();
    const ChosenRouter = reqUrl ? StaticRouter : ConnectedRouter;
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Provider store={store}>
                <I18nextProvider i18n={instanceI18n}>
                    <ChosenRouter
                        history={history}
                        location={reqUrl}
                    >
                        <MainElement />
                    </ChosenRouter>
                </I18nextProvider>
            </Provider>
            <ReactNotification />
        </Suspense>
    );
};

RootElement.propTypes = {
    history: PropTypes.object.isRequired,
    instanceI18n: PropTypes.object.isRequired,
    initialState: PropTypes.object.isRequired,
    reqUrl: PropTypes.string,
};


export const startUp = (paramsForInitialState) => {
    const history = createBrowserHistory();
    const initialState = getInitialState(paramsForInitialState, history);
    const options = {
        ...getI18nStore(initialState),
        saveMissing: true,
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            notifyMissingMessage(lng, ns, key, fallbackValue);
        }
    };
    const instanceI18n = initializeI18n([], options, false);
    registerInstanceI18n(instanceI18n);
    if (window.Cypress?.testingType === 'component') {
        return { history, initialState, instanceI18n };
    }

    const rootElement = (
        <RootElement
            history={history}
            initialState={initialState}
            instanceI18n={instanceI18n}
        />
    );
    const reactWrapper = getReactWrapper();
    if (initialState.useSSR) {
        ReactDOM.hydrate(rootElement, reactWrapper);
    } else {
        ReactDOM.render(rootElement, reactWrapper);
        removeLoadingPage(reactWrapper);
    }

};

const removeLoadingPage = (reactWrapper) => {
    const loadingPageSelector = ':scope > .loadingPage';
    // This shouldnt be needed, for us to explicitly remove the loadingPage, but it has been observed that in chrome,
    // the element is still there after refresh for unknown reason. So here we ensure that it is deleted.
    reactWrapper.querySelector(loadingPageSelector)?.remove();
    setTimeout(() => {
        // I am not sure what is happening. The loadingPage is in the html element (as logged by console log)
        // right after render, but this query selector returns null. So we wait a few milliseconds
        // and then reattempt removal. Sorry for the hacks. I do not know why it is like this... I can not do better :(
        reactWrapper.querySelector(loadingPageSelector)?.remove();
    }, 10);
    setTimeout(() => {
        reactWrapper.querySelector(loadingPageSelector)?.remove();
    }, 100);
};


if (typeof window !== 'undefined' && window.Cypress?.testingType !== 'component') {
    window.onload = startUp(window._PARAMS_FOR_INITIAL_STATE);
}

/**
 * Returns the entire application. Used on backend.
 *
 * @returns {object}
 */
const Frontend = ({ history, instanceI18n, initialState, reqUrl, onRootSagaError, onSubSagaError }) => {
    history.push(reqUrl);
    updateCurrentOnRootSagaError(onRootSagaError);
    setOnSubSagaError(onSubSagaError);
    return (
        <RootElement
            history={history}
            initialState={initialState}
            instanceI18n={instanceI18n}
            reqUrl={reqUrl}
        />
    );
};

Frontend.propTypes = {
    history: PropTypes.object.isRequired,
    instanceI18n: PropTypes.object.isRequired,
    initialState: PropTypes.object.isRequired,
    reqUrl: PropTypes.string.isRequired,
    onRootSagaError: PropTypes.func.isRequired,
    onSubSagaError: PropTypes.func.isRequired,
};

export default Frontend;
