/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import Svg from 'react-inlinesvg';


type SvgInlinerType = {
    className: string,
    height: string,
    isBackend: boolean,
    src: string,
    width: string
}

const SvgInliner = ({ className, height, isBackend, src, width }: SvgInlinerType) => {
    if (isBackend) {
        const html = src.replace(
            /<svg /,
            [
                '<svg',
                height ? `height="${height}"` : undefined,
                width ? `width="${width}"` : undefined,
                className ? `class="${className}"` : undefined,
            ].filter(item => item).join(' ') + ' '
        );
        return (
            <div
                className="svg-inliner"
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );
    }
    return (
        <Svg
            className={className}
            height={height}
            src={src}
            width={width}
        />
    );
};

export default SvgInliner;
