/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBDropdownToggle, MDBWaves, MDBDropdownMenu } from 'mdbreact';
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';

import logo from '~frontendRoot/img/svg/logo-gray.svg';
import { setHardwareInfoModalOpen } from '~frontendDucks/systemInfo';
import { DropdownWrap } from '~frontendComponents/Generic';
import Message from '~frontendComponents/Message';

import MenuDropdownItem from '../MenuDropdownItem';


@connect(
    () => ({}),
    {
        doSetHardwareInfoModalOpen: setHardwareInfoModalOpen
    }
)
class MenuDropdownDevice extends Component {
    static get propTypes() {
        return {
            // from connect:
            doSetHardwareInfoModalOpen: PropTypes.func,
        };
    }

    static defaultProps = {
        caret: true
    };

    constructor(props) {
        super(props);
        this.state = {
            cursorPos: {},
            open: false,
        };
    }

    handleClick = (event) => {
        event.stopPropagation();
        // Waves - Get Cursor Position
        const cursorPos = {
            top: event.clientY,
            left: event.clientX,
            time: Date.now() // time indicates particular clicks
        };

        this.setState({ cursorPos: cursorPos });
    };


    dropdownToggleClick = (event) => {
        event.stopPropagation();
        const {
            open
        } = this.state;
        this.setState({
            open: !open
        });
    };

    openModal = ({ name }) => {
        const { doSetHardwareInfoModalOpen } = this.props;
        doSetHardwareInfoModalOpen({ name, value: true });
    };

    render() {
        const { open } = this.state;
        return (
            <DropdownWrap
                className={classNames('dropdown', 'dropdown-item__wrap', open ? 'show' : '')}
                isOpen={open}
            >
                <MDBDropdownToggle
                    caret={true}
                    className="waves-effect device__menu--padding"
                    nav
                    onClick={this.dropdownToggleClick}
                    onMouseUp={this.handleClick}
                    onTouchStart={this.handleClick}
                    tag="button"
                >
                    <Svg
                        className="p-0 device__menuIcon"
                        height="24"
                        src={logo}
                        width="24"
                    />
                    <span><Message message={'widgets:device.title'} /></span>
                    <MDBWaves cursorPos={this.state.cursorPos} />
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MenuDropdownItem
                        icon="reload"
                        iconClassName="device__rebootIcon"
                        key="reboot"
                        message="widgets:device.reboot.title"
                        onClick={() => this.openModal({ name: 'rebootModal' })}

                    />
                    <MenuDropdownItem
                        icon="power"
                        key="power"
                        message="widgets:device.shutdown.title"
                        onClick={() => this.openModal({ name: 'shutdownModal' })}
                    />

                </MDBDropdownMenu>

            </DropdownWrap>
        );
    }
}

export default MenuDropdownDevice;
