/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';

import charts from './charts';
import { getTableName, getColumnName, getColumnVariant } from '../reportingUtils';
import { tableBySeverityComparator } from '../ChartSort';


const defineOrderOfCols = (cols, orderedCols) => {
    const newOrder = [];
    for (const colName of orderedCols) {
        for (const col of cols) {
            if (col === colName) {
                newOrder.push(col);
                break;
            }
        }
    }
    for (const col of cols) {
        if (!newOrder.includes(col)) {
            newOrder.push(col);
        }
    }
    return newOrder;
};

const defineOrderOfRows = (oldCols, newCols, rows) => {
    const newRows = [];
    const indexArray = [];
    for (const col of newCols) {
        indexArray.push(oldCols.indexOf(col));
    }
    for (const row of rows) {
        const newRow = [];
        for (const index of indexArray) {
            newRow.push(row[index]);
        }
        newRows.push(newRow);
    }
    return newRows;
};

const findIndexOfColumn = (columnName, cols) => {
    for (const col of cols) {
        if (col.name === columnName) {
            return col.index;
        }
    }
    return -1;
};

const ChartData = function({
    reporterTemplates, refreshResult, activeReportDefinition,
    activeReportUsage, frozenReportDefinition, frozenReportUsage, objFilters,
}) {
    this.refreshResult = refreshResult;

    this.reporterTemplates = reporterTemplates;
    this.activeReportDefinition = activeReportDefinition;
    this.activeReportUsage = activeReportUsage;
    this.frozenReportDefinition = frozenReportDefinition;
    this.frozenReportUsage = frozenReportUsage;
    this.objFilters = objFilters;

    if (!frozenReportDefinition) {
        throw new Error('Missing report definition');
    }

    this.additionalParameters = this.frozenReportDefinition.charts[0].config.additionalParameters || {};

    if (this.additionalParameters['columnOrder']) {
        const newColOrder = defineOrderOfCols(this.refreshResult['columns'], this.additionalParameters['columnOrder']);
        this.refreshResult['rows'] = defineOrderOfRows(
            this.refreshResult['columns'], newColOrder, this.refreshResult['rows']
        );
        this.refreshResult['columns'] = newColOrder;
    }

    this.noData = !refreshResult.columns || !refreshResult.rows;
    this.cols = (getValue(refreshResult, 'columns') || []).map(function(columnId) {
        const tableName = getTableName(columnId);
        const columnName = getColumnName(columnId);
        const columnVariant = getColumnVariant(columnId);
        return Object.assign(
            { variant: columnVariant },
            (getValue(reporterTemplates, 'columns.byName') || {})[tableName + '.' + columnName]
        );
    });

    this.cache = refreshResult.cache;
    this.containsMetricZero = false;
    this.rows = (getValue(refreshResult, 'rows') || []).map((row) => {
        const ret = row.slice();
        this.cols.forEach((objColumn, index) => {
            if (reporterTemplates.columnIsOfType(objColumn.name, 'integer') ||
                reporterTemplates.columnIsOfType(objColumn.name, 'duration'))
            {
                ret[index] = parseFloat(ret[index]);
            }
            if (reporterTemplates.columnIsOfType(objColumn.name, 'metric') &&
                !ret[index])
            {
                this.containsMetricZero = true;
            }
        });
        return ret;
    });


    this.categories = [];
    this.metrics = [];

    const indices = {
        category: 0,
        metric: 0
    };

    this.cols.forEach((col, colIndex) => {
        col.index = colIndex;
        if (this.categories.length === 0 && col.isCategory) {
            col.isFirstCategory = true;
        }
        if (!col.isCategory) {
            col.metricIndex = this.metrics.length;
        }
        (col.isCategory ? this.categories : this.metrics).push(
            this.cols[colIndex]
        );
    });

    if (this.activeReportDefinition.charts[0].config.type === 'table' && this.additionalParameters['sortBy']) {
        let colIndex;
        switch (this.additionalParameters['sortBy']) {
        case 'severity':
            colIndex = findIndexOfColumn('eve_e_alert.alert_metadata_sigseverity', this.cols);
            if (colIndex < 0) {
                throw new Error('trying to sort table by not present column');
            }
            this.rows.sort(tableBySeverityComparator(colIndex));
            break;
        default:
            throw new Error('sorting of table not implemented');
        }
    }

    this.paginated = {
        actualize: () => {
            const paginated = this.paginated;
            paginated.totalItems = this.table ? this.table.nRows : this.rows.length;
            paginated.pages = Math.ceil(paginated.totalItems / paginated.itemsPerPage);
            if (paginated.page > paginated.pages) {
                paginated.page = paginated.pages || 1;
            }
            paginated.from = Math.max(-1, (paginated.page - 1) * paginated.itemsPerPage);
            paginated.to = Math.max(0, Math.min(paginated.page * paginated.itemsPerPage, paginated.totalItems));
            const rowsTemp = this.rows.slice();
            paginated.rows = rowsTemp.slice(paginated.from, paginated.to);
        },
        orderBy: (columnIndex, isAscending) => {
            const paginated = this.paginated;
            paginated.orderedBy = [
                {
                    columnIndex: columnIndex,
                    isAscending: isAscending
                }
            ];
            paginated.orderedByCols = {};
            paginated.orderedByCols[columnIndex] = {
                isAscending: isAscending
            };
            paginated.actualize();
        },
        orderedBy: [],
        orderedByCols: {},
        from: Math.min(0, this.rows.length),
        to: Math.min(charts.ROWS_PER_PAGE_INITIAL, this.rows.length),
        itemsPerPage: charts.ROWS_PER_PAGE_INITIAL,
        page: 1,
        rows: this.rows.slice(0, charts.ROWS_PER_PAGE_INITIAL),
        totalItems: this.rows.length,
        pages: Math.ceil(this.rows.length / charts.ROWS_PER_PAGE_INITIAL)
    };


    this.indices = this.cols.map((col) => {
        return {
            isCategory: col.isCategory,
            index: indices[col.isCategory ? 'category' : 'metric']++
        };
    });
};

export default ChartData;
