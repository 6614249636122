/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';

import moment from '~commonLib/moment';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge';

import Highcharts from '../Highcharts';
import colValue from './colValue';
import colTitle from './colTitle';
import chartTypes from './chartTypes';
import generateColors from './generateColors';
import modals from './modals';
import reportTypes from './reportTypes';
import tFilter from './tFilter';
import vFilter from './vFilter';
import { getReporterTimeFrom, getReporterTimeTo } from './filterObjectTimeManipulator';


const getChartObject = function(chartContainer, index) {
    return chartContainer && chartContainer[index];
};

const getChartType = function(chartContainer, index) {
    return getValue(getChartObject(chartContainer, index), 'config.type');
};

const getChartAdditionalParameters = function(chartContainer, index) {
    return getValue(getChartObject(chartContainer, index), 'config.additionalParameters');
};

const getChartOverridingParameters = function(chartContainer, index) {
    return getValue(getChartObject(chartContainer, index), 'config.overridingParameters');
};

const ChartObject = function(chartData, dashboardDefinition, extraData) {
    this.error = null;
    this.data = chartData;
    this.indexInContainer = 0;
    this.dashboardDefinition = dashboardDefinition;
    this.useReportUsageChartType = !!dashboardDefinition;
    this.extraData = extraData;
    Object.defineProperty(this, 'config', {
        get: function() {
            return this.getSelectedChartCfgObject();
        }
    });
    const activeReportDefinitionChartType = getChartType(
        getValue(this.data, 'activeReportDefinition.charts'),
        this.indexInContainer
    );
    const availableChartTypes = this.getAvailableChartTypes();
    const isViable = availableChartTypes.filter(function(chartTypeObj) {
        return chartTypeObj.key === activeReportDefinitionChartType;
    })[0];
    if (isViable) {
        this.data.frozenReportDefinition.charts[
            this.indexInContainer
        ].config.type = activeReportDefinitionChartType;
    }
};

/**
 * Returns chart type of the frozen report definition.
 *
 * @memberof ChartObject
 * @returns {string}
 */
ChartObject.prototype.getReportDefinitionChartType = function() {
    return getChartType(
        getValue(this.data, 'frozenReportDefinition.charts'),
        this.indexInContainer
    );
};

/**
 * Returns chart type of the frozen report usage.
 *
 * @memberof ChartObject
 * @returns {string?}
 */
ChartObject.prototype.getReportUsageChartType = function() {
    return getChartType(
        getValue(this.data, 'frozenReportUsage.charts'),
        this.indexInContainer
    );
};

/**
 * Returns the selected chart configuration container.
 *
 * @memberof ChartObject
 */
ChartObject.prototype.getSelectedChartCfgContainer = function() {
    return this.useReportUsageChartType && getChartType(
        getValue(this.data, 'frozenReportUsage.charts'),
        this.indexInContainer
    ) ?
        getValue(this.data, 'frozenReportUsage.charts') :
        getValue(this.data, 'frozenReportDefinition.charts');
};

/**
 * Returns the selected chart configuration object.
 *
 * @memberof ChartObject
 */
ChartObject.prototype.getSelectedChartCfgObject = function() {
    return getChartObject(
        this.getSelectedChartCfgContainer(),
        this.indexInContainer
    );
};

/**
 * Returns the selected chart type.
 *
 * @memberof ChartObject
 * @returns {string}
 */
ChartObject.prototype.getSelectedChartType = function() {
    return getChartType(
        this.getSelectedChartCfgContainer(),
        this.indexInContainer
    );
};

ChartObject.prototype.getAdditionalParameters = function() {
    return getChartAdditionalParameters(
        this.getSelectedChartCfgContainer(),
        this.indexInContainer
    );
};

ChartObject.prototype.getOverridingParameters = function() {
    return getChartOverridingParameters(
        this.getSelectedChartCfgContainer(),
        this.indexInContainer,
    );
};

/**
 * Changes the selected chart type.
 *
 * @memberof ChartObject
 * @param {string?} chartType
 */
ChartObject.prototype.setChartType = function(chartType) {
    if (this.useReportUsageChartType) {
        this.data.activeReportUsage.charts[this.indexInContainer].config.type = chartType;
        this.data.frozenReportUsage.charts[this.indexInContainer].config.type = chartType;
    } else {
        this.data.activeReportDefinition.charts[this.indexInContainer].config.type = chartType;
        this.data.frozenReportDefinition.charts[this.indexInContainer].config.type = chartType;
    }
};

/**
 * Returns the number of categorical columns.
 *
 * @memberof ChartObject
 * @returns {number}
 */
ChartObject.prototype.getNumCategories = function() {
    const chartData = this.data;
    const reportCfg = chartData.frozenReportDefinition.report;
    const reportParams = reportCfg.params;
    const selectedReportType = reportCfg.type;
    return chartData ?
        chartData.categories.length :
        reportTypes[selectedReportType].getCategoryCols(reportParams).length;
};

/**
 * Returns the number of numerical columns.
 *
 * @memberof ChartObject
 * @returns {number}
 */
ChartObject.prototype.getNumMetrics = function() {
    const chartData = this.data;
    const reportCfg = chartData.frozenReportDefinition.report;
    const reportParams = reportCfg.params;
    const selectedReportType = reportCfg.type;
    return chartData ?
        chartData.metrics.length :
        reportTypes[selectedReportType].getMetricCols(reportParams).length;
};

/**
 * Returns the list of available chart types.
 *
 * @memberof ChartObject
 */
ChartObject.prototype.getAvailableChartTypes = function() {
    const chartData = this.data;
    const reportCfg = getValue(chartData, 'frozenReportDefinition.report');
    if (!reportCfg) {
        return [];
    }
    const reportParams = reportCfg.params;
    const selectedReportType = reportCfg.type;
    const nCats = this.getNumCategories(chartData, reportParams, selectedReportType);
    const nMets = this.getNumMetrics(chartData, reportParams, selectedReportType);
    return chartTypes.getAvailableChartTypes(selectedReportType, nCats, nMets);
};

/**
 * Returns fragment of a highchart object that contains properly set chart
 * type and related fields.
 *
 * @memberof ChartObject
 * @returns {object}
 */
ChartObject.prototype.getType = function() {
    const stacking = {
        'stacked_area': 'normal',
        'stacked_bar': 'normal',
        'stacked_column': 'normal'
    };
    const translateType = {
        'stacked_area': 'area',
        'stacked_bar': 'bar',
        'stacked_column': 'column',
        'polar_line': 'line',
        'polar_area': 'area',
        'polar_column': 'column'
    };
    const chartType = this.getSelectedChartType();
    return {
        chart: {
            polar: chartTypes.POLAR_CHART_TYPES.indexOf(chartType) !== -1,
            type: translateType[chartType] || chartType
        },
        plotOptions: {
            series: {
                stacking: stacking[chartType] ||
                    (this.isStacked() && 'normal') ||
                    (this.isPercentage() && 'percent')
            }
        }
    };
};

/**
 * Returns step between values.
 *
 * @memberof ChartObject
 * @param {string} columnName - name of the column
 * @returns {object|undefined} undefined or object with properties step and
 * epsilon
 */
ChartObject.prototype.getStep = function(columnName) {
    switch (this.data.reporterTemplates.columns.byName[columnName].type) {
    case 'time_second': return {
        step: 1000,
        epsilon: 0
    };
    case 'time_minute': return {
        step: 1000 * 60,
        epsilon: 0
    };
    case 'time_hour': return {
        step: 1000 * 60 * 60,
        epsilon: 0
    };
    case 'time_day': return {
        step: 1000 * 60 * 60 * 24,
        epsilon: 1000 * 60 * 60
    };
    case 'time_week': return {
        step: 1000 * 60 * 60 * 24 * 7,
        epsilon: 1000 * 60 * 60
    };
    default: return undefined;
    }
};

/**
 * Returns min and max value of the column or undefined when the column has
 * no limits.
 *
 * @memberof ChartObject
 * @param {string} columnName - name of the column
 * @returns {Object<string, number>|undefined} minimum and maximum value of
 * the column.
 */
ChartObject.prototype.getLimits = function(columnName) {
    switch (columnName) {
    case 'event.minute_of_hour': return { min: 0, max: 59 };
    case 'event.hour_of_day': return { min: 0, max: 23 };
    case 'event.day_of_week': return { min: 0, max: 6 };
    case 'event.day_of_month': return { min: 1, max: 31 };
    case 'event.day_of_year': return { min: 1, max: 365 };
    case 'event.week_of_month': return { min: 0, max: 4 };
    case 'event.week_of_year': return { min: 1, max: 50 };
    case 'event.month_of_year': return { min: 0, max: 11 };
    default: return undefined;
    }
};

/**
 * Returns the height of the chart.
 *
 * @memberof ChartObject
 * @param {number} items - number of items on the y axis
 * @returns {number}
 */
ChartObject.prototype.computeHeight = function(items) {
    return 150 + items * 18;
};

const tooltipOrderedBySeverity = (cols) => {
    const tmpResult = sortBySeverity(cols);
    const result = [];
    result.push(tmpResult[tmpResult.length - 1]);
    for (let index = 0; index < tmpResult.length - 1; ++index) {
        result.push(tmpResult[index]);
    }
    return result;
};

const getRowValue = (row, col, colIndex) => {
    if (!row) {
        return row;
    }
    if (col.index !== undefined) {
        return row[col.index];
    }
    if (colIndex !== undefined) {
        return row[colIndex];
    }
    return row;
};

/**
 * Returns formatted tooltip string.
 *
 * @memberof ChartObject
 * @param {Array.<string|number>} row - row to be formatted
 * @param {Array.<string|number>} sum - sum of all rows values
 * @param {boolean} sortBySeverity - sorts tooltip lines by severity
 * @param {Array.<string|number>} point - array of plot.y values to resolve +- point value during dataGrouping
 * @returns {string}
 */
ChartObject.prototype.tooltipFormatter = function(row, sum, sortBySeverity = false, point) {
    let columns = this.data.cols;
    const extraData = this.extraData;
    if (sortBySeverity) {
        columns = tooltipOrderedBySeverity(this.data.cols);
    }
    if (!row && !point) {
        return null;
    }

    const result = columns.map((col, colIndex) => {
        if (point && !point[colIndex]) {
            return undefined;
        }
        return colTitle(this.data.reporterTemplates, col) + ': <b>' +
            colValue(this.data.reporterTemplates, getRowValue(row || point,
                col, colIndex), col.name, undefined, extraData) +
            '</b>';
    }).filter(Boolean);
    if (sum) {
        result.push(tFilter('report:Tooltip.sum.title') + ': <b>' +
            colValue(this.data.reporterTemplates, sum, this.data.cols[this.data.cols.length - 1],
                undefined, extraData) +
            '</b>');
    }
    return result.join('<br>');
};

ChartObject.prototype.moreThanOneCategoryFormatter = function(hover, secondCategoriesVals) {

    const result = hover.points.map((point, pointIndex) => {
        return secondCategoriesVals[pointIndex] + ': <b>' +
            colValue(this.data.reporterTemplates, point.y, this.data.metrics[0]) +
            '</b>';
    });
    return '<b>' + colTitle(this.data.reporterTemplates, hover.points[0].x) + '</b>' + '<br>' + result.join('<br>');
};

/**
 * Returns formatted tooltip string. Used when the tooltipFormatter method
 * cannot be used.
 *
 * @memberof ChartObject
 * @param {object[]} rows - rows to be formatted
 * @returns {string}
 */
ChartObject.prototype.tooltipFormatterCustom = function(rows) {
    return rows.map((row) => {
        return colTitle(this.data.reporterTemplates, row.column) + ': <b>' +
            row.value + '</b>';
    }).join('<br>');
};

/**
 * Callback to when user clicks on a point in the chart.
 *
 * @memberof ChartObject
 * @param {Array.<string|number>} [row] - row that was clicked, can be
 * undefined when clicking on an empty cell of a heatmap
 */
ChartObject.prototype.pointClick = function(row) {
    if (!row) {
        return;
    }
    const additionalFilters = this.data &&
        this.data.objFilters;
    let differentFilters;
    let filterPlacement = 'report';
    if (additionalFilters) {
        if (additionalFilters.drilldown) {
            differentFilters = additionalFilters.drilldown;
            filterPlacement = 'drilldown';
        } else if (additionalFilters.myGlobal) {
            differentFilters = additionalFilters.myGlobal;
            filterPlacement = 'global';
        } else if (additionalFilters.dashboard) {
            differentFilters = additionalFilters.dashboard;
            filterPlacement = 'dashboard';
        }
    }
    modals.chartPointClick(
        this,
        row,
        filterPlacement,
        differentFilters,
        additionalFilters
    );
};

ChartObject.prototype.ensureCatsMets = function(numCats, numMetrics) {
    const chartType = this.getSelectedChartType();
    if (this.data.categories.length < numCats) {
        throw new Error(tFilter('report:chart.error-' + chartType));
    }
    if (this.data.metrics.length < numMetrics) {
        throw new Error(tFilter('report:chart.error-' + chartType));
    }
};

const getYAxis = function(isLogarithmic, isOpposite, extras) {
    const yAxis = {
        min: isLogarithmic ? 1 : 0,
        type: isLogarithmic ? 'logarithmic' : undefined,
        gridLineWidth: isOpposite ? 0 : undefined,
        opposite: isOpposite ? true : undefined
    };
    if (extras) {
        Object.keys(extras).forEach(function(key) {
            yAxis[key] = extras[key];
        });
    }
    return yAxis;
};

const getIsFn = function(keyCfg, keyStat, exclusiveWith) {
    return function() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this; // eslint-disable-line @babel/no-invalid-this
        const chartCfgObject = _this.getSelectedChartCfgObject();
        return (exclusiveWith || []).reduce(
            function(temp, item) {
                return temp && !_this[item]();
            },
            chartCfgObject.config[keyCfg] && chartTypes[keyStat][chartCfgObject.config.type]
        );
    };
};

ChartObject.prototype.isMultiaxis = getIsFn('isMultiaxis', 'MULTIAXIS_TYPES');
ChartObject.prototype.isParaaxis = getIsFn('isParaaxis', 'PARAAXIS_TYPES');
ChartObject.prototype.isPercentage = getIsFn('isPercentage', 'PERCENTAGE_TYPES');
ChartObject.prototype.isStacked = getIsFn('isStacked', 'STACKABLE_TYPES');

ChartObject.prototype.isSetToBeLogarithmic = getIsFn('isLogarithmic', 'LOGARITHMIC_TYPES');
ChartObject.prototype.canBeLogarithmic = function() {
    return !this.data || !this.data.containsMetricZero;
};
ChartObject.prototype.isLogarithmic = function() {
    return this.isSetToBeLogarithmic() && this.canBeLogarithmic();
};

ChartObject.prototype.getRowsMap = function(forcedRows, rowLimit, dataRows) {
    if (forcedRows) {
        return forcedRows.map.bind(forcedRows);
    }
    const skippedRows = {};
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    if (this.isLogarithmic()) {
        dataRows.forEach(function(row, rowIndex) {
            if (rowLimit && rowIndex >= rowLimit) {
                return;
            }
            _this.data.metrics.forEach(function(metCol) {
                if (!row[metCol.index]) {
                    skippedRows[rowIndex] = true;
                }
            });
        });
    }
    return (fn) => {
        return dataRows.map((row, rowIndex) => {
            if (rowLimit && rowIndex >= rowLimit) {
                return;
            }
            if (!row || skippedRows[rowIndex]) {
                return;
            }
            return fn(row, rowIndex);
        });
    };
};

const identity = item => item;

ChartObject.prototype.getRowLimit = function() {
    const chartCfgObject = this.getSelectedChartCfgObject();
    const typeObject = chartTypes.CHART_TYPES[chartCfgObject.config.type];
    return !chartCfgObject.config.showAllRows && typeObject && typeObject.maxRows;
};

const calculateSumAllSeries = (series) => {
    let sum = 0;
    for (const serie of series) {
        sum += calculateSumRow(serie);
    }
    return sum;
};

const calculateSumRow = (serie) => {
    let sum = 0;
    for (const number of serie.data) {
        sum += number;
    }
    return sum;
};

const calculateAverageRow = (serie) => {
    let sum = 0;
    let counter = 0;
    for (const number of serie.data) {
        sum += number;
        ++counter;
    }
    return counter !== 0 ? sum / counter : 0;
};

const calculateSumCol = (metrics, row) => {
    let sum = 0;
    metrics.forEach(function(col) {
        sum += row[col.index];
    });
    return sum;
};

const checkSpecialTypes = (chartType, thisObject) => {
    if (chartType === 'pie' || chartType === 'pyramid') {
        return thisObject.getDataPie();
    }
    if (chartType === 'dpie') {
        return thisObject.getDataDonut();
    }
    if (chartType === 'heatmap') {
        return thisObject.getDataHeatmap();
    }
    if (chartType === 'bubble') {
        return thisObject.getDataBubble();
    }
    return undefined;
};

const createSeriesParaaxis = ({ data, xAxisCategories, rowsMap, thisObject, fstCatCol }) => {
    const result = [];
    data.metrics.forEach(function(metCol) {
        xAxisCategories.push(metCol.title);
    });
    rowsMap((row) => {
        if (!row) {
            return;
        }
        const serie = {
            name: colValue(thisObject.data.reporterTemplates, row[fstCatCol.index], fstCatCol),
            yAxis: undefined, // multiple axes are unsupported
            data: []
        };
        data.metrics.forEach(function(metCol) {
            serie.data.push(row[metCol.index]);
        });
        result.push(serie);
    });
    return result;
};

const sortBySeverity = (arrayOfSeries) => {
    const sorted = [ null, null, null, null, null, null ];
    for (const serie of arrayOfSeries) {
        switch (serie.group) {
        case '["Critical"]':
            sorted[0] = serie;
            break;
        case '["Major"]':
            sorted[1] = serie;
            break;
        case '["Minor"]':
            sorted[2] = serie;
            break;
        case '["Informational"]':
            sorted[3] = serie;
            break;
        case '["Audit"]':
            sorted[4] = serie;
            break;
        case '<NULL>':
            sorted[5] = serie;
            break;
        default:
            sorted.push(serie);
        }
    }
    return sorted;
};

const createSerieGeneral = ({
    data, thisObject, isMultiaxis, series, isXAxisDatetime, datetimeStep, rowsMap, additionalParameters,
}) => {
    let result = [];
    data.metrics.forEach(function(col) {
        const serie = {
            name: colTitle(thisObject.data.reporterTemplates, col),
            yAxis: isMultiaxis ? series.length : undefined,
            data: [],
            pointStart: isXAxisDatetime ?
                vFilter(data.rows && data.rows[0] && data.rows[0][0], 'datetime_milliseconds') :
                undefined,
            pointInterval: isXAxisDatetime ? datetimeStep.step : undefined,
            group: col['group'],
            color: col.color ||
                undefined,
            fillOpacity: additionalParameters.fillOpacity,
        };
        rowsMap(function(row) {
            serie.data.push(row && row[col.index]); // because of isXAxisDatetime
        });
        if (additionalParameters.sumInLegend) {
            serie.name = serie.name + ': ' + colValue(thisObject.data.reporterTemplates, calculateSumRow(serie), col);
        }
        if (additionalParameters.averageInLegend) {
            serie.name = serie.name + ': ' +
                colValue(thisObject.data.reporterTemplates, calculateAverageRow(serie), col);
        }
        result.push(serie);
    });
    if (additionalParameters['sortBy']) {
        switch (additionalParameters['sortBy']) {
        case 'severity':
            result = sortBySeverity(result);
            break;
        case 'action':
            break;
        default:
            throw new Error('sorting not implemented');
        }
    }
    return result;
};

const createSeriesGeneral = (
    { data, thisObject, isMultiaxis, series, isXAxisDatetime, datetimeStep, rowsMap, additionalParameters }
) => {
    return createSerieGeneral(
        { data, thisObject, isMultiaxis, series, isXAxisDatetime, datetimeStep, rowsMap, additionalParameters }
    );
};

ChartObject.prototype.changeTitles = function() {
    const titles = this.data.refreshResult.severities || this.data.refreshResult.actions ||
        this.data.refreshResult.titles || this.data.refreshResult.interfaces;
    if (!titles) {
        return;
    }
    const extraData = this.extraData;

    const column = {
        type: 'titles',
        nullval: '',
        types: []
    };
    if (this.data.refreshResult.severities) {
        column.type = 'alert_severity';
        column.nullval = tFilter('reporter:values.alert_severity.null');
    }
    else if (this.data.refreshResult.interfaces) {
        column.type = 'iface_name_with_direction';
    }

    for (const key in titles) {
        if (this.data.cols[titles[key]]) {
            if (this.data.cols[titles[key]].types?.time) {
                continue;
            } else {
                this.data.cols[titles[key]].title = colValue(
                    this.data.reporterTemplates,
                    key,
                    column,
                    undefined,
                    extraData
                );
            }
        }
        if (this.data.metrics[titles[key] - 1]) {
            this.data.metrics[titles[key] - 1].group = key;
            this.data.metrics[titles[key] - 1].title = colValue(
                this.data.reporterTemplates,
                key,
                column,
                undefined,
                extraData
            );
        }
    }
};


/**
 * Returns fragment of a highchart object that contains properly set series
 * and related fields.
 *
 * @memberof ChartObject
 * @returns {object}
 */
ChartObject.prototype.getData = function() {
    const chartType = this.getSelectedChartType();
    const additionalParameters = this.getAdditionalParameters() || {};
    const overridingParameters = this.getOverridingParameters() || {};
    const isSpecialType = checkSpecialTypes(chartType, this);
    if (isSpecialType) {
        return isSpecialType;
    }
    const data = this.data;
    const extraData = this.extraData;
    const datetimeStep = data.categories.length && this.getStep(data.categories[0].name);
    let isXAxisDatetime = typeof datetimeStep === 'object';
    const rowLimit = this.getRowLimit();
    let rowsMap = this.getRowsMap(undefined, rowLimit, this.data.rows);

    this.changeTitles();
    if (isXAxisDatetime) {
        const dateTimeRows = [];
        let lastTime = null;
        rowsMap(function(row) {
            const thisTime = data.categories[0] && moment(row[data.categories[0].index]);
            if (lastTime) {
                let diffTime = thisTime.diff(lastTime);
                const modulo = diffTime % datetimeStep.step;
                if (diffTime <= 0 ||
                    (modulo > datetimeStep.epsilon &&
                        modulo < datetimeStep.step - datetimeStep.epsilon)) {
                    isXAxisDatetime = false;
                } else {
                    let time = lastTime;
                    while (diffTime > datetimeStep.step) {
                        time = time.add(datetimeStep.step, 'ms');
                        dateTimeRows.push([ time.format(), ...row.map(() => 0) ]);
                        diffTime -= datetimeStep.step;
                    }
                }
            }
            dateTimeRows.push(row);
            lastTime = thisTime;
        });
        rowsMap = this.getRowsMap(dateTimeRows, undefined, this.data.rows);
    }

    const rows = rowsMap(identity);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;

    const xAxisCategories = [];
    const series = [];
    const fstCatCol = data.categories[0];
    if (!fstCatCol) {
        return null;
    }

    const isLogarithmic = this.isLogarithmic();
    const isParaaxis = this.isParaaxis();
    const isMultiaxis = this.isMultiaxis();
    if (isParaaxis && !isXAxisDatetime) {
        const newSeries = createSeriesParaaxis({ data, xAxisCategories, rowsMap, fstCatCol, thisObject: this });
        for (const serie of newSeries) {
            series.push(serie);
        }
        const dateTimeRows = [];
        let lastTime = null;
        rowsMap(function(row) {
            const thisTime = data.categories[0] && moment(row[data.categories[0].index]);
            if (lastTime) {
                let diffTime = thisTime.diff(lastTime);
                const modulo = diffTime % datetimeStep.step;
                if (diffTime <= 0 ||
                    (modulo > datetimeStep.epsilon &&
                        modulo < datetimeStep.step - datetimeStep.epsilon)) {
                    isXAxisDatetime = false;
                } else {
                    while (diffTime > datetimeStep.step) {
                        dateTimeRows.push(null);
                        diffTime -= datetimeStep.step;
                    }
                }
            }
            dateTimeRows.push(row);
            lastTime = thisTime;
        });
    } else {
        if (!isXAxisDatetime) {
            rowsMap((row) => {
                xAxisCategories.push(
                    colValue(_this.data.reporterTemplates, row && row[fstCatCol.index], fstCatCol,
                        undefined, extraData)
                );
            });
        }

        const newSeries = createSeriesGeneral({
            data, isMultiaxis, series, isXAxisDatetime, datetimeStep, rowsMap,
            additionalParameters, thisObject: this
        });
        for (const serie of newSeries) {
            series.push(serie);
        }
        if (additionalParameters.title === 'sum') {
            const sum = colValue(_this.data.reporterTemplates, calculateSumAllSeries(series), data.metrics[0]);
            const serie = {
                name: tFilter('reporter:chart.sum.title') + sum,
                yAxis: undefined,
                data: [],
                pointStart: undefined,
                pointInterval: undefined,
                color: undefined,
                events: {
                    legendItemClick: function() {
                        return false;
                    }
                },
                marker: {
                    enabled: false,
                    height: 0,
                    width: 0,
                }
            };
            series.push(serie);
        }
    }


    let colors;
    if (!additionalParameters.colors || additionalParameters.colors.length < 1) {
        colors = generateColors(series.length);
    } else {
        colors = additionalParameters.colors;
    }
    series.forEach(function(series, seriesIndex) {
        if (!series.color) {
            series.color = colors[seriesIndex % colors.length];
        }
    });

    let height = overridingParameters?.chart?.height || 400;
    if (chartType === 'bar' || chartType === 'stacked_bar') {
        height = this.computeHeight(series[0] ? series[0].data.length : 0);

    }
    const isPolar = chartTypes.POLAR_CHART_TYPES.indexOf(chartType) !== -1;
    if (isPolar) {
        height = 750;
    }
    const yAxis = isMultiaxis ?
        data.metrics.map(function(metColumn, index) {
            return getYAxis(isLogarithmic, index, {
                labels: {
                    formatter: function() {
                        return colValue(_this.data.reporterTemplates, this.value, metColumn.name,
                            undefined, extraData);
                    }
                },
                title: {
                    text: metColumn.title
                }
            });
        }) :
        getYAxis(isLogarithmic, false, {
            labels: {
                formatter: function() {
                    return colValue(_this.data.reporterTemplates, this.value, data.metrics[0].name,
                        undefined, extraData);
                }
            },
            allowDecimals: false,
            title: {
                text: additionalParameters.yAxisTitle ?
                    tFilter(additionalParameters.yAxisTitle) :
                    data.metrics.length > 1 ?
                        undefined :
                        data.metrics[0].title
            },
            endOnTick: additionalParameters.endOnTick === false ? false : true
        });
    const time = _this.data.frozenReportUsage.clientOnly.time;
    const result = {
        chart: {
            zoomType: isXAxisDatetime ? 'x' : undefined,
            height: height,
            panning: true,
            panKey: 'shift',
            events: isLogarithmic ? {
                load: function() {
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const _this = this;
                    this.renderer.button('Log', 50, height - 40, function() {
                        this.attr({ class: '' }); //eslint-disable-line
                        this.addClass(_this.yAxis[0].isLog ? 'graphLogButton' : 'graphLogButton--active', true); //eslint-disable-line
                        _this.yAxis[0].update({ type: _this.yAxis[0].isLog ? 'linear' : 'logarithmic' });
                    }, null, null, null, null, null, true).css({
                        'cursor': 'pointer',
                        'font-weight': 'bold',
                    }).attr({ class: '' }).addClass('graphLogButton--active', true).add();
                }
            } : {}
        },
        xAxis: {
            categories: xAxisCategories.length === 0 ? undefined : xAxisCategories,
            type: isXAxisDatetime ? 'datetime' : 'linear',
            maxZoom: isXAxisDatetime ? datetimeStep.step : undefined,
            max: isXAxisDatetime && time ? getReporterTimeFrom(time.to).valueOf() : undefined,
            min: isXAxisDatetime && time ? getReporterTimeTo(time.from).valueOf() : undefined,
            allowDecimals: false,
            title: {
                text: isPolar || isParaaxis ? undefined : fstCatCol.title
            },
        },
        yAxis: yAxis,
        plotOptions: {
            series: {
                marker: {
                    enabled: additionalParameters.plotPoints,
                },
                point: {
                    events: {
                        click: function() {
                            _this.pointClick(rows[this.index]);
                        }
                    }
                },
                color: colors,
            }
        },
        series: series,
        tooltip: {
            shared: true,
            formatter: function() {
                const point = this.points ? this.points[0] && this.points[0].point : this.point;
                if (!point) {
                    return null;
                }
                // if (data.categories.length > 1 && additionalParameters.useMoreThanOneTooltip) {
                //     return _this.moreThanOneCategoryFormatter(this, secondCategoriesVals);
                // }
                return _this.tooltipFormatter(
                    rows[point.index],
                    additionalParameters.sumInTooltip ? calculateSumCol(data.metrics, rows[point.index]) : null,
                    additionalParameters.sortTooltipBySeverity,
                    !rows[point.index] && [ null, ...this.points.map(item => item.y) ]

                );
            }
        }
    };
    return result;
};

const getPieLabelFormatter = function(isInner) {
    return function() {
        const { percentage, name } = this.point; // eslint-disable-line @babel/no-invalid-this
        return percentage > (isInner ? 5 : 1) ?
            '<b>' + name + ': ' + vFilter(percentage / 100, 'percent') + '</b>' :
            null;
    };
};

const findMiddle = (additionalParameters, data) => {
    const middle = moment(data.cache.last_refreshed);
    switch (additionalParameters.middle.aggregation) {
    case 'days':
        middle.subtract(additionalParameters.middle.number, 'days');
        if (additionalParameters.middle.offset) {
            middle.subtract(additionalParameters.middle.offset, 'days');
        }
        return middle;
    case 'hours':
        middle.subtract(additionalParameters.middle.number, 'hours');
        if (additionalParameters.middle.offset) {
            middle.subtract(additionalParameters.middle.offset, 'hours');
        }
        return middle;
    default:
        throw new Error('aggregation ' + additionalParameters.middle.aggregation + ' is not implemented');
    }
};

export const createTrendNumber = (older, newer) => {
    return vFilter({ older, newer }, 'trendChartDiff');
};

const trendOnLoadOrRedraw = (chart, older, newer) => {
    const fontSize = Math.min(chart.renderer.width, chart.renderer.height) / 6;

    return chart.renderer.
        text(
            createTrendNumber(older, newer),
            chart.renderer.width / 2,
            chart.renderer.height / 2 + fontSize / 4,
        ).
        css({
            fontSize: fontSize + 'px',
            textAnchor: 'middle',
            color: '#666666',
        }).
        add();
};


/**
 * Returns fragment of a highchart object that contains properly set chart
 * type and related fields. Used when chart type is pie.
 *
 * @memberof ChartObject
 * @returns {object}
 */
ChartObject.prototype.getDataPie = function() {

    const data = this.data;
    const additionalParameters = this.getAdditionalParameters() || {};
    this.ensureCatsMets(1, 1);
    const catColumn = data.categories[0];
    const xColumn = data.metrics[0];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;

    const rowLimit = this.getRowLimit();
    const colors = generateColors(data.rows.length);
    const seriesData = [];

    let chartResizeWatcher;
    let insidePieText;

    if (additionalParameters.isTrendChart) {
        if (!additionalParameters.middle) {
            throw new Error('middle for this trend chart is not defined in additional parameters');
        }
        const middle = findMiddle(additionalParameters, data);
        let older = 0;
        let newer = 0;

        for (const row of data.rows) {
            const current = moment(row[0]);
            if (current.diff(middle) > 0) {
                newer += row[1];
            } else {
                older += row[1];
            }
        }
        data.rows = [
            [ 'older', older ],
            [ 'newer', newer ],
        ];
        this.data.cols[0].name = { type: 'trendPeriod' };
        this.data.cols[0].type = 'trendPeriod';
        this.data.cols[0].title = tFilter('report:Tooltip.trendChart.period.title');
        seriesData.push({
            name: colValue(_this.data.reporterTemplates, data.rows[0][1], data.cols[0]),
            y: older,
            color: additionalParameters.colors ? additionalParameters.colors.older || colors[0] : colors[0],
        });
        seriesData.push({
            name: colValue(_this.data.reporterTemplates, data.rows[1][1], data.cols[0]),
            y: newer,
            color: additionalParameters.colors ? additionalParameters.colors.newer || colors[1] : colors[1],
        });

        if (additionalParameters.title === 'trend') {
            chartResizeWatcher = {
                events: {
                    load: function() {
                        // eslint-disable-next-line @typescript-eslint/no-this-alias
                        const chart = this;
                        insidePieText = trendOnLoadOrRedraw(chart, older, newer);
                    },
                    redraw: function() {
                        // eslint-disable-next-line @typescript-eslint/no-this-alias
                        const chart = this;
                        if (insidePieText) {
                            insidePieText.destroy();
                        }
                        insidePieText = trendOnLoadOrRedraw(chart, older, newer);
                    }
                }
            };
        }

    } else {
        data.rows.forEach(function(row, rowIndex) {
            if (rowLimit && rowIndex >= rowLimit) {
                return;
            }
            seriesData.push({
                name: colValue(_this.data.reporterTemplates, row[catColumn.index], catColumn.name),
                y: row[xColumn.index],
                color: colors[rowIndex]
            });
        });
    }
    return {
        tooltip: {
            formatter: function() {
                return _this.tooltipFormatter(data.rows[this.point.index]);
            }
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: function() {
                            _this.pointClick(_this.data.rows[this.index]);
                        }
                    }
                },
            }
        },
        series: [
            {
                data: seriesData,
                dataLabels: {
                    formatter: getPieLabelFormatter(false)
                },
                type: this.getSelectedChartType()
            }
        ],
        chart: chartResizeWatcher,
    };
};

const donutBySeverityComparator = (assignPriorityToSeverity, asc = false) =>
    (first, second) => {
        const firstPriority = assignPriorityToSeverity(first.category, asc);
        const secondPriority = assignPriorityToSeverity(second.category, asc);
        if (firstPriority === secondPriority) {
            return asc ? first.sums[0] - second.sums[0] : second.sums[0] - first.sums[0];
        }
        return secondPriority - firstPriority;
    };

const donutRowsBySeverityComparator = (assignPriorityToSeverity, asc = false) =>
    (first, second) => {
        const firstPriority = assignPriorityToSeverity(first[0], asc);
        const secondPriority = assignPriorityToSeverity(second[0], asc);
        if (firstPriority === secondPriority) {
            return asc ? first[2] - second[2] : second[2] - first[2];
        }
        return secondPriority - firstPriority;
    };

/**
 * Returns fragment of a highchart object that contains properly set chart
 * type and related fields. Used when chart type is pie.
 *
 * @memberof ChartObject
 * @returns {object}
 */
ChartObject.prototype.getDataDonut = function() {
    const data = this.data;
    const additionalParameters = this.getAdditionalParameters() || {};

    this.ensureCatsMets(2, 1);
    this.computeDrilldown();
    const fstCatColumn = data.categories[0];
    const sndCatColumn = data.categories[1];
    const metricIndex = 0;
    const xColumn = data.metrics[metricIndex];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    const nCats = data.categories.length;
    const rowLimit = this.getRowLimit();


    const initSerie = function(isInner) {
        const catIndex = isInner ? 0 : 1;
        return {
            innerSize:
                isInner ?
                    (100 * catIndex / 2) + '%' :
                    (additionalParameters.innerSize || (100 * catIndex / 2)) + '%',
            size:
                isInner ?
                    (additionalParameters.smallerSize || (100 * (catIndex + 1) / 2)) + '%' :
                    (100 * (catIndex + 1) / 2) + '%',
            dataLabels: {
                formatter: getPieLabelFormatter(isInner),
                color: catIndex === nCats - 1 ? undefined : '#ffffff',
                distance: catIndex === nCats - 1 ? undefined : -30,
                enabled: additionalParameters.showLabels !== undefined ? additionalParameters.showLabels : true,
            },
            data: [],
            type: 'pie'
        };
    };

    const innerSeries = initSerie(true);
    const outerSeries = initSerie(false);

    const nFstRows = data.drilldown.rows.length;
    const colors = additionalParameters.colors || generateColors(nFstRows);

    const assignPriorityToSeverity = getAssignPriorityToSeverity();
    data.drilldown.rows = data.drilldown.rows.sort(donutBySeverityComparator(assignPriorityToSeverity));
    data.rows = data.rows.sort(donutRowsBySeverityComparator(assignPriorityToSeverity));

    data.drilldown.rows.forEach(function(fstLvl, fstIndex) {
        if (rowLimit && fstIndex >= rowLimit) {
            return;
        }
        innerSeries.data.push({
            name: colValue(_this.data.reporterTemplates, fstLvl.category, fstCatColumn.name),
            group: colValue(_this.data.reporterTemplates, fstLvl.category, fstCatColumn.name),
            y: fstLvl.sums[metricIndex],
            color: additionalParameters.colorsForSeries[fstLvl.category] || colors[fstIndex % colors.length]
        });
        const nSndRows = fstLvl.rows.length;
        fstLvl.rows.forEach(function(sndLvl, sndIndex) {
            if (rowLimit && sndIndex >= rowLimit) {
                return;
            }
            outerSeries.data.push({
                name: colValue(_this.data.reporterTemplates, sndLvl[sndCatColumn.index], sndCatColumn.name),
                group: colValue(_this.data.reporterTemplates, fstLvl.category, fstCatColumn.name),
                y: sndLvl[xColumn.index],
                color: Highcharts.Color(additionalParameters.colorsForSeries[fstLvl.category] ||
                    colors[fstIndex % colors.length]).brighten(0.1 - (sndIndex / nSndRows) / 5).get()
            });
        });
    });

    return {
        tooltip: {
            formatter: function() {
                if (this.series.index) {
                    const newRow = [
                        this.point.options.group,
                        this.point.options.name,
                        this.point.options.y,
                    ];
                    return _this.tooltipFormatter(newRow);
                }
                return _this.tooltipFormatterCustom([
                    {
                        column: fstCatColumn,
                        value: this.key,
                    },
                    {
                        column: xColumn,
                        value: colValue(_this.data.reporterTemplates, this.y, xColumn.name),
                    }
                ]);
            }
        },
        plotOptions: {
            pie: {
                shadow: false,
                center: [ '50%', '50%' ]
            },
            series: {
                point: {
                    events: {
                        click: function() {
                            _this.pointClick(_this.data.rows[this.index]);
                        }
                    }
                }
            }
        },
        series: [
            innerSeries,
            outerSeries
        ]
    };
};

/**
 * Returns fragment of a highchart object that contains properly set chart
 * type and related fields. Used when chart type is bubble.
 *
 * @memberof ChartObject
 * @returns {object}
 */
ChartObject.prototype.getDataBubble = function() {
    const data = this.data;
    this.ensureCatsMets(1, 3);
    const catColumn = data.categories[0];
    const xColumn = data.metrics[0];
    const yColumn = data.metrics[1];
    const zColumn = data.metrics[2];
    const dataMap = {};
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    const colors = generateColors(data.rows.length);
    const isLogarithmic = this.isLogarithmic();
    const series = [];
    const rowLimit = this.getRowLimit();
    data.rows.forEach(function(row, rowIndex) {
        if (rowLimit && rowIndex >= rowLimit) {
            return;
        }
        dataMap[row[catColumn.index]] = row;
        const xAxis = row[xColumn.index];
        const yAxis = row[yColumn.index];
        const zAxis = row[zColumn.index];
        if (!isLogarithmic || (xAxis && yAxis && zAxis)) {
            series.push({
                data: [ [ xAxis, yAxis, zAxis ] ],
                name: colValue(_this.data.reporterTemplates, row[catColumn.index], catColumn.name),
                color: colors[rowIndex]
            });
        }
    });
    return {
        chart: {
            type: 'bubble',
            zoomType: 'xy',
            height: 600,
            panning: true,
            panKey: 'shift'
        },
        legend: {
            maxHeight: 100
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: function() {
                            _this.pointClick(_this.data.rows[this.series.index]);
                        }
                    }
                }
            }
        },
        tooltip: {
            formatter: function() {
                return _this.tooltipFormatterCustom([
                    {
                        column: catColumn,
                        value: this.point.series.name,
                    },
                    {
                        column: xColumn,
                        value: colValue(_this.data.reporterTemplates, this.point.x, xColumn.name),
                    },
                    {
                        column: yColumn,
                        value: colValue(_this.data.reporterTemplates, this.point.y, yColumn.name),
                    },
                    {
                        column: zColumn,
                        value: colValue(_this.data.reporterTemplates, this.point.z, zColumn.name),
                    }
                ]);
            }
        },
        series: series,
        xAxis: {
            min: isLogarithmic ? 1 : 0,
            type: isLogarithmic ? 'logarithmic' : undefined,
            allowDecimals: false,
            title: {
                text: xColumn.title
            },
            labels: {
                formatter: function() {
                    return colValue(_this.data.reporterTemplates, this.value, xColumn.name);
                }
            },
        },
        yAxis: {
            min: isLogarithmic ? 1 : 0,
            type: isLogarithmic ? 'logarithmic' : undefined,
            allowDecimals: false,
            title: {
                text: yColumn.title
            },
            labels: {
                formatter: function() {
                    return colValue(_this.data.reporterTemplates, this.value, yColumn.name);
                }
            },
        }
    };
};

const LOG_10 = Math.log(10);

const roundToSignificant = function(value, nSignificantDigits) {
    if (!value) {
        return value;
    }
    const divider = Math.pow(10, Math.round(Math.log(Math.abs(value)) / LOG_10) - nSignificantDigits);
    return Math.round(value / divider) * divider;
};

/**
 * Returns fragment of a highchart object that contains properly set chart
 * type and related fields. Used when chart type is heatmap.
 *
 * @memberof ChartObject
 * @returns {object}
 */
ChartObject.prototype.getDataHeatmap = function() {
    const data = this.data;
    const extraData = this.extraData;
    this.ensureCatsMets(2, 1);
    const xColumn = data.categories[0];
    const yColumn = data.categories[1];
    const zColumn = data.metrics[0];
    const xIndices = {};
    const yIndices = {};
    const xLimits = this.getLimits(xColumn.name);
    const yLimits = this.getLimits(yColumn.name);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    const rowLimit = this.getRowLimit();
    const indicesToArray = function(indices, limits, column) {
        const indicesArray = Object.keys(indices).map(limits ? parseFloat : identity);
        if (column.type === 'hour_of_day') {
            return indicesArray.reverse();
        }
        return indicesArray;
    };
    const setIndices = function(limits, indices, column) {
        if (limits) {
            for (let index = limits.min; index <= limits.max; ++index) {
                indices[index] = { name: index };
            }
        } else {
            data.rows.forEach(function(row, rowIndex) {
                if (rowLimit && rowIndex >= rowLimit) {
                    return;
                }
                indices[row[column.index]] = { name: row[column.index] };
            });
        }
        indicesToArray(indices, limits, column).forEach(function(key, keyIndex) {
            indices[key] = keyIndex;
        });
    };
    setIndices(xLimits, xIndices, xColumn);
    setIndices(yLimits, yIndices, yColumn);
    const xIndicesArray = indicesToArray(xIndices, xLimits, xColumn);
    const yIndicesArray = indicesToArray(yIndices, yLimits, yColumn);
    const seriesData = [];
    xIndicesArray.forEach(function(xAxis, xIndex) {
        yIndicesArray.forEach(function(yAxis, yIndex) {
            seriesData.push([
                xIndex,
                yIndex,
                0,
                0
            ]);
        });
    });
    const transformDataParam = 0.75;
    const transformData = function(value) {
        return Math.pow(value, transformDataParam) / Math.pow(10, transformDataParam);
    };
    const untransformData = function(value) {
        return Math.pow(value * Math.pow(10, transformDataParam), 1 / transformDataParam);
    };
    data.rows.forEach(function(row) {
        const xIndex = xIndices[row[xColumn.index]];
        const yIndex = yIndices[row[yColumn.index]];
        seriesData[xIndex * yIndicesArray.length + yIndex] = [
            xIndex,
            yIndex,
            transformData(row[zColumn.index]),
            row[zColumn.index]
        ];
        seriesData[xIndex * yIndicesArray.length + yIndex].row = row;
    });
    const highchart = {
        boost: {
            useGPUTranslations: true
        },
        chart: {
            height: this.computeHeight(yIndicesArray.length),
            type: 'heatmap',
        },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: '#f6791f',
            labels: {
                formatter: function() {
                    return colValue(
                        _this.data.reporterTemplates,
                        roundToSignificant(untransformData(this.value), 2),
                        zColumn.name,
                        { precision: 0, isShort: true },
                        extraData
                    );
                }
            }
        },
        legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top',
            y: 25,
            symbolHeight: 320
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: function() {
                            _this.pointClick(seriesData[this.x * yIndicesArray.length + this.y].row);
                        }
                    }
                }
            }
        },
        series: [
            {
                name: zColumn.name,
                borderWidth: 0,
                data: seriesData,
                turboThreshold: Number.MAX_VALUE
            }
        ],
        tooltip: {
            formatter: function() {
                const row = seriesData[this.point.x * yIndicesArray.length + this.point.y];
                return _this.tooltipFormatterCustom([
                    {
                        column: xColumn,
                        value: colValue(_this.data.reporterTemplates, xIndicesArray[row[0]],
                            xColumn.name, undefined, extraData),
                    },
                    {
                        column: yColumn,
                        value: colValue(_this.data.reporterTemplates, yIndicesArray[row[1]],
                            yColumn.name, undefined, extraData),
                    },
                    {
                        column: zColumn,
                        value: colValue(_this.data.reporterTemplates, row[3], zColumn.name, undefined, extraData),
                    },
                ]);
            }
        },
        xAxis: {
            categories: xIndicesArray.map(function(val) {
                return colValue(_this.data.reporterTemplates, val, xColumn.name, undefined, extraData);
            }),
            title: {
                text: xColumn.title
            }
        },
        yAxis: {
            categories: yIndicesArray.map(function(val) {
                return colValue(_this.data.reporterTemplates, val, yColumn.name, undefined, extraData);
            }),
            title: {
                text: yColumn.title
            }
        }
    };
    return highchart;
};

ChartObject.prototype.formatSeverityValues = function(dataRows, dataCategories, severityIndex) {
    const severityColumn = {
        type: 'alert_severity',
        nullval: tFilter('reporter:values.alert_severity.null'),
        types: [],
    };

    if (severityIndex) {
        for (const row of dataRows) {
            row[severityIndex] = colValue(this.data.reporterTemplates, row[severityIndex], severityColumn);
        }
    }
};

const getAssignPriorityToSeverity = () => {
    // cache the translated values to prevent calling tFilter multiple times for each comparison
    const tCritical = tFilter('reporter:values.alert_severity.critical');
    const tMajor = tFilter('reporter:values.alert_severity.major');
    const tMinor = tFilter('reporter:values.alert_severity.minor');
    const tInformational = tFilter('reporter:values.alert_severity.informational');
    const tAudit = tFilter('reporter:values.alert_severity.audit');
    const tNull = tFilter('reporter:values.alert_severity.null');
    return (candidate, asc) => {
        switch (candidate) {
        case '["Critical"]':
        case tCritical:
            return asc ? 0 : 6;
        case '["Major"]':
        case tMajor:
            return asc ? 1 : 5;
        case '["Minor"]':
        case tMinor:
            return asc ? 2 : 4;
        case '["Informational"]':
        case tInformational:
            return 3;
        case '["Audit"]':
        case tAudit:
            return asc ? 4 : 2;
        case '<NULL>':
        case tNull:
            return asc ? 5 : 1;
        default:
            return asc ? 6 : 0;
        }
    };
};

const tableBySeverityComparator =
    (assignPriorityToSeverity, severityIndex, asc = false, secondaryIndex = 0, secondary = false) =>
        (first, second) => {
            const firstPriority = assignPriorityToSeverity(first[severityIndex], asc);
            const secondPriority = assignPriorityToSeverity(second[severityIndex], asc);
            if (secondary && secondPriority === firstPriority) {
                return asc ?
                    first[secondaryIndex] - second[secondaryIndex] :
                    second[secondaryIndex] - first[secondaryIndex];
            }
            return secondPriority - firstPriority;
        };

ChartObject.prototype.computeTable = function(dataRows, dataCategories, dataMetrics) {
    this.severityIndex = -1;
    for (const category of dataCategories) {
        if (category.name === 'eve_e_alert.alert_metadata_sigseverity') {
            this.severityIndex = category.index;
            break;
        }
    }
    this.eventsIndex = -1;
    if (dataMetrics && dataMetrics.length !== 0) {
        this.eventsIndex = dataMetrics[0].index;
    }

    this.formatSeverityValues(dataRows, dataCategories, this.severityIndex);

    const additionalParameters = this.getAdditionalParameters() || {};
    const assignPriorityToSeverity = getAssignPriorityToSeverity();

    if (additionalParameters.sortBy) {
        switch (additionalParameters.sortBy) {
        case 'severity':
            if (this.severityIndex === -1) {
                throw new Error('trying to sort by severity, but no severity category is defined');
            }
            if (this.eventsIndex !== -1) {
                dataRows.sort(
                    tableBySeverityComparator(
                        assignPriorityToSeverity,
                        this.severityIndex,
                        false,
                        this.eventsIndex,
                        true
                    )
                );
            } else {
                dataRows.sort(tableBySeverityComparator(assignPriorityToSeverity, this.severityIndex));
            }
            break;
        case 'action':
            if (this.eventsIndex !== -1) {
                dataRows.sort(
                    tableBySeverityComparator(
                        assignPriorityToSeverity,
                        this.severityIndex,
                        false,
                        this.eventsIndex,
                        true
                    )
                );
            } else {
                dataRows.sort(tableBySeverityComparator(assignPriorityToSeverity, this.severityIndex));
            }
            break;
        default:
            throw new Error('trying to sort by ' + additionalParameters.sortBy + ' but comparator is not implemented');
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;

    const table = {
        isDrilldown: false,
        orderBy: function(paginated) {
            if (!paginated) {
                return;
            }
            const orderedBy = paginated.orderedBy;

            if (!orderedBy) {
                return;
            }
            const catIndices = _this.data.cols;
            let sortingColumn;
            for (const col of catIndices) {
                if (col.index === orderedBy[0].columnIndex) {
                    sortingColumn = col;
                }
            }
            if (sortingColumn.name === 'eve_e_alert.alert_metadata_sigseverity') {
                dataRows.sort(
                    tableBySeverityComparator(assignPriorityToSeverity, sortingColumn.index, orderedBy[0].isAscending)
                );
            } else {

                dataRows.sort((left, right) => {
                    for (let index = 0, len = orderedBy.length; index < len; ++index) {
                        // keep this consistent with getAddRow() in
                        // report-drawer-service
                        const itemOrdered = orderedBy[index];
                        const colIndex = catIndices[itemOrdered.columnIndex].index;
                        if (left[colIndex] < right[colIndex]) {
                            return itemOrdered.isAscending ? -1 : 1;
                        }
                        if (left[colIndex] > right[colIndex]) {
                            return itemOrdered.isAscending ? 1 : -1;
                        }
                    }
                    return 0;
                });
            }
        },
        getRowGetter: function(fromIndex) {
            let rowIndex = 0;
            return {
                getNextRow: function() {
                    const row = dataRows[fromIndex + rowIndex];
                    ++rowIndex;
                    return row;
                },
                getRowNumbering: function() {
                    return fromIndex + rowIndex + 1;
                },
                getOuterRow: function() { },
                isOuterRow: function() {
                    return false;
                }
            };
        },
        refreshNRows: function() {
            table.nRows = dataRows.length;
        },
        toggleExpand: function() { throw new Error('Unreached'); }
    };
    table.refreshNRows();
    this.data.table = table;
    return null;
};

// computes standard score
const computeZscore = function(value, mean, stdev) {
    return stdev ? (value - mean) / stdev : 0;
};

// computes mean
const computeMean = function(values) {
    return values.length ? values.reduce(function(temp, val) {
        return temp + val;
    }, 0) / values.length : 0;
};

// computes standard deviation
const computeStdev = function(values, mean) {
    return values.length > 1 ? Math.sqrt(values.reduce(function(temp, val) {
        return temp + (val - mean) * (val - mean);
    }, 0) / (values.length - 1)) : 0;
};

ChartObject.prototype.computeDrilldownAnomalies = function() {
    this.computeDrilldown();
    const newDataRows = [];
    this.data.drilldown.rows.forEach(function(firstLevel) {
        const values = firstLevel.rows.map(function(secondLevel) {
            return secondLevel[2];
        });
        const mean = computeMean(values);
        const stdev = computeStdev(values, mean);
        firstLevel.rows.map(function(secondLevel) {
            const value = secondLevel[2];
            const zscore = computeZscore(value, mean, stdev);
            if (zscore > 10) {
                newDataRows.push(secondLevel);
                // console.log('anomalous row', secondLevel, 'zscore', zscore);
            }
        });
    });
    // this.data.rows = newDataRows;
    this.computeTable(newDataRows, this.data.categories, this.data.metrics);
    this.data.paginated.actualize();
    return null;
};

ChartObject.prototype.computeDrilldown = function() {
    const data = this.data;
    const rows = [];
    if (!data.drilldown) {
        data.drilldown = {
            cols: data.cols,
            firstLevelCats: data.categories.slice(1),
            rows: rows,
            sums: data.metrics.map(function() { return 0; })
        };
        let metricIndex = 0;
        data.cols.forEach(function(col) {
            if (!col.isCategory) {
                col.metricIndex = metricIndex++;
            }
        });
        const indices = {};
        let indicesLength = 0;
        this.data.rows.forEach(function(row) {
            if (!(row[0] in indices)) {
                indices[row[0]] = indicesLength++;
                rows.push({
                    category: row[0],
                    sums: data.metrics.map(function() { return 0; }),
                    rows: [],
                    isExpanded: false,
                    index: rows.length
                });
            }
            const innerRows = rows[indices[row[0]]];
            innerRows.rows.push(row);
            data.metrics.forEach(function(column, columnIndex) {
                innerRows.sums[columnIndex] += row[column.index];
                data.drilldown.sums[columnIndex] += row[column.index];
            });
            innerRows.row = [
                innerRows.category
            ].concat(
                data.categories.slice(1).map(function() {
                    const count = innerRows.rows.length;
                    const textItems = tFilter('report:chart.items_interval', { count: count, postProcess: 'interval' });
                    return '(' + count + ' ' + textItems + ')';
                }),
                innerRows.sums
            );
        });
    }
    data.table = {
        isDrilldown: true,
        orderBy: function() { },
        getRowGetter: function(fromIndex) {
            let outerIndex = 0;
            let innerIndex = 0;
            let flatIndex = 0;
            (function() {
                const nOuterRows = data.drilldown.rows.length;
                while (outerIndex < nOuterRows) {
                    const outerRow = data.drilldown.rows[outerIndex];
                    if (outerRow.isExpanded) {
                        if (flatIndex === fromIndex) {
                            return;
                        }
                        ++flatIndex;
                        ++innerIndex;
                        const nInnerRows = outerRow.rows.length;
                        while (innerIndex <= nInnerRows) { // this loop may be optimized out
                            if (flatIndex === fromIndex) {
                                return;
                            }
                            ++innerIndex;
                            ++flatIndex;
                        }
                    } else {
                        if (flatIndex === fromIndex) {
                            return;
                        }
                        ++flatIndex;
                    }
                    innerIndex = 0;
                    ++outerIndex;
                    if (flatIndex === fromIndex) {
                        return;
                    }
                }
            })(); // TODO why though?
            return {
                getNextRow: function() {
                    const outerRow = data.drilldown.rows[outerIndex];
                    if (!outerRow) {
                        return outerRow;
                    }
                    if (outerRow.isExpanded) {
                        const result = innerIndex ? outerRow.rows[innerIndex - 1] : outerRow.row;
                        ++innerIndex;
                        if (innerIndex > outerRow.rows.length) {
                            ++outerIndex;
                            innerIndex = 0;
                        }
                        return result;
                    } else {
                        ++outerIndex;
                        innerIndex = 0;
                        return outerRow.row;
                    }
                },
                isOuterRow: function() {
                    return innerIndex === 0;
                },
                getRowNumbering: function(isOuter) {
                    return (outerIndex + 1) + (!isOuter && innerIndex ? '/' + innerIndex : '');
                },
                getOuterRow: function() {
                    return data.drilldown.rows[outerIndex];
                }
            };
        },
        refreshNRows: function() {
            data.table.nRows = data.drilldown.rows.map(function(row) {
                return row.isExpanded ? row.rows.length + 1 : 1;
            }).reduce((left, right) => left + right, 0);
        },
        toggleExpand: function(outerRow) {
            if (!outerRow) {
                return;
            }
            outerRow.isExpanded = !outerRow.isExpanded;
            data.table.refreshNRows();
            data.paginated.actualize();
        },
        forceExpansion: function(isExpanded) {
            data.drilldown.rows.forEach(function(row) {
                row.isExpanded = isExpanded;
                data.table.refreshNRows();
                data.paginated.actualize();
            });
        }
    };
    data.table.refreshNRows();
    data.paginated.actualize();
    return null;
};

const DEFAULT_HIGHCHART_OPTIONS = {
    chart: {},
    title: {
        text: ''
    },
    subtitle: {},
    xAxis: {
        title: {
            text: ''
        }
    },
    yAxis: {
        title: {
            text: ''
        }
    },
    plotOptions: {
        bar: {
            groupPadding: 0,
            pointPadding: 0
        },
        bubble: {},
        column: {
            groupPadding: 0,
            pointPadding: 0
        },
        heatmap: {},
        pie: {
            allowPointSelect: false
        },
        series: {
            turboThreshold: Number.MAX_SAFE_INTEGER,
        },
    },
    exporting: {
        enabled: false
    }
};

/** Documentation inherited from MetaSerializable. */
ChartObject.prototype.serialize = function() {
    if (!this.data) {
        return null;
    }
    const chartType = this.getSelectedChartType();
    if (chartType === 'drilldown') {
        return this.computeDrilldown();
    }
    if (chartType === 'drilldown_anomalies') {
        return this.computeDrilldownAnomalies();
    }
    if (chartType === 'table') {
        return this.computeTable(this.data.rows, this.data.categories, this.data.metrics);
    }
    const highchartsOptions = this.getData();
    if (!highchartsOptions) {
        return highchartsOptions;
    }
    const overridingParameters = this.getOverridingParameters() || {};
    return deepCloneAndMerge(DEFAULT_HIGHCHART_OPTIONS, this.getType(), highchartsOptions, overridingParameters);
};

/**
 * Draws chart into the container.
 *
 * @memberof ChartObject
 */
ChartObject.prototype.draw = function(container, callback) {
    if (typeof callback !== 'function') {
        callback = function() { };
    }
    this.error = null;
    if (!this.data) {
        return callback(new Error('No data'));
    }
    let highchart;
    try {
        highchart = this.serialize();
    } catch (error) {
        this.error = error;
        return callback(error);
    }
    if (!highchart) {
        return callback(null);
    }
    const height = highchart && highchart.chart && highchart.chart.height;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    container.height = height || 400;
    // wait until the container height is adjusted
    setTimeout(function() {
        try {
            Highcharts.chart(container, highchart);
        } catch (err) {
            _this.error = err;
            return callback(err);
        }
        callback();
    });
};

export default ChartObject;
