/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from '~commonLib/moment';


// actions
const SET_TIMESTAMP = 'ak/currentTime/SET_TIMESTAMP';
export const SET_SERVER_TIMESTAMP = 'ak/currentTime/SET_SERVER_TIMESTAMP';


// initial state
const initialState = {
    timestamp: null,
    serverTimestamp: null
};


// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
    case SET_TIMESTAMP:
        return {
            ...state,
            timestamp: action.payload,
        };
    case SET_SERVER_TIMESTAMP: // THIS ACTION IS HIDDEN IN DEVTOOLS!
        return {
            ...state,
            timestamp: moment(),
            serverTimestamp: action.payload ? moment(action.payload) : action.payload,
        };
    default:
        return state;
    }
};

export default reducer;

export const setServerTimestamp = serverTimestamp =>
    ({ type: SET_SERVER_TIMESTAMP, payload: serverTimestamp });


// data accessors
const getState = rootState => rootState.currentTime;

export const getCurrentTimestamp = rootState => getState(rootState).timestamp;
export const getCurrentServerTimestamp = rootState => getState(rootState).serverTimestamp;

export const sagas = [];
