/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy';
import { PROTECTION_PROXY_PROFILES_SCENE_PATH } from '~frontendConstants';

import { createScene } from '../../../../lib/scenes';


const PolicyProfilesNew = createLazy(() => import('./PolicyProfilesNew'));
const Settings = createLazy(() => import('./Settings'));
const Antivirus = createLazy(() => import('./Antivirus'));


export const PROTECTION_PROXY_SETTINGS_SCENE_PATH = '/protection/proxy/settings';


const PROTECTION_PROXY_SETTINGS_SCENE = createScene({
    component: Settings,
    path: PROTECTION_PROXY_SETTINGS_SCENE_PATH,
    title: 'scenes:scenes.Protection.scenes.Proxy.scenes.Settings.title',
});

const PROTECTION_PROXY_ANTIVIRUS_SCENE = createScene({
    component: Antivirus,
    path: '/protection/proxy/antivirus',
    title: 'scenes:scenes.Protection.scenes.Proxy.scenes.Antivirus.title',
});


const PROTECTION_PROXY_POLICY_PROFILES = createScene({
    path: PROTECTION_PROXY_PROFILES_SCENE_PATH,
    component: PolicyProfilesNew,
    title: 'scenes:scenes.Protection.scenes.Proxy.scenes.Profiles.title',
});


const scenes = () => {
    return [
        PROTECTION_PROXY_SETTINGS_SCENE,
        PROTECTION_PROXY_POLICY_PROFILES,
        PROTECTION_PROXY_ANTIVIRUS_SCENE,
    ];
};

export default scenes;
