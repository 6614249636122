/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy';

import { createScene } from '../../../../lib/scenes';


const SystemComponents = createLazy(() => import('./SystemComponents'));
const Upgrade = createLazy(() => import('./Upgrade'));
const Machine = createLazy(() => import('./Machine'));


export const SYSTEM_UPGRADE_PATH = '/system/management/upgrade';
export const SYSTEM_COMPONENTS_PATH = '/system/management/components';
export const SYSTEM_MANAGEMENT_DEVICE = '/system/management/device';

const MANAGEMENT_UPGRADE_SCENE = createScene({
    component: Upgrade,
    path: SYSTEM_UPGRADE_PATH,
    title: 'scenes:scenes.System.scenes.Management.scenes.Upgrade.title',
});

const MANAGEMENT_COMPONENTS_SCENE = createScene({
    path: SYSTEM_COMPONENTS_PATH,
    component: SystemComponents,
    title: 'scenes:scenes.System.scenes.Management.scenes.Components.title',
});

const MANAGEMENT_MACHINE_SCENE = createScene({
    path: '/system/management/device',
    component: Machine,
    title: 'scenes:scenes.System.scenes.Management.scenes.Device.title',
});


const scenes = (/*{ deployment } = {}*/) => {
    return [
        MANAGEMENT_MACHINE_SCENE,
        MANAGEMENT_UPGRADE_SCENE,
        MANAGEMENT_COMPONENTS_SCENE,
    ];
};

export default scenes;
