/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import filterObjectManipulator from './filterObjectManipulator';
import tFilter from './tFilter';


const colTitle = function(reporterTemplates, name) {
    if (name in filterObjectManipulator.SPECIALS) {
        return tFilter('report:column.extra.columns.' + name + '-title', {}, name);
    }
    if (!reporterTemplates.columns) {
        return name;
    }
    const col = typeof name === 'object' ? name : reporterTemplates.columns.byName[name];
    return (col && col.title) || name || '';
};

export default colTitle;
