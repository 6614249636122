/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import scenes from '../../../../scenes';
import RouteScenes from '../../../../components/RouteScenes';


const Content = ({ deployment }) => (
    <RouteScenes
        deployment={deployment}
        scenes={scenes}
    />
);

Content.propTypes = {
    deployment: PropTypes.string,
};

export default Content;
