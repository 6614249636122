/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Select, } from '~frontendComponents/Generic';
import { interfaceDeviceOptions } from '~frontendLib/interfaceDeviceUtils';
import { getHoney, getGuiHttps, getInterfacesFromConfiguration, getProxyWpad,
    getIsCluster, } from '~frontendDucks/hlcfgEditor';
import glcfgDefinition from '~frontendLib/glcfgDefinition';
import { getMyNode, getOtherNode } from '~frontendRoot/ducks/clusterSetup';


const makeMapStateToProps = () => {
    const getWpad = getProxyWpad();
    const getGui = getGuiHttps();
    const getHoneypot = getHoney();


    const mapStateToProps = (state, { fake }) => {
        if (fake) {
            return {
                networkInterfaces: getInterfacesFromConfiguration(state),
                wpad: getWpad(state),
                guiHTTPS: getGui(state),
                honeypot: getHoneypot(state),
                isCluster: getIsCluster(state),
                myNode: getMyNode(state),
                otherNode: getOtherNode(state),
            };
        }
        return {
            networkInterfaces: getInterfacesFromConfiguration(state),
            isCluster: getIsCluster(state),
            myNode: getMyNode(state),
            otherNode: getOtherNode(state),
        };
    };
    return mapStateToProps;
};

/**
 * Component to create select from interfaces
 *
 * @param {object} props
 * @param {object} [props.iface] - picked iface
 * @param {object} [props.interfacesNames] - redux return about ifaces names
 * @param {object} [props.networkInterfacesInfo] - redux return about ifaces
 * @param {boolean} [props.disabled] - if component is disabled
 * @param {boolean} [props.label] - if component should have label
 * @param {string} [props.id] - id of the select
 * @param {string} [props.name] - name of the select
 * @param {Function} [props.onChange] - function send to the select as onChange
 * @param {Function} [props.doLoadSystemComponents] - redux function to update systemcomponents
 * @param {string} [props.error] - error of the select
 * @returns {Component}
 */
@hot
@withTranslation()
@connect(
    makeMapStateToProps,
    {
    }
)
class NetworkInterfaceDevice extends Component {
    static get propTypes() {
        return {
            networkInterfaces: PropTypes.array,
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
            ]),
            onChange: PropTypes.func,
            iface: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.array,
                PropTypes.object, //nft table
            ]),
            id: PropTypes.string,
            singleInterface: PropTypes.bool,
            schema: PropTypes.object,
            options: PropTypes.array,
            wpad: PropTypes.object,
            guiHTTPS: PropTypes.object,
            fake: PropTypes.bool, // NOT GREAT SOLUTION
            t: PropTypes.func,
            honeypot: PropTypes.object,
            exceptions: PropTypes.array,
            interfaceSelect: PropTypes.bool,
            isCluster: PropTypes.bool,
            myNode: PropTypes.string,
            otherNode: PropTypes.string,
        };
    }

    render() {
        const {
            id,
            label,
            iface,
            networkInterfaces,
            singleInterface,
            schema,
            options,
            fake,
            wpad,
            guiHTTPS,
            honeypot,
            t,
            exceptions,
            interfaceSelect = true,
            isCluster,
            myNode,
            otherNode,
        } = this.props;
        return (
            <Select
                {...this.props}
                interfaceSelect={interfaceSelect}
                isMulti={!singleInterface}

                label={label || glcfgDefinition[id]?.title || null}
                noOptionsMessage
                options={interfaceDeviceOptions({
                    networkInterfaces,
                    schema,
                    options,
                    fake,
                    wpad,
                    guiHTTPS,
                    honeypot,
                    t,
                    exceptions,
                    isCluster,
                    myNode,
                    otherNode,
                })}
                value={iface}
            />
        );
    }
}

export default NetworkInterfaceDevice;
