/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { AnyFunc, AnyFuncMaybeAsync, PromiseOrNot } from '~commonLib/types';
import { setTimeoutSafe } from '~commonLib/timeUtils';

/**
 * Returns a promise and a callback that are tied together. The promise is resolved or rejected when the callback gets
 * called.
 * Useful for using functions with a callback in an API that uses promises.
 *
 * @returns {Array} array with promise being first element and callback second
 */
export const promisifyCallback = () => {
    let callback;
    const promise = new Promise((resolve, reject) => {
        callback = (error, result) => {
            if (error) {
                return reject(error);
            }
            resolve(result);
        };
    });
    return [ promise, callback ];
};

/**
 * Returns a promise that resolves when one of eventNamesSuccess events is emitted and rejects when one of
 * eventNamesError is emitted.
 * Useful for transforming event emitters to promises.
 * This functions differs from promisifyEventEmitter in that there is no way to obtain the name of the event that caused
 * the resolution/rejection.
 *
 * @param eventEmitter
 * @param {string[]} eventNamesSuccess
 * @param {string[]} eventNamesError
 * @returns {Promise}
 */
export const complexPromisifyEventEmitter = (eventEmitter, eventNamesSuccess, eventNamesError) => {
    return new Promise((resolve, reject) => {
        let hasEnded = false;
        eventNamesSuccess.forEach(eventName => {
            eventEmitter.on(eventName, result => {
                if (hasEnded) {
                    return;
                }
                hasEnded = true;
                resolve({ eventName, result });
            });
        });
        eventNamesError.forEach(eventName => {
            eventEmitter.on(eventName, error => {
                if (hasEnded) {
                    return;
                }
                hasEnded = true;
                reject({ eventName, error });
            });
        });
    });
};

/**
 * Returns a promise that resolves when one of eventNamesSuccess events is emitted and rejects when one of
 * eventNamesError is emitted.
 * Useful for transforming event emitters to promises.
 *
 * @param eventEmitter
 * @param {string[]} eventNamesSuccess
 * @param {string[]} eventNamesError
 * @returns {Promise}
 */
export const promisifyEventEmitter = async (eventEmitter, eventNamesSuccess, eventNamesError) => {
    let result;
    try {
        result = await complexPromisifyEventEmitter(eventEmitter, eventNamesSuccess, eventNamesError);
    } catch (rejection) {
        throw rejection.error;
    }
    return result.result;
};

/**
 * Wraps a promise, allowing to pass a function that will get an error upon rejection.
 *
 * @param promise
 * @param onError - function that is called when the promise is rejected
 * @returns a promise that behaves the same as the argument this function takes
 */
export const wrapReject = async <T>(promise: Promise<T>, onError: AnyFunc<PromiseOrNot<void>>): Promise<T> => {
    let result;
    try {
        result = await promise;
    } catch (error) {
        await onError(error);
        throw error;
    }
    return result;
};

export class AtomicWrapperLockedError extends Error {}
/**
 * Returns a function that is used to wrap async functions with a lock that ensures that a wrapper function is not
 * called multiple times at the same time.
 *
 * @returns {Function}
 */
export const getAtomicAsyncWrapper = (throwInsteadOfWaiting = false) => {
    let lockPromise = null; // this promise is used as a lock
    return <T extends AnyFuncMaybeAsync>(asyncFunction: T) => {
        return async (...args: Parameters<T>): Promise<ReturnType<T>> => {
            while (lockPromise) {
                // Loop because someone else who is also awaiting the promise can be faster than us and can create a new
                // promise.
                if (throwInsteadOfWaiting) {
                    throw new AtomicWrapperLockedError('The function is currently being executed');
                } else {
                    try {
                        await lockPromise;
                    } catch (error) {
                        // Do nothing, this promise was not created by us, we are not interested in it being rejected.
                    }
                    // Do not set the promise to null here because it will be set by the initiator.
                }
            }
            lockPromise = asyncFunction(...args);
            let result;
            try {
                result = await lockPromise;
            } finally {
                lockPromise = null;
            }
            return result;
        };
    };
};

export const sleep = async (ms: number): Promise<void> => {
    return new Promise(resolve => {
        setTimeoutSafe(resolve, ms);
    });
};

type AnyFuncPromise<T = any> = (...args: any[]) => Promise<T>;
export const slowDownWrap = <T extends AnyFuncPromise>(fn: T, minTimeMs: number): T => {
    return (async (...args) => {
        const start = Date.now();
        const result = await fn(...args);
        const tookMs = Date.now() - start;
        if (tookMs < minTimeMs) {
            await sleep(minTimeMs - tookMs);
        }
        return result;
    }) as T;
};


type PropsAwaited<T extends object> = {
    [P in keyof T]: Awaited<T[P]>
}
/**
 * This function is used for retrieving multiple values of mixed types asynchronously at once. Normally you would
 * use Promise.all for this task, but then values and their respective promises may drift away from each other,
 * causing the code to be unreadable, kind of hard to write, and also to be incorrectly typed.
 */
export const promiseRecord = async <T extends object>(record: T): Promise<PropsAwaited<T>> => {
    const valuesAwaited = await Promise.all(Object.values(record));
    return Object.keys(record).reduce((acc, key, idx) => {
        return { ...acc, [key]: valuesAwaited[idx] };
    }, {}) as PropsAwaited<T>;
};
