/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { delay, fork, race, take } from 'redux-saga/effects';

/*
 * Like debounce from redux saga but with second pattern that skips the debouncing and causes task to run immediately.
 */
export const flushableDebounce = (ms, pattern, flushPattern, task, ...args) => fork(function*() {
    while (true) {
        let action = yield take(pattern);

        while (true) {
            const { debounced, _action, _flush, } = yield race({
                debounced: delay(ms),
                _action: take(pattern),
                _flush: take(flushPattern),
            });

            if (debounced || _flush) {
                yield fork(task, ...args, action);
                break;
            }

            action = _action;
        }
    }
});
