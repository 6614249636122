/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { combineReducers } from 'redux';

import currentUserHistory, { sagas as currentUserHistorySagas } from './ducks/currentUserHistory';
import allUsers, { sagas as allUsersSagas } from './ducks/allUsers';
import selectedUser, { sagas as selectedUserSagas } from './ducks/selectedUser';
import ssoSettings, { sagas as ssoSettingsSagas } from './ducks/ssoSettings';

// reducer
const reducer = combineReducers({
    allUsers,
    currentUserHistory,
    selectedUser,
    ssoSettings
});
export default reducer;


// side effects
export const sagas = [
    ...allUsersSagas,
    ...currentUserHistorySagas,
    ...selectedUserSagas,
    ...ssoSettingsSagas
];
