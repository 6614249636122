/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { CustomCompareMap } from '~commonLib/CustomCompareMap';

import { arrShallowEq } from './arrayUtils';


export class ShallowArrMap<Key extends any[], Val> extends CustomCompareMap<Key, Val> {
    protected customCompare(val1: Key, val2: Key): boolean {
        return arrShallowEq(val1, val2);
    }
}
