/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import whereAmI from './whereAmI';


/**
 * Holds settings that can be changed dynamically by the user. Note that
 * the values can only be booleans currently. That is because they are
 * settable by localStorage.
 *
 * @name dynamicSettings
 * @property {boolean} actionSequenceStepByStep - Whether action sequence
 * modals should not advance automatically to next step and instead require
 * the user to press the continue button.
 * @property {boolean} extensiveDebugLog - Whether to log extensively debug
 * quotes.
 * @property {boolean} isAdvanced - Whether the user was advanced enough to
 * type 'showAdvanced' into the console. Various advanced editors are shown
 * if this property is true.
 * @property {boolean} isBuiltinEditable - Whether it is possible to edit
 * read-only things, currently used only in KBI.
 * @property {boolean} kbiAllVisible - Whether all KBI entities are visible
 * unconditionally.
 * @property {boolean} kbiShowStoreOnServerButtons - Whether the buttons to
 * store PDF and HTML on the server are shown.
 * @property {boolean} logAllKmgrRequests - Whether to log all KMGR
 * requests, that is even those that have doNotLog defined.
 * @property {boolean} logKbiReportRefresh - Whether report refreshes in KBI
 * should be logged
 * @property {boolean} logKernundEmptyResponse - Whether an empty response
 * of a kernund request is logged. So far, it is known to mean one of the
 * three following things:
 *  - kernund service has failed by returning false from hnd.cpp
 *  - kernund worker was abruptly terminated
 *  - kernund service running on another node had its SSH tunnel broken
 * @property {boolean} logKernundError - Whether an error result of a
 * kernund request is logged. An error is when an error happens while
 * processing the kernund request.
 * @property {boolean} logKernundSessionStart - Whether the start of a
 * kernund session.
 * @property {boolean} logKernundStart - Whether the start of a kernund
 * request is logged.
 * @property {boolean} logKernundSuccess - Whether the successful result of
 * a kernund request is logged.
 * @property {boolean} logKernundWarning - Whether a warning result of a
 * kernund request is logged. A warning is when kernund service returns
 * a XML response with root element <error/>.
 * @property {boolean} logKmgrRequestAbort - Whether to log KMGR request
 * abort.
 * @property {boolean} logKmgrRequestError - Whether to log KMGR request
 * error.
 * @property {boolean} logKmgrRequestResult - Whether to log KMGR request
 * results.
 * @property {boolean} logKmgrRequestParams - Whether parameters of KMGR
 * requests are logged.
 * @property {boolean} logModals - Whether modal window opening and closures
 * are logged.
 * @property {boolean} logNotifierChange - Whether notifier changes are
 * logged.
 * @property {boolean} logXmlMutations - Whether XML mutations are logged.
 * @property {boolean} logRootScopeEvents - Whether events on $rootScope
 * should be logged.
 * @property {boolean} skipInstallWizard - Whether to automatically skip the
 * install wizard modal window.
 * @property {boolean} skipLoaderError - Whether to automatically skip the
 * loader error modal error.
 * @property {boolean} skipPasswordChange - Whether to automatically skip
 * the password change modal window.
 * @property {boolean} sysCfgLockAutobreak - Whether to automatically break
 * the system configuration lock.
 */
const dynamicSettings = {};

const objectFreeze = function(object) {
    return Object.freeze ? Object.freeze(object) : object;
};

const booleanize = function(object) {
    for (const key in object) {
        object[key] = !!object[key];
    }
    return object;
};

dynamicSettings.defaults = objectFreeze(booleanize({
    actionSequenceStepByStep: false,
    isAdvanced: false,
    isBuiltinEditable: false,
    extensiveDebugLog: false,
    kbiAllVisible: whereAmI.offline,
    kbiShowStoreOnServerButtons: false,
    logAllKmgrRequests: false,
    logKbiReportRefresh: false,
    logKernundEmptyResponse: true,
    logKernundError: true,
    logKernundStart: false,
    logKernundSessionStart: false,
    logKernundSuccess: false,
    logKernundWarning: whereAmI.client && !whereAmI.tests,
    logKmgrRequestAbort: true,
    logKmgrRequestError: true,
    logKmgrRequestParams: false,
    logKmgrRequestResult: whereAmI.client && !whereAmI.tests,
    logModals: whereAmI.tests,
    logNotifierChange: false,
    logXmlMutations: false,
    logRootScopeEvents: false,
    skipInstallWizard: false,
    skipLoaderError: false,
    skipPasswordChange: false,
    sysCfgLockAutobreak: false,
}));

// The actual settings are stored here in order to prevent them being
// accidentally set.
const properties = {};

Object.keys(dynamicSettings.defaults).forEach(function(key) {
    properties[key] = dynamicSettings.defaults[key];
});

dynamicSettings.keys = Object.keys(properties);

// Define getters to prevent unintentionally setting the value.
Object.keys(properties).forEach(function(key) {
    Object.defineProperty(dynamicSettings, key, {
        get: function() {
            return properties[key];
        }
    });
});

/**
 * Returns truthy value if there is localStorage available.
 *
 * @memberof dynamicSettings
 * @returns {boolean}
 */
const hasLocalStorage = function() {
    return typeof window !== 'undefined' && window && window.localStorage;
};

/**
 * Returns localStorage keyName
 *
 * @memberof dynamicSettings
 * @param {string} property
 * @returns {string}
 */
dynamicSettings.getLocalStorageKeyName = function(property) {
    return 'kmgr' + property[0].toUpperCase() + property.substr(1);
};

/**
 * Setter for the properties.
 *
 * @memberof dynamicSettings
 * @param {string} key
 * @param {boolean} value
 * @param {boolean} [persistent=false] - whether the value should also be
 * stored to localStorage
 */
dynamicSettings.set = function(key, value, persistent) {
    if (!(key in properties)) {
        throw new Error('Unknown key "' + key + '"');
    }
    properties[key] = value;
    if (persistent && hasLocalStorage()) {
        window.localStorage.setItem(dynamicSettings.getLocalStorageKeyName(key), value);
    }
};

// Set values from localStorage.
if (hasLocalStorage()) {
    Object.keys(properties).forEach(function(key) {
        const value = window.localStorage.getItem(dynamicSettings.getLocalStorageKeyName(key));
        if (value !== null) {
            dynamicSettings.set(key, value === 'true');
        }
    });
}

export default dynamicSettings;
