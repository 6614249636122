/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { combineReducers } from 'redux';

import navBarLeft, { sagas as navBarLeftSagas } from './ducks/navBarLeft';


// reducer
const reducer = combineReducers({ navBarLeft });
export default reducer;

export const sagas = [
    ...navBarLeftSagas
];
