/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { setRootSaga } from '~frontendRoot/saga/sagaMiddleware';
import socketIOExample, { sagas as socketIOExampleSagas } from '~frontendDucks/socketIOExample';
import reporterDbStructure, {
    getReporterReporterTemplates,
    loadReporterTemplatesFailure,
    loadReporterTemplatesRequest,
    sagas as reporterDbStructureSagas,
} from '~frontendDucks/reporterDbStructure';
import { INTERFACES_COLUMNS_IP6 } from '~frontendRoot/constants';
import { SESSION_WAS_TERMINATED_BY_BACKEND } from '~frontendDucks/actions';
import { clusterSetupSagas } from '~frontendDucks/clusterSetup/sagas';

import combineSagas from '../lib/combineSagas';
import actionSequenceReplayList, { sagas as actionSequenceReplayListSagas } from './actionSequenceReplayList';
import adaptiveFirewallUpdatePerform, { sagas as adaptiveFirewallUpdatePerformSagas } from './adaptiveFirewallUpdatePerform';
import systemInfo, { sagas as systemInfoSagas, setHardwareInfo, setHostname, setProductVersion } from './systemInfo';
import cfgActivation, { getSagas as getCfgActivationSagas } from './cfgActivation';
import cfgRevisionList, { sagas as cfgRevisionListSagas } from './cfgRevisionList';
import constantInformation, {
    sagas as constantInformationSagas,
    setIsProductionMode,
    setIsWebpackInitialized,
} from './constantInformation';
import currentTime, { sagas as currentTimeSagas, setServerTimestamp } from './currentTime';
import dhcpClient, { sagas as dhcpClientSagas } from './dhcpClient';
import dynamicSettings, { sagas as dynamicSettingsSagas, setShowTodos } from './dynamicSettings';
import hlcfgDiffer, { sagas as hlcfgDifferSagas } from './hlcfgDiffer';
import hlcfgEditor, {
    sagas as hlcfgEditorSagas,
    schemaLoadSuccess,
    schemaTransformRequest,
    setInitGlcfgTree,
    treeLoadSuccess,
    treeStoreSuccess,
    sagasNormalize,
    sagasPf,
    sagasGlcfg,
    needsResetSessionHlcfg,
} from './hlcfgEditor';
import i18next, { sagas as i18nextSagas } from './i18next';
import language, { sagas as languageSagas, setInitLanguage } from './language';
import layout, { sagas as layoutSagas } from './layout';
import nmapUser, { sagas as nmapUserSagas } from './nmapUser';
import ntpdTimeSync, { sagas as ntpdTimeSyncSagas } from './ntpdTimeSync';
import reporterEntities, { sagas as reporterEntitiesSagas, setAllHwInterfacesAsFilter } from './reporterEntities';
import sessionsManagement, {
    sagas as sessionsManagementSagas,
    setPasswordResetTimerStart,
    setSessionTerminateReasonRequest,
} from './sessionsManagement';
import { sagas as socketIOSagas } from './socketIO';
import statusCode, { sagas as statusCodeSagas, setApiError, setStatusCode } from './statusCode';
import suricataFilteringTable, { sagas as suricateFilteringTableSagas } from './suricataFilteringTable';
import suricataRulesUpdatePerform, { sagas as suricataRulesUpdatePerformSagas } from './suricataRulesUpdatePerform';
import systemComponentAction, { sagas as systemComponentActionSagas } from './systemComponentAction';
import systemComponents, { sagas as systemComponentsSagas } from './systemComponents';
import userAuthentication, { sagas as userAuthenticationSagas } from './userAuthentication';
import { guiLoadSuccess } from './userAuthentication/ducks/guiLoading';
import { loginFormHide, loginFormShow, loginSuccess, LOGOUT_REQUEST } from './userAuthentication/ducks/login';
import userManagement, { sagas as userManagementSagas } from './userManagement';
import userDashboard, { sagas as userDashboardSagas } from './userDashboard';
import userProfile, { sagas as userProfileSagas } from './userProfile';
import packetFilterRules from './packetFilterRules';
import policy, { setCategories } from './policy';
import upgrade, { sagas as upgradeSagas } from './upgrade';
import database, { sagas as databaseSagas } from './database';
import authentication, { sagas as authenticationSagas } from './authentication';
import clusterSetup from './clusterSetup';
import clusterDbReplicationStatus, { sagas as clusterDbReplicationStatusSagas } from './clusterDbReplication';
import { setInitialClusterSetup } from './clusterSetup/actions';
import modals from './modals';
import protectionDatabases, { sagas as protectionDatabasesSagas } from './protectionDatabases';
import networkInterfaces, { sagas as networkInterfacesSagas } from './networkInterfaces';
import uploadFile, { sagas as uploadFileSagas } from './uploadFile';
import openvpn, { sagas as openvpnSagas } from './openvpn';
import dhcpd, { sagas as dhcpdSagas } from './dhcpd';
import interfaces from './interfaces';
import lock from './lock';
import activeCards, { setInitialCards } from './activeCards';
import activeDiffersCards from './activeDiffersCards';
import selectedColumns, { sagas as selectedColumnsSagas, setColumns } from './selectedColumns';
import treeChartHidden from './treeChartHidden';
import isInAf, { sagas as isInAfSagas } from './isInAF';
import isInHP, { sagas as isInHPSagas } from './isInHP';
import isInCategories, { sagas as isInCategoriesSagas } from './isInCategories';
import imInAf, { sagas as imInAfSagas } from './imInAF';
import crlUrlTest, { sagas as crlUrlTestSagas } from './crlUrlTest';
import backup, { sagas as backupSagas, recoverySuccess } from './backup';
import userSettings, { sagas as userSettingsSagas } from './userSettings';
import certificationExpiration, { sagas as certificationExpirationSagas } from './certificationExpiration';
import vpnUserQrcode, { sagas as vpnUserQrcodeSagas } from './vpnUserQrcode';
import actionSequence from './actionSequence';
import ipsec, { sagas as ipsecSagas } from './ipsec';
import logDataWIndow from './logDataWindow/logDataWIndow';


/** Obtains a root reducer. */

const getRootReducer = (history) => {
    const combinedReducer = combineReducers({
        actionSequence,
        actionSequenceReplayList,
        activeCards,
        activeDiffersCards,
        adaptiveFirewallUpdatePerform,
        authentication,
        backup,
        certificationExpiration,
        cfgActivation,
        cfgRevisionList,
        clusterDbReplicationStatus,
        clusterSetup,
        constantInformation,
        currentTime,
        crlUrlTest,
        database,
        dhcpClient,
        dhcpd,
        dynamicSettings,
        hlcfgDiffer,
        hlcfgEditor,
        i18next,
        interfaces,
        imInAf,
        ipsec,
        isInAf,
        isInHP,
        isInCategories,
        language,
        layout,
        lock,
        logDataWIndow,
        networkInterfaces,
        ntpdTimeSync,
        nmapUser,
        modals,
        openvpn,
        packetFilterRules,
        policy,
        protectionDatabases,
        reporterDbStructure,
        reporterEntities,
        router: connectRouter(history),
        selectedColumns,
        sessionsManagement,
        socketIOExample,
        statusCode,
        suricataFilteringTable,
        suricataRulesUpdatePerform,
        systemComponentAction,
        systemComponents,
        systemInfo,
        treeChartHidden,
        upgrade,
        uploadFile,
        userAuthentication,
        userDashboard,
        userManagement,
        userProfile,
        userSettings,
        vpnUserQrcode,
    });

    return (state, action) => {
        switch (action.type) {
        case LOGOUT_REQUEST: {
            const paramsForInitialState = window._PARAMS_FOR_INITIAL_STATE;
            return fillInitialState(
                {
                    ...paramsForInitialState,
                    currentUser: null,
                    terminateReason: action.payload?.reason,
                    statusCode: action.payload?.statusCode
                },
                {
                    ...combinedReducer(undefined, { type: 'backend/init' }),
                    router: state.router,
                }
            );
        }
        case SESSION_WAS_TERMINATED_BY_BACKEND: { //UNUSED FOR NOW, MAYBE WE WILL FIND SOME USAGE FOR THIS
            const paramsForInitialState = window._PARAMS_FOR_INITIAL_STATE;
            return fillInitialState(
                {
                    ...paramsForInitialState,
                    currentUser: null,
                    terminateReason: action.payload?.reason,
                    statusCode: action.payload?.statusCode
                },
                {
                    ...combinedReducer(undefined, { type: 'backend/init' }),
                    router: state.router,
                }
            );
        }
        default:
            return combinedReducer(state, action);
        }
    };
};

export default getRootReducer;


// initial state

const fillInitialState = (paramsForInitialState, initialState) => {
    const {
        i18nOptions, isProductionMode, isWebpackInitialized, selectedLanguage, currentUser, apiError,
        hlcfgSchemaTree, initHlcfgTree, hlcfgTree, hlcfgVerificationErrors, hlcfgVerificationWarnings,
        cwCategories, templatesCfg, diffs, restoringCfgBackup, initHlcfgIsStoredOnDisk
    } = paramsForInitialState;
    if (templatesCfg?.reporter) {
        getReporterReporterTemplates().load(templatesCfg.reporter);
    }
    return {
        ...initialState,
        activeCards: [
            initHlcfgTree ? setInitialCards(hlcfgTree) : null,
        ].filter(action => action).reduce(activeCards, initialState.activeCards),
        clusterSetup: [
            initHlcfgTree ? setInitialClusterSetup(hlcfgTree) : null
        ].filter(action => action).reduce(clusterSetup, initialState.clusterSetup),
        currentTime: [
            setServerTimestamp(paramsForInitialState.currentTime.serverTimestamp),
        ].reduce(currentTime, initialState.currentTime),
        backup: [
            recoverySuccess(restoringCfgBackup)
        ].reduce(backup, initialState.restoringCfgBackup),
        constantInformation: [
            setIsWebpackInitialized(isWebpackInitialized),
            setIsProductionMode(isProductionMode),
        ].reduce(constantInformation, initialState.constantInformation),

        policy: [
            cwCategories ? setCategories(cwCategories) : null,
        ].filter(action => action).reduce(policy, initialState.cwCategories),

        dynamicSettings: [
            setShowTodos(!isProductionMode)
        ].reduce(dynamicSettings, initialState.dynamicSettings),

        hlcfgEditor: [
            hlcfgSchemaTree ? schemaLoadSuccess(hlcfgSchemaTree) : null,
            initHlcfgTree ? setInitGlcfgTree(initHlcfgTree) : null,
            hlcfgTree ? treeLoadSuccess(hlcfgTree) : null,
            hlcfgVerificationErrors && hlcfgVerificationWarnings && diffs ?
                treeStoreSuccess(hlcfgVerificationErrors, hlcfgVerificationWarnings, diffs) :
                null,
            hlcfgSchemaTree ? schemaTransformRequest(hlcfgSchemaTree) : null,
            //if initHlcfg is stored on disk, we dont need to reset hlcfg
            initHlcfgIsStoredOnDisk !== undefined ? needsResetSessionHlcfg(!initHlcfgIsStoredOnDisk) : null,
        ].filter(action => action).reduce(hlcfgEditor, initialState.hlcfgEditor),
        i18next: i18nOptions,

        language: [
            setInitLanguage({ selectedLanguage })
        ].reduce(language, initialState.language),

        userAuthentication: [
            currentUser ? loginSuccess(currentUser) : null,
            currentUser && !currentUser.pwdReset ? guiLoadSuccess({}) : null,
            apiError ? loginFormHide() : loginFormShow(),
        ].filter(action => action).reduce(userAuthentication, initialState.userAuthentication),

        reporterEntities: [
            initHlcfgTree ? setAllHwInterfacesAsFilter(initHlcfgTree.interfaces) : null
        ].filter(Boolean).reduce(reporterEntities, initialState.reporterEntities),
        reporterDbStructure: [
            loadReporterTemplatesRequest(templatesCfg),
            currentUser && !Object.keys(templatesCfg).length ?
                loadReporterTemplatesFailure({ message: 'charts:templatesCfg.error', code: 500 }) : null
        ].filter(action => action).reduce(reporterDbStructure, initialState.reporterDbStructure),

        selectedColumns: [
            hlcfgTree ?  setColumns(INTERFACES_COLUMNS_IP6, 'interfaces', hlcfgTree.network.ipv6Enabled,
                !hlcfgTree.network.ipv6Enabled) : null
        ].filter(action => action).reduce(selectedColumns, initialState.selectedColumns),

        statusCode: [
            setStatusCode(paramsForInitialState.statusCode || 200),
            apiError ? setApiError(apiError) : null,
        ].filter(action => action).reduce(statusCode, initialState.statusCode),

        sessionsManagement: [
            setSessionTerminateReasonRequest(paramsForInitialState.terminateReason),
            setPasswordResetTimerStart(paramsForInitialState.sessionManagement?.passwordResetTimerStart),
        ].filter(action => action).reduce(sessionsManagement, initialState.sessionsManagement),

        systemInfo: [
            setProductVersion({
                pretty: paramsForInitialState.systemInfo.productVersion,
                raw: paramsForInitialState.systemInfo.productVersionRaw,
            }),
            setHardwareInfo(paramsForInitialState.systemInfo.hardwareInfo),
            setHostname(paramsForInitialState.systemInfo.hostname),

        ].reduce(systemInfo, initialState.systemInfo),
    };
};

export const getInitialState = (paramsForInitialState, history) => {
    const initialState = getRootReducer(history)(undefined, { type: 'backend/init' });
    return fillInitialState(paramsForInitialState, initialState);
};


// side effects
export const rootSaga = combineSagas(
    actionSequenceReplayListSagas,
    adaptiveFirewallUpdatePerformSagas,
    authenticationSagas,
    certificationExpirationSagas,
    backupSagas,
    getCfgActivationSagas(),
    cfgRevisionListSagas,
    constantInformationSagas,
    clusterDbReplicationStatusSagas,
    clusterSetupSagas,
    currentTimeSagas,
    crlUrlTestSagas,
    protectionDatabasesSagas,
    databaseSagas,
    dhcpdSagas,
    dhcpClientSagas,
    dynamicSettingsSagas,
    hlcfgDifferSagas,
    hlcfgEditorSagas,
    sagasNormalize,
    sagasPf,
    sagasGlcfg,
    i18nextSagas,
    imInAfSagas,
    isInAfSagas,
    isInHPSagas,
    isInCategoriesSagas,
    ipsecSagas,
    languageSagas,
    layoutSagas,
    networkInterfacesSagas,
    ntpdTimeSyncSagas,
    nmapUserSagas,
    openvpnSagas,
    reporterDbStructureSagas,
    reporterEntitiesSagas,
    selectedColumnsSagas,
    sessionsManagementSagas,
    socketIOExampleSagas,
    socketIOSagas,
    statusCodeSagas,
    suricataRulesUpdatePerformSagas,
    suricateFilteringTableSagas,
    systemComponentActionSagas,
    systemComponentsSagas,
    systemInfoSagas,
    upgradeSagas,
    uploadFileSagas,
    userAuthenticationSagas,
    userDashboardSagas,
    userManagementSagas,
    userProfileSagas,
    userSettingsSagas,
    vpnUserQrcodeSagas,
);

setRootSaga(rootSaga);
