/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import ReportType from './ReportType';
import filterContainerManipulator from './filterContainerManipulator';


const _genRows = function(from, to) {
    if (!from || from === -Infinity) {
        return to;
    } else {
        if (!to || to === Infinity) {
            return [ from, 1000000 ];
        } else {
            return [ from, to ];
        }
    }
};

const setArray = function(objectDst, key, arraySrc) {
    if (arraySrc && arraySrc.length) {
        objectDst[key] = arraySrc;
    }
};

const setAttribute = function(objectDst, key, objectSrc) {
    if (objectSrc && objectSrc[key]) {
        objectDst[key] = objectSrc[key];
    }
};

const setValue = function(objectDst, key, valueSrc) {
    if (valueSrc) {
        objectDst[key] = valueSrc;
    }
};

const universal = new ReportType({
    order: 0,
    id: 'universal',
    title: 'reporter:report-type.universal-title',
    doc: 'reporter:report-type.universal-doc',
    fields: [
        {
            id: 'table',
            title: 'reporter:editor-field.table-title',
            doc: 'reporter:editor-field.table-doc',
            type: 'table',
        },
        {
            id: 'categories',
            title: 'reporter:editor-field.group-by-title',
            doc: 'reporter:editor-field.group-by-doc',
            type: 'columns',
            columnType: 'category',
        },
        {
            id: 'metrics',
            title: 'reporter:editor-field.display-metrics-title',
            doc: 'reporter:editor-field.display-metrics-doc',
            type: 'columns',
            columnType: 'metric',
        },
        {
            id: 'orderBy',
            title: 'reporter:editor-field.order-by-title',
            doc: 'reporter:editor-field.order-by-doc',
            type: 'orderBy',
            columnRefs: [ 'categories', 'metrics' ],
        },
        {
            id: 'rowsFrom',
            title: 'reporter:editor-field.rows-from-title',
            doc: 'reporter:editor-field.rows-from-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            id: 'rowsTo',
            title: 'reporter:editor-field.rows-to-title',
            doc: 'reporter:editor-field.rows-to-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            id: 'filters',
            title: 'reporter:editor-field.filters-title',
            doc: 'reporter:editor-field.filters-doc',
            type: 'filters',
        },
    ],
    getDefaultParams: function(reportParams, reportType) {
        const result = {
            table: 'http_proxy',
            categories: [ 'network.client' ],
            metrics: [ 'event.events' ],
            orderBy: [ { col: 'event.events', dir: 'desc' } ],
            filters: filterContainerManipulator.createNewFilterContainer()
        };
        if (reportType && reportParams) {
            setAttribute(result, 'table', reportParams);
            setArray(result, 'categories', reportType.getCategoryCols(reportParams));
            setArray(result, 'metrics', reportType.getMetricCols(reportParams));
            setArray(result.orderBy[0], 'col', result.metrics[0]);
            setAttribute(result, 'filters', reportParams);
            setAttribute(result, 'rowsFrom', reportParams);
            setAttribute(result, 'rowsTo', reportParams);
        }
        return result;
    },
    generateCfg: function(
        customFilterStorage, reporterTemplates, params, filters
    ) {
        return {
            template: 'simple',
            table: params.table,
            database: params.database,
            categories: params.categories,
            metrics: params.metrics,
            orderBy: params.orderBy.map(item => item.col + ' ' + item.dir),
            rows: _genRows(params.rowsFrom, params.rowsTo) || undefined,
            filters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                filterContainerManipulator.concatenateFilterContainers(
                    filters
                )
            ),
        };
    },
    getTable: function(params) {
        return params.table;
    },
    reporterTemplate: 'simple',
    template: 'universal'
});

const hitparada = new ReportType({
    order: 2,
    id: 'hitparada',
    title: 'reporter:report-type.hitparada-title',
    doc: 'reporter:report-type.hitparada-doc',
    fields: [
        {
            id: 'table',
            type: 'table',
            title: 'reporter:editor-field.table-title',
            doc: 'reporter:editor-field.table-doc',
        },
        {
            id: 'categories',
            title: 'reporter:editor-field.group-by-title',
            doc: 'reporter:editor-field.group-by-doc',
            type: 'columns',
            columnType: 'category',
        },
        {
            id: 'orderBy',
            title: 'reporter:editor-field.order-by-title',
            doc: 'reporter:editor-field.order-by-doc',
            type: 'orderBy',
            columnType: 'metric',
        },
        {
            id: 'limit',
            title: 'reporter:editor-field.limit-title',
            doc: 'reporter:editor-field.limit-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            id: 'filters',
            title: 'reporter:editor-field.filters-title',
            doc: 'reporter:editor-field.filters-doc',
            type: 'filters',
        }
    ],
    getDefaultParams: function(reportParams, reportType) {
        const result = {
            table: 'http_proxy',
            categories: [ 'network.client' ],
            orderBy: [ { col: 'event.events', dir: 'desc' } ],
            limit: 10,
            filters: filterContainerManipulator.createNewFilterContainer(),
        };
        if (reportType && reportParams) {
            setAttribute(result, 'table', reportParams);
            setArray(result, 'categories', reportType.getCategoryCols(reportParams));
            setAttribute(result, 'filters', reportParams);
            if (typeof reportParams.rowsFrom === 'number' && typeof reportParams.rowsTo === 'number') {
                result.limit = Math.abs(reportParams.rowsTo - reportParams.rowsFrom);
            }
        }
        return result;
    },
    generateCfg: function(
        customFilterStorage, reporterTemplates, params, filters
    ) {
        return {
            template: 'simple',
            table: params.table,
            database: params.database,
            categories: params.categories,
            metrics: params.orderBy.map(item => item.col),
            orderBy: params.orderBy.map(item => item.col + ' ' + item.dir),
            rows: params.limit || undefined,
            filters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                filterContainerManipulator.concatenateFilterContainers(
                    params.filters, filters
                )
            ),
        };
    },
    getTable: function(params) {
        return params.table;
    },
    reporterTemplate: 'simple',
    template: 'universal'
});

const heatmap = new ReportType({
    order: 1,
    id: 'heatmap',
    title: 'reporter:report-type.heatmap-title',
    doc: 'reporter:report-type.heatmap-doc',
    fields: [
        {
            id: 'table',
            type: 'table',
            title: 'reporter:editor-field.table-title',
            doc: 'reporter:editor-field.table-doc',
        },
        {
            type: 'section',
            title: 'reporter:editor-field.colour-title',
            doc: 'reporter:editor-field.colour-doc',
        },
        {
            id: 'metric',
            title: 'reporter:editor-field.metric-title',
            doc: 'reporter:editor-field.metric-doc',
            type: 'column',
            columnType: 'metric',
        },
        {
            type: 'section',
            title: 'reporter:horizontal-axis',
        },
        {
            id: 'hCategory',
            title: 'reporter:editor-field.column-title',
            doc: 'reporter:editor-field.column-doc',
            type: 'column',
            columnType: 'category',
        },
        {
            id: 'hOrderBy',
            title: 'reporter:editor-field.order-by-title',
            doc: 'reporter:editor-field.order-by-doc',
            type: 'orderBy',
            columnRefs: [ 'hCategory', 'metric' ],
        },
        {
            id: 'hLimit',
            title: 'reporter:editor-field.h-limit-title',
            doc: 'reporter:editor-field.h-limit-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            type: 'section',
            title: 'reporter:vertical-axis',
        },
        {
            id: 'vCategory',
            title: 'reporter:editor-field.column-title',
            doc: 'reporter:editor-field.column-doc',
            type: 'column',
            columnType: 'category',
        },
        {
            id: 'vOrderBy',
            title: 'reporter:editor-field.order-by-title',
            doc: 'reporter:editor-field.order-by-doc',
            type: 'orderBy',
            columnRefs: [ 'vCategory', 'metric' ],
        },
        {
            id: 'vLimit',
            title: 'reporter:editor-field.v-limit-title',
            doc: 'reporter:editor-field.v-limit-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            type: 'section',
            title: 'reporter:editor-field.filters-title',
            doc: 'reporter:editor-field.filters-doc',
        },
        {
            id: 'filters',
            title: '',
            type: 'filters',
        }
    ],
    getDefaultParams: function(reportParams, reportType) {
        const result = {
            table: 'http_proxy',
            hCategory: 'network.client',
            vCategory: 'network.server',
            hOrderBy: [ { col: 'network.client', dir: 'desc' } ],
            vOrderBy: [ { col: 'network.server', dir: 'desc' } ],
            hLimit: 20,
            vLimit: 20,
            filters: filterContainerManipulator.createNewFilterContainer(),
            metric: 'event.events',
        };
        if (reportType && reportParams) {
            setAttribute(result, 'table', reportParams);
            const categories = reportType.getCategoryCols(reportParams);
            setValue(result, 'hCategory', categories[0]);
            setValue(result, 'vCategory', categories[1]);
            setValue(result.hOrderBy[0], 'col', result.hCategory);
            setValue(result.vOrderBy[0], 'col', result.vCategory);
            setValue(result, 'hLimit', reportParams.rowsFrom);
            setValue(result, 'vLimit', reportParams.rowsTo);
            setAttribute(result, 'filters', reportParams);
            setValue(result, 'metric', reportType.getMetricCols(reportParams)[0]);
        }
        return result;
    },
    generateCfg: function(
        customFilterStorage, reporterTemplates, params, filters
    ) {
        return {
            template: 'heatmap',
            table: params.table,
            database: params.database,
            categories: [
                {
                    col: params.hCategory,
                    orderBy: params.hOrderBy.map(item => item.col + ' ' + item.dir),
                    rows: params.hLimit || undefined,
                },
                {
                    col: params.vCategory,
                    orderBy: params.vOrderBy.map(item => item.col + ' ' + item.dir),
                    rows: params.vLimit || undefined,
                },
            ],
            filters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                filterContainerManipulator.concatenateFilterContainers(
                    params.filters, filters
                )
            ),
            metrics: [ params.metric ],
        };
    },
    getTable: function(params) {
        return params.table;
    },
    reporterTemplate: 'heatmap',
    template: 'universal'
});

const drilldown = new ReportType({
    order: 3,
    id: 'drilldown',
    title: 'reporter:report-type.drilldown-title',
    doc: 'reporter:report-type.drilldown-doc',
    fields: [
        {
            id: 'table',
            type: 'table',
            title: 'reporter:editor-field.table-title',
            doc: 'reporter:editor-field.table-doc',
        },
        {
            type: 'section',
            title: 'reporter:editor-field.metrics-title',
            doc: 'reporter:editor-field.metrics-doc',
        },
        {
            id: 'metrics',
            title: 'reporter:editor-field.display-summable-metrics-title',
            doc: 'reporter:editor-field.display-summable-metrics-doc',
            type: 'columns',
            columnType: 'summable',
        },
        {
            type: 'section',
            title: 'reporter:editor-field.first-level-title',
            doc: 'reporter:editor-field.first-level-doc',
        },
        {
            id: 'l1Category',
            title: 'reporter:editor-field.column-title',
            doc: 'reporter:editor-field.column-doc',
            type: 'column',
            columnType: 'category',
        },
        {
            id: 'l1OrderBy',
            title: 'reporter:editor-field.order-by-title',
            doc: 'reporter:editor-field.order-by-doc',
            type: 'orderBy',
            columnRefs: [ 'l1Category', 'metrics' ],
        },
        {
            id: 'l1Limit',
            title: 'reporter:editor-field.l1-limit-title',
            doc: 'reporter:editor-field.l1-limit-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            type: 'section',
            title: 'reporter:editor-field.second-level-title',
            doc: 'reporter:editor-field.second-level-doc',
        },
        {
            id: 'l2Category',
            title: 'reporter:editor-field.column-title',
            doc: 'reporter:editor-field.column-doc',
            type: 'column',
            columnType: 'category',
        },
        {
            id: 'l2OrderBy',
            title: 'reporter:editor-field.order-by-title',
            doc: 'reporter:editor-field.order-by-doc',
            type: 'orderBy',
            columnRefs: [ 'l2Category', 'metrics' ],
        },
        {
            id: 'l2Limit',
            title: 'reporter:editor-field.l2-limit-title',
            doc: 'reporter:editor-field.l2-limit-doc',
            type: 'number',
            placeholder: 'reporter:no-limit',
        },
        {
            type: 'section',
            title: 'reporter:editor-field.filters-title',
            doc: 'reporter:editor-field.filters-doc',
        },
        {
            id: 'filters',
            title: '',
            type: 'filters',
        }
    ],
    getDefaultParams: function(reportParams, reportType) {
        const result = {
            table: 'http_proxy',
            l1Category: 'network.client',
            l2Category: 'network.server',
            l1OrderBy: [ { col: 'network.client', dir: 'desc' } ],
            l2OrderBy: [ { col: 'network.server', dir: 'desc' } ],
            l1Limit: 500,
            l2Limit: 5,
            filters: filterContainerManipulator.createNewFilterContainer(),
            metrics: [ 'event.events' ]
        };
        if (reportType && reportParams) {
            setAttribute(result, 'table', reportParams);
            const categories = reportType.getCategoryCols(reportParams);
            setValue(result, 'l1Category', categories[0]);
            setValue(result, 'l2Category', categories[1]);
            setValue(result.l1OrderBy[0], 'col', result.l1Category);
            setValue(result.l2OrderBy[0], 'col', result.l2Category);
            setValue(result, 'l1Limit', reportParams.rowsFrom);
            setValue(result, 'l2Limit', reportParams.rowsTo);
            setAttribute(result, 'filters', reportParams);
            setArray(result, 'metrics', reportType.getMetricCols(reportParams));
        }
        return result;
    },
    generateCfg: function(
        customFilterStorage, reporterTemplates, params, filters
    ) {
        return {
            template: 'drilldown',
            table: params.table,
            database: params.database,
            categories: [
                {
                    col: params.l1Category,
                    orderBy: params.l1OrderBy.map(item => item.col + ' ' + item.dir),
                    rows: params.l1Limit || undefined,
                },
                {
                    col: params.l2Category,
                    orderBy: params.l2OrderBy.map(item => item.col + ' ' + item.dir),
                    rows: params.l2Limit || undefined,
                },
            ],
            filters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                filterContainerManipulator.concatenateFilterContainers(
                    params.filters, filters
                )
            ),
            metrics: params.metrics,
        };
    },
    getTable: function(params) {
        return params.table;
    },
    reporterTemplate: 'drilldown',
    template: 'universal'
});

const userHttpActivity = new ReportType({
    order: 4,
    id: 'userHttpActivity',
    title: 'reporter:report-type.userHttpActivity-title',
    doc: 'reporter:report-type.userHttpActivity-doc',
    fields: [
        {
            id: 'detailed',
            title: 'reporter:editor-field.detailed-title',
            doc: 'reporter:editor-field.detailed-doc',
            type: 'boolean',
        },
        {
            id: 'filters',
            title: 'reporter:editor-field.filters-title',
            doc: 'reporter:editor-field.filters-doc',
            type: 'filters',
        }
    ],
    getDefaultParams: function(reportParams, reportType) {
        const result = {
            filters: filterContainerManipulator.createNewFilterContainer(),
        };
        if (reportType && reportParams) {
            setAttribute(result, 'filters', reportParams);
        }
        return result;
    },
    generateCfg: function(
        customFilterStorage, reporterTemplates, params, filters
    ) {
        return {
            template: 'user_http_activity',
            database: params.database,
            detailed: params.detailed,
            filters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                filterContainerManipulator.concatenateFilterContainers(
                    params.filters, filters
                )
            ),
        };
    },
    getTable: function() {
        return 'http_proxy';
    },
    reporterTemplate: 'user_http_activity',
    template: 'universal'
});

const actionRequestRatio = new ReportType({
    order: 5,
    id: 'actionRequestRatio',
    title: 'reporter:report-type.actionRequestRatio-title',
    doc: 'reporter:report-type.actionRequestRatio-doc',
    fields: [
        {
            id: 'table',
            type: 'table',
            title: 'reporter:editor-field.table-title',
            doc: 'reporter:editor-field.table-doc',
        },
        {
            id: 'categories',
            title: 'reporter:editor-field.group-by-title',
            doc: 'reporter:editor-field.group-by-doc',
            type: 'columns',
            columnType: 'category',
        },
        {
            id: 'metrics',
            title: 'reporter:editor-field.display-metrics-title',
            doc: 'reporter:editor-field.display-metrics-doc',
            type: 'columns',
            columnType: 'metric',
        },
        {
            id: 'minRatio',
            title: 'reporter:editor-field.min-ratio-title',
            doc: 'reporter:editor-field.min-ratio-doc',
            type: 'number',
        },
        {
            id: 'orderByRatioIsAsc',
            title: 'reporter:editor-field.orderByRatioIsAsc-title',
            doc: 'reporter:editor-field.orderByRatioIsAsc-doc',
            type: 'boolean',
        },
        {
            id: 'dividendFilters',
            title: 'reporter:editor-field.dividend-filters-title',
            doc: 'reporter:editor-field.dividend-filters-doc',
            type: 'filters',
        },
        // divisorFilters are not editable currently
        {
            id: 'filters',
            title: 'reporter:editor-field.filters-title',
            doc: 'reporter:editor-field.filters-doc',
            type: 'filters',
        },
    ],
    getDefaultParams: function(reportParams, reportType) {
        const result = {
            table: 'http_proxy',
            categories: [ 'network.client' ],
            metrics: [ 'event.events' ],
            filters: filterContainerManipulator.createNewFilterContainer(),
            dividendFilters: filterContainerManipulator.createNewFilterContainer(),
            divisorFilters: filterContainerManipulator.createNewFilterContainer(),
            minRatio: 0.6,
            orderByRatioIsAsc: false,
        };
        if (reportType && reportParams) {
            setAttribute(result, 'table', reportParams);
            setArray(result, 'categories', reportType.getCategoryCols(reportParams));
            setArray(result, 'metrics', reportType.getMetricCols(reportParams));
            setAttribute(result, 'filters', reportParams);
        }
        return result;
    },
    generateCfg: function(customFilterStorage, reporterTemplates, params, filters) {
        return {
            template: 'action_request_ratio',
            table: params.table,
            database: params.database,
            categories: params.categories,
            metrics: params.metrics,
            orderBy: params.metrics.map(function(colName) {
                //return '"' + colName + '.ratio" desc';
                return '"' + colName + '.ratio" ' + (params.orderByRatioIsAsc ? 'asc' : 'desc');
            }),
            filters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                filterContainerManipulator.concatenateFilterContainers(
                    params.filters, filters
                )
            ),
            dividendFilters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                params.dividendFilters
            ),
            divisorFilters: filterContainerManipulator.getReporterParams(
                customFilterStorage,
                reporterTemplates,
                params.divisorFilters
            ),
            minRatio: params.minRatio,
        };
    },
    getTable: function(params) {
        return params.table;
    },
    reporterTemplate: 'action_request_ratio',
    template: 'universal'
});

/**
 * Service that defines possible report types. These are not to be confused
 * with reporter templates. Report type is a GUI thing that defines how to
 * display editors. Report template is a Reporter thing that defines how to
 * obtain data from the database.
 *
 * @name reportTypes
 */
const reportTypes = {
    actionRequestRatio: actionRequestRatio,
    drilldown: drilldown,
    heatmap: heatmap,
    hitparada: hitparada,
    universal: universal,
    userHttpActivity: userHttpActivity,
};

export default reportTypes;
